import { MainPageApiResponse } from '../../../../api/fca/fight-event/response/main-page-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { MainPageModelData } from './main-page.model';

export class MainPageMapper implements ModelDataMapper<MainPageApiResponse, MainPageModelData> {
    mapData(raw: MainPageApiResponse): MainPageModelData {
        return {
            category: raw.category,
            translates: raw.translates,
            typeId: raw.typeId || '',
        };
    }
}
