import { ActiveYookassaSubscriptionResponse } from '../../../../api/fca/payments/response/active-yookassa-subscription.response';
import { PaymentSystemEnum } from '../../../shared/enums/payment-system.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { ActiveSubscriptionModelData } from './active-subscription.model';

export class ActiveYookassaSubscriptionMapper
    implements ModelDataMapper<ActiveYookassaSubscriptionResponse, ActiveSubscriptionModelData>
{
    mapData(raw: ActiveYookassaSubscriptionResponse): ActiveSubscriptionModelData {
        return {
            subscriptionId: raw.subscription.id,
            interval: raw.subscription.interval,
            intervalCount: raw.intervalCount,
            currency: raw.amount.currency,
            startDate: new Date(raw.subscription.createdAt).getTime(),
            endDate: new Date(raw.endDate).getTime(),
            canceledAt: raw.canceledAt,
            cancellationReason: raw.cancellationReason,
            paymentSystem: PaymentSystemEnum.YOOKASSA,
        } as ActiveSubscriptionModelData;
    }
}
