import { Pipe, PipeTransform } from '@angular/core';
import { currencySymbolConfig } from '../../config/currency-symbol.config';
import { CurrencyEnum } from '../enums/currency.enum';

@Pipe({
    name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
    transform(value: CurrencyEnum, ...args: unknown[]): string {
        return currencySymbolConfig[value];
    }
}
