import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NewCommentParams } from '../../../../api/fca/bloods-video/params/new-comment.params';
import { FcaStoreService } from '../../../store/store.service';
import { AuthData } from '../../interfaces/auth-data.interface';
import { CommentReactionModel } from '../../models/shared/comment-reaction.model';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
    currentUser$: Observable<AuthData | null>;
    @Input() comments: any;
    @Input() loading = false;
    @Input() newCommentOnTop = true;
    @Output() onSend = new EventEmitter<NewCommentParams>();
    @Output() onScroll = new EventEmitter<boolean>();
    @Output() onLikeAction = new EventEmitter<CommentReactionModel>();

    constructor(private storeService: FcaStoreService) {
        this.currentUser$ = this.storeService.getAuthData();
    }

    ngOnInit(): void {}

    onSendComment(event: NewCommentParams) {
        this.onSend.emit(event);
    }

    onScrollToEndData() {
        this.onScroll.emit(true);
    }

}
