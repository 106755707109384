import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, Subject, switchMap, take, tap } from 'rxjs';
import { AppMainConfigService } from '../../../app/config/app-main-config.service';
import { BaseSocialService } from '../base-social.service';
import { SocialUser } from '../interfaces/social-user.interface';

declare let google: any;

export interface GoogleSignInResponse {
    accessToken: string;
    email: string;
    firstName: string;
    lastName: string;
}

interface GoogleOneTapLogInResponse {
    credential: string;
}

interface GoogleLogInResponse {
    access_token: string;
}

interface GetUserInfoResponse {
    email: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    verified_email: boolean;
}

@Injectable()
export class GoogleApiService extends BaseSocialService {
    private readonly PROVIDER_ID = 'google';
    private accessToken$ = new Subject<string | null>();
    private _client: any;

    constructor(protected readonly appConfigService: AppMainConfigService, private _http: HttpClient) {
        super(appConfigService);
        // One tap Login
        // google.accounts.id.initialize({
        //     client_id: '1000866745065-csoe6lbgr3065o4nfhihhmhiaen94n7i.apps.googleusercontent.com',
        //     scope: 'https://www.googleapis.com/auth/userinfo.email',
        //     callback: (resp: GoogleLogInResponse) => {
        //         const socialUser = this.createSocialUser(resp.credential);
        //         this.user$.next(socialUser);
        //     },
        // });
        // google.accounts.id.prompt();

        this.loadScript(this.PROVIDER_ID, 'https://accounts.google.com/gsi/client', () => {
            this._client = google.accounts.oauth2.initTokenClient({
                client_id: this.appConfigService.getConfig().google.clientId,
                scope: 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (tokenResponse: GoogleLogInResponse) => {
                    this.accessToken$.next(tokenResponse.access_token);
                },
                error_callback: (err: any) => {
                    this.accessToken$.next(null);
                },
            });
        });
    }

    signIn(): Observable<SocialUser | null> {
        this._client.requestAccessToken();

        return this.accessToken$.asObservable().pipe(
            switchMap((accessToken: string | null) => {
                return accessToken
                    ? this.getUserInfo(accessToken).pipe(
                          take(1),
                          map((userInfo: GetUserInfoResponse) => ({
                              name: userInfo.name,
                              email: userInfo.email,
                              photoUrl: userInfo.picture,
                              firstName: userInfo.given_name,
                              lastName: userInfo.family_name,
                              provider: this.PROVIDER_ID,
                              token: accessToken,
                          }))
                      )
                    : of(null);
            })
        );
    }

    signOut(): void {
        google.accounts.id.disableAutoSelect();
        this.accessToken$.next(null);
    }

    private getUserInfo(accessToken: string): Observable<GetUserInfoResponse> {
        return this._http
            .get<GetUserInfoResponse>(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${accessToken}`)
            .pipe(tap((resp) => {}));
    }
}
