import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * Сервис определения инициатора выполнения кода server/browser
 */
@Injectable()
export class PlatformService {
    private readonly isPlatformBrowser: boolean;

    /**
     *
     * @param {string} platformId
     */
    constructor(@Inject(PLATFORM_ID) platformId: string) {
        this.isPlatformBrowser = isPlatformBrowser(platformId);
    }

    /**
     * Возвращает платформу выполения кода
     *
     * @returns {boolean}
     */
    public isBrowser() {
        return this.isPlatformBrowser;
    }
}
