import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { StripeCoinsPaymentCheckoutParams } from './params/stripe-coins-payment-checkout.params';
import { YookassaCoinsPaymentCheckoutParams } from './params/yookassa-coins-payment-checkout.params';
import { CoinsListItemApiResponse } from './response/coins-list-item-api.response';

@Injectable({
    providedIn: 'root',
})
export class SpartaCoinsApiService extends FcaApiServiceBase {
    loadBalance(): Observable<number> {
        const url = [this.apiUrl, 'coins-payments/balance'].join('/');
        return this.http.get<number>(url);
    }
    loadHistory(): Observable<any> {
        const url = [this.apiUrl, 'coins-payments/history'].join('/');
        return this.http.get<any>(url);
    }
    purchase(eventId: string): Observable<void> {
        const url = [this.apiUrl, 'coins-payments/purchase'].join('/');
        return this.http.post<void>(url, { eventId });
    }
    loadCoinsList(): Observable<CoinsListItemApiResponse[]> {
        const url = [this.apiUrl, 'coins-payments/coins-list'].join('/');
        return this.http.get<CoinsListItemApiResponse[]>(url);
    }
    stripeCoinsPaymentCheckout(body: StripeCoinsPaymentCheckoutParams): Observable<string> {
        const url = [this.apiUrl, 'coins-payments/stripe-coins-payment-checkout'].join('/');
        // @ts-ignore
        return this.http.post<string>(url, body, { responseType: 'text' });
    }
    yookassaCoinsPaymentCheckout(body: YookassaCoinsPaymentCheckoutParams): Observable<string> {
        const url = [this.apiUrl, 'coins-payments/yookassa-coins-payment-checkout'].join('/');
        // @ts-ignore
        return this.http.post<string>(url, body, { responseType: 'text' });
    }
}
