import { EEventType, EFightRequirements, EProviding } from './enums/fight-event-additional.enum';

export interface FightEventAdditionalModelData {
    description?: string;
    type: EEventType;
    fightRequirements?: EFightRequirements[];
    providing?: EProviding[];
}

export interface FightEventAdditionalModel extends FightEventAdditionalModelData {}

export class FightEventAdditionalModel {}
