<ng-contaier *transloco='let t'>
  <div
    class="select-wrapper"
    [class.open]="isOpen && optionList.length"
    (clickOutside)="isOpen = false"
    (click)="searchInput.focus()"
  >
    <div class="select-inner" [class.invalid]="invalid && !isOpen" (click)="isOpen = !isOpen">
      <ng-container *ngIf="selectedTagsList.length">
        <ng-container *ngFor="let item of selectedTagsList">
          <div class="selected-item flex align-items-center">
            <p class="text">{{ item.text }}</p>
            <button (click)="deleteItem(item.value)" class="delete-item-btn center">
              <img src="assets/images/close-popup.svg" alt="" />
            </button>
          </div>
        </ng-container>
      </ng-container>

      <input
        type="text"
        #searchInput
        class="search-input"
        [placeholder]="t(placeholder)"
        (input)="searchInputChange($event)"
      />
    </div>

    <div class="select-option-container">
      <div class="select-option-list-wrapper">
        <ul class="select-option-list scroll-bar">
          <ng-container *ngFor="let option of optionList">
            <li (click)="selectOption(option)" [class.active]="checkIsSelected(option)">
              {{ option.text }}
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-contaier>
