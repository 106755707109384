import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EFightEventReactionType, FightEventReaction } from '../../models/fight-event/fight-event.model';

@Injectable({
    providedIn: 'root',
})
export class LiveEventReactionService {
    reactionAction$: Subject<EFightEventReactionType> = new Subject<EFightEventReactionType>();
    reactionList$: Subject<FightEventReaction[]> = new Subject<FightEventReaction[]>();

    constructor() {}

    emitAction(event: EFightEventReactionType) {
        this.reactionAction$.next(event);
    }

    updateReactionList(reactions: FightEventReaction[]) {
        this.reactionList$.next(reactions);
    }
}
