import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FcaAuthApiService } from '../../api/fca/auth/fca-auth-api.service';
import { PatchUserDataParams } from '../../api/fca/auth/params/patch-user-data.params';
import { UserResponse } from '../../api/fca/auth/response/user.response';
import { ProfilePublicListParams } from '../../api/fca/profiles-public/params/profile-public-list.params';
import { ProfilesPublicApiService } from '../../api/fca/profiles-public/profiles-public-api.service';
import { ProfileByIdResponse } from '../../api/fca/profiles-public/response/profile-by-id.response';
import { ProfilePublicListItemResponse } from '../../api/fca/profiles-public/response/profile-public-list-item.response';
import { GetProfilesPublicFactory } from '../models/profiles/get-profiles-public/get-profiles-public.factory';
import { GetProfilesPublicMapper } from '../models/profiles/get-profiles-public/get-profiles-public.mapper';
import { GetProfilesPublicModel } from '../models/profiles/get-profiles-public/get-profiles-public.model';
import { AuthUserFactory } from '../models/users/auth-user/auth-user.factory';
import { AuthUserMapper } from '../models/users/auth-user/auth-user.mapper';

@Injectable({
    providedIn: 'root',
})
export class ProfilesPublicService {
    constructor(
        private profilesPublicApiService: ProfilesPublicApiService,
        private fcaAuthApiService: FcaAuthApiService
    ) {}

    getProfilePublic(id: number): Observable<GetProfilesPublicModel> {
        return this.profilesPublicApiService.getProfileInfoPublicById(id).pipe(
            map((data: ProfileByIdResponse) => {
                return new GetProfilesPublicFactory().getModelFromData(new GetProfilesPublicMapper().mapData(data));
            })
        );
    }

    patchUserInfo(params: PatchUserDataParams) {
        const body: any = {};
        Object.keys(params).map((key: string) => {
            // @ts-ignore
            if (params[key] !== '' && params[key] !== null) {
                // @ts-ignore
                body[key] = params[key];
            }
        });
        return this.fcaAuthApiService
            .patchUserInfo(body)
            .pipe(
                map((raw: UserResponse) => new AuthUserFactory().getModelFromData(new AuthUserMapper().mapData(raw)))
            );
    }

    getProfilePublicList(body: ProfilePublicListParams): Observable<ProfilePublicListItemResponse[]> {
        return this.profilesPublicApiService.getProfilePublicList(body);
    }
}
