<div class="w-100 content shared-popup" *transloco="let t">
    <div *ngIf="data?.imageSrc" class="image-wrapper center">
        <img [src]="data?.imageSrc" alt="" />
    </div>
    <p class="title" *ngIf="data?.title" [innerHTML]="t(data?.title)"></p>
    <p class="subtitle" *ngIf="data?.text">{{ t(data?.text) }}</p>
    <button class="submit-btn" (click)='dialogRef.close()' *ngIf="data?.btnText">
        {{ t(data?.btnText) }}
    </button>
</div>
