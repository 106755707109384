<label
    class="video-file-label flex flex-column"
    [class.file-selected]="file"
    [class.invalid]="invalid"
    *transloco="let t"
>
    <span class="file-content flex align-items-center">
        <input #fileInput (change)="videoFileInputChangeHandler($event)" type="file" hidden [disabled]='file' [accept]="accept" />
        <span class="w-100 flex align-items-center">
            <span class="video-file-inner flex align-items-center justify-content__center">
                <img src="assets/images/upload-video.svg" alt="" />
                <ng-container *ngIf="!file">
                    <span>{{ t(placeholder) }}</span>
                </ng-container>
            </span>
            <span class="file-description">
                <ng-container *ngIf="file">
                    <span class="file-name">{{ file?.name }}</span>
                    <span class="file-size">{{ file?.size | fileSize }}</span>
                </ng-container>
            </span>
        </span>
        <button class="delete-btn" (click)="deleteVideo($event)" *ngIf="file">
            <img src="assets/images/close-popup.svg" alt="" />
        </button>
    </span>
    <ng-container *ngIf="file && !invalid && showProgressBar">
        <div
            class="progress-data-wrapper"
            *ngIf="uploadFileProgressService.getProgressPercent() | async as progressPercent"
        >
            <app-progress-bar [percent]="progressPercent"></app-progress-bar>
        </div>
    </ng-container>
</label>
