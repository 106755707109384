import { Injectable } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';
import { FcaFightEventsApiService } from '../../api/fca/fight-event/fca-fight-events-api.service';
import { FightEventsPreview } from '../../api/fca/fight-event/params/fight-events-preview';
import { ReactionType } from '../../api/fca/fight-event/response/fight-event-api.response';
import { MainPageApiResponse } from '../../api/fca/fight-event/response/main-page-api.response';
import { TvEventResponse } from '../../api/fca/fight-event/response/tv-event.response';
import { FightEventLightFactory } from '../models/fight-event/fight-event-light/fight-event-light.factory';
import { FightEventLightMapper } from '../models/fight-event/fight-event-light/fight-event-light.mapper';
import { FightEventLightModel } from '../models/fight-event/fight-event-light/fight-event-light.model';
import { FightEventTvFactory } from '../models/fight-event/fight-event-tv/fight-event-tv.factory';
import { FightEventTvMapper } from '../models/fight-event/fight-event-tv/fight-event-tv.mapper';
import { TvEventModelData } from '../models/fight-event/fight-event-tv/fight-event-tv.model';
import { FightEventFactory } from '../models/fight-event/fight-event.factory';
import { FightEventMapper } from '../models/fight-event/fight-event.mapper';
import { EFightEventStatus, FightEventModel } from '../models/fight-event/fight-event.model';
import { MainPageFactory } from '../models/fight-event/main-page/main-page.factory';
import { MainPageMapper } from '../models/fight-event/main-page/main-page.mapper';
import { MainPageModel } from '../models/fight-event/main-page/main-page.model';
import { OffsetPagination } from '../shared/interfaces/offset-pagination.interface';
import { FcaStoreService } from '../store/store.service';

@Injectable()
export class FcaFightEventsService {
    constructor(
        private readonly fightEventsService: FcaFightEventsApiService,
        private readonly storeService: FcaStoreService
    ) {}

    getFightEventsForMainPage(params: FightEventsPreview): Observable<FightEventLightModel[]> {
        return this.fightEventsService.mainPageFightEvents(params).pipe(
            map((rawData) =>
                rawData.reduce<FightEventLightModel[]>((acc, raw) => {
                    acc.push(new FightEventLightFactory().getModelFromData(new FightEventLightMapper().mapData(raw)));

                    return acc;
                }, [])
            )
        );
    }

    getFightEventsLightList(status: EFightEventStatus[], offset: OffsetPagination): Observable<FightEventLightModel[]> {
        return this.fightEventsService
            .list({
                ...offset,
                status,
            })
            .pipe(
                map((rawData) =>
                    rawData.reduce<FightEventLightModel[]>((acc, raw) => {
                        acc.push(
                            new FightEventLightFactory().getModelFromData(new FightEventLightMapper().mapData(raw))
                        );

                        return acc;
                    }, [])
                )
            );
    }

    getPpvFightEventsLightList(params: FightEventsPreview): Observable<FightEventLightModel[]> {
        return this.fightEventsService.getPpvList(params).pipe(
            map((rawData) =>
                rawData.reduce<FightEventLightModel[]>((acc, raw) => {
                    acc.push(new FightEventLightFactory().getModelFromData(new FightEventLightMapper().mapData(raw)));
                    return acc;
                }, [])
            )
        );
    }

    getFightEventFull(id: string): Observable<FightEventModel> {
        return this.fightEventsService
            .getFightEventDetail(id)
            .pipe(map((raw) => new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw))));
    }

    getFightEventWebFull(id: string): Observable<FightEventModel> {
        return this.fightEventsService
            .getFightEventDetailWeb(id)
            .pipe(map((raw) => new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw))));
    }

    putReaction(fightEventId: string, reaction: ReactionType): Observable<any> {
        return this.storeService.getUser().pipe(
            filter(Boolean),
            switchMap((user) => {
                return this.fightEventsService.putReaction({ fightEventId, userId: user.id, reaction });
            })
        );
    }

    getTvList(): Observable<TvEventModelData[]> {
        return this.fightEventsService.getTvList().pipe(
            map((rawData) =>
                rawData.reduce<TvEventResponse[]>((acc, raw) => {
                    acc.push(new FightEventTvFactory().getModelFromData(new FightEventTvMapper().mapData(raw)));
                    return acc;
                }, [])
            )
        );
    }

    getMainPage(): Observable<MainPageModel[]> {
        return this.fightEventsService.getMainPage().pipe(
            map((data: MainPageApiResponse[]) => {
                return data.reduce<MainPageApiResponse[]>((acc, raw) => {
                    acc.push(new MainPageFactory().getModelFromData(new MainPageMapper().mapData(raw)));
                    return acc;
                }, []);
            })
        );
    }
}
