import { PostContentAccessEnum } from '../../../shared/enums/post-content-access.enum';

export interface PostListModelData {
    id: string;
    text: string;
    commentsEnabled: false;
    access: PostContentAccessEnum;
    state: 'published';
    subscribed: false;
    likes: number;
    ownerId: number;
    liked: false;
    images: {
        id: string;
        position: number;
        name: string;
        url: string;
    }[];
    createdAt: string;
    updatedAt: string;
    owner: {
        id: number;
        firstName: string;
        lastName: string;
        thumbnailAvatar: string;
        backgroundImg: string;
        nickname: string;
        followers: number;
        posts: number;
        videos: number;
        views: number;
    };
    likeUsers: {
        id: number;
        avatar: string;
        firstName: string;
        lastName: string;
        thumbnailAvatar: string;
        nickname: string;
    }[];
}
export interface PostListModel extends PostListModelData {}

export class PostListModel {}
