import * as moment from 'moment-timezone';
import { Language } from '../../../shared/enums/language.enum';
import { ImageModel } from '../../../shared/models/image/image.model';
import { FightEventAdditionalModel } from '../fight-event-additional/fight-event-additional.model';
import { FightEventConferenceModel } from '../fight-event-conference/fight-event-conference.model';
import { FightEventFightSlotLightModel } from '../fight-event-fight-slot/fight-event-fight-slot-light/fight-event-fight-slot-light.model';
import { FightEventLocationLightModel } from '../fight-event-location/fight-event-location-light/fight-event-location-light.model';
import { FightEventPlaylistModel } from '../fight-event-playlist/fight-event-playlist.model';
import { FightEventVideoModel } from '../fight-event-video/fight-event-video.model';
import { FightEventWeightingModel } from '../fight-event-weighting/fight-event-weighting.model';
import { EFightEventReactionType, EFightEventStatus } from '../fight-event.model';

export type FightEventReactions = { [key in EFightEventReactionType]: number };

export interface FightEventLightModelData {
    id: string;
    name: string;
    video: FightEventVideoModel;
    fromDate: moment.Moment;
    honorarium: number;
    toDate: moment.Moment;
    hidden: boolean;
    ppv: boolean;
    location: FightEventLocationLightModel;
    fightSlots: FightEventFightSlotLightModel[];
    status: EFightEventStatus;
    images: ImageModel[];
    reactions: FightEventReactions;
    votingAvailable: boolean;
    weighting?: FightEventWeightingModel | null;
    conference?: FightEventConferenceModel | null;
    additional: FightEventAdditionalModel | null;
}

export interface FightEventLightModel extends FightEventLightModelData {}

export class FightEventLightModel {
    get isLive(): boolean {
        return this.status === EFightEventStatus.ONGOING;
    }

    get datePreview(): string {
        return this.fromDate.format('DD MMM yyyy HH:mm');
    }

    get topReaction(): { view: EFightEventReactionType; count: number } {
        const entries = Object.entries(this.reactions);
        let max = entries[0];

        for (let i = 1; i < entries.length; i++) {
            if (max[1] < entries[i][1]) {
                max = entries[i];
            }
        }
        return { view: max[0] as EFightEventReactionType, count: max[1] };
    }

    get reactionsCount() {
        return Object.values(this.reactions).reduce((a, b) => a + b);
    }

    get cardImagePath() {
        return this.images?.[0]?.href || this.video?.thumbnail || '';
    }

    get videoDuration() {
        return moment.duration(this.fromDate.diff(this.toDate));
    }

    get statusLabel() {
        switch (this.status) {
            case EFightEventStatus.FINISHED:
                return 'shared-status-label-finished';
            case EFightEventStatus.UPCOMING:
                return 'shared-status-label-upcoming';
            case EFightEventStatus.ONGOING:
                return 'shared-status-label-ongoing';
        }
        return '';
    }

    get getPlaylistData(): FightEventPlaylistModel[] {
        const playList: FightEventPlaylistModel[] = [];
        if (this.weighting) {
            const weightingItem: FightEventPlaylistModel = {
                label: 'Weighting',
                image: this.images[0]?.href || this.weighting?.video?.thumbnail || '',
                fromDate: this.weighting?.fromDate,
                toDate: this.weighting?.toDate,
                title: this.name,
                description: this.additional?.description || '',
                id: this.weighting?.video?.id,
                video: this.weighting?.video,
                status: this.weighting?.status,
                statusByDate:
                    new Date(this.weighting?.fromDate) > new Date() &&
                    this.weighting?.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.weighting?.status,
            };
            playList.push(weightingItem);
        }
        if (this.conference) {
            const conferenceItem: FightEventPlaylistModel = {
                label: 'Conference',
                fromDate: this.conference?.fromDate,
                toDate: this.conference?.toDate,
                image: this.images[0]?.href || this.conference?.video?.thumbnail || '',
                title: this.name,
                description: this.additional?.description || '',
                id: this.conference?.video?.id,
                video: this.conference?.video,
                status: this.conference?.status,
                statusByDate:
                    new Date(this.conference?.fromDate) > new Date() &&
                    this.conference?.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.conference?.status,
            };
            playList.push(conferenceItem);
        }
        if (playList.length) {
            const fight: FightEventPlaylistModel = {
                label: 'Fight',
                image: this.images[0]?.href || this.video?.thumbnail || '',
                title: this.name,
                description: this.additional?.description || '',
                id: this.video?.id,
                video: this.video,
                fromDate: this.fromDate.toString(),
                toDate: this.toDate.toString(),
                status: this.status,
                statusByDate:
                    new Date(this.fromDate.toString()) > new Date() && this.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.status,
            };
            playList.push(fight);
        }
        return playList;
    }

    get languageForCard() {
        return (
            this.video.languages?.find((lng: Language) => lng.toLowerCase() === Language.PT.toLowerCase()) ||
            this.video.languages?.find((lng: Language) => lng.toLowerCase() === Language.EN.toLowerCase()) ||
            null
        );
    }
}
