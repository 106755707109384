<div class="popup-wrapper" *transloco="let t">
    <button class="close-btn center" (click)="dialogRef.close(false)">
        <img src="assets/images/close-popup.svg" alt="" />
    </button>
    <p class="title">{{ t('profile-page-become-content-creator-popup-title') }}</p>
    <textarea
        name="question"
        required
        [minlength]="3"
        [maxlength]="500"
        class="main-input text-textarea"
        #questionTemplate="ngModel"
        [(ngModel)]="text"
    ></textarea>
    <div class="action-btn-list">
        <button [disabled]="questionTemplate.invalid" (click)="submit()" class="main-btn">
            {{ t('shared-submit-button') }}
        </button>
    </div>
</div>
