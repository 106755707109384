export enum EUserState {
    EXISTS = 'EXIST',
    DELETED = 'DELETED',
    NOT_FOUND = 'NOT_FOUND',
}

export interface CheckUserResponse {
    userExists: boolean;
    registrationToken: string;
    userState: EUserState;
}
