<div class='comment flex flex-row' *transloco='let t'>
  <div class='comment-author-avatar center'
       [style.background-image]="avatarUrl | backgroundImageUrl : '/assets/images/avatar_placeholder.svg'"
  >
  </div>
  <div class='comment-data'>
    <p class='comment-meta'>{{ userName }}</p>
    <p class='comment-data__text'>{{ data.text }}</p>
    <div class='flex align-items-center'>
      <p class='comment-date'>{{ data.created | date : 'dd MMM yyyy HH:mm'}}</p>
      <div *ngIf='!isInner && replyHidden' class='comment-reactions-reply' (click)='replyHidden = false'>
        {{t('dashboard-event-comments-reply-btn')}}
      </div>
    </div>
    <div *ngIf='!replyHidden' class='replay-event-wrapper flex align-items-center'>
      <app-new-comment (onSend)='onSendReply($event)'></app-new-comment>
      <button class='cancel-replay' (click)='replyHidden = true'>
        {{t('dashboard-event-comments-cancel-btn')}}
      </button>
    </div>
    <div class='comment-data__actions'>
      <div class='comment-data__reactions'>
        <app-comment-reaction
          *ngIf='currentUserId !== data?.user?.id'
          [comment]='data'
          [likes]='data.likes'
          [dislikes]='data.dislikes'
          [hasLike]='data.userHasLike'
          [hasDislike]='data.userHasDislike'
          [replyAvailable]='!isInner'
        ></app-comment-reaction>
      </div>
    </div>
    <cdk-accordion class='accordion comment' *ngIf='data.children.length'>
      <cdk-accordion-item
        #commentThread='cdkAccordionItem'
        role='button'
        [attr.id]="'comments-header-'+data.id"
        [attr.aria-expanded]='commentThread.expanded'
        [attr.aria-controls]="'comments-body-'+data.id">
        <div class='comments-item flex flex-column'>
          <div class='comments-item-header' [class.expanded]='commentThread.expanded' (click)='commentThread.toggle()'>
            {{t('dashboard-event-comments-see-reply')}}({{data?.children?.length}})
          </div>
          <div
            class='comments-item-body' [class.clear-padding]='!commentThread.expanded'
            role='region'>
            <div [style.display]="commentThread.expanded ? '' : 'none'">
              <app-comment-item
                [isInner]='true'
                [data]='child'
                [currentUserId]='currentUserId'
                *ngFor='let child of data.children'
              ></app-comment-item>
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
