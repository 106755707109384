/**
 * Базовый класс логера.
 * Испльзуется как InjectionToken в DI
 */
export abstract class AppLogger {
    /**
     * Error уровень ошибки
     *
     * @param mgs текст сообщения
     * @param meta объект доп данных для логирования
     * @param req входящий запрос для логирования
     * @param res ответ для логирования
     */
    abstract error(mgs: string, meta?: { [name: string]: any }, req?: any, res?: any): void;

    /**
     * Info уровень ошибки
     *
     * @param mgs текст сообщения
     * @param meta объект доп данных для логирования
     * @param req входящий запрос для логирования
     * @param res ответ для логирования
     */
    abstract log(mgs: string, meta?: { [name: string]: any }, req?: any, res?: any): void;

    /**
     * Логирует объект Error
     *
     * @param e ошибка
     * @param meta объект доп данных для логирования
     * @param req входящий запрос для логирования
     * @param res ответ для логирования
     */
    abstract logError(e: Error, meta?: { [name: string]: any }, req?: any, res?: any): void;
}
