import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { map, Observable, shareReplay } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MainPageMenuPaths } from '../app.component';
import { FightEventPlaylistModel } from '../models/fight-event/fight-event-playlist/fight-event-playlist.model';
import { EventDataBeforePayment } from '../models/ppv-payment/event-data-before-payment/event-data-before-payment';
import { ActiveSubscriptionModel } from '../models/subscription/active-subscription/active-subscription.model';
import { AuthUserFactory } from '../models/users/auth-user/auth-user.factory';
import { AuthUserModel } from '../models/users/auth-user/auth-user.model';
import { Language } from '../shared/enums/language.enum';
import { PermissionEnum } from '../shared/enums/permission.enum';
import { AuthData } from '../shared/interfaces/auth-data.interface';
import { SideBarStateModel } from '../shared/models/shared/side-bar-state.model';
import { getCurrentItemFromPlaylist, getEventDataBeforePayment } from './selectors';
import * as FcaSelectors from './selectors';
import { FcaState } from './state';

@Injectable({ providedIn: 'root' })
export class FcaStoreService {
    constructor(private store: Store<FcaState>) {}

    getAuthData(): Observable<AuthData | null> {
        return this.store.pipe(
            select(FcaSelectors.getAuthData),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getCurrentFightEventId(): Observable<string | null> {
        return this.store.pipe(
            select(FcaSelectors.getCurrentFightEventId),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getActiveMenuItemMainPage(): Observable<MainPageMenuPaths> {
        return this.store.pipe(
            select(FcaSelectors.getActiveMenuItemForMainPage),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getUser(): Observable<AuthUserModel | null> {
        return this.store.pipe(
            select(FcaSelectors.getUser),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            map((u) => (u ? new AuthUserFactory().getModelFromData(u) : null)),
            shareReplay(1)
        );
    }

    getLanguage(): Observable<Language> {
        return this.store.pipe(
            select(FcaSelectors.getLanguage),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getDefaultVideoVolume() : Observable<number> {
      return this.store.pipe(
        select(FcaSelectors.getVolume),
        // @ts-ignore
        distinctUntilChanged(null, (val) => JSON.stringify(val)),
        shareReplay(1)
      )
    }

    getSubscriptionData(): Observable<ActiveSubscriptionModel | null> {
        return this.store.pipe(
            select(FcaSelectors.getSubscriptionData),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getLastEventVideoWatchTime(): Observable<string | null> {
        return this.store.pipe(
            select(FcaSelectors.getLastEventVideoWatchTime),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getCurrentItemFromPlaylist(): Observable<FightEventPlaylistModel | null> {
        return this.store.pipe(
            select(FcaSelectors.getCurrentItemFromPlaylist),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getEventDataBeforePayment(): Observable<EventDataBeforePayment | null> {
        return this.store.pipe(
            select(FcaSelectors.getEventDataBeforePayment),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getSpartaCoinsBalance(): Observable<number | null> {
        return this.store.pipe(
            select(FcaSelectors.getSpartaCoinsBalance),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getUserPermissions(): Observable<PermissionEnum[] | null> {
        return this.store.pipe(
            select(FcaSelectors.getUserPermissions),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getSideBarState(): Observable<SideBarStateModel | null> {
        return this.store.pipe(
            select(FcaSelectors.getSideBarState),
            // @ts-ignore
            distinctUntilChanged(null, (val) => JSON.stringify(val)),
            shareReplay(1)
        );
    }

    getFCMToken(): Observable<string | null> {
      return this.store.pipe(
        select(FcaSelectors.getFCMToken),
        shareReplay(1)
      )
    }

    dispatch<V extends Action = Action>(action: V) {
        return this.store.dispatch(action as any);
    }
}
