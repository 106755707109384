import * as moment from 'moment-timezone';
import { FightEventFightSlotApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { FightFactory } from '../../fight/fight.factory';
import { FightMapper } from '../../fight/fight.mapper';
import { FightEventFightSlotModelData } from './fight-event-fight-slot.model';

export class FightEventFightSlotMapper
    implements ModelDataMapper<FightEventFightSlotApiResponse, FightEventFightSlotModelData>
{
    mapData(raw: FightEventFightSlotApiResponse): FightEventFightSlotModelData {
        return {
            id: raw.id,
            fromDate: moment(raw.fromDate),
            toDate: moment(raw.toDate),
            fight: new FightFactory().getModelFromData(new FightMapper().mapData(raw.fight)),
            locationId: raw.locationId || null,
            fightId: raw.fightId || null,
            isMainCard: raw.isMainCard,
        };
    }
}
