import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { defer, take, tap } from 'rxjs';
import { FollowersService } from '../../../services/followers.service';
import { UntilDestroy, untilDestroy } from '../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-content-creator-preview',
    templateUrl: './content-creator-preview.component.html',
    styleUrls: ['./content-creator-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCreatorPreviewComponent implements OnInit {
    @Input() isFollowed = false;
    @Input() avatarUrl = '';
    @Input() fullName = '';
    @Input() followers = '';
    @Input() userId = 0;
    @Input() loading = false;

    constructor(private followService: FollowersService, private _cdr: ChangeDetectorRef, private _router: Router) {}

    ngOnInit(): void {}

    followUnfollowUser($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        defer(() => {
            if (this.isFollowed) {
                return this.followService.unfollowUser(this.userId);
            } else {
                return this.followService.followUser(this.userId);
            }
        })
            .pipe(
                take(1),
                untilDestroy(this),
                tap((response: void) => {
                    this.isFollowed = !this.isFollowed;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    getFollowersRuVersion(followers: number) {
        const cases = [2, 0, 1, 1, 1, 2];
        const titles = ['shared-followers-label-ru-0', 'shared-followers-label-ru-1', 'shared-followers-label-ru-2'];
        return titles[followers % 100 > 4 && followers % 100 < 20 ? 2 : cases[Math.min(followers % 10, 5)]];
    }

    goToProfile() {
        this._router.navigate(['profile', this.userId]);
    }
}
