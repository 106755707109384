import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { ContactUsParams } from './params/contact-us-params';

@Injectable({
    providedIn: 'root',
})
export class AppApiService extends FcaApiServiceBase {
    postComment(params: ContactUsParams): Observable<void> {
        const url = [this.apiUrl, 'contact-us'].join('/');
        return this.http.post<void>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
}
