import * as moment from 'moment-timezone';
import { FightEventLightApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { ImageFactory } from '../../../shared/models/image/image.factory';
import { ImageMapper } from '../../../shared/models/image/image.mapper';
import { ImageModel } from '../../../shared/models/image/image.model';
import { FightEventAdditionalFactory } from '../fight-event-additional/fight-event-additional.factory';
import { FightEventAdditionalMapper } from '../fight-event-additional/fight-event-additional.mapper';
import { FightEventConferenceFactory } from '../fight-event-conference/fight-event-conference.factory';
import { FightEventConferenceMapper } from '../fight-event-conference/fight-event-conference.mapper';
import { FightEventFightSlotLightModel } from '../fight-event-fight-slot/fight-event-fight-slot-light/fight-event-fight-slot-light.model';
import { FightEventFightSlotLightFactory } from '../fight-event-fight-slot/fight-event-fight-slot-light/fight-event-time-slot-light.factory';
import { FightEventFightSlotLightMapper } from '../fight-event-fight-slot/fight-event-fight-slot-light/fight-event-time-slot-light.mapper';
import { FightEventLocationLightFactory } from '../fight-event-location/fight-event-location-light/fight-event-location-light.factory';
import { FightEventLocationLightMapper } from '../fight-event-location/fight-event-location-light/fight-event-location-light.mapper';
import { FightEventVideoFactory } from '../fight-event-video/fight-event-video.factory';
import { FightEventVideoMapper } from '../fight-event-video/fight-event-video.mapper';
import { FightEventWeightingFactory } from '../fight-event-weighting/fight-event-weighting.factory';
import { FightEventWeightingMapper } from '../fight-event-weighting/fight-event-weighting.mapper';
import { EFightEventReactionType, EFightEventStatus } from '../fight-event.model';
import { FightEventLightModelData, FightEventReactions } from './fight-event-light.model';

export class FightEventLightMapper implements ModelDataMapper<FightEventLightApiResponse, FightEventLightModelData> {
    mapData(raw: FightEventLightApiResponse): FightEventLightModelData {
        return {
            id: raw.id,
            name: raw.name,
            video: new FightEventVideoFactory().getModelFromData(new FightEventVideoMapper().mapData(raw.video)),
            fromDate: moment(raw.fromDate),
            toDate: moment(raw.toDate),
            honorarium: raw.honorarium,
            hidden: raw.hidden,
            ppv: raw.ppv,
            location: new FightEventLocationLightFactory().getModelFromData(
                new FightEventLocationLightMapper().mapData(raw.location)
            ),
            fightSlots: (raw.fightSlots || []).reduce<FightEventFightSlotLightModel[]>((acc, rawFightSlot) => {
                acc.push(
                    new FightEventFightSlotLightFactory().getModelFromData(
                        new FightEventFightSlotLightMapper().mapData(rawFightSlot)
                    )
                );
                return acc;
            }, []),
            images: (raw.images || []).reduce<ImageModel[]>((acc, rawImg) => {
                acc.push(new ImageFactory().getModelFromData(new ImageMapper().mapData(rawImg)));
                return acc;
            }, []),
            status: raw.status as EFightEventStatus,
            reactions: raw.reactions
                ? (raw.reactions as FightEventReactions)
                : Object.values(EFightEventReactionType).reduce<FightEventReactions>((acc, reaction) => {
                      acc[reaction] = 0;
                      return acc;
                  }, {} as FightEventReactions),
            votingAvailable: raw.votingAvailable,
            weighting: raw.weighting
                ? new FightEventWeightingFactory().getModelFromData(
                      new FightEventWeightingMapper().mapData(raw.weighting)
                  )
                : null,
            conference: raw.weighting
                ? new FightEventConferenceFactory().getModelFromData(
                      new FightEventConferenceMapper().mapData(raw.weighting)
                  )
                : null,
            additional: raw.additional
                ? new FightEventAdditionalFactory().getModelFromData(
                      new FightEventAdditionalMapper().mapData(raw.additional)
                  )
                : null,
        };
    }
}
