import { UserFighterModel } from '../users/user-fighter/user-fighter.model';

export enum EFightStatus {
    CONFIRMED = 'CONFIRMED',
    PENDING = 'PENDING',
    FINISHED = 'FINISHED',
    UNPERFORMED = 'UNPERFORMED',
    CANCELED_BY_FIGHT = 'CANCELED_BY_FIGHT',
    CANCELED_BY_TARGET = 'CANCELED_BY_TARGET',
    CANCELED_BY_INITIATOR = 'CANCELED_BY_INITIATOR',
    CANCELED_BY_ARENA = 'CANCELED_BY_ARENA',
}

export enum EFightWinner {
    INITIATOR = 'INITIATOR',
    TARGET = 'TARGET',
    BOTH = 'BOTH',
}

export interface FightModelData {
    id: number;
    status: EFightStatus;
    winner: EFightWinner;
    initiator: UserFighterModel;
    target: UserFighterModel;
    fightLink: string | null;
}

export interface FightModel extends FightModelData {}

export class FightModel {}
