import { NgModule } from '@angular/core';
import { AppMainConfigService } from '../app/config/app-main-config.service';
import { FcaAuthApiService } from './fca/auth/fca-auth-api.service';
import { ChatService } from './fca/chat/chat.service';
import { FcaCommentsApiService } from './fca/comments/fca-comments-api.service';
import { FcaFightEventsApiService } from './fca/fight-event/fca-fight-events-api.service';
import { FcaImagesApiService } from './fca/images/fca-images-api.service';
import { AppleApiService } from './social/apple/apple-api.service';
import { FacebookApiService } from './social/facebook/facebook-api.service';
import { GoogleApiService } from './social/google/google-api.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        FcaAuthApiService,
        FcaImagesApiService,
        GoogleApiService,
        FacebookApiService,
        AppleApiService,
        FcaFightEventsApiService,
        FcaCommentsApiService,
        ChatService,
        AppMainConfigService,
    ],
})
export class ApiModule {}
