import { Language } from '../../shared/enums/language.enum';
import {
    Alternate,
    Canonical,
    MetaTags,
    PageCanonical,
    PageLinks,
    Pages,
    PagesAlternates,
} from './seo-config.interface';

export const APP_NAME_SEO = 'Spartacus MMA';
export const SPARTACUS_SPORT_SEO = 'Spartacus MMA';

export const PAGES_TITLES = {
    [PageLinks.Main]: {
        [Language.EN]: `${SPARTACUS_SPORT_SEO} - Streaming Platform for Combat Sports and More`,
        [Language.PT]: `${SPARTACUS_SPORT_SEO} - Plataforma de streaming para esportes de combate e muito mais`,
        [Language.RU]: `${SPARTACUS_SPORT_SEO}- Платформа потокового вещания для киберспортивных боев и многого другого`,
    },
    [PageLinks.ContactUs]: {
        [Language.EN]: `Contact Us - ${SPARTACUS_SPORT_SEO}`,
        [Language.PT]: `Fale conosco - ${SPARTACUS_SPORT_SEO}`,
        [Language.RU]: `Связаться с нами - ${SPARTACUS_SPORT_SEO}`,
    },
    [PageLinks.TermsOfUse]: {
        [Language.EN]: `Terms of Use - ${APP_NAME_SEO}`,
        [Language.PT]: `Termos de Uso - ${APP_NAME_SEO}`,
        [Language.RU]: `Условия эксплуатации -${APP_NAME_SEO}`,
    },
    [PageLinks.PrivacyPolicy]: {
        [Language.EN]: `Privacy Policy - ${SPARTACUS_SPORT_SEO}`,
        [Language.PT]: `Política de Privacidade - ${SPARTACUS_SPORT_SEO}`,
        [Language.RU]: `Политика конфиденциальности -${SPARTACUS_SPORT_SEO}`,
    },
    [PageLinks.Login]: {
        [Language.EN]: `Login - ${APP_NAME_SEO}`,
        [Language.PT]: `Conecte-se - ${APP_NAME_SEO}`,
        [Language.RU]: `Авторизоваться -${APP_NAME_SEO}`,
    },
    [PageLinks.LiveEvents]: {
        [Language.EN]: `Live Combat Sports Events | Stream Best Fights in MMA and more - ${SPARTACUS_SPORT_SEO}`,
        [Language.PT]: `Eventos de esportes de combate ao vivo | Transmita as melhores lutas no MMA e muito mais - ${SPARTACUS_SPORT_SEO}`,
        [Language.RU]: `Прямые трансляции спортивных единоборств | Смотрите лучшие бои ММА и многое другое —${SPARTACUS_SPORT_SEO}`,
    },
    [PageLinks.UpcomingEvents]: {
        [Language.EN]: `Upcoming Events: Watch Best Fighters - ${SPARTACUS_SPORT_SEO}`,
        [Language.PT]: `Próximos eventos: assista aos melhores lutadores - ${SPARTACUS_SPORT_SEO}`,
        [Language.RU]: `Ближайшие события: смотрите лучших бойцов -${SPARTACUS_SPORT_SEO}`,
    },
    [PageLinks.PastEvents]: {
        [Language.EN]: `Past Events: Watch the Best from MMA to Bare-Knuckle Fights - ${SPARTACUS_SPORT_SEO}`,
        [Language.PT]: `Eventos anteriores: assista ao melhor do MMA às lutas sem luvas - ${SPARTACUS_SPORT_SEO}`,
        [Language.RU]: `Предыдущие события: смотрите лучшее от ММА до боев на кулаках - ${SPARTACUS_SPORT_SEO}`,
    },
};

export const buildAlternateHref = (path: string, lang: Language): string => {
    return ['https://spartacus-mma.com', lang === Language.EN ? '' : lang, path].filter(Boolean).join('/');
};

export const DEFAULT_ROBOTS = 'index,follow';

export const PAGES_ALTERNATES: PagesAlternates = {
    [PageLinks.Main]: [
        {
            [Alternate.Href]: buildAlternateHref('', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.ContactUs]: [
        {
            [Alternate.Href]: buildAlternateHref('contacts', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('contacts', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('contacts', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.PrivacyPolicy]: [
        {
            [Alternate.Href]: buildAlternateHref('privacy-policy', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('privacy-policy', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('privacy-policy', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.TermsOfUse]: [
        {
            [Alternate.Href]: buildAlternateHref('terms-of-use', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('terms-of-use', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('terms-of-use', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.Login]: [
        {
            [Alternate.Href]: buildAlternateHref('login', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('login', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('login', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.LiveEvents]: [
        {
            [Alternate.Href]: buildAlternateHref('live-events', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('live-events', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('live-events', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.UpcomingEvents]: [
        {
            [Alternate.Href]: buildAlternateHref('upcoming-events', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('upcoming-events', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('upcoming-events', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
    [PageLinks.PastEvents]: [
        {
            [Alternate.Href]: buildAlternateHref('past-events', Language.EN),
            [Alternate.Hreflang]: 'en',
        },
        {
            [Alternate.Href]: buildAlternateHref('past-events', Language.PT),
            [Alternate.Hreflang]: 'pt',
        },
        {
            [Alternate.Href]: buildAlternateHref('past-events', Language.RU),
            [Alternate.Hreflang]: 'ru',
        },
    ],
};

export const buildCanonicalHref = (path: string): string => {
    return ['https://spartacus-mma.com', ':page-lang:', path].filter(Boolean).join('/');
};

export const PAGES_CANONICAL: PageCanonical = {
    [PageLinks.Main]: [
        {
            [Canonical.Href]: buildCanonicalHref(''),
        },
    ],
    [PageLinks.ContactUs]: [
        {
            [Canonical.Href]: buildCanonicalHref('contacts'),
        },
    ],
    [PageLinks.PrivacyPolicy]: [
        {
            [Canonical.Href]: buildCanonicalHref('privacy-policy'),
        },
    ],
    [PageLinks.Login]: [
        {
            [Canonical.Href]: buildCanonicalHref('login'),
        },
    ],
    [PageLinks.LiveEvents]: [
        {
            [Canonical.Href]: buildCanonicalHref('live-events'),
        },
    ],
    [PageLinks.UpcomingEvents]: [
        {
            [Canonical.Href]: buildCanonicalHref('upcoming-events'),
        },
    ],
    [PageLinks.PastEvents]: [
        {
            [Canonical.Href]: buildCanonicalHref('past-events'),
        },
    ],
};

export const PAGES: Pages = {
    [PageLinks.Main]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.Main][Language.EN],
                meta: {
                    [MetaTags.Description]:
                        'Experience a library of combat sports content, enjoy real-time MMA entertainment, become a knowledgeable member of the MMA community, and much more. Also available on Android and iOS',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.Main][Language.PT],
                meta: {
                    [MetaTags.Description]:
                        'Experimente uma biblioteca de conteúdo de esportes de combate, aproveite o entretenimento MMA em tempo real, torne-se um membro experiente da comunidade MMA e muito mais. Disponível também para Android e iOS',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.Main][Language.RU],
                meta: {
                    [MetaTags.Description]:
                        'Experimente uma biblioteca de conteúdo de esportes de combate, aproveite o entretenimento MMA em tempo real, torne-se um membro experiente da comunidade MMA e muito mais. Disponível também para Android e iOS',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.Main],
        canonical: PAGES_CANONICAL[PageLinks.Main],
    },
    [PageLinks.ContactUs]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.ContactUs][Language.EN],
                meta: {
                    [MetaTags.Description]:
                        'Got a question? Reach out to us for any queries, support, or feedback. Support team will quickly get back to you.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.ContactUs][Language.PT],
                meta: {
                    [MetaTags.Description]:
                        'Tem uma questão? Entre em contato conosco para qualquer dúvida, suporte ou feedback. A equipe de suporte entrará em contato rapidamente.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.ContactUs][Language.RU],
                meta: {
                    [MetaTags.Description]:
                        'Tem uma questão? Entre em contato conosco para qualquer dúvida, suporte ou feedback. A equipe de suporte entrará em contato rapidamente.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.ContactUs],
        canonical: PAGES_CANONICAL[PageLinks.ContactUs],
    },
    [PageLinks.TermsOfUse]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.TermsOfUse][Language.EN],
                meta: {
                    [MetaTags.Description]: `Read our terms of use`,
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.TermsOfUse][Language.PT],
                meta: {
                    [MetaTags.Description]: `Leia nossos termos de uso`,
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.TermsOfUse][Language.RU],
                meta: {
                    [MetaTags.Description]: `Leia nossos termos de uso`,
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.TermsOfUse],
        canonical: PAGES_CANONICAL[PageLinks.TermsOfUse],
    },
    [PageLinks.PrivacyPolicy]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.PrivacyPolicy][Language.EN],
                meta: {
                    [MetaTags.Description]: 'Privacy Policy of Spartacus Sports',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.PrivacyPolicy][Language.PT],
                meta: {
                    [MetaTags.Description]: 'Política de Privacidade da Spartacus Sports',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.PrivacyPolicy][Language.RU],
                meta: {
                    [MetaTags.Description]: 'Política de Privacidade da Spartacus Sports',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.PrivacyPolicy],
        canonical: PAGES_CANONICAL[PageLinks.PrivacyPolicy],
    },
    [PageLinks.Login]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.Login][Language.EN],
                meta: {
                    [MetaTags.Description]: 'Login to your account on Spartacus',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.Login][Language.PT],
                meta: {
                    [MetaTags.Description]: 'Entre na sua conta no Spartacus',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.Login][Language.RU],
                meta: {
                    [MetaTags.Description]: 'Entre na sua conta no Spartacus',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.Login],
        canonical: PAGES_CANONICAL[PageLinks.Login],
    },
    [PageLinks.LiveEvents]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.LiveEvents][Language.EN],
                meta: {
                    [MetaTags.Description]:
                        'Don\'t miss a single moment of the action! Watch live Spartacus MMA events online, featuring the best fighters in the world.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.LiveEvents][Language.PT],
                meta: {
                    [MetaTags.Description]:
                        'Não perca um único momento da ação! Assista online aos eventos Spartacus MMA ao vivo, apresentando os melhores lutadores do mundo.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.LiveEvents][Language.RU],
                meta: {
                    [MetaTags.Description]:
                        'Não perca um único momento da ação! Assista online aos eventos Spartacus MMA ao vivo, apresentando os melhores lutadores do mundo.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.LiveEvents],
        canonical: PAGES_CANONICAL[PageLinks.LiveEvents],
    },
    [PageLinks.UpcomingEvents]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.UpcomingEvents][Language.EN],
                meta: {
                    [MetaTags.Description]:
                        'Don\'t miss out on the biggest MMA events of the year! Our upcoming events page showcases the world\'s top fighters as they go head-to-head in the octagon.  ',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.UpcomingEvents][Language.PT],
                meta: {
                    [MetaTags.Description]:
                        'Não perca os maiores eventos de MMA do ano! Nossa próxima página de eventos mostra os melhores lutadores do mundo enquanto eles se enfrentam no octógono.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.UpcomingEvents][Language.RU],
                meta: {
                    [MetaTags.Description]:
                        'Não perca os maiores eventos de MMA do ano! Nossa próxima página de eventos mostra os melhores lutadores do mundo enquanto eles se enfrentam no octógono.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.UpcomingEvents],
        canonical: PAGES_CANONICAL[PageLinks.UpcomingEvents],
    },
    [PageLinks.PastEvents]: {
        lng: {
            [Language.EN]: {
                title: PAGES_TITLES[PageLinks.PastEvents][Language.EN],
                meta: {
                    [MetaTags.Description]:
                        'Step into the cage of history with Spartacus’ events library. Watch the most epic moments of the combat sports history.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.PT]: {
                title: PAGES_TITLES[PageLinks.PastEvents][Language.PT],
                meta: {
                    [MetaTags.Description]:
                        'Entre na jaula da história com a biblioteca de eventos de Spartacus. Assista aos momentos mais épicos da história dos esportes de combate.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
            [Language.RU]: {
                title: PAGES_TITLES[PageLinks.PastEvents][Language.RU],
                meta: {
                    [MetaTags.Description]:
                        'Entre na jaula da história com a biblioteca de eventos de Spartacus. Assista aos momentos mais épicos da história dos esportes de combate.',
                    [MetaTags.Robots]: DEFAULT_ROBOTS,
                },
            },
        },
        alternate: PAGES_ALTERNATES[PageLinks.PastEvents],
        canonical: PAGES_CANONICAL[PageLinks.PastEvents],
    },
};
