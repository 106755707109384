import * as moment from 'moment-timezone';
import { EImageType } from '../../../models/images/user-image.model';

export interface ImageModelData {
    id: number;
    href: string;
    name: string;
    original: boolean;
    position: number;
    imageType: EImageType;
    createDate: string;
}

export interface ImageModel extends ImageModelData {}

export class ImageModel {}
