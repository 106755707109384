import { EWeightCategory } from '../../../../shared/enums/e-weight-category.enum';
import { EUnitSystem} from '../user-fighter.model';

export interface FighterWeightCategoryData {
    weightCategory: EWeightCategory;
    unitType: EUnitSystem;
    minKg: number;
    maxKg: number;
    minLbs: number;
    maxLbs: number;
}

export const MALE_CATEGORIES: FighterWeightCategoryData[] = [
    {
        weightCategory: EWeightCategory.FLY_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 53,
        maxKg: 57,
        minLbs: 116,
        maxLbs: 125,
    },
    {
        weightCategory: EWeightCategory.BANTAM_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 57,
        maxKg: 61,
        minLbs: 126,
        maxLbs: 135,
    },
    {
        weightCategory: EWeightCategory.FEATHER_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 61,
        maxKg: 66,
        minLbs: 136,
        maxLbs: 145,
    },
    {
        weightCategory: EWeightCategory.LIGHT_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 66,
        maxKg: 70,
        minLbs: 146,
        maxLbs: 155,
    },
    {
        weightCategory: EWeightCategory.WELTER_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 70,
        maxKg: 77,
        minLbs: 156,
        maxLbs: 170,
    },
    {
        weightCategory: EWeightCategory.MIDDLE_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 77,
        maxKg: 84,
        minLbs: 171,
        maxLbs: 185,
    },
    {
        weightCategory: EWeightCategory.LIGHT_HEAVY_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 84,
        maxKg: 93,
        minLbs: 186,
        maxLbs: 205,
    },
    {
        weightCategory: EWeightCategory.HEAVY_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 93,
        maxKg: 120,
        minLbs: 206,
        maxLbs: 265,
    },
];
export const FEMALE_CATEGORIES: FighterWeightCategoryData[] = [
    {
        weightCategory: EWeightCategory.STRAW_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 48,
        maxKg: 52,
        minLbs: 106,
        maxLbs: 115,
    },
    {
        weightCategory: EWeightCategory.BANTAM_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 57,
        maxKg: 61,
        minLbs: 126,
        maxLbs: 135,
    },
    {
        weightCategory: EWeightCategory.FEATHER_WEIGHT,
        unitType: EUnitSystem.METRIC,
        minKg: 61,
        maxKg: 66,
        minLbs: 136,
        maxLbs: 145,
    },
];
