import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { catchError, filter, of, switchMap, take, tap } from 'rxjs';
import { PatchUserDataParams } from '../../../../../api/fca/auth/params/patch-user-data.params';
import { EImageType } from '../../../../models/images/user-image.model';
import { AuthUserModel } from '../../../../models/users/auth-user/auth-user.model';
import { FcaImagesService } from '../../../../services/fca-images.service';
import { ProfilesPublicService } from '../../../../services/profiles-public.service';
import { EUserRole } from '../../../../shared/enums/user-role.enum';
import { untilDestroy, UntilDestroy } from '../../../../shared/operators/until-destroy.operator';
import { PreloadService } from '../../../../shared/services/preload.service';
import { SaveUserDataAction } from '../../../../store/actions';
import { FcaStoreService } from '../../../../store/store.service';

@UntilDestroy()
@Component({
    selector: 'app-user-personal-data-popup',
    templateUrl: './user-personal-data-popup.component.html',
    styleUrls: ['./user-personal-data-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPersonalDataPopupComponent implements OnInit {
    firstName = '';
    lastName = '';
    nickName = '';
    description = '';
    image = '';
    roleName: EUserRole = EUserRole.NONE;
    cookieService = inject(SsrCookieService);
    selectedPhoto: File | null;

    constructor(
        public dialogRef: MatDialogRef<UserPersonalDataPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly profilesPublicService: ProfilesPublicService,
        private readonly storeService: FcaStoreService,
        private readonly imagesService: FcaImagesService,
        private preloadService: PreloadService,
        private _cdr: ChangeDetectorRef
    ) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.nickName = data.nickname;
        this.description = data.description;
        this.image = data.thumbnailAvatar;
        this.roleName = data.roleName;
    }

    ngOnInit(): void {}

    updateUser() {
        this.preloadService.preload(true);
        const body = new PatchUserDataParams();
        body.firstName = this.firstName;
        body.lastName = this.lastName;
        body.description = this.description;
        body.nickname = this.nickName;
        body.roleName = this.roleName;
        this.storeService
            .getUser()
            .pipe(
                filter(Boolean),
                switchMap((user: AuthUserModel) => {
                    return this.selectedPhoto
                        ? this.imagesService.uploadUserPhoto(user.id, this.selectedPhoto, EImageType.FULL_FACE_AVATAR)
                        : of(user);
                }),
                switchMap((user: AuthUserModel) => {
                    return this.profilesPublicService.patchUserInfo(body).pipe(
                        catchError((err: HttpErrorResponse) => {
                            this.preloadService.preload(false);
                            throw err;
                        })
                    );
                }),
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.preloadService.preload(false);
                }),
                tap((user: AuthUserModel) => {
                    this.storeService.dispatch(new SaveUserDataAction({ data: user }));
                    this.cookieService.set('authUserModelData', JSON.stringify(user));
                    this.dialogRef.close();
                })
            )
            .subscribe();
    }

    onChangePhoto(file: File | null): void {
        this.selectedPhoto = file;
    }
}
