import { Attribute, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appImageLoadError]',
})
export class ImageLoadErrorDirective {
    @Input('onErrorSrc') public onErrorSrc: string;
    @Input('loader') public loader: string;
    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.loader || this.onErrorSrc || '');
        this.el.nativeElement.addEventListener('error', () => this.errorListener());
        this.el.nativeElement.addEventListener('load', () => this.loadListener());
    }

    errorListener() {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.onErrorSrc || '');
        this.el.nativeElement.removeAllListeners('error');
    }

    loadListener() {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.el.nativeElement.src);
        this.el.nativeElement.removeAllListeners('load');
    }
}
