import { Observable } from 'rxjs';
import { LoginSocialParams, SocialProvider } from '../../../../api/fca/auth/params/login.params';
import { LoginResponse } from '../../../../api/fca/auth/response/login.response';
import { FcaAuthService } from '../../fca-auth.service';
import { BaseAuthSocialHandler } from './base-handler';

export class AppleAuthHandler extends BaseAuthSocialHandler {
    constructor(authService: FcaAuthService) {
        super(authService);
    }

    protected allowHandle(params: LoginSocialParams): boolean {
        return params.provider === SocialProvider.APPLE;
    }

    protected doHandle(params: LoginSocialParams, code: string, userId: string): Observable<LoginResponse | null> {
        return this.authService.loginWithApple(params, code, userId);
    }
}
