import {Inject, inject, Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import {BehaviorSubject, catchError, filter, map, Observable, of, shareReplay, switchMap, take, tap} from 'rxjs';
// @ts-ignore
import * as uuid from 'uuid';
import {GetPermissionListParams} from '../../api/fca/api/permissions-user/params/get-permission-list.params';
import {PermissionsUserApiService} from '../../api/fca/api/permissions-user/permissions-user-api.service';
import {
  PermissionListItemApiResponse
} from '../../api/fca/api/permissions-user/responses/permission-list-item-api.response';
import {FcaAuthApiService} from '../../api/fca/auth/fca-auth-api.service';
import {
  LoginAppleParams,
  LoginFacebookParams,
  LoginGoogleParams,
  SocialProvider,
} from '../../api/fca/auth/params/login.params';
import {RegisterUserSocialParams} from '../../api/fca/auth/params/register-user.params';
import {AuthType} from '../../api/fca/auth/params/send-sms.params';
import {CheckUserResponse} from '../../api/fca/auth/response/check-user.response';
import {AuthResponse, LoginResponse} from '../../api/fca/auth/response/login.response';
import {UserResponse} from '../../api/fca/auth/response/user.response';
import {AppleApiService} from '../../api/social/apple/apple-api.service';
import {BaseSocialService} from '../../api/social/base-social.service';
import {FacebookApiService} from '../../api/social/facebook/facebook-api.service';
import {GoogleApiService} from '../../api/social/google/google-api.service';
import {SocialUser} from '../../api/social/interfaces/social-user.interface';
import {ViewerType} from '../constants';
import {AuthUserFactory} from '../models/users/auth-user/auth-user.factory';
import {AuthUserMapper} from '../models/users/auth-user/auth-user.mapper';
import {AuthUserModel} from '../models/users/auth-user/auth-user.model';
import {
  GetPermissionsListFactory
} from '../models/users/permissions-user/get-permissions-list/get-permissions-list.factory';
import {
  GetPermissionsListMapper
} from '../models/users/permissions-user/get-permissions-list/get-permissions-list.mapper';
import {
  GetPermissionsListModel
} from '../models/users/permissions-user/get-permissions-list/get-permissions-list.model';
import {EUserRole} from '../shared/enums/user-role.enum';
import {AuthData} from '../shared/interfaces/auth-data.interface';
import {UntilDestroy, untilDestroy} from '../shared/operators/until-destroy.operator';
import {PreloadService} from '../shared/services/preload.service';
import {WINDOW} from '../shared/tokens/window.token';
import {
  ClearAuthDataAction,
  ClearUserDataAction,
  SaveAuthDataAction,
  SaveSpartaCoinsBonus,
  SaveUserDataAction,
  SaveUserPermissions,
  SetCurrentFightEventDataAction,
} from '../store/actions';
import {FcaStoreService} from '../store/store.service';
import {DeviceParamsService} from './device-params.service';
import {FollowersService} from './followers.service';
import {AppleAuthHandler} from './handlers/social-auth/apple-auth.handler';
import {FacebookAuthHandler} from './handlers/social-auth/facebook-auth.handler';
import {GoogleAuthHandler} from './handlers/social-auth/google-auth.handler';
import {
  CreateUserDefaultFormDataParams,
  CreateUserSocialFormDataParams,
} from './params/users/create-user-form-data.params';
import {Language} from "../shared/enums/language.enum";

export interface SocialLoginResponse {
  loginData: LoginResponse | null;
  userExists: boolean;
  providerData: {
    token: string;
    email: string;
    firstName: string;
    lastName: string;
    identifier?: string;
  } | null;
}

@UntilDestroy()
@Injectable()
export class FcaAuthService {
  readonly socialStrategies = new Map<SocialProvider, BaseSocialService>();
  private accessKeySubject = new BehaviorSubject<any>(null);
  readonly currentUser$: Observable<AuthUserModel | null>;
  cookieService = inject(SsrCookieService);
  webDeviceData = {
    deviceToken: 'web-site',
    deviceIdentifier: 'web-site',
    deviceName: 'web-site',
  };

  constructor(
    private readonly authService: FcaAuthApiService,
    private readonly storeService: FcaStoreService,
    private readonly googleSigninService: GoogleApiService,
    private readonly facebookSigninService: FacebookApiService,
    private readonly appleSigninService: AppleApiService,
    private readonly preloadService: PreloadService,
    private readonly permissionsUserApiService: PermissionsUserApiService,
    private readonly deviceParamsService: DeviceParamsService,
    private readonly followersService: FollowersService,
    private _router: Router,
    @Inject(WINDOW) private window: Window
  ) {
    this.currentUser$ = this.accessKeySubject.pipe(
      switchMap(() => this.fetchCurrentUser()),
      tap((user: AuthUserModel) => {
        this.storeService.dispatch(new SaveUserDataAction({data: user}));
        this.cookieService.set('authUserModelData', JSON.stringify(user));
        this.storeService.getFCMToken()
          .pipe(
            take(1)
          )
          .subscribe(value => {
            if (value?.length != null) {
              this.authService.patchFCMToken(
                value,
                this.deviceParamsService.deviceIdentifier(),
                "EN",
              ).subscribe(result => {

              })
            }
          });
      }),
      untilDestroy(this),
      catchError(() => {
        return of(null);
      })
    );
    this.socialStrategies.set(SocialProvider.APPLE, appleSigninService);
    this.socialStrategies.set(SocialProvider.FACEBOOK, facebookSigninService);
    this.socialStrategies.set(SocialProvider.GOOGLE, googleSigninService);
  }

  initWebDeviceData() {
    this.webDeviceData = {
      deviceToken: this.deviceParamsService.getDeviceToken(),
      deviceName: this.deviceParamsService.getDeviceName(),
      deviceIdentifier: this.deviceParamsService.deviceIdentifier(),
    };
  }

  refreshTokens(): Observable<AuthResponse> {
    return this.storeService.getAuthData().pipe(
      switchMap((data: AuthData | null) => {
        return this.authService.generateNewTokens(data?.refreshToken || '');
      })
    );
  }

  sendPinToEmail(email: string, type: AuthType): Observable<void> {
    return this.authService.sendPinToEmail({email, authType: type});
  }

  checkUser(email: string, pin: string): Observable<CheckUserResponse> {
    return this.authService.checkUser({email, pincode: pin});
  }

  saveLoginDataToStore(loginResponse: LoginResponse): void {
    this.storeService.dispatch(
      new SaveAuthDataAction({
        data: {
          id: loginResponse.id,
          email: loginResponse.email,
          jwt: loginResponse.jwt,
          refreshToken: loginResponse.refreshToken,
          provider: loginResponse.provider,
          stripeCustomerId: loginResponse.stripeCustomerId,
        },
      })
    );
  }

  loginWithApple(params: LoginAppleParams, code: string, userId: string): Observable<LoginResponse> {
    return this.authService.loginWithApple(params, code, userId);
  }

  loginWithGoogle(params: LoginGoogleParams, idToken: string): Observable<LoginResponse> {
    return this.authService.loginWithGoogle(params, idToken);
  }

  loginWithFacebook(params: LoginFacebookParams, token: string): Observable<LoginResponse> {
    return this.authService.loginWithFacebook(params, token);
  }

  fetchCurrentUser(): Observable<AuthUserModel> {
    return this.authService
      .getAuthUser()
      .pipe(
        map((raw: UserResponse) => new AuthUserFactory().getModelFromData(new AuthUserMapper().mapData(raw)))
      );
  }

  logout(redirectUrl?: string): void {
    this.storeService
      .getAuthData()
      .pipe(take(1))
      .subscribe((authData: AuthData | null) => {
        if (authData?.provider) {
          this.socialStrategies.get(authData.provider)!.signOut();
        }
        this.storeService.dispatch(new ClearAuthDataAction());
        this.storeService.dispatch(new ClearUserDataAction());
        this.storeService.dispatch(new SaveSpartaCoinsBonus({spartaCoinsBalance: null}));
        this.storeService.dispatch(new SetCurrentFightEventDataAction({fightEventId: null}));
        this.storeService.dispatch(new SaveUserPermissions({userPermissions: null}));
        this.followersService.reset();
        this.cookieService.deleteAll();
        if (redirectUrl && !redirectUrl.startsWith('/auth/login')) {
          const extras: NavigationExtras = {
            queryParams: {redirectUrl},
          };
          this._router.navigate(['/auth/login'], extras).then();
        } else {
          this._router.navigate(['/auth/login']).then();
        }
      });
    this.cookieService.deleteAll();
  }

  loginDefault(email: string, token: string): Observable<LoginResponse> {
    this.initWebDeviceData();
    return this.authService.login({
      email,
      registrationToken: token,
      ...this.webDeviceData,
    });
  }

  loginSocial(provider: SocialProvider): Observable<SocialLoginResponse | null> {
    const emptyResponse = {
      loginData: null,
      userExists: false,
      providerData: null,
    } as SocialLoginResponse;
    const loginHandler = new GoogleAuthHandler(this);
    loginHandler.setNext(new FacebookAuthHandler(this)).setNext(new AppleAuthHandler(this));
    this.initWebDeviceData();
    return this.socialStrategies
      .get(provider)!
      .signIn()
      .pipe(
        take(1),
        switchMap((user: SocialUser | null) => {
          return user
            ? loginHandler
              .handleLogin({provider, ...this.webDeviceData}, user.token, user.identifier)
              .pipe(
                take(1),
                map((loginResp: LoginResponse | null) => {
                  return loginResp
                    ? ({
                      loginData: {...loginResp, provider},
                      userExists: true,
                      providerData: {
                        token: user.token,
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        identifier: user.identifier,
                      },
                    } as SocialLoginResponse)
                    : emptyResponse;
                }),
                catchError(() =>
                  of({
                    ...emptyResponse,
                    providerData: {
                      token: user.token,
                      email: user.email,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      identifier: user.identifier,
                    },
                    userExists: false,
                  })
                )
              )
            : of(emptyResponse);
        })
      );
  }

  registerDefault(params: CreateUserDefaultFormDataParams): Observable<LoginResponse> {
    this.initWebDeviceData();
    return this.authService.registerNewUserDefault({
      firstName: params.firstName,
      lastName: params.lastName,
      nickname: params.nickname,
      email: params.email,
      registrationToken: params.token,
      roleName: EUserRole.FAN,
      referralCode: params.referralCode,
      locale: this.window?.navigator.language.split('-')[0].toUpperCase(),
      ...this.webDeviceData,
    });
  }

  registerSocial(params: CreateUserSocialFormDataParams): Observable<LoginResponse> {
    this.initWebDeviceData();
    const registerParams = {
      firstName: params.firstName,
      lastName: params.lastName,
      nickname: params.nickname,
      email: params.email,
      provider: params.provider,
      roleName: EUserRole.FAN,
      referralCode: params.referralCode,
      ...this.webDeviceData,
      locale: this.window?.navigator.language.split('-')[0].toUpperCase(),
    } as RegisterUserSocialParams;
    let result$: Observable<LoginResponse>;
    switch (params.provider) {
      case SocialProvider.GOOGLE:
        result$ = this.authService.registerNewUserGoogle(registerParams, params.token);
        break;
      case SocialProvider.FACEBOOK:
        result$ = this.authService.registerNewUserFacebook(registerParams, params.token);
        break;
      case SocialProvider.APPLE:
        result$ = this.authService.registerNewUserApple(registerParams, params.token, params.identifier!);
        break;
      default:
        throw new Error('Provider not found');
    }

    return result$;
  }

  authSeoBot(viewer: ViewerType): void {
    if (viewer === 'bot') {
      this.storeService
        .getAuthData()
        .pipe(
          take(1),
          filter((authData) => !Boolean(authData)),
          switchMap(() => this.authService.fakeSeoBotLogin()),
          tap((loginResponse) => this.saveLoginDataToStore(loginResponse)),
          switchMap(() => this.currentUser$)
        )
        .subscribe();
    }
  }

  generateAndSaveSid(): void {
    this.window?.sessionStorage.setItem('sid', uuid.v4());
  }

  getSid(): string {
    return this.window?.sessionStorage.getItem('sid') || '';
  }

  generateAndSaveRid(): void {
    this.window?.sessionStorage.setItem('rid', uuid.v4());
  }

  getRid(): string {
    return this.window?.sessionStorage.getItem('rid') || '';
  }

  getPermissionList(body: GetPermissionListParams): Observable<GetPermissionsListModel[]> {
    return this.permissionsUserApiService.loadPermissionList(body).pipe(
      map((rawData: PermissionListItemApiResponse[]) => {
        return rawData.map((item: PermissionListItemApiResponse) => {
          return new GetPermissionsListFactory().getModelFromData(
            new GetPermissionsListMapper().mapData(item)
          );
        });
      })
    );
  }

  requestContentCreator(body: string): Observable<void> {
    return this.permissionsUserApiService.requestContentCreator(body);
  }
}
