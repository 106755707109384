import { FightEventVideoModel } from '../fight-event-video/fight-event-video.model';
import { EFightEventStatus } from '../fight-event.model';

export interface FightEventConferenceModelData {
    fromDate: string;
    status: EFightEventStatus;
    toDate: string;
    video: FightEventVideoModel;
}

export interface FightEventConferenceModel extends FightEventConferenceModelData {}
export class FightEventConferenceModel {}
