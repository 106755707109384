<div class="Roles-container" *transloco="let t">
  <ul class="Roles" [ngClass]="hasError ? 'Error' :''">
    <li
      (click)="onSelectListItem(role)"
      *ngFor="let role of roles"
      data-id="{{ role.role  }}"
      [attr.data-role-selected]="role.selected">
      <span class="symbol">{{role.icon}}</span>
      <p>{{ t(role.role)  }}</p>
    </li>
  </ul>
  <div *ngIf="hasError"
       class="input-validation">
    <div class="input-validation-error">
      {{ t('Select role') }}
    </div>
  </div>
</div>
