import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { FcaStoreService } from '../../../../store/store.service';
import { AuthData } from '../../../interfaces/auth-data.interface';
import { untilDestroy } from '../../../operators/until-destroy.operator';

@Component({
    selector: 'app-invite-link',
    templateUrl: './invite-link.component.html',
    styleUrls: ['./invite-link.component.scss'],
})
export class InviteLinkComponent {
    constructor(private _router: Router, private _route: ActivatedRoute, private storeService: FcaStoreService) {
        const inviteLinkId = this._route.snapshot.params['inviteLinkId'];
        if (inviteLinkId) {
            const extras: NavigationExtras = {
                queryParams: { inviteLinkId },
            };
            this.storeService
                .getAuthData()
                .pipe(
                    take(1),
                    untilDestroy(this),
                    tap((user: AuthData | null) => {
                        if (user?.jwt) {
                            this._router.navigate(['/']);
                        } else {
                            this._router.navigate(['/auth/sign-up'], extras);
                        }
                    })
                )
                .subscribe();
        }
    }
}
