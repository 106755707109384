import * as moment from 'moment-timezone';
import { FighterDetailsApiResponse } from '../../../../../api/fca/users/response/user-api.response';
import { EWeightCategory } from '../../../../shared/enums/e-weight-category.enum';
import { EFightStyle } from '../../../../shared/enums/fight-style.enum';
import { EGender } from '../../../../shared/enums/gender.enum';
import { EStand } from '../../../../shared/enums/stand.enum';
import { EUserExperience } from '../../../../shared/enums/user-experience.enum';
import { ModelDataMapper } from '../../../../shared/interfaces/model-mapper.interface';
import { EUnitSystem} from '../user-fighter.model';
import {
    ESearchDistance,
    FighterDetailsModelData,
} from './fighter-details.model';

export class FighterDetailsMapper implements ModelDataMapper<FighterDetailsApiResponse, FighterDetailsModelData> {
    mapData(raw: FighterDetailsApiResponse): FighterDetailsModelData {
        return {
            birthdate: moment(raw.birthdate),
            club: raw.club,
            coaches: raw.coaches,
            draw: raw.draw,
            experience: raw.experience as EUserExperience,
            fightStyle: raw.fightStyle as EFightStyle,
            gender: raw.gender as EGender,
            handsSize: raw.handsSize,
            height: raw.height,
            isActive: raw.isActive,
            loss: raw.loss,
            ranking: raw.ranking,
            searchDistance: raw.searchDistance as ESearchDistance,
            sigStrikes: raw.sigStrikes,
            stand: raw.stand as EStand,
            takedowns: raw.takedowns,
            unitSystem: raw.unitSystem as EUnitSystem,
            weight: raw.weight as EWeightCategory,
            win: raw.win,
            winsByKo: raw.winsByKo,
            winsBySub: raw.winsBySub,
        };
    }
}
