import { CreateSubscriptionResponse } from '../../../../api/fca/payments/response/create-subscription.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { CreateSubscriptionModelData } from './create-subscription.model';

export class CreateSubscriptionMapper
    implements ModelDataMapper<CreateSubscriptionResponse, CreateSubscriptionModelData>
{
    mapData(raw: CreateSubscriptionResponse): CreateSubscriptionModelData {
        return {
            clientSecret: raw.clientSecret,
            subscriptionId: raw.subscriptionId,
        };
    }
}
