import { CoinPaymentSystemEnum } from '../../shared/enums/coin-payment-system.enum';
import { CurrencyEnum } from '../../shared/enums/currency.enum';
import { SubscriptionInterval } from '../../shared/enums/subscription-interval.enum';

export interface SubscriptionModelData {
    id: string;
    amount: number;
    displayAmount: number;
    currency: CurrencyEnum;
    interval: SubscriptionInterval;
    intervalCount: number;
    currencySymbol: string;
    monthIntervalCount: number;
    paymentSystem: CoinPaymentSystemEnum;
}

export interface SubscriptionModel extends SubscriptionModelData {}

export class SubscriptionModel {
    get wholePart(): number {
        return this.paymentSystem === CoinPaymentSystemEnum.STRIPE ? this.amount / 100 : this.amount;
    }

    get amountPerMonth(): string {
        return (this.wholePart / this.monthIntervalCount).toFixed(2).replace(/\.?0+$/, '');
    }

    get isPopular(): boolean {
        return this.intervalCount === 6 && this.interval === SubscriptionInterval.MONTH;
    }
}
