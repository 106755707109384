import { ErrorHandler, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, take } from 'rxjs';
import { SnackbarService, SnackBarStyles } from '../services/snackbar.service';

export interface ApiErrorData {
    error: {
        code: ErrorCode;
        message: string;
    };
    statusCode: number;
}

export enum ErrorCode {
    USER_NOT_FOUND = 'user_not_found_error',
    INVALID_PIN = 'invalid_pin_code_error',
    DUPLICATE_NICKNAME = 'duplicate_nickname_error',
    VALIDATION_ERROR = 'validation_error',
}

@Injectable()
export class FcaErrorHandler implements ErrorHandler {
    readonly errorStyles = {
        [ErrorCode.USER_NOT_FOUND]: SnackBarStyles.ERROR,
        [ErrorCode.INVALID_PIN]: SnackBarStyles.ERROR,
        [ErrorCode.DUPLICATE_NICKNAME]: SnackBarStyles.ERROR,
        [ErrorCode.VALIDATION_ERROR]: SnackBarStyles.ERROR,
    };

    constructor(private readonly snackbarService: SnackbarService, private readonly translate: TranslocoService) {}

    handleError(err: any): void {
        const error = err.error as ApiErrorData;
        const code = error?.error?.code;

        if (code) {
            this.processError(code);
        } else {
            throw err;
        }
    }

    processError(code: ErrorCode): void {
        const errorMessage = code ? this.errorMessage(code) : of('Something went wrong');
        const style = code ? this.errorStyles[code] : SnackBarStyles.ERROR;

        errorMessage.pipe(take(1)).subscribe((msg) => {
            this.snackbarService.show({
                message: msg,
                style,
            });
        });
    }

    private errorMessage(code: ErrorCode): Observable<string> {
        return this.translate.selectTranslate(`errors-${code}`);
    }
}
