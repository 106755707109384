import { ModelFactory } from '../../../shared/abstract/model-factory.abstract';
import { FightEventConferenceModel, FightEventConferenceModelData } from './fight-event-conference.model';

export class FightEventConferenceFactory extends ModelFactory<
    FightEventConferenceModelData,
    FightEventConferenceModel
> {
    protected getInstance(data: FightEventConferenceModelData): FightEventConferenceModel {
        return new FightEventConferenceModel();
    }
}
