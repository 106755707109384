<div class='toggle' [class.transparent-bg]='transparentBg' *transloco='let t'>
  <ng-container *ngFor='let item of tabsList'>
    <button
      [disabled]='item.disabled'
      class='toggle-item flex align-items-center'
      (click)='selectTab(item)'
      [matTooltip]="t(item.tooltipText)"
      [matTooltipDisabled]='!item.disabled'
      [class.active]='activeTab === item.value'
    >
            <span class='label'>
                {{ t(item.text) }}
            </span>
      <span class='value' *ngIf='item.count'>
                {{ item.count }}
            </span>
    </button>
  </ng-container>
</div>
