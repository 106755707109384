import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { ScaleEmitModel } from '../../../models/shared/scale-emit.model';

type VideoPreviewSelectionScaleComponentSimpleChanges = {
    [propName in keyof VideoPreviewSelectionScaleComponent]: SimpleChange;
};

@Component({
    selector: 'app-video-preview-selection-scale',
    templateUrl: './video-preview-selection-scale.component.html',
    styleUrls: ['./video-preview-selection-scale.component.scss'],
})
export class VideoPreviewSelectionScaleComponent implements OnChanges, AfterViewInit {
    @ViewChild('sliderLeft') sliderLeft: ElementRef<HTMLInputElement>;
    @ViewChild('sliderRight') sliderRight: ElementRef<HTMLInputElement>;
    @ViewChild('sliderTrack') sliderTrack: ElementRef;
    @ViewChild('topBorder') topBorder: ElementRef;
    @ViewChild('bottomBorder') bottomBorder: ElementRef;
    @ViewChild('leftDrag') leftDrag: ElementRef;
    @ViewChild('rightDrag') rightDrag: ElementRef;
    @ViewChild('centerInput') centerInput: ElementRef;
    @ViewChild('currentTimeEl') currentTimeEl: ElementRef;
    @Input() minGap = 1;
    @Input() maxGapPercent = 10;
    @Input() duration = 190;
    @Input() currentTime = 0;
    @Output() onChange = new EventEmitter<ScaleEmitModel>();
    sliderMaxValue = 0;
    minValue = 0;
    maxValue = 0;
    minTime = 0;
    maxTime = 0;
    initialCenterInputData = 0;
    count = 0;
    math = Math;

    constructor() {}

    ngOnChanges(changes: VideoPreviewSelectionScaleComponentSimpleChanges) {
        if (changes.duration) {
            this.initialAction();
        }
    }

    ngAfterViewInit() {
        this.initialAction();
    }

    initialAction() {
        this.sliderMaxValue = +this.sliderLeft?.nativeElement.max;
        this.slideOne();
        this.slideTwo();
        this.initialCenterInputData = +this.sliderLeft?.nativeElement.value;
        this.onCenterMouseMove();
    }

    onChangeHandler() {
        this.minTime = Math.floor((+this.sliderLeft?.nativeElement.value * this.duration) / 100);
        this.maxTime = Math.floor((+this.sliderRight?.nativeElement.value * this.duration) / 100);
        this.onChange.emit({
            min: this.minTime,
            max: this.maxTime,
        });
    }

    slideOne() {
        if (
            parseInt(this.sliderRight?.nativeElement.value, 10) - parseInt(this.sliderLeft?.nativeElement.value, 10) <=
            this.minGap
        ) {
            this.sliderLeft.nativeElement.value = String(
                parseInt(this.sliderRight.nativeElement.value, 10) - this.minGap
            );
        }
        if (
            parseInt(this.sliderRight?.nativeElement.value, 10) - parseInt(this.sliderLeft?.nativeElement.value, 10) >=
            this.maxGapPercent
        ) {
            this.sliderLeft.nativeElement.value = String(
                parseInt(this.sliderRight.nativeElement.value, 10) - this.maxGapPercent
            );
        }
        this.fillColor();
    }

    slideTwo() {
        if (
            parseInt(this.sliderRight?.nativeElement.value, 10) - parseInt(this.sliderLeft?.nativeElement.value, 10) <=
            this.minGap
        ) {
            this.sliderRight.nativeElement.value = String(
                parseInt(this.sliderLeft?.nativeElement.value || '0', 10) + this.minGap
            );
        }
        if (
            parseInt(this.sliderRight?.nativeElement.value, 10) - parseInt(this.sliderLeft?.nativeElement.value, 10) >=
            this.maxGapPercent
        ) {
            this.sliderRight.nativeElement.value = String(
                parseInt(this.sliderLeft?.nativeElement.value || '0', 10) + this.maxGapPercent
            );
        }
        this.fillColor();
    }

    fillColor() {
        this.onChangeHandler();
        const percent1 = (+this.sliderLeft?.nativeElement.value / this.sliderMaxValue) * 100;
        const percent2 = (+this.sliderRight?.nativeElement.value / this.sliderMaxValue) * 100;
        if (this.topBorder) {
            this.topBorder.nativeElement.style.background = `linear-gradient(to right, transparent ${percent1}% , rgb(253, 80, 3) ${percent1}% , rgb(253, 80, 3) ${percent2}%, transparent ${percent2}%)`;
        }
        if (this.bottomBorder) {
            this.bottomBorder.nativeElement.style.background = `linear-gradient(to right, transparent ${percent1}% , rgb(253, 80, 3) ${percent1}% , rgb(253, 80, 3) ${percent2}%, transparent ${percent2}%)`;
        }
        if (this.leftDrag) {
            this.leftDrag.nativeElement.style.left = percent1 + '%';
        }
        if (this.leftDrag) {
            this.leftDrag.nativeElement.style.transform = `translateX(Calc(${-percent1}%))`;
        }
        if (this.rightDrag) {
            this.rightDrag.nativeElement.style.left = percent2 + '%';
        }
        if (this.rightDrag) {
            this.rightDrag.nativeElement.style.transform = `translateX(${-percent2}%)`;
        }
        if (this.currentTimeEl) {
            this.currentTimeEl.nativeElement.style.left = (this.currentTime * 100) / this.duration + '%';
        }
        this.count++;
    }

    onCenterMouseMove($event = '0') {
        const dif = +$event - this.initialCenterInputData;
        if (this.sliderLeft) {
            this.sliderLeft.nativeElement.value = (dif + +this.sliderLeft.nativeElement.value).toString();
        }
        if (this.sliderRight) {
            this.sliderRight.nativeElement.value = (dif + +this.sliderRight.nativeElement.value).toString();
        }
        this.initialCenterInputData = +$event;
        this.fillColor();
        if (this.currentTimeEl) {
            this.currentTimeEl.nativeElement.style.left = this.currentTimePercent() + '%';
        }
    }

    currentTimePercent(): number {
        const a =
            (((this.currentTime * 100) / this.duration) * 100) / +this.sliderRight?.nativeElement.value -
            +this.sliderLeft?.nativeElement.value;

        console.log(a);
        return a;
    }
}
