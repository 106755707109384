<div class="wrapper">
    <img class="scale" src="assets/images/scale-duration.png" alt="" />

    <div class="container">
        <div #sliderTrack class="slider">
            <input #sliderLeft class="range-input" type="range" min="0" max="100" value="1" (input)="slideOne()" />
            <input #sliderRight class="range-input" type="range" min="0" max="100" value="99" (input)="slideTwo()" />
            <div class="border top" #topBorder></div>
            <div class="border bottom" #bottomBorder></div>
            <div #leftDrag class="drag left-drag">
                <div class="time left">{{ minTime | duration : ':' }}</div>
            </div>
            <div #rightDrag class="drag right-drag">
                <div class="time right">{{ maxTime | duration : ':' }}</div>
            </div>
            <div
                class="current-time-wrapper"
                [style.width]="'calc(' + (sliderRight.value - sliderLeft.value) + '% - 40px)'"
                [style.left]="'calc(' + sliderLeft.value + '% + ' + (40 - (sliderLeft.value / 100) * 40) + 'px)'"
            >
<!--                <div #currentTimeEl class="current-time"-->
<!--                  [style.left]="currentTimePercent() + '%'"-->
<!--                ></div>-->
            </div>
            <input
                #centerInput
                (input)="onCenterMouseMove(centerInput.value)"
                class="range-input center-input"
                type="range"
                min="0"
                [style]="
                    '--width:' +
                    'calc(' +
                    (sliderRight.value - sliderLeft.value) +
                    '% - ' +
                    (sliderLeft.value / 100) * 40 +
                    'px);' +
                    ' --left: ' +
                    'calc(' +
                    sliderLeft.value +
                    '% + ' +
                    (30 - (sliderLeft.value / 100) * 40) +
                    'px)'
                "
                max="100"
                value="50"
            />
        </div>
    </div>
</div>
