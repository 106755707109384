<ng-container *transloco='let t'>
  <div class='popup-header flex align-items-center justify-content__space-between'>
    <p class='title'>
      {{ t('add-post-modal-label') }}
    </p>
    <button class='close-btn center' (click)='dialogRef.close()'>
      <img src='assets/images/close-popup.svg' alt='' />
    </button>
  </div>

  <div class='step-list-wrapper'>
    <ng-container *ngIf='activeStep === 0'>
      <div class='adding-media center flex-column' appDnd (fileDropped)='onFileDropped($event)'>
        <img src='assets/images/add-img-video-to-post.svg' alt='' />
        <p class='label'>
          {{ t('add-post-modal-drag-image-label') }}
        </p>
        <label class='main-btn' (change)='fileInputChangeHandler($event.target?.files)'>
          {{ t('add-post-modal-select-on-comp-label') }}
          <input id='fileInput' multiple type='file' accept='.jpg, .png' />
        </label>
      </div>
    </ng-container>

    <ng-container *ngIf='activeStep === 1'>
      <div class='media-slider'>
        <ngx-slick-carousel
          #slickMainCarousel
          (afterChange)='sliderChangeHandler(slickMainCarousel?.currentIndex)'
          [config]='mainSlideConfig'
        >
          <div ngxSlickItem *ngFor='let file of postData?.files'>
            <div class='slide-media-wrapper scroll-bar center'>
              <img [src]='file.src' alt='' />
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <div class='carousel-nav flex align-items-center justify-content__space-between'>
        <button class='center' (click)='slickMainCarousel.slickPrev()'>
          <img src='assets/images/arrow-white-left.svg' alt='' />
        </button>
        <button class='center' (click)='slickMainCarousel.slickNext()'>
          <img src='assets/images/arrow-white-right.svg' alt='' />
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf='activeStep === 2'>
      <div class='post-configs'>
        <div
          class='min-carousel-wrapper flex'
          cdkDropList
          cdkDropListOrientation='horizontal'
          (cdkDropListDropped)='drop($event)'
        >
          <div class='dragged-item' cdkDrag *ngFor='let file of postData?.files; let i = index'>
            <div class='media-wrapper min center'>
              <div class='loader-wrapper center' *ngIf='!file.uploadedFileResponse'>
                {{ t('add-post-modal-loading') }}
              </div>
              <img [src]='file.src' alt='' />
              <button
                *ngIf='isEdit'
                class='center remove-image-btn'
                (click)='deleteImage(file.customFrontId)'
              >
                <img src='assets/images/remove-red.svg' alt='' />
              </button>
            </div>
            <button class='handle' cdkDragHandle>
              <img src='assets/images/drag-handle.svg' alt='' />
            </button>
          </div>
          <label
            *ngIf='postData.files?.length < 10'
            class='add-image-btn center'
            (change)='addFileOnEdit($event.target?.files)'
          >
            <img src='assets/images/white-plus.svg' alt='' />
            <input hidden multiple type='file' accept='.jpg, .png' />
          </label>
        </div>

        <p class='label'>
          {{t('add-post-modal-description-label')}}
        </p>
        <textarea
          maxlength='1000'
          class='main-input scroll-bar'
          name='description'
          [class.invalid]='description.length < 3 && submitText'
          [(ngModel)]='description'
          #descriptionEl
          [placeholder]="t('add-post-modal-description-placeholder')"
        ></textarea>
        <p class='description-note'>
          {{ description.length }}
          /
          {{ descriptionEl.maxLength }}
        </p>

        <p class='label flex align-items-center'>
          {{t('add-post-modal-access-label')}}
          <img src='assets/images/lock.svg' alt='' />
        </p>
        <p class='note'>
          {{t('add-post-modal-access-note')}}
        </p>

        <app-radio-btn
          *ngFor='let accessItem of accessRadioList'
          [label]='accessItem.label'
          [name]='accessItem.name'
          [description]='accessItem.description'
          [value]='accessItem.value'
          [disabled]='accessItem.disabled'
          [tooltipText]='accessItem.tooltipText'
          [selectedValue]='access'
          (onSelect)='access = $event'
        ></app-radio-btn>

        <p class='label flex align-items-center'>
          {{t('add-post-modal-additional-settings-label')}}
        </p>
        <app-toggle
          [active]='!commentsEnabled'
          (onChange)='changeCommentsEnabled($event)'
          [label]="t('add-post-modal-close-comments-label')"
        ></app-toggle>
      </div>
    </ng-container>
  </div>

  <div *ngIf='activeStep !== 0' class='popup-footer flex align-items-center justify-content__space-between'>
    <div *ngIf='activeStep === 1' class='footer-carousel-wrapper'>
      <div class='min-carousel-wrapper'>
        <ngx-slick-carousel #slickMinCarousel [config]='minSlideConfig'>
          <div ngxSlickItem *ngFor='let file of postData?.files; let i = index'>
            <div
              class='slide-media-wrapper min center'
              [class.active]='i === activeSlideIndex'
              (click)='sliderChangeHandler(i)'
            >
              <img [src]='file.src' alt='' />
              <button (click)='removeSlide(i)' *ngIf='i === activeSlideIndex' class='remove-slide center'>
                <img src='assets/images/remove-red.svg' alt='' />
              </button>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <label
        *ngIf='postData.files?.length < 10'
        class='add-image-btn center'
        (change)='fileInputChangeHandler($event.target?.files)'
      >
        <img src='assets/images/white-plus.svg' alt='' />
        <input hidden multiple type='file' accept='.jpg, .png' />
      </label>
    </div>
    <ng-template #emptyEl>
      <div></div>
    </ng-template>
    <button *ngIf='activeStep > 1 && !isEdit; else emptyEl' class='footer-btn' (click)='stepToggle(-1)'>
      <img src='assets/images/arrow-black-left.svg' alt='' />
      <span class='footer-btn-text'>
        {{t('add-post-modal-prev-slider-btn')}}
      </span>
    </button>
    <button *ngIf='activeStep < 2; else publishBtn' class='footer-btn' (click)='stepToggle(+1)'>
      <span class='footer-btn-text'>
              {{t('add-post-modal-next-slider-btn')}}
      </span>
      <img src='assets/images/arrow-right.svg' alt='' />
    </button>
    <ng-template #publishBtn>
      <button class='footer-btn' *ngIf='!isEdit' [disabled]='isPublishBtnDisabled()' (click)='publish()'>
        {{t('add-post-modal-publish-btn')}}
      </button>
      <button class='footer-btn' *ngIf='isEdit' [disabled]='isPublishBtnDisabled()' (click)='update()'>
        {{t('add-post-modal-update-btn')}}
      </button>
    </ng-template>
  </div>
</ng-container>
