import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-buy-subscription-popup',
    templateUrl: './buy-subscription-popup.component.html',
    styleUrls: ['./buy-subscription-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuySubscriptionPopupComponent {
    constructor(public dialogRef: MatDialogRef<BuySubscriptionPopupComponent>, private _router: Router) {}

    closeDialog() {
        this.dialogRef.close();
    }

    subscribe() {
        this.dialogRef.close();
        this._router.navigate(['/subscription']);
    }
}
