import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { defer, finalize, iif, map, of, switchMap, take, tap } from 'rxjs';
import { SocialProvider } from '../../../api/fca/auth/params/login.params';
import { LoginResponse } from '../../../api/fca/auth/response/login.response';
import { AuthUserModelData } from '../../models/users/auth-user/auth-user.model';
import { FcaAuthService } from '../../services/fca-auth.service';
import { FcaImagesService } from '../../services/fca-images.service';
import { UntilDestroy, untilDestroy } from '../../shared/operators/until-destroy.operator';
import { PreloadService } from '../../shared/services/preload.service';
import { SaveAuthDataAction } from '../../store/actions';
import { FcaStoreService } from '../../store/store.service';
import { EFlatButtonType } from '../../uikit/buttons/flat-button/flat-button.component';

export interface RegisterRouteParams {
    email: string;
    token?: string;
    identifier?: string;
    firstName?: string;
    lastName?: string;
    provider?: SocialProvider;
}

type RegisterUserData = Omit<AuthUserModelData, 'id' | 'roleName'>;

const defaultValidators = [Validators.required, Validators.minLength(3), Validators.maxLength(50)];

@UntilDestroy()
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
    userData: RegisterUserData | null = null;
    form: FormGroup;
    buttonType = EFlatButtonType.primary;
    selectedPhoto: File | null;
    routeParams: RegisterRouteParams;
    latinPattern = '^[a-zA-Z -]+$';
    symbolsPattern = '^(?!(.*\\.{2,}|.*-{2,}|.*\\s{2,})).*$';

    constructor(
        private readonly authService: FcaAuthService,
        private readonly preloadService: PreloadService,
        private readonly store: FcaStoreService,
        private readonly imagesService: FcaImagesService,
        private readonly dialog: MatDialog,
        private _router: Router,
        private _route: ActivatedRoute,
        private _fb: FormBuilder,
        private _cdr: ChangeDetectorRef
    ) {
        this.form = this._fb.group({
            firstName: [
                '',
                [...defaultValidators, Validators.pattern(this.latinPattern), Validators.pattern(this.symbolsPattern)],
            ],
            lastName: [
                '',
                [...defaultValidators, Validators.pattern(this.latinPattern), Validators.pattern(this.symbolsPattern)],
            ],
            nickname: [
                '',
                [...defaultValidators, Validators.pattern('^[a-zA-Z0-9 \\\\ !"&\'()*+,\\-.\\/:;<=>?@[\\]_]+$')],
            ],
            email: ['', [Validators.required, Validators.email]],
        });
        this.checkRegistrationEmail();
    }

    changePattern(key: string, pattern: string) {
        return this.form?.controls?.[key].errors?.['pattern']?.['requiredPattern'] === pattern;
    }

    onChangePhoto(file: File | null): void {
        this.selectedPhoto = file;
    }

    private checkRegistrationEmail(): void {
        const state = this._router.getCurrentNavigation()?.extras.state as RegisterRouteParams;
        const { email } = state || { email: '' };
        if (!email) {
            this._router.navigate(['/auth/login']).then();
            return;
        }
        this.routeParams = state;
    }

    get firstName() {
        return this.form?.get('firstName');
    }

    get lastName() {
        return this.form?.get('lastName');
    }

    get nickName() {
        return this.form?.get('nickname');
    }

    get email() {
        return this.form?.get('email');
    }

    get errors(): Map<string, boolean | undefined> {
        return new Map([
            [
                'firstName',
                this.form?.get('firstName')?.invalid &&
                    (this.form?.get('firstName')?.dirty || this.form?.get('firstName')?.touched),
            ],
            [
                'lastName',
                this.form?.get('lastName')?.invalid &&
                    (this.form?.get('lastName')?.dirty || this.form?.get('lastName')?.touched),
            ],
            [
                'nickname',
                this.form?.get('nickname')?.invalid &&
                    (this.form?.get('nickname')?.dirty || this.form?.get('nickname')?.touched),
            ],
        ]);
    }

    inputChange(key: string) {
        this.form.controls[key].markAsUntouched();
    }

    ngOnInit() {
        if (this.routeParams) {
            this.form.patchValue({
                email: this.routeParams.email,
                firstName: this.routeParams.firstName || '',
                lastName: this.routeParams.lastName || '',
            });
            this._cdr.detectChanges();
        }
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const referralCode = this._route.snapshot.queryParams['inviteLinkId'] || '';
            this.preloadService.preload(true);
            defer(() =>
                Boolean(this.routeParams?.provider)
                    ? this.authService.registerSocial({
                          ...this.form.value,
                          token: this.routeParams.token,
                          provider: this.routeParams.provider,
                          identifier: this.routeParams.identifier,
                          referralCode,
                      })
                    : this.authService.registerDefault({
                          ...this.form.value,
                          referralCode,
                          token: this.routeParams.token,
                      })
            )
                .pipe(
                    take(1),
                    tap((resp) => {
                        this.store.dispatch(
                            new SaveAuthDataAction({
                                data: {
                                    id: resp.id,
                                    email: resp.email,
                                    jwt: resp.jwt,
                                    refreshToken: resp.refreshToken,
                                    stripeCustomerId: resp.stripeCustomerId,
                                },
                            })
                        );
                    }),
                    switchMap((resp) => {
                        return this.selectedPhoto
                            ? this.imagesService
                                  .uploadUserPhoto(resp.id, this.selectedPhoto, 'FULL_FACE_AVATAR')
                                  .pipe(map(() => resp))
                            : of(resp);
                    }),
                    tap(() => {
                        this.authService.currentUser$.subscribe((value: any) => {
                            this._router.navigate(['/']).then(() => {});
                        });
                    }),
                    finalize(() => this.preloadService.preload(false)),
                    untilDestroy(this)
                )
                .subscribe();
        }
    }
}
