import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../../../environments/environment';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: 'spartacus-mma.com',
    },
    position: 'bottom',
    enabled: environment.production,
    theme: 'block',
    palette: {
        popup: {
            background: '#181818',
            text: '#fff',
            link: '#fff',
        },
        button: {
            background: '#FD5003',
            text: '#fff',
            border: 'transparent',
        },
    },
    type: 'info',
    content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: 'https://cookiesandyou.com',
        policy: 'Cookie Policy',
    },
};
