import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
} from '@angular/core';
import { TabsListItemModel } from '../../models/shared/tabs-list-item.model';

type TabsComponentSimpleChanges<T> = {
    [propName in keyof TabsComponent<T>]: SimpleChange;
};

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent<T> implements OnInit, OnChanges {
    @Input() tabsList: TabsListItemModel<T>[] = [];
    @Input() defaultSelected: T | null = null;
    @Input() eventsStyle = false;
    @Input() transparentBg = false;
    @Output() onChange = new EventEmitter<T>();
    activeTab: T;

    constructor(public _cdr: ChangeDetectorRef) {}

    selectTab(item: TabsListItemModel<T>) {
        this.activeTab = item.value;
        this.onChange.emit(this.activeTab);
    }

    ngOnInit() {
        this.activeTab = this.tabsList[0]?.value;
    }

    ngOnChanges(changes: TabsComponentSimpleChanges<T>) {
        if (changes.defaultSelected) {
            this.activeTab =
                this.tabsList.find((item: TabsListItemModel<T>) => item.value === this.defaultSelected)?.value ||
                this.tabsList[0]?.value;
        }
    }
}
