<ng-container *ngIf="{ layout: layoutState$ | async } as data">
    <app-loader *ngIf="this.preloadService.isLoad$ | async"></app-loader>
    <app-header [sideMenuState]='sideMenuState' (menuToggle)='burgerMenuClickHandler($event)' [layoutState]="data?.layout"></app-header>
    <main class='main' [class.menu-open]='sideMenuState.isOpen'>
        <div class="left-menu-wrapper" *ngIf="data.layout.showSideBar">
            <app-left-menu [sideMenuState]='sideMenuState'></app-left-menu>
        </div>
        <div class="main-content" [class.no-header]="!data.layout.showHeader">
            <router-outlet></router-outlet>
        </div>
    </main>
</ng-container>

<ng-template #mobileView>
    <app-mobile-view></app-mobile-view>
</ng-template>
