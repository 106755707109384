import { ChangeDetectorRef, Component, Inject, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { concatMap, filter, forkJoin, map, Observable, of, tap } from 'rxjs';
import { LayoutStateInterface } from '../../../../app.service';
import { HEADER_NAV_CONFIG } from '../../../../config/header-nav.config';
import { GetPermissionsListModel } from '../../../../models/users/permissions-user/get-permissions-list/get-permissions-list.model';
import { FcaAuthService } from '../../../../services/fca-auth.service';
import { SpartaCoinsService } from '../../../../services/sparta-coins.service';
import { SaveSpartaCoinsBonus, SaveUserPermissions } from '../../../../store/actions';
import { FcaStoreService } from '../../../../store/store.service';
import { Language } from '../../../enums/language.enum';
import { PermissionStatusEnum } from '../../../enums/permission-status.enum';
import { PermissionEnum } from '../../../enums/permission.enum';
import { AuthData } from '../../../interfaces/auth-data.interface';
import { HeaderNavModel } from '../../../models/shared/header-nav.model';
import { SideMenuStateModel } from '../../../models/shared/side-menu-state.model';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';
import { ScreenSizeService } from '../../../services/screen-size.service';
import { WINDOW } from '../../../tokens/window.token';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    lang: Language | '';
    @Input() layoutState: LayoutStateInterface;
    routerConfig: HeaderNavModel[] = HEADER_NAV_CONFIG;
    scrolledHeader = false;
    balance = new Observable<number | null>();
    isForMobile$ = new Observable<boolean>();
    @Output() menuToggle = new EventEmitter<boolean>();
    @Input() sideMenuState: SideMenuStateModel;

    constructor(
        private readonly screenSizeService: ScreenSizeService,
        private readonly storeService: FcaStoreService,
        public readonly spartaCoinsService: SpartaCoinsService,
        public readonly fcaAuthService: FcaAuthService,
        public _cdr: ChangeDetectorRef,
        @Inject(WINDOW) private window: Window,
        private readonly translate: TranslocoService
    ) {
        this.isForMobile$ = this.screenSizeService.isForMobile();
        storeService
            .getLanguage()
            .pipe(
                tap((lang: Language) => {
                    this.lang = lang === Language.EN ? '' : lang;
                }),
                untilDestroy(this)
            )
            .subscribe();
        this.checkScroll();
        this.getSpartaCoinsBalance();
    }

    getTranslate(key: string): Observable<string> {
        return this.translate.selectTranslate(key);
    }

    getSpartaCoinsBalance() {
        this.storeService
            .getAuthData()
            .pipe(
                untilDestroy(this),
                concatMap((authData: AuthData | null) => {
                    if (authData) {
                        return forkJoin([
                            this.spartaCoinsService.getBalance(),
                            this.fcaAuthService.getPermissionList({
                                limit: 20,
                                page: 0,
                                types: [PermissionEnum.CONTENT_CREATOR],
                                statuses: [PermissionStatusEnum.APPROVED],
                                userIds: [authData.id || 0],
                            }),
                        ]);
                    } else {
                        return of(null);
                    }
                }),
                tap((response: { 0: number; 1: GetPermissionsListModel[] } | null) => {
                    if (response) {
                        this.storeService.dispatch(new SaveSpartaCoinsBonus({ spartaCoinsBalance: response[0] }));
                        this.storeService.dispatch(
                            new SaveUserPermissions({
                                userPermissions: response[1]?.map((item: GetPermissionsListModel) => item.type),
                            })
                        );
                    }
                })
            )
            .subscribe();
    }

    checkScroll() {
        this.window?.addEventListener('scroll', (e: any) => {
            this.scrolledHeader = this.window?.scrollY > 10;
            this._cdr.detectChanges();
        });
    }

    ngOnInit(): void {}

    closeMenu() {
        this.sideMenuState.isOpen = false;
        this._cdr.detectChanges();
    }

    burgerMenuClickHandler() {
        this.sideMenuState.isOpen = !this.sideMenuState.isOpen;
    }
}
