import { FightEventPriceApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { FightEventPriceModel } from './fight-event-price.model';

export class FightEventPriceMapper implements ModelDataMapper<FightEventPriceApiResponse, FightEventPriceModel> {
    mapData(raw: FightEventPriceApiResponse): FightEventPriceModel {
        return {
            currency: raw.currency,
            default: raw.default,
            discountPrice: raw.discountPrice,
            discountPriceForDisplay: raw?.currency ? raw.discountPrice / 100 : raw.discountPrice,
            originalPrice: raw.originalPrice,
            originalPriceForDisplay: raw?.currency ? raw.originalPrice / 100 : raw.originalPrice,
            priceId: raw.priceId,
            sale: raw.sale,
        };
    }
}
