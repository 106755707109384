import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { CheckUserParams } from './params/check-user.params';
import { LoginAppleParams, LoginDefaultParams, LoginFacebookParams, LoginGoogleParams } from './params/login.params';
import { PatchUserDataParams } from './params/patch-user-data.params';
import { RegisterUserDefaultParams, RegisterUserSocialParams } from './params/register-user.params';
import { SendSmsParams } from './params/send-sms.params';
import { CheckUserResponse } from './response/check-user.response';
import { AuthResponse, LoginResponse } from './response/login.response';
import { UserResponse } from './response/user.response';

@Injectable()
export class FcaAuthApiService extends FcaApiServiceBase {
    login(params: LoginDefaultParams): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'login'].join('/');

        return this.http.post<LoginResponse>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    loginWithApple(params: LoginAppleParams, code: string, userId: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'apple', 'login'].join('/');

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-apple-code': code,
                    'x-apple-user-id': userId,
                },
            })
            .pipe(shareReplay(1));
    }

    loginWithFacebook(params: LoginFacebookParams, token: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'facebook', 'login'].join('/');

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-facebook-token': token,
                },
            })
            .pipe(shareReplay(1));
    }

    loginWithGoogle(params: LoginGoogleParams, idToken: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'google', 'login'].join('/');

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-google-token': idToken,
                },
            })
            .pipe(shareReplay(1));
    }

    registerNewUserDefault(params: RegisterUserDefaultParams): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'register-fan'].join('/');

        return this.http.post<LoginResponse>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    registerNewUserGoogle(params: RegisterUserSocialParams, token: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'google', 'register-fan'].join('/');

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-google-token': token,
                },
            })
            .pipe(shareReplay(1));
    }

    registerNewUserFacebook(params: RegisterUserSocialParams, token: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'facebook', 'register-fan'].join('/');

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-facebook-token': token,
                },
            })
            .pipe(shareReplay(1));
    }

    registerNewUserApple(params: RegisterUserSocialParams, code: string, userId: string): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'apple', 'register-fan'].join('/');
        console.log('before register', params);
        console.log('headers', {
            ...this.defaultHeaders,
            'x-apple-code': code,
            'x-apple-user-id': userId,
        });

        return this.http
            .post<LoginResponse>(url, params, {
                headers: {
                    ...this.defaultHeaders,
                    'x-apple-code': code,
                    'x-apple-user-id': userId,
                },
            })
            .pipe(shareReplay(1));
    }

    generateNewTokens(refreshToken: string): Observable<AuthResponse> {
        const url = [this.apiUrl, 'auth', 'refresh'].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                ...this.defaultHeaders,
                refresh: refreshToken,
            }),
        };

        return this.http.post<AuthResponse>(url, {}, httpOptions).pipe(shareReplay(1));
    }

    getAuthUser(): Observable<UserResponse> {
        const url = [this.apiUrl, 'users', 'current'].join('/');

        return this.http.get<UserResponse>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    sendPinToEmail(params: SendSmsParams): Observable<void> {
        const url = [this.apiUrl, 'email', 'send-pincode'].join('/');

        return this.http.post<void>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    checkUser(params: CheckUserParams): Observable<CheckUserResponse> {
        const url = [this.apiUrl, 'email', 'check-user'].join('/');

        return this.http.post<CheckUserResponse>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    fakeSeoBotLogin(): Observable<LoginResponse> {
        const url = [this.apiUrl, 'auth', 'fake-bot-login'].join('/');

        return this.http.post<LoginResponse>(url, {}, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    patchUserInfo(body: PatchUserDataParams): Observable<UserResponse> {
        const url = [this.apiUrl, 'users'].join('/');
        return this.http.patch<UserResponse>(url, { ...body }, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    patchFCMToken(token: string, deviceId: string, locale: string): Observable<any> {
      const url = [this.apiUrl, 'users', 'devices', deviceId].join('/');
      console.log(token);
      console.log(deviceId);
      console.log(locale);
      return this.http.patch(url, { "fcmToken": token, "locale": locale, }, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
}
