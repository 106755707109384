function arrayToIndexString(key: string, values: string[]): string {
    return values.map((val, idx) => `${key}[${idx}]=${val}`).join('&');
}

export const objectToQuery = (obj: any): string => {
    return (
        '?' +
        Object.entries(obj)
            .map(([key, value]) => {
                return Array.isArray(value) ? arrayToIndexString(key, value) : `${key}=${value}`;
            })
            .join('&')
    );
};
