import { EUserRole } from '../../../../app/shared/enums/user-role.enum';

export class PatchUserDataParams {
    roleName: EUserRole = EUserRole.NONE;
    lastName = '';
    firstName = '';
    description = '';
    nickname = '';
    thumbnailAvatar = '';
}
