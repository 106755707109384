import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { AddImageToPostParams } from './params/add-image-to-post.params';
import { GetSignedUploadUrlParams } from './params/get-signed-upload-url.params';
import { UpdateImageParams } from './params/update-image.params';
import { HandleUploadApiResponse } from './response/handle-upload-api.response';

@Injectable({
    providedIn: 'root',
})
export class PostImageApiService extends FcaApiServiceBase {
    getSignedUploadUrl(body: GetSignedUploadUrlParams): Observable<{ id: string; url: string }> {
        const url = [this.postUrl, 'images/upload/signed-upload-url'].join('/');
        return this.http.post<any>(url, body);
    }

    handleUpload(postId: string): Observable<HandleUploadApiResponse> {
        const url = [this.postUrl, 'images/upload/handle-upload'].join('/');
        return this.http.post<HandleUploadApiResponse>(url, { postId });
    }

    deleteImage(imageId: string, postId: string): Observable<void> {
        const url = [this.postUrl, `images/${imageId}`].join('/');
        return this.http.delete<void>(url, { body: { postId } });
    }

    addImageToPost(body: AddImageToPostParams) {
        const url = [this.postUrl, `images`].join('/');
        return this.http.post<void>(url, body);
    }

    updateImages(body: UpdateImageParams): Observable<void> {
        const url = [this.postUrl, `images`].join('/');
        return this.http.patch<void>(url, body);
    }
}
