import { FightEventVideoModel } from '../fight-event-video/fight-event-video.model';
import { EFightEventStatus } from '../fight-event.model';

export interface FightEventWeightingModelData {
    fromDate: string;
    status: EFightEventStatus;
    toDate: string;
    requirements: string[];
    video: FightEventVideoModel;
}

export interface FightEventWeightingModel extends FightEventWeightingModelData {}

export class FightEventWeightingModel {}
