import { ModelFactory } from '../../../shared/abstract/model-factory.abstract';
import { KicksMinInfoListItemModel, KicksMinInfoListItemModelData } from './kicks-min-info-list-item.model';

export class KicksMinInfoListItemFactory extends ModelFactory<
    KicksMinInfoListItemModelData,
    KicksMinInfoListItemModel
> {
    protected getInstance(data: KicksMinInfoListItemModelData): KicksMinInfoListItemModel {
        return new KicksMinInfoListItemModel();
    }
}
