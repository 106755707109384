import * as moment from 'moment-timezone';
import { UserResponseDevices } from '../../../api/fca/auth/response/user.response';
import { UserApiResponse } from '../../../api/fca/users/response/user-api.response';
import { EUserRole } from '../../shared/enums/user-role.enum';
import { ModelDataMapper } from '../../shared/interfaces/model-mapper.interface';
import { ImageFactory } from '../../shared/models/image/image.factory';
import { ImageMapper } from '../../shared/models/image/image.mapper';
import { ImageModel } from '../../shared/models/image/image.model';
import { UserModelData } from './user.model';

export class UserMapper implements ModelDataMapper<UserApiResponse, UserModelData> {
    mapData(raw: UserApiResponse): UserModelData {
        return {
            id: raw.id,
            roleName: raw.roleName as EUserRole,
            email: raw.email,
            isPremium: raw.isPremium,
            isContentCreator: raw.isContentCreator,
            images: (raw.images || []).reduce<ImageModel[]>((acc, rawImg) => {
                acc.push(new ImageFactory().getModelFromData(new ImageMapper().mapData(rawImg)));
                return acc;
            }, []),
            createDate: raw.createDate,
            deletedDate: raw.deletedDate ? moment(raw.deletedDate) : null,
            referralCode: raw.referralCode,
            stripeCustomerId: raw.stripeCustomerId || null,
            socials: raw.socials || [],
            locale:
                raw.devices.find((device: UserResponseDevices) => device.deviceName === 'web-site')?.locale ||
                raw.devices[0]?.locale ||
                '',
        };
    }
}
