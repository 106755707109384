import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageLinks } from '../../../config/seo/seo-config.interface';
import { SeoSetUpService } from '../../../config/seo/seo-set-up.service';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyComponent {
    constructor(private readonly seoService: SeoSetUpService) {
        this.seoService.setMetaByPage(PageLinks.PrivacyPolicy);
    }
}
