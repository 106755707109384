import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { objectToQuery } from '../../helpers/object-to-query.helper';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { FightEventsPreview } from './params/fight-events-preview';
import { GetFightEventsLightListParams } from './params/get-fight-events-light-list.params';
import { PutReactionParams } from './params/put-reaction.params';
import { FightEventApiResponse, FightEventLightApiResponse } from './response/fight-event-api.response';
import { GroupedReactionsDataResponse } from './response/grouped-reactions-data.response';
import { MainPageApiResponse } from './response/main-page-api.response';
import { TvEventResponse } from './response/tv-event.response';

@Injectable()
export class FcaFightEventsApiService extends FcaApiServiceBase {
    list(params: GetFightEventsLightListParams): Observable<FightEventLightApiResponse[]> {
        const query = objectToQuery(params);
        const url = [this.apiUrl, 'fight-events', 'list', query].join('/');

        return this.http.get<FightEventLightApiResponse[]>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    mainPageFightEvents(params: FightEventsPreview): Observable<FightEventLightApiResponse[]> {
        const query = objectToQuery(params);
        const url = [this.apiUrl, 'web', 'fight-events', 'preview', query].join('/');

        return this.http.get<FightEventLightApiResponse[]>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    getFightEventDetail(id: string): Observable<FightEventApiResponse> {
        const url = [this.apiUrl, 'fight-events', id].join('/');

        return this.http.get<FightEventApiResponse>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    getFightEventDetailWeb(id: string): Observable<FightEventApiResponse> {
        const url = [this.apiUrl, 'web', 'fight-events', id].join('/');

        return this.http.get<FightEventApiResponse>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    putReaction(params: PutReactionParams): Observable<GroupedReactionsDataResponse> {
        const url = [this.apiUrl, 'fight-events', 'put-reaction'].join('/');

        return this.http
            .post<GroupedReactionsDataResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(shareReplay(1));
    }

    getTvList(): Observable<TvEventResponse[]> {
        const url = [this.apiUrl, 'tv/list'].join('/');
        return this.http.get<TvEventResponse[]>(url).pipe(shareReplay(1));
    }

    getPpvList(params: GetFightEventsLightListParams): Observable<FightEventLightApiResponse[]> {
        const query = objectToQuery(params);
        const url = [this.apiUrl, 'fight-events/list/ppv', query].join('/');

        return this.http.get<FightEventLightApiResponse[]>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    getMainPage(): Observable<MainPageApiResponse[]> {
        const url = [this.apiUrl, 'meta/main-page'].join('/');
        return this.http.get<MainPageApiResponse[]>(url).pipe(shareReplay(1));
    }
}
