import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '../../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    readonly year = new Date().getFullYear();
    pageList: { url: string; text: string; openNewTab: boolean }[] = [
        { url: '/contacts', text: 'footer-contact', openNewTab: false },
        { url: 'https://blog.spartacus-mma.com/', text: 'footer-blog', openNewTab: true },
        { url: '', text: 'footer-company', openNewTab: false },
    ];
    pageOutList: { url: string; text: string; disabled: boolean }[] = [
        { url: '/privacy-policy', text: 'footer-privacy-policy', disabled: false },
        { url: '/terms-of-use', text: 'footer-terms-conditions', disabled: false },
        // { url: '', text: 'footer-spartacus', disabled: true },
    ];
    smList: { url: string; imgName: string; alt: string }[] = [
        { imgName: 'footer_fb-icon.svg', url: 'https://m.facebook.com/100083197333420/', alt: 'Spartacus MMA in facebook' },
        { imgName: 'footer_in-icon.svg', url: 'https://instagram.com/spartacusapp?igshid=YmMyMTA2M2Y=', alt: 'Spartacus MMA in instagram' },
        { imgName: 'footer_youtube-icon.svg', url: 'https://www.youtube.com/@spartacusapp', alt: 'Spartacus MMA in youtube' },
    ];

    constructor() {}
}
