<ng-container *transloco="let t">
    <div class="three-dots-wrapper" *ngIf='threeDotsMenuList?.length' (clickOutside)="isOpen = false" (click)='stopPropagation($event)'>
        <div class="three-dots-image-wrapper" (click)="dotClick($event)">
            <img src="assets/images/three-dots.svg" alt="" />
        </div>
        <ul class="menu"
            [class.left-position]="menuPosition === 'left'"
            [class.right-position]="menuPosition === 'right'"
            *ngIf="isOpen && threeDotsMenuList">
            <ng-container *ngFor="let item of threeDotsMenuList">
                <li class="menu-item" (click)='menuItemClick($event, item)'>
                    {{ t(item.text) }}
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>
