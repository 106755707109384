<div class="avatar-uploader" *transloco="let t">
    <label
        for="avatar-uploader"
        [matTooltip]="t('register-add-photo-tooltip')"
        class="avatar-uploader-block"
        [ngStyle]="cardImageBase64?.length ? { 'background-image': (cardImageBase64 | backgroundImageUrl : '') } : {}"
    >
        <input
            type="file"
            id="avatar-uploader"
            style="display: none"
            #fileInput
            (input)="onPhotoSelected($event)"
            accept="image/jpeg, image/jpg, image/png, image/webp"
        />
        <span *ngIf="!cardImageBase64" class="avatar-uploader-button avatar-uploader-button-empty center">
            <span class="note">
                {{ t('register-add-photo-label') }}
            </span>
        </span>
        <span
            *ngIf="cardImageBase64"
            class="avatar-uploader-button avatar-uploader-button-filled"
            (click)="removeImage()"
        >
        </span>
    </label>
</div>
