import { createSelector } from '@ngrx/store';
import { FcaState } from './state';

export const selectFcaFeature = (state: any): FcaState => state.fca;

export const getAuthData = createSelector(selectFcaFeature, (state) => state.authData);

export const getUser = createSelector(selectFcaFeature, (state) => state.user);

export const getLanguage = createSelector(selectFcaFeature, (state) => state.settings.language);

export const getVolume = createSelector(selectFcaFeature, (state) => state.settings.videoVolume);

export const getCurrentFightEventId = createSelector(selectFcaFeature, (state) => state.currentFightEventId);

export const getActiveMenuItemForMainPage = createSelector(
    selectFcaFeature,
    (state) => state.settings.mainPageMenuActiveItem
);

export const getSubscriptionData = createSelector(selectFcaFeature, (state) => state.subscriptionData);
export const getLastEventVideoWatchTime = createSelector(
    selectFcaFeature,
    (state) => state.settings.lastEventVideoWatchTime
);
export const getCurrentItemFromPlaylist = createSelector(selectFcaFeature, (state) => state.currentItemInPlaylist);
export const getEventDataBeforePayment = createSelector(selectFcaFeature, (state) => state.eventDataBeforePayment);
export const getSpartaCoinsBalance = createSelector(selectFcaFeature, (state) => state.spartaCoinsBalance);
export const getUserPermissions = createSelector(selectFcaFeature, (state) => state.userPermissions);
export const getSideBarState = createSelector(selectFcaFeature, (state) => state.sideBarState);
export const getFCMToken = createSelector(selectFcaFeature, (state) => state.fcmToken);
