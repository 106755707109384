import { FightEventLocationApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { GeneralAddressFactory } from '../../../shared/models/general-address/general-address.factory';
import { GeneralAddressMapper } from '../../../shared/models/general-address/general-address.mapper';
import { FightEventLocationModelData } from './fight-event-location.model';

export class FightEventLocationMapper
    implements ModelDataMapper<FightEventLocationApiResponse, FightEventLocationModelData>
{
    mapData(raw: FightEventLocationApiResponse): FightEventLocationModelData {
        return {
            id: raw.id,
            ...(raw.address && {
                address: new GeneralAddressFactory().getModelFromData(new GeneralAddressMapper().mapData(raw.address)),
            }),
            name: raw.name,
            link: raw.link,
            description: raw.description,
            isPending: raw.isPending,
            timeZone: raw.timeZone,
            timeZoneOffset: raw.timeZoneOffset,
            fightEvents: [],
        };
    }
}
