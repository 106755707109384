<div class="JoinNowTo" [ngClass]="data?.isModalLayout === true ? 'modal':''" *transloco="let t">
  <h1>{{ t('index-body-join_now-title')  }}</h1>
  <h2>{{ t('index-body-join_now-subtitle')  }}</h2>
  <app-roles-list (roleChanged)="onSelectRole($event)" [hasError]="errors.hasRoleError"></app-roles-list>
  <div class="Input">
    <input name="email" [formControl]="email" type="email" required autocomplete="off"
           placeholder="{{ t('index-body-join_now-placeholder') }}"/>
    <div *ngIf="email.invalid && (email.dirty || email.touched)"
         class="input-validation">
      <div class="input-validation-error" *ngIf="email.errors?.['required']">
        The email field must not be empty
      </div>
      <div class="input-validation-error" *ngIf="email.errors?.['email']">
        Email address is incorrect
      </div>
    </div>
  </div>
  <button (click)="onSubmit()">{{ t('index-body-join_now_header-button')  }}</button>
</div>
