import { PostListItemApiResponse } from '../../../../api/fca/post/response/post-list-item-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { PostListModelData } from './post-list.model';

export class PostListMapper implements ModelDataMapper<PostListItemApiResponse, PostListModelData> {
    mapData(raw: PostListItemApiResponse): PostListModelData {
        return {
            id: raw.id,
            text: raw.text,
            commentsEnabled: raw.commentsEnabled,
            access: raw.access,
            state: raw.state,
            subscribed: raw.subscribed,
            likes: raw.likes,
            ownerId: raw.ownerId,
            liked: raw.liked,
            images: raw.images.sort((a, b) => a.position - b.position),
            createdAt: raw.createdAt,
            updatedAt: raw.updatedAt,
            owner: {
                id: raw.owner.id,
                firstName: raw.owner.firstName,
                lastName: raw.owner.lastName,
                thumbnailAvatar: raw.owner.thumbnailAvatar,
                backgroundImg: raw.owner.backgroundImage,
                nickname: raw.owner.nickname,
                followers: raw.owner.followers,
                posts: raw.owner.posts,
                videos: raw.owner.videos,
                views: raw.owner.views,
            },
            likeUsers: raw.likeUsers,
        };
    }
}
