import { KicksListMinInfoApiResponse } from '../../../../api/fca/kicks-video/response/kicks-list-min-info-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { KicksMinInfoListItemModel } from './kicks-min-info-list-item.model';

export class KicksMinInfoListItemMapper
    implements ModelDataMapper<KicksListMinInfoApiResponse, KicksMinInfoListItemModel>
{
    mapData(raw: KicksListMinInfoApiResponse): KicksMinInfoListItemModel {
        return {
            id: raw.id,
            href: raw.href,
            thumbnailHref: raw.thumbnailHref,
            views: raw.views,
        };
    }
}
