import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreloadService implements OnDestroy {
    private destroy$ = new Subject<void>();
    private activations = 0;

    private loadStatus$ = new BehaviorSubject<boolean>(false);

    get isLoad$(): Observable<boolean> {
        return this.loadStatus$.asObservable();
    }

    preload(status: boolean): void {
        if (status) {
            this.runPreload();
        } else {
            this.stopPreload();
        }
    }

    private runPreload() {
        if (!this.activations) {
            this.loadStatus$.next(true);
        }
        this.activations++;
    }

    private stopPreload() {
        this.activations--;

        if (this.activations < 0) {
            this.activations = 0;
        }

        if (!this.activations) {
            this.loadStatus$.next(false);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
