import { FightEventLocationLightApiResponse } from '../../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../../shared/interfaces/model-mapper.interface';
import { FightEventLocationLightModelData } from './fight-event-location-light.model';

export class FightEventLocationLightMapper
    implements ModelDataMapper<FightEventLocationLightApiResponse, FightEventLocationLightModelData>
{
    mapData(raw: FightEventLocationLightApiResponse): FightEventLocationLightModelData {
        return {
            name: raw.name,
            address: {
                city: raw.address.city,
                country: raw.address.country,
            },
            isPending: raw.isPending,
            image: raw.image,
        };
    }
}
