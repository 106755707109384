import * as moment from 'moment-timezone';
import { ImageApiResponse } from '../../../../api/fca/images/response/user-image-api.response';
import { ModelDataMapper } from '../../interfaces/model-mapper.interface';
import { ImageModel } from './image.model';

export class ImageMapper implements ModelDataMapper<ImageApiResponse, ImageModel> {
    mapData(raw: ImageApiResponse): ImageModel {
        return {
            id: raw.id,
            href: raw.href,
            name: raw.name,
            original: raw.original,
            position: raw.positionId,
            imageType: raw.imageType,
            createDate: raw.createDate,
        };
    }
}
