import { EFightEventStatus } from '../../../../app/models/fight-event/fight-event.model';
import { OffsetPagination } from '../../../../app/shared/interfaces/offset-pagination.interface';

export class FightEventsPreview implements OffsetPagination {
    status: EFightEventStatus[] = [];
    order: 'ASC' | 'DESC' = 'DESC';
    limit = 10;
    page = 0;

    constructor(params?: { status: EFightEventStatus[]; limit?: number; order?: 'ASC' | 'DESC' }) {
        if (params?.status) {
            this.status = params.status;
        }
        if (params?.limit) {
            this.limit = params.limit;
        }
        if (params?.order) {
            this.order = params.order;
        }
    }
}
