import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FlatButtonIconData } from '../flat-button/flat-button.component';

@Component({
    selector: 'app-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent implements OnInit {
    @Output() onClick = new EventEmitter<any>();

    icon: FlatButtonIconData = {
        alt: 'Share',
        assetUrl: 'assets/images/ic_share.svg',
        title: 'Share',
        width: 16,
        height: 16,
    };

    constructor() {}

    ngOnInit(): void {}

    click(e: Event) {
        e.preventDefault();
        this.onClick.emit(e);
    }
}
