import { ActiveSubscriptionResponse } from '../../../../api/fca/payments/response/active-subscription.response';
import { PaymentSystemEnum } from '../../../shared/enums/payment-system.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { ActiveSubscriptionModelData } from './active-subscription.model';

export class ActiveStripeSubscriptionMapper
    implements ModelDataMapper<ActiveSubscriptionResponse, ActiveSubscriptionModelData>
{
    mapData(raw: ActiveSubscriptionResponse): ActiveSubscriptionModelData {
        return {
            subscriptionId: raw.subscriptionId,
            interval: raw.interval,
            intervalCount: raw.intervalCount,
            currency: raw.currency,
            startDate: raw.startDate,
            endDate: new Date(raw.endDate).getTime() * 1000,
            canceledAt: raw.canceledAt,
            cancellationReason: raw.cancellationReason,
            paymentSystem: PaymentSystemEnum.STRIPE,
        } as ActiveSubscriptionModelData;
    }
}
