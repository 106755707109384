import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationEnd, Event, Router } from '@angular/router';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { GetPermissionListParams } from '../../../../../api/fca/api/permissions-user/params/get-permission-list.params';
import { AuthUserModel } from '../../../../models/users/auth-user/auth-user.model';
import { GetPermissionsListModel } from '../../../../models/users/permissions-user/get-permissions-list/get-permissions-list.model';
import { FollowListItemModel } from '../../../../modules/profile/models/follow-list-item.model';
import { FcaAuthService } from '../../../../services/fca-auth.service';
import { FollowersService } from '../../../../services/followers.service';
import { ProfileService } from '../../../../services/profile.service';
import { FcaStoreService } from '../../../../store/store.service';
import { Language } from '../../../enums/language.enum';
import { PermissionStatusEnum } from '../../../enums/permission-status.enum';
import { PermissionEnum } from '../../../enums/permission.enum';
import { SideBarStateModel } from '../../../models/shared/side-bar-state.model';
import { SideMenuStateModel } from '../../../models/shared/side-menu-state.model';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';
import { SnackbarService, SnackBarStyles } from '../../../services/snackbar.service';
import { AddPostPopupComponent } from '../../add-post-popup/add-post-popup.component';
import { BecomeContentCreatorPopupComponent } from '../../become-content-creator-popup/become-content-creator-popup.component';
import { CreatorsListComponent } from '../creators-list/creators-list.component';

interface NavMenu {
    iconName: string;
    text: string;
    href?: string;
    subMenuList?: SubNavMenu[];
    clickHandler?: () => void;
    menuIsOpen?: boolean;
}

interface SubNavMenu {
    iconName: string;
    text: string;
    href: string;
    clickHandler?: () => void;
}

@UntilDestroy()
@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
    navMenu: NavMenu[] = [
        {
            iconName: 'side-nav-discovery.svg',
            text: 'side-bar-menu-discovery',
            href: '/',
        },
        {
            iconName: 'side-nav-category.svg',
            text: 'side-bar-menu-category',
            href: '/other-events',
        },
        {
            iconName: 'side-nav-kicks.svg',
            text: 'side-bar-menu-kicks',
            href: '/kicks',
        },
        {
            iconName: 'side-nav-creators.svg',
            text: 'side-bar-menu-creators',
            href: '',
            clickHandler: this.openCreatorsTab,
        },
        {
            iconName: 'side-nav-live.svg',
            text: 'side-bar-menu-live',
            href: '/live-events',
        },
        {
            iconName: 'side-nav-events.svg',
            text: 'side-bar-menu-events',
            href: '/events',
        },
        // {
        //     iconName: 'side-nav-watched.svg',
        //     text: 'Watched',
        //     href: '/watched',
        // },
    ];
    navMenuForCreatorSpace: NavMenu[] = [
        {
            iconName: 'side-nav-creators-space.svg',
            text: 'side-bar-menu-creators-space',
            href: '',
            subMenuList: [
                {
                    iconName: 'side-nav-added-videos.svg',
                    text: 'side-bar-menu-added-videos',
                    href: '/account',
                },
                {
                    iconName: 'side-nav-added-videos.svg',
                    text: 'side-bar-menu-add-post',
                    href: '',
                    clickHandler: this.openAddPostModal,
                },
                // {
                //     iconName: 'side-nav-my-streams.svg',
                //     text: 'My streams',
                //     href: '/my-streams',
                // },
                // {
                //     iconName: 'side-nav-analitycs.svg',
                //     text: 'Analitycs',
                //     href: '/analitycs',
                // },
            ],
        },
    ];
    lang: Language | '';
    followsList$ = new Observable<FollowListItemModel[]>();
    sideBarState = new SideBarStateModel();
    profileData$ = new Observable();
    user$ = new Observable();
    @Input() sideMenuState: SideMenuStateModel;

    constructor(
        private readonly storeService: FcaStoreService,
        private readonly dialog: MatDialog,
        private followersService: FollowersService,
        private authService: FcaAuthService,
        private snackbarService: SnackbarService,
        private _route: Router,
        private _cdr: ChangeDetectorRef,
        private profileService: ProfileService
    ) {
        storeService
            .getLanguage()
            .pipe(
                tap((lang: Language) => {
                    this.lang = lang === Language.EN ? '' : lang;
                }),
                untilDestroy(this)
            )
            .subscribe();
        this.user$ = this.storeService.getUser();
        this.getProfileInfo();
        this._route.events
            .pipe(
                filter((ev: Event) => ev instanceof ActivationEnd),
                tap(() => {
                    this.sideMenuState.isOpen = false;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    getProfileInfo() {
        this.profileData$ = this.storeService.getUser().pipe(
            filter(Boolean),
            map((user: AuthUserModel) => user?.id),
            switchMap((userId: number) => this.profileService.getUserDataById(userId)),
            tap((user: any) => {
                if (user.isContentCreator) {
                    this.navMenu.push(...this.navMenuForCreatorSpace);
                }
            }),
            take(1),
            untilDestroy(this)
        );
    }

    openBecomeContentCreatorPopup(userId: number) {
        const permissionParams: GetPermissionListParams = {
            limit: 1,
            page: 0,
            statuses: [PermissionStatusEnum.APPROVED, PermissionStatusEnum.PENDING],
            userIds: [userId],
            types: [PermissionEnum.CONTENT_CREATOR],
        };
        this.authService
            .getPermissionList(permissionParams)
            .pipe(
                take(1),
                untilDestroy(this),
                tap((permissions: GetPermissionsListModel[]) => {
                    if (
                        permissions.some(
                            (item: GetPermissionsListModel) => item.status === PermissionStatusEnum.PENDING
                        )
                    ) {
                        this.snackbarService.show({
                            message: 'profile-page-become-content-creator-request-in-review',
                            style: SnackBarStyles.WARNING,
                            verticalPosition: 'bottom',
                        });
                    } else {
                        this.dialog.open(BecomeContentCreatorPopupComponent, {
                            backdropClass: 'become-content-creator-popup-backdrop',
                            panelClass: 'become-content-creator-popup-panel',
                            disableClose: true,
                        });
                    }
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.loadFollowsList();
        this.storeService
            .getSideBarState()
            .pipe(
                untilDestroy(this),
                filter(Boolean),
                tap((data: SideBarStateModel) => {
                    this.sideBarState = data;
                    this.navMenu.find((item: NavMenu) => item.text === 'Creators')!.menuIsOpen =
                        this.sideBarState.isCreatorsSpaceItemOpened;
                })
            )
            .subscribe();
    }

    loadFollowsList() {
        this.followsList$ = this.followersService.getFollows().pipe(
            take(1),
            untilDestroy(this),
            map((response: any[]) => {
                return response.map((user: any) => {
                    return {
                        avatar: user?.images[0]?.href,
                        fullName: `${user.firstName} ${user.lastName}`,
                        nickName: user.nickname,
                        id: user.userId,
                        isFollowing: true,
                        countFollowers: user.countFollowers,
                        verified: user.verified,
                    } as FollowListItemModel;
                });
            })
        );
    }

    toggleSubMenu(menuItemEl: HTMLDivElement, item: NavMenu) {
        if (item.subMenuList?.length) {
            menuItemEl.classList.toggle('open');
        }
        if (item.clickHandler) {
            item.clickHandler.call(this);
        }
    }

    openCreatorsTab() {
        this.dialog.open(CreatorsListComponent, {
            backdropClass: 'creators-list-popup-backdrop',
            panelClass: 'creators-list-popup-panel',
            disableClose: true,
        });
    }

    toggleCreatorsList(el: HTMLDivElement) {
        el.classList.toggle('open');
    }

    openAddPostModal() {
        this.dialog.open(AddPostPopupComponent, {
            backdropClass: 'add-post-popup-backdrop',
            panelClass: 'add-post-popup-panel',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    subMenuClick(subMenu: SubNavMenu) {
        if (subMenu.clickHandler) {
            subMenu.clickHandler.call(this);
        }
    }
}
