import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FightEventFightSlotModel } from '../../../models/fight-event/fight-event-fight-slot/fight-event-fight-slot.model';
import { EFightWinner } from '../../../models/fight/fight.model';

@Component({
    selector: 'app-fight-card',
    templateUrl: './fight-card.component.html',
    styleUrls: ['./fight-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FightCardComponent {
    @Input() slot: FightEventFightSlotModel;
    eFightWinner = EFightWinner;

    constructor(public translate: TranslocoService) {}

    checkCurrentFight(): boolean {
        return false;
    }
}
