import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, take, tap } from 'rxjs';
import { AuthUserModel } from '../models/users/auth-user/auth-user.model';
import { AuthData } from '../shared/interfaces/auth-data.interface';
import { untilDestroy, UntilDestroy } from '../shared/operators/until-destroy.operator';
import { FcaStoreService } from '../store/store.service';

@UntilDestroy()
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    user$: Observable<AuthUserModel | null> = of(null);

    constructor(private readonly storeService: FcaStoreService, private _router: Router) {
        this.user$ = this.storeService.getUser();
    }

    ngOnInit(): void {
        this.storeService
            .getAuthData()
            .pipe(
                take(1),
                tap((authData: AuthData | null) => {
                    if (authData) {
                        this._router.navigate(['/fight-event']).then();
                    }
                }),
                untilDestroy(this)
            )
            .subscribe();
    }
}
