import { EUserRole } from '../../../shared/enums/user-role.enum';

export interface KicksInfoByIdModel {
    id: string;
    description: string;
    href: string;
    thumbnailHref: string;
    likes: number;
    isLiked: boolean;
    comments: number;
    views: number;
    users: number;
    relation: any;
    tags: [];
    user: {
        avatar: string;
        donateRate: number;
        firstName: string;
        id: number;
        isPremiumUser: boolean;
        lastName: string;
        rating: number;
        roleName: EUserRole;
        thumbnailAvatar: string;
    };
}
export interface KicksInfoByIdModelModel {}
export class KicksInfoByIdModel {}
