import { Injectable } from '@angular/core';
import { AppLogger } from './logger';

/**
 * Реализация логера приложения, который кидает сообщения с помощью console
 */
@Injectable()
export class ConsoleLogger extends AppLogger {
    constructor() {
        super();
    }

    error(mgs: string, meta?: { [p: string]: any }) {
        console.error(mgs, meta);
    }

    log(mgs: string, meta?: { [p: string]: any }) {
        console.log(mgs, meta);
    }

    logError(e: Error, meta?: { [p: string]: any }) {
        console.error(e);
    }
}
