import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { SnackbarModel } from '../models/shared/snackbar.model';

export enum SnackBarStyles {
    ERROR = 'mat-snack-bar-container-error',
    SUCCESS = 'mat-snack-bar-container-success',
    WARNING = 'mat-snack-bar-container-warning',
    INFO = 'mat-snack-bar-container-info',
}

@Injectable()
export class SnackbarService {
    constructor(private _snackBar: MatSnackBar, private tra: TranslocoService) {}

    show(options: SnackbarModel): { close$: Observable<MatSnackBarDismiss> } {
        const ref = this._snackBar.open(this.tra.translate(options.message), options.action, {
            horizontalPosition: options.horizontalPosition || 'end',
            verticalPosition: options.verticalPosition || 'top',
            panelClass: options.style || SnackBarStyles.INFO,
            duration: options.duration || 3000,
        });

        return { close$: ref.afterDismissed() };
    }
}
