export interface GeneralAddressModelData {
    formattedAddress: string;
    country: string;
    city: string;
    street: string;
    building: string;
    latitude: number;
    longitude: number;
}

export interface GeneralAddressModel extends GeneralAddressModelData {}

export class GeneralAddressModel {}
