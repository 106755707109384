import {CurrencyEnum} from "../../../../app/shared/enums/currency.enum";
import {ImageApiResponse} from "../../images/response/user-image-api.response";

// BODY покупка подписки на криейтора /api/v1/user-personal-subscriptions/session
export interface BuyPersonalSubscriptionBody {
  "priceId": string;
  "subscriptionId": string;
  "subscriptionOwnerId": number;
  "currency": CurrencyEnum;
  "returnUrl": string;
}

/// BODY проверка подписки на криейтора
/// /api/v1/user-personal-subscriptions/{targetId}/status
export interface PersonalSubscriptionStatus {
  "targetId": number;
}
/// RESPONSE проверка подписки на криейтора
/// /api/v1/user-personal-subscriptions/session
export interface PersonalSubscriptionStatusResult {
  "active": boolean;
  "canceled": boolean | null;
  "expiresIn": number;
  "subscriptionId": string | null;
}

/// BODY список подписок у выбранного пользователя на которого хотим подписаться
/// /api/v1/user-personal-subscriptions/{userId}
export interface CreatorSubscriptionsList {
  "userId": number;
}
/// Экземпляр цены
export interface SubscriptionPrice {
  "id": string | null;
  "amount": number;
  "currency": CurrencyEnum;
}
/// RESPONSE список подписок у выбранного пользователя на которого хотим подписаться
export interface CreatorSubscriptionsListData {
  "id": string;
  "createdAt": Date;
  "interval": string;
  "intervalCount": number;
  "prices": Array<SubscriptionPrice>;
}

export class CreatorSubscriptionsListData implements CreatorSubscriptionsListData {
  constructor(e: CreatorSubscriptionsListData) {
    this.id = e.id;
    this.createdAt = e.createdAt;
    this.interval = e.interval;
    this.intervalCount = e.intervalCount;
    this.prices = e.prices;
  }
  public get defaultPrice(): SubscriptionPrice {
    return this.prices[0];
  }

  public get humanPrice(): string {
    return `${this.defaultPrice.amount / 100}`;
  }

  public get currencySymbol(): string {
    const currency = this.defaultPrice.currency;
    switch (currency) {
      case CurrencyEnum.USD:
        return '$';
      default:
        return currency;
    }
  }
}

/// BODY создание подписки криэйтором
/// /api/v1/user-personal-subscriptions
export interface CreateSubscriptionBody {
  "name": string;
  "interval": string;
  "intervalCount": number;
  "prices": Array<SubscriptionPrice>;
}

export interface UpdateSubscriptionPriceBody {
  "subscriptionId": string;
  "priceId": string;
  "newPrice": number;
}


export interface PersonalSubscriber {
  "firstName": string;
  "lastName": string;
  "nickname": string;
  "userId": number;
  "images": Array<ImageApiResponse>;
}
