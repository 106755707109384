export class OffsetPagination {
    limit = 10;
    page = 0;

    constructor(options?: { limit?: number; page?: number }) {
        if (options?.limit) {
            this.limit = options?.limit;
        }
        if (options?.page) {
            this.page = options?.page;
        }
    }
}
