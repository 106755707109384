import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { SelectOptionModel } from '../../models/shared/select-option.model';
import { TagsInputItemModel } from '../../models/shared/tags-input-item.model';
import { UntilDestroy } from '../../operators/until-destroy.operator';

type TagsInputComponentSimpleChanges = {
    [propName in keyof TagsInputComponent]: SimpleChange;
};

@UntilDestroy()
@Component({
    selector: 'app-tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsInputComponent implements OnInit, OnChanges {
    @Input() optionList: TagsInputItemModel[] = [];
    @Input() invalid = false;
    @Input() selectedTagsList: TagsInputItemModel[] = [];
    @Output() onInputChange = new EventEmitter<string>();
    @Output() onChange = new EventEmitter<TagsInputItemModel[]>();
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    maxLength = 0;
    placeholder = 'shared-select-tag-placeholder';
    isOpen = false;

    constructor(private _cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: TagsInputComponentSimpleChanges) {
        const inputValue = this.searchInput?.nativeElement?.value;
        if (changes.optionList && !this.optionList?.length && inputValue) {
            this.optionList = [{ value: inputValue, text: inputValue }];
            this._cdr.detectChanges();
        }
    }

    selectOption(option: TagsInputItemModel): void {
        const alreadySelected = this.selectedTagsList.find((item: SelectOptionModel) => item.value === option.value);
        if (alreadySelected) {
            this.selectedTagsList = this.selectedTagsList.filter(
                (item: SelectOptionModel) => item.value !== option.value
            );
        } else {
            this.selectedTagsList.push(option);
        }
        this.onChange.emit(this.selectedTagsList);
        this.searchInput.nativeElement.value = '';
        this.isOpen = false;
        this.optionList = [];
        this._cdr.detectChanges();
    }

    checkIsSelected(option: TagsInputItemModel): boolean {
        return this.selectedTagsList.some((item: TagsInputItemModel) => item.value === option.value);
    }

    deleteItem(value: string) {
        this.selectedTagsList = this.selectedTagsList.filter((item: TagsInputItemModel) => item.value !== value);
        this._cdr.detectChanges();
    }

    searchInputChange(event: Event) {
      const filteredString = (event.target as HTMLInputElement).value.replace(/[^\w\u0400-\u04FF-]/gi, '');
        this.searchInput.nativeElement.value = filteredString;
        this.isOpen = true;
        this.onInputChange.emit(filteredString);
        this._cdr.detectChanges();
    }
}
