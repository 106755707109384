<ng-container *transloco="let t">
    <div
        class="menu"
        [class.open]="open"
        *ngIf="auth$ | async as user; else loginTemplate"
        clickOutside
        (clickOutside)="open = false"
    >
        <div class="menu-panel flex align-items-center" (click)="open = !open">
            <div class="menu-text">
                <ng-container *ngIf="!user.isAdmin">
                    <span class="menu-text__title">{{ user.firstName }} {{ user.lastName }}</span>
                    <span class="menu-text__sub-title">{{ user.nickname }}</span>
                </ng-container>
                <ng-container *ngIf="user.isAdmin">
                    <span class="menu-text__title">{{ user.email }}</span>
                    <span class="menu-text__sub-title">{{ user.roleName }}</span>
                </ng-container>
            </div>
            <div class="avatar-wrapper center">
                <img [src]="user.avatarUrl || '/assets/images/avatar_placeholder.svg'" alt="user avatar" />
            </div>
            <div class="arrow-icon-wrapper" [class.open]="open">
                <img src="assets/images/white-arrow_down.svg" alt="dropdown arrow" />
            </div>
        </div>
        <ul class="action-list" [class.open]="open" (click)="close($event)">
            <li
                class="action-item account-btn"
                (click)="menuItemClick.emit(true)"
                [routerLink]="'profile'"
            >
                {{ 'nav-bar-profile' | transloco }}
            </li>
<!--            <li-->
<!--                class="action-item account-btn"-->
<!--                (click)="goToProfileEdit()"-->
<!--            >-->
<!--                {{ 'nav-bar-edit-profile' | transloco }}-->
<!--            </li>-->
<!--            <li-->
<!--                *ngIf="isContentCreator$ | async"-->
<!--                class="action-item account-btn"-->
<!--                (click)="menuItemClick.emit(true)"-->
<!--                [routerLink]="'account'"-->
<!--            >-->
<!--                {{ 'nav-bar-content-creator' | transloco }}-->
<!--            </li>-->
            <li class="action-item log-out-btn" (click)="onLogoutClick()">
                {{ t('nav-bar-logout') }}
                <img src="assets/images/log-out-icon.svg" alt="logout icon" />
            </li>
        </ul>
    </div>
    <ng-template #loginTemplate>
        <div class="action-button-list flex">
            <button routerLink="/auth/sign-up" class="main-btn register-btn">{{ t('register-button') }}</button>
            <button routerLink="/auth/login" class="main-btn">{{ t('login-button') }}</button>
        </div>
    </ng-template>
</ng-container>
