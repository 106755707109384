<div (click)="toggle()" class="wrapper flex align-items-center">
  <div [class.active]="active" class="circle">
    <div class="main"></div>
    <div class="dynamic"></div>
  </div>
  <p class="label" *ngIf="label">
    <ng-container *transloco="let t">
      {{ t(label) }}
    </ng-container>
  </p>
</div>
