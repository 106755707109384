import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { take, tap } from 'rxjs';
import { FcaStoreService } from '../../../store/store.service';
import { AuthData } from '../../interfaces/auth-data.interface';
import { OnFragChangedModel } from '../../models/shared/on-frag-changed.model';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';
import { PlatformService } from '../../services/platform.service';

import Hls from 'hls.js';
import { ChangeLanguageDataAction, ChangeVolumeDataAction } from '../../../store/actions';

type HlsPlayerComponentSimpleChanges = {
    [propName in keyof HlsPlayerComponent]: SimpleChange;
};

@UntilDestroy()
@Component({
    selector: 'app-hls-player',
    templateUrl: './hls-player.component.html',
    styleUrls: ['./hls-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HlsPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('hlsContainer') containerElementRef: ElementRef | undefined;
    @Input() src: string | null;
    @Input() rid: string | null;
    @Input() sid: string | null;
    @Input() setHeaders: boolean = false;
    @Output() videHeightEmit = new EventEmitter<number>();
    @Output() onFragChanged = new EventEmitter<OnFragChangedModel>();
    @Output() onVideoEnded = new EventEmitter<void>();
    @HostBinding('style.height.px')
    elHeight: number;
    private hls = new Hls();
    volume: number = 0;

    @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;

    constructor(
        private readonly platformService: PlatformService,
        private _cdk: ChangeDetectorRef,
        private storeService: FcaStoreService,
        private cookieService: SsrCookieService
    ) {}

    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.resize();
    }

    resize() {
        this.elHeight = this.containerElementRef!.nativeElement.offsetWidth * (9 / 16);
        this.videHeightEmit.emit(this.elHeight);
        this._cdk.detectChanges();
    }

    ngAfterViewInit(): void {
        this.initPlayer();
    }

    ngOnChanges(changes: HlsPlayerComponentSimpleChanges) {
        if (!changes.src.isFirstChange()) {
            this.initPlayer();
        }
    }

    initPlayer() {
        this.setHeaders ? this.setAuthParams() : this.startPlayer();
    }

    setAuthParams() {
        this.storeService
            .getAuthData()
            .pipe(
                untilDestroy(this),
                take(1),
                tap((authData: AuthData | null) => {
                    if (Hls.isSupported()) {
                        this.setHeaderViaHls(authData?.jwt || '');
                    } else {
                        this.setCookies(authData?.jwt || '');
                    }
                    this.startPlayer();
                })
            )
            .subscribe();
    }

    setHeaderViaHls(token: string) {
        this.hls = new Hls({
            xhrSetup: (xhr) => {
                xhr.setRequestHeader('rid', 'setHeaderViaHls' || '');
                xhr.setRequestHeader('sid', this.sid || '');
                xhr.setRequestHeader('token', token || '');
            },
        });
    }

    setCookies(token: string) {
        const rid = this.rid || '';
        const sid = this.sid || '';
        const options = {
            domain: '.spartacus-mma.com',
            secure: true,
            path: '/',
            expires: new Date().setDate(new Date().getDate() + 1),
        };
        this.cookieService.set('rid', rid, options);
        this.cookieService.set('sid', sid, options);
        this.cookieService.set('token', token, options);
    }

    startPlayer() {
        this.resize();
        if (!this.src) {
            return;
        }
        if (Hls.isSupported()) {
            this.loadVideoWithHLS(this.src || '');
        } else {
            this.loadVideo(this.src || '');
        }
        this.storeService.getDefaultVideoVolume().subscribe((value) => (this.volume = value));
        this._cdk.detectChanges();
    }

    addListenersForVideoPlay() {
        this.videoElementRef?.nativeElement.removeAllListeners();
        this.videoElementRef?.nativeElement.addEventListener('timeupdate', () => {
            this.emitDataForMetrics();
        });
        this.videoElementRef?.nativeElement.addEventListener('ended', () => {
            this.onVideoEnded.emit();
        });
        this.videoElementRef?.nativeElement.addEventListener('volumechange', () => {
            this.volume = this.videoElementRef?.nativeElement.volume;
            this.storeService.dispatch(new ChangeVolumeDataAction({ volume: this.volume }));
        });
    }

    emitDataForMetrics() {
        this.onFragChanged.emit({
            videoTime: this.videoElementRef?.nativeElement.currentTime,
            currentTime: new Date().getTime(),
            sid: this.sid || '',
            rid: this.rid || '',
        });
    }

    private loadVideo(src: string) {
        if (this.videoElementRef) {
            this.videoElementRef.nativeElement.pause();
            this.videoElementRef.nativeElement.load();
            this.videoElementRef.nativeElement.src = this.src;
        }
        this.addListenersForVideoPlay();
        this._cdk.detectChanges();
    }

    private loadVideoWithHLS(currentVideo: string) {
        this.hls.startLevel = 999;
        this.hls.loadSource(currentVideo);
        this.hls.attachMedia(this.videoElementRef?.nativeElement);
        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            this.addListenersForVideoPlay();
        });
    }

    destroyPlayers() {
        this.videoElementRef?.nativeElement.removeAllListeners();
        this.hls.destroy();
    }

    ngOnDestroy(): void {
        this.destroyPlayers();
    }
}
