import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SelectOptionModel } from '../../models/shared/select-option.model';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSelectComponent implements OnInit {
    @Input() optionList: SelectOptionModel[] = [];
    @Input() placeholder = 'shared-select-option';
    @Input() isLoading = false;
    @Input() multiSelect = false;
    @Input() maxSelectSize = 0;
    @Input() invalid = false;
    @Input() selectedList: SelectOptionModel[] = [];
    @Output() onChange = new EventEmitter<SelectOptionModel[]>();
    @Output() onClose = new EventEmitter<SelectOptionModel[]>();
    selectedItem: SelectOptionModel | null;
    isOpen = false;

    constructor(private _cdr: ChangeDetectorRef, private translocoService: TranslocoService) {}

    ngOnInit(): void {
    }

    selectOption(option: SelectOptionModel) {
        const alreadySelected = this.selectedList.find((item: SelectOptionModel) => item.value === option.value);
        if (alreadySelected) {
            this.selectedList = this.selectedList.filter((item: SelectOptionModel) => item.value !== option.value);
        } else {
            if (this.multiSelect && this.maxSelectSize && this.selectedList.length >= this.maxSelectSize) {
                return;
            }
            if (this.multiSelect) {
                this.selectedList.push(option);
            } else {
                this.selectedList = [option];
            }
            if (this.multiSelect && this.maxSelectSize && this.selectedList.length === this.maxSelectSize) {
                this.onClose.emit(this.selectedList);
                this.isOpen = false;
                this._cdr.detectChanges();
            }
        }
        if (!this.multiSelect) {
            this.isOpen = false;
            this.isOpen = false;
            this.onClose.emit(this.selectedList);
        }
        this.onChange.emit(this.selectedList);
        this._cdr.detectChanges();
    }

    checkIsSelected(option: SelectOptionModel) {
        return this.selectedList.some((item: SelectOptionModel) => item.value === option.value);
    }

    get getSelectInnerText(): string {
        if (this.selectedList.length) {
            if (this.multiSelect) {
                return `${this.selectedList.length.toString()} ${this.translocoService.translate(
                    'content-creator-upload-video-selected-category-items'
                )}`;
            } else {
                return this.selectedList[0] && this.selectedList[0]?.text;
            }
        } else {
            return this.placeholder;
        }
    }
}
