<ng-container *transloco='let t'>

  <ng-container *ngIf='currentLoginStep === loginStateType.ENTER_EMAIL'>
    <div class='login-container'>
      <p class='title'>{{t('login-welcome')}}</p>
      <p class='sub-title'>
        <ng-container *ngIf='isLoginPage else registerByEmail'>
          {{t('login-sign-in-by-email')}}
        </ng-container>
      </p>

      <ng-template #registerByEmail>
        {{t('register-by-email')}}
      </ng-template>

      <input
        [placeholder]="t('login-email-placeholder')"
        class='main-input'
        [class.invalid]='email?.invalid && email?.touched'
        id='email'
        (input)='email.markAsUntouched()'
        type='email'
        [formControl]='email'
        autocomplete='off'
      >
      <ng-container *ngIf='email?.invalid && email?.touched'>
        <p class='error-text' *ngIf="email?.errors['required']">
          {{t('input-invalid-required')}}
        </p>
        <p class='error-text' *ngIf="email?.errors['pattern']">
          {{t('login-page-invalid-email')}}
        </p>
      </ng-container>

      <ng-container *ngIf='errorMessage'>
        <p class='error-text'>
          {{t(errorMessage)}}
        </p>
      </ng-container>

      <p class='or-title'>{{t('login-or-use-socials')}}</p>

      <div class='socials flex align-items-center center'>
        <app-flat-button
          (click)='onSocialLogin(socialProvider.APPLE)'
          [type]="buttons.get('apple')?.type"
          [icon]="buttons.get('apple')?.icon"
        ></app-flat-button>
        <app-flat-button
          (click)='onSocialLogin(socialProvider.GOOGLE)'
          [type]="buttons.get('google')?.type"
          [icon]="buttons.get('google')?.icon"
        ></app-flat-button>
      </div>



      <button
        *ngIf='isLoginPage else letRegister'
        class='main-btn w-100'
        (click)='onEmailLoginClick()'
      >
        {{t('login-let-started')}}
      </button>

      <ng-template #letRegister>
        <button class='main-btn w-100' (click)='onCreateNewAccountClick()'>
          {{t('register-button')}}
        </button>
      </ng-template>
    </div>

    <a href='' routerLink='/auth/sign-up' class='not-have-account' *ngIf='isLoginPage'>
      {{t('login-page-no-have-account')}}
    </a>

  </ng-container>


  <div class='login-container' *ngIf='currentLoginStep === loginStateType.ENTER_CODE'>
    <div class='auth-code-input'>
      <p for='email' class='title'>{{t('login-code-from-email')}}</p>
      <p class='sub-title'>{{t('login-enter-pin-code')}} {{email?.value}}</p>
      <code-input
        #codeInput
        class='auth-code-input-fields'
        [class.invalid]='invalidPinCode'
        [isCodeHidden]='false'
        [codeLength]='6'
        (codeChanged)='onCodeChanged($event)'
      >
      </code-input>
    </div>
    <div class='timer-wrapper'>
      <p class='sub-title'
         *ngIf='resendTimeout !== null && resendTimeout > 0'>{{t('login-resend-code-timer')}} {{ timerEmitter$ | async }}</p>
      <p class='sub-title resend' *ngIf='resendTimeout === null'
         (click)='onResendCodeClick()'>{{t('login-resend-code')}}</p>
    </div>
    <button
      class='main-btn check-pin-code w-100'
      (click)='checkPinCode()'
      [disabled]='pinCode?.length !== 6 || invalidPinCode'
      [class.invalid]='invalidPinCode'
    >
      <ng-container *ngIf='!invalidPinCode'>
        {{t('login-check-code-btn')}}
      </ng-container>
      <ng-container *ngIf='invalidPinCode'>
        {{t('login-invalid-code-btn')}}
      </ng-container>
    </button>
    <p class='orange-note use-another-email' (click)='userOtherEmail()'>
      {{t('login-use-another-email')}}
    </p>

  </div>
</ng-container>
