import { Injectable } from '@angular/core';
import { map, Observable, tap, merge, filter, concatMap, mergeMap, forkJoin, shareReplay } from 'rxjs';
import { CreateCustomerParams } from '../../api/fca/payments/params/create-customer.params';
import { CreateSubscriptionParams } from '../../api/fca/payments/params/create-subscription.params';
import { YookassaSubscriptionServiceParams } from '../../api/fca/payments/params/yookassa-subscription-service.params';
import { PaymentsApiService } from '../../api/fca/payments/payments-api.service';
import { CancelStripeSubscriptionResponse } from '../../api/fca/payments/response/cancel-stripe-subscription.response';
import { ActiveSubscriptionFactory } from '../models/subscription/active-subscription/active-subscription.factory';
import { ActiveStripeSubscriptionMapper } from '../models/subscription/active-subscription/active-stripe-subscription.mapper';
import { ActiveYookassaSubscriptionMapper } from '../models/subscription/active-subscription/active-yookassa-subscription.mapper';
import { CreateSubscriptionFactory } from '../models/subscription/create-subscription/create-subscription.factory';
import { CreateSubscriptionMapper } from '../models/subscription/create-subscription/create-subscription.mapper';
import { SubscriptionFactory } from '../models/subscription/subscription.factory';
import { SubscriptionMapper } from '../models/subscription/subscription.mapper';
import { YookassaSubscriptionFactory } from '../models/subscription/yookassa-subscription/yookassa-subscription.factory';
import { YookassaSubscriptionMapper } from '../models/subscription/yookassa-subscription/yookassa-subscription.mapper';
import { CoinPaymentSystemEnum } from '../shared/enums/coin-payment-system.enum';
import {
    BuyPersonalSubscriptionBody,
    CreateSubscriptionBody,
    CreatorSubscriptionsList,
    PersonalSubscriber,
    PersonalSubscriptionStatus,
    PersonalSubscriptionStatusResult,
    UpdateSubscriptionPriceBody,
} from '../../api/fca/payments/params/personal_subscription';

declare let Stripe: any;

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    paymentElement: any;
    stripe: any;

    constructor(private paymentsApiService: PaymentsApiService) {}

    initStripe(publishableKey: string) {
        this.stripe = Stripe(publishableKey);
    }

    getStripe() {
        return this.stripe;
    }

    getPlans(currency: string) {
        return this.paymentsApiService.getStripePrices(currency).pipe(
            tap((tapEvent) => this.initStripe(tapEvent.publishableKey)),
            map((prices) =>
                prices.prices.map((price) =>
                    new SubscriptionFactory().getModelFromData(new SubscriptionMapper().mapData(price))
                )
            )
        );
    }

    createCustomer(body: CreateCustomerParams): Observable<string> {
        return this.paymentsApiService.createCustomer(body);
    }

    getSubscriptionList(ip: string): Observable<any> {
        return this.paymentsApiService.getSubscriptionList(ip).pipe(
            map((tapEvent: any) => {
                if (tapEvent[CoinPaymentSystemEnum.YOOKASSA]) {
                    return tapEvent[CoinPaymentSystemEnum.YOOKASSA].map((price: any) =>
                        new YookassaSubscriptionFactory().getModelFromData(
                            new YookassaSubscriptionMapper().mapData(price)
                        )
                    );
                } else if (tapEvent[CoinPaymentSystemEnum.STRIPE]) {
                    this.initStripe(tapEvent[CoinPaymentSystemEnum.STRIPE].publishableKey);
                    return tapEvent[CoinPaymentSystemEnum.STRIPE].prices.map((price: any) =>
                        new SubscriptionFactory().getModelFromData(new SubscriptionMapper().mapData(price))
                    );
                }
            })
        );
    }

    createSubscription(customerId: string, priceId: string) {
        return this.paymentsApiService
            .createSubscription(new CreateSubscriptionParams(customerId, priceId))
            .pipe(
                map((data) =>
                    new CreateSubscriptionFactory().getModelFromData(new CreateSubscriptionMapper().mapData(data))
                )
            );
    }

    yookassaSubscriptionSession(body: YookassaSubscriptionServiceParams): Observable<ArrayBuffer> {
        return this.paymentsApiService.yookassaSubscriptionSession(body);
    }

    getActiveStripeSubscription() {
        return this.paymentsApiService.getActiveStripeSubscription().pipe(
            map((data) => {
                if (data) {
                    return new ActiveSubscriptionFactory().getModelFromData(
                        new ActiveStripeSubscriptionMapper().mapData(data)
                    );
                } else {
                    return null;
                }
            })
        );
    }

    getActiveYookassaService() {
        return this.paymentsApiService.getActiveYookassaSubscription().pipe(
            map((data) => {
                if (data) {
                    return new ActiveSubscriptionFactory().getModelFromData(
                        new ActiveYookassaSubscriptionMapper().mapData(data)
                    );
                } else {
                    return null;
                }
            })
        );
    }

    checkUserSubscription() {
        return forkJoin({
            yookassa: this.getActiveYookassaService(),
            stripe: this.getActiveStripeSubscription(),
        }).pipe(shareReplay(1));
    }

    cancelYookassaSubscription(subscriptionId: string) {
        return this.paymentsApiService.cancelYookassaSubscription(subscriptionId);
    }

    cancelStripeSubscription(subscriptionId: string): Observable<CancelStripeSubscriptionResponse> {
        return this.paymentsApiService.cancelStripeSubscription(subscriptionId);
    }

    updateSubscriptionPrice(body: UpdateSubscriptionPriceBody) {
        return this.paymentsApiService.updateSubscriptionPrice(body);
    }

    buyPersonalSubscription(body: BuyPersonalSubscriptionBody) {
        return this.paymentsApiService.buyPersonalSubscription(body);
    }

    checkPersonalSubscriptionStatus(data: PersonalSubscriptionStatus) {
        return this.paymentsApiService.checkPersonalSubscriptionStatus(data);
    }

    getSubscriptions(data: CreatorSubscriptionsList) {
        return this.paymentsApiService.getSubscriptions(data);
    }

    createSubscriptionPlan(body: CreateSubscriptionBody) {
        return this.paymentsApiService.createSubscriptionPlan(body);
    }

    unsubscribe(targetId: number) {
        return this.paymentsApiService.unsubscribe(targetId);
    }

    getSubscribers(): Observable<PersonalSubscriber[]> {
        return this.paymentsApiService.getSubscribers();
    }

    getSubscribes(): Observable<PersonalSubscriber[]> {
        return this.paymentsApiService.getSubscribes();
    }
}
