import { Inject, Injectable } from '@angular/core';
// @ts-ignore
import { DeviceUUID } from 'device-uuid';
import { WINDOW } from '../shared/tokens/window.token';
import { UtilsService } from './utils.service';

declare function generateUid(nav: Navigator): string;

@Injectable({
    providedIn: 'root',
})
export class DeviceParamsService {
    constructor(@Inject(WINDOW) private window: Window, private utilService: UtilsService) {}

    getDeviceToken() {
        return this.utilService.generateUuid();
    }

    getDeviceName() {
        return this.window?.navigator.userAgent;
    }

    deviceIdentifier() {
        const du: any = generateUid(this.window.navigator);
        const dua = [
            du.language,
            du.platform,
            du.os,
            du.cpuCores,
            du.isAuthoritative,
            du.silkAccelerated,
            du.isKindleFire,
            du.isDesktop,
            du.isMobile,
            du.isTablet,
            du.isWindows,
            du.isLinux,
            du.isLinux64,
            du.isMac,
            du.isiPad,
            du.isiPhone,
            du.isiPod,
            du.isSmartTV,
            du.pixelDepth,
            du.isTouchScreen,
        ];
        return du.DefaultAgent.hashMD5(dua.join(':'));
    }
}
