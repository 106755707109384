import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../shared/components/layout/header/header.component';
import { LoginStatusItemComponent } from '../shared/components/login-status-item/login-status-item.component';
import { SharedModule } from '../shared/shared.module';
import { DonateButtonComponent } from './buttons/donate-button/donate-button.component';
import { FlatButtonComponent } from './buttons/flat-button/flat-button.component';
import { ShareButtonComponent } from './buttons/share-button/share-button.component';
import { ShimmerComponent } from './shimmer/shimmer.component';

@NgModule({
    declarations: [
        FlatButtonComponent,
        ShareButtonComponent,
        DonateButtonComponent,
        ShimmerComponent,
        HeaderComponent,
    ],
    imports: [SharedModule, CommonModule, RouterModule],
    exports: [
        FlatButtonComponent,
        ShareButtonComponent,
        DonateButtonComponent,
        ShimmerComponent,
        HeaderComponent,
        LoginStatusItemComponent,
    ],
})
export class UikitModule {}
