import { ModuleWithProviders, NgModule } from '@angular/core';
import {appFullConfigFactory} from "./app-full-config";
import {APP_FULL_CONFIG} from "./app-main-config.interface";
import {AppMainConfigService} from "./app-main-config.service";

@NgModule()
export class AppMainConfigModule {
  static forRoot(): ModuleWithProviders<AppMainConfigModule> {
    return {
      ngModule: AppMainConfigModule,
      providers: [
        AppMainConfigService,
        {
          provide: APP_FULL_CONFIG,
          useFactory: appFullConfigFactory,
        },
      ],
    };
  }
}
