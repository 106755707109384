import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import {
    Translation,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
} from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json?${new Date().getTime()}`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useFactory: () => {
                return translocoConfig({
                    availableLangs: ['pt', 'en', 'ru'],
                    defaultLang: 'pt',
                    fallbackLang: 'pt',
                    reRenderOnLangChange: true,
                    prodMode: !isDevMode(),
                    missingHandler: {
                        logMissingKey: true,
                    },
                });
            },
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoTranslateModule {}
