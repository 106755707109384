import { Injectable } from '@angular/core';
import { finalize, from, Observable, of, Subject, switchMap, take } from 'rxjs';
import { AppMainConfigService } from '../../../app/config/app-main-config.service';
import { BaseSocialService } from '../base-social.service';
import { SocialUser } from '../interfaces/social-user.interface';

declare let FB: any;

@Injectable()
export class FacebookApiService extends BaseSocialService {
    private readonly PROVIDER_ID = 'facebook';
    private requestOptions = {
        scope: 'email,public_profile',
        fields: 'name,email,picture,first_name,last_name',
        version: 'v10.0',
    };

    constructor(protected readonly appConfigService: AppMainConfigService) {
        super(appConfigService);
        this.loadScript(this.PROVIDER_ID, '//connect.facebook.net/en_US/sdk.js', () => {
            FB.init({
                appId: appConfigService.getConfig().facebook.appId,
                autoLogAppEvents: true,
                cookie: true,
                xfbml: true,
                version: this.requestOptions.version,
            });
        });
    }

    getLoginStatus(): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            FB.getLoginStatus((response: any) => {
                if (response.status === 'connected') {
                    resolve(response.authResponse.accessToken);
                } else {
                    resolve(null);
                }
            });
        });
    }

    me(accessToken: string): Promise<SocialUser | null> {
        return new Promise<SocialUser | null>((resolve) => {
            FB.api(`/me?fields=${this.requestOptions.fields}`, (fbUser: any) => {
                resolve({
                    name: fbUser.name,
                    email: fbUser.email,
                    photoUrl: `https://graph.facebook.com/${fbUser.id}/picture?type=normal`,
                    firstName: fbUser.first_name,
                    lastName: fbUser.last_name,
                    token: accessToken,
                    provider: this.PROVIDER_ID,
                } as SocialUser);
            });
        });
    }

    signIn(): Observable<SocialUser | null> {
        return from(this.getLoginStatus()).pipe(
            switchMap((accessToken) => {
                return accessToken ? of(accessToken) : from(this.loginModal());
            }),
            switchMap((accessToken) => (accessToken ? from(this.me(accessToken)) : of(null)))
        );
    }

    signOut(): Promise<void> {
        return new Promise((resolve) => {
            FB.logout(() => {
                resolve();
            });
        });
    }

    private loginModal(): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            FB.login((response: any) => {
                resolve(response.authResponse?.accessToken || null);
            });
        });
    }
}
