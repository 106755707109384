import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { finalize, Observable, take, tap } from 'rxjs';
import { FightEventsPreview } from '../../../../api/fca/fight-event/params/fight-events-preview';
import { FightEventLightModel } from '../../../models/fight-event/fight-event-light/fight-event-light.model';
import { FightEventPriceModel } from '../../../models/fight-event/fight-event-price/fight-event-price.model';
import { EFightEventStatus } from '../../../models/fight-event/fight-event.model';
import { ActiveSubscriptionModel } from '../../../models/subscription/active-subscription/active-subscription.model';
import { FcaFightEventsService } from '../../../services/fca-fight-events.service';
import { LanguageService } from '../../../services/language.service';
import { FcaStoreService } from '../../../store/store.service';
import { CurrencyEnum } from '../../enums/currency.enum';
import { EventDetailsRelatedVideoModel } from '../../models/shared/event-details-related-video.model';

@Component({
    selector: 'app-event-info-shared',
    templateUrl: './event-info-shared.component.html',
    styleUrls: ['./event-info-shared.component.scss'],
})
export class EventInfoSharedComponent implements OnInit {
    @Input() date: string = '';
    @Input() name: string = '';
    @Input() description: string = '';
    @Input() locationName: string = '';
    @Input() country: string = '';
    @Input() routingPathWithoutId: string = '';
    @Input() city: string = '';
    @Input() isPPV: boolean = false;
    @Input() originalPriceForDisplay: number = 0;
    @Input() discountPriceForDisplay: number = 0;
    @Input() isUserPay4PPV: boolean = false;
    @Input() isFreeForSubscribers: boolean = false;
    @Input() originalPrice: boolean = false;
    @Input() discountPrice: boolean = false;
    @Input() showRelatedVideo: boolean = true;
    @Input() currency: CurrencyEnum;
    @Input() inputListOfRelatedVideo: EventDetailsRelatedVideoModel[] = [];
    @Input() isShowInputListOfRelatedVideo: boolean = false;
    dragging = false;
    slideConfig = {
        dots: false,
        infinite: false,
        draggable: true,
        focusOnSelect: false,
        swipe: true,
        arrows: false,
        swipeToSlide: true,
        slidesToShow: 1.3,
        slidesToScroll: 1,
    };
    eventParams = new FightEventsPreview({
        status: [EFightEventStatus.FINISHED, EFightEventStatus.UPCOMING, EFightEventStatus.ONGOING],
        limit: 3,
    });
    relatedVideoList: FightEventLightModel[] = [];
    relatedVideoListLoader = false;
    priceData: FightEventPriceModel | null;
    userSubscriptionData$: Observable<ActiveSubscriptionModel | null>;

    constructor(
        private readonly fightEventsService: FcaFightEventsService,
        private _cdk: ChangeDetectorRef,
        private readonly languageService: LanguageService,
        private readonly storeService: FcaStoreService
    ) {
        this.userSubscriptionData$ = this.storeService.getSubscriptionData();
    }

    ngOnInit() {
        if (!this.isShowInputListOfRelatedVideo) {
            this.loadRelatedVideos();
        }
    }

    loadRelatedVideos() {
        this.fightEventsService
            .getFightEventsForMainPage(this.eventParams)
            .pipe(
                take(1),
                tap(() => (this.relatedVideoListLoader = true)),
                finalize(() => (this.relatedVideoListLoader = false))
            )
            .subscribe((value: FightEventLightModel[]) => {
                this.relatedVideoList = value;
            });
    }

    onScrollStart(event: any) {
        this.dragging = true;
    }

    onScrollEnd(event: any) {
        this.dragging = false;
    }
}
