import { PermissionStatusEnum } from '../../../../shared/enums/permission-status.enum';
import { PermissionEnum } from '../../../../shared/enums/permission.enum';

export class GetPermissionsListModelData {
    id: string;
    type: PermissionEnum;
    status: PermissionStatusEnum;
    description: string;
    userId: number;
}
export interface GetPermissionsListModel extends GetPermissionsListModelData {}
export class GetPermissionsListModel {}
