import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { of, switchMap, take, tap } from 'rxjs';
import { AppMainConfigService } from '../../../../config/app-main-config.service';
import { ActiveSubscriptionModel } from '../../../../models/subscription/active-subscription/active-subscription.model';
import { SubscriptionService } from '../../../../services/subscription.service';
import { SaveSubscriptionData } from '../../../../store/actions';
import { FcaStoreService } from '../../../../store/store.service';
import { AuthData } from '../../../interfaces/auth-data.interface';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-subscription-status',
    templateUrl: './subscription-status.component.html',
    styleUrls: ['./subscription-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionStatusComponent implements OnInit {
    activeSubscription: ActiveSubscriptionModel | null = null;
    loading = false;

    constructor(
        private subscriptionService: SubscriptionService,
        private _cdk: ChangeDetectorRef,
        private readonly storeService: FcaStoreService
    ) {}

    getActiveSubscription() {
        this.loading = true;
        this.storeService
            .getAuthData()
            .pipe(
                untilDestroy(this),
                switchMap((authData: AuthData | null) => {
                    return authData ? this.subscriptionService.checkUserSubscription() : of(null);
                }),
                tap((a: { yookassa: any; stripe: ActiveSubscriptionModel | null } | null) => {
                    this.loading = false;
                    this.activeSubscription = a?.stripe || a?.yookassa;
                    this.storeService.dispatch(new SaveSubscriptionData({ subscriptionData: this.activeSubscription }));
                    this._cdk.detectChanges();
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.getSubscriptionData();
        this.getActiveSubscription();
    }

    getSubscriptionData() {
        this.storeService
            .getSubscriptionData()
            .pipe(untilDestroy(this))
            .subscribe((value: ActiveSubscriptionModel | null) => {
                this.activeSubscription = value;
                this._cdk.detectChanges();
            });
    }
}
