import { PermissionListItemApiResponse } from '../../../../../api/fca/api/permissions-user/responses/permission-list-item-api.response';
import { ModelDataMapper } from '../../../../shared/interfaces/model-mapper.interface';
import { GetPermissionsListModelData } from './get-permissions-list.model';

export class GetPermissionsListMapper
    implements ModelDataMapper<PermissionListItemApiResponse, GetPermissionsListModelData>
{
    mapData(raw: PermissionListItemApiResponse): GetPermissionsListModelData {
        return {
            id: raw.id,
            type: raw.type,
            status: raw.status,
            description: raw.description,
            userId: raw.userId,
        };
    }
}
