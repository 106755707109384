import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-creator-block',
    templateUrl: './content-creator-block.component.html',
    styleUrls: ['./content-creator-block.component.scss'],
})
export class ContentCreatorBlockComponent {
    @Input() ownerId: string;
    @Input() posts = 0;
    @Input() followers = 0;
    @Input() videos = 0;
    @Input() views = 0;
    @Input() name = '';
    @Input() surname = '';
    @Input() avatar = '';
    @Input() ownerBackgroundImg = '';
}
