import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { Refresh } from '@ngrx/store-devtools/src/actions';
import { AuthGuard } from './auth/guards/auth.guard';
import { CheckTokenGuard } from './auth/guards/check-token.guard';
import { ContactsComponent } from './shared/components/contacts/contacts.component';
import { InviteLinkComponent } from './shared/components/layout/invite-link/invite-link.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { TermsComponent } from './shared/components/terms/terms.component';

import {
    AccountModuleRoutingRegex,
    AuthPageRoutingRegex,
    CoinsModuleRoutingRegex,
    ContactsPageRoutingRegex,
    DashboardPageRoutingRegex,
    FighterProfileModuleRoutingRegex,
    FighterSubscriptionModuleRoutingRegex,
    InviteLinkRoutingRegex,
    KicksModuleRoutingRegex,
    MainPageRoutingRegex,
    OtherEventsModuleRoutingRegex,
    PaymentModuleRoutingRegex,
    PrivacyPolicyPagePageRoutingRegex,
    ProfileModuleRoutingRegex,
    TermsOfUsePagePageRoutingRegex,
} from './shared/helpers/routing-helpers';

const routes: Routes = [
    {
        matcher: MainPageRoutingRegex.match,
        pathMatch: 'full',
        loadChildren: () => import('./main-page/main-page.module').then((m) => m.MainPageModule),
        data: { module: 'main' },
        canActivate: [CheckTokenGuard],
    },
    {
        component: ContactsComponent,
        pathMatch: 'full',
        matcher: ContactsPageRoutingRegex.match,
        data: { module: 'contacts' },
    },
    { component: PrivacyComponent, matcher: PrivacyPolicyPagePageRoutingRegex.match, data: { module: 'privacy' } },
    { component: TermsComponent, matcher: TermsOfUsePagePageRoutingRegex.match, data: { module: 'terms' } },
    {
        matcher: AuthPageRoutingRegex.match,
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
        data: { module: 'auth' },
    },
    {
        matcher: DashboardPageRoutingRegex.match,
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { module: 'dashboard' },
        canActivate: [CheckTokenGuard],
    },
    {
        matcher: FighterProfileModuleRoutingRegex.match,
        loadChildren: () => import('./fighter-profile/fighter-profile.module').then((m) => m.FighterProfileModule),
        data: { module: 'fighter-profile' },
        canActivate: [CheckTokenGuard],
    },
    {
        matcher: FighterSubscriptionModuleRoutingRegex.match,
        loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule),
        data: { module: 'subscription' },
        canActivate: [CheckTokenGuard, AuthGuard],
    },
    {
        matcher: PaymentModuleRoutingRegex.match,
        loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
        data: { module: 'payment' },
        canActivate: [CheckTokenGuard],
    },
    {
        matcher: KicksModuleRoutingRegex.match,
        loadChildren: () => import('./modules/kicks/kicks.module').then((m) => m.KicksModule),
        data: { module: 'kicks' },
        canActivate: [CheckTokenGuard, AuthGuard],
    },
    {
        matcher: CoinsModuleRoutingRegex.match,
        loadChildren: () => import('./modules/coins/coins.module').then((m) => m.CoinsModule),
        data: { module: 'coins' },
        canActivate: [CheckTokenGuard, AuthGuard],
    },
    {
        matcher: OtherEventsModuleRoutingRegex.match,
        loadChildren: () => import('./modules/other-events/other-events.module').then((m) => m.OtherEventsModule),
        data: { module: 'other-events' },
        canActivate: [CheckTokenGuard, AuthGuard],
    },
    {
        matcher: AccountModuleRoutingRegex.match,
        loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
        data: { module: 'account' },
        canActivate: [CheckTokenGuard, AuthGuard],
    },
    {
        matcher: InviteLinkRoutingRegex.match,
        component: InviteLinkComponent,
        data: { module: 'account' },
    },
    {
        matcher: ProfileModuleRoutingRegex.match,
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
        data: { module: 'profile' },
        canActivate: [CheckTokenGuard],
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledNonBlocking',
            preloadingStrategy: NoPreloading,
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
