export enum EUserRole {
    NONE = '',
    FIGHTER = 'FIGHTER',
    ARENA = 'LOCATION_PROVIDER',
    MANAGER = 'MANAGER',
    JUDGE = 'JUDGE',
    COMMENTATOR = 'COMMENTATOR',
    FAN = 'FAN',
    ADMIN = 'ADMIN',
}
