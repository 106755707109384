import { InjectionToken } from '@angular/core';

export const APP_FULL_CONFIG = new InjectionToken('APP_FULL_CONFIG_TOKEN');

/**
 * Интерфейс, описывающий структуру конфигурации
 */
export interface AppMainConfigInterface {
    apiUrl: string;
    webUrl: string;
    chatUrl: string;
    kickVideoUrl: string;
    bloodsVideoUrl: string;
    metricsUrl: string;
    otherEventsUrl: string;
    i18nHost: string;
    google: {
        clientId: string;
    };
    apple: {
        clientId: string;
        redirectURI: string;
    };
    facebook: {
        appId: string;
    };
    fightEvents: {
        limit: number;
    };
    production: boolean;
    qaUrl: string;
    discoveryUrl: string;
    postUrl: string;
}
