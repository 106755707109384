import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { filter, switchMap, take, tap } from 'rxjs';
import { Language } from '../shared/enums/language.enum';
import { FcaStoreService } from '../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly defaultLanguage: Language = Language.EN;
    private readonly availableLanguages: Language[] = Object.values(Language);
    private urlWithoutLang: string = '';
    private isBrowser: boolean;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) platformId: Object,
        private readonly translate: TranslocoService,
        private readonly storeService: FcaStoreService,
        private location: Location,
        private router: Router
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        storeService
            .getLanguage()
            .pipe(take(1))
            .subscribe((lang: Language | null) => {
                this.document.documentElement.lang = lang ? lang : Language.PT;
            });
        this.configureUrlWithLang();
    }

    get currentLanguage(): Language {
        return this.translate.getActiveLang() as Language;
    }

    get nowIsDefaultLanguage(): boolean {
        return this.currentLanguage === this.defaultLanguage;
    }

    get languages(): { code: string; lang: string }[] {
        return [
            { code: Language.EN, lang: 'English' },
            { code: Language.PT, lang: 'Portuguesa' },
        ];
    }

    private configureUrlWithLang(): void {
        // Adding a language to the url
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                switchMap((event) => {
                    return this.storeService.getLanguage().pipe(
                        take(1),
                        tap((lang: Language | null) => {
                            const url = (event as NavigationEnd).url;

                            const urlParts = url.split('/');
                            if (urlParts.hasOwnProperty(1) && this.availableLanguages.some((l) => l === urlParts[1])) {
                                urlParts.splice(1, 1);
                            }
                            this.urlWithoutLang = urlParts.join('/');
                            let href = this.urlWithoutLang;
                            if (lang !== this.defaultLanguage) {
                                href = '/' + (lang ? lang : Language.PT) + this.urlWithoutLang;
                            }
                            if (href.endsWith('/')) {
                                href = href.slice(0, -1);
                            }
                            if (this.isBrowser && !this.location.isCurrentPathEqualTo(href)) {
                                this.location.replaceState(href);
                            } else if (!this.isBrowser && !this.location.isCurrentPathEqualTo(href)) {
                                this.router.navigate([``]); // FAKE
                            }
                        })
                    );
                })
            )
            .subscribe();
    }
}
