<ng-container *transloco="let t">
    <div class="subscription-status-wrapper flex align-items-center" *ngIf="!loading; else preloader">
        <ng-container *ngIf="!activeSubscription">
            <p class="status-label">{{ t('subscription-update-to') }}</p>
        </ng-container>
        <a href="" routerLink="subscription" class="status-value premium">{{ t('subscription-plan-premium') }}</a>
    </div>
    <ng-template #preloader>
        <ngx-skeleton-loader
            [theme]="{
                width: '200px',
                height: '36px',
                margin: '0'
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</ng-container>
