import * as moment from 'moment-timezone';
import { FightModel } from '../../fight/fight.model';

export interface FightEventFightSlotModelData {
    id: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    fight: FightModel;
    locationId: number | null;
    fightId: number | null;
    isMainCard: boolean;
}

export interface FightEventFightSlotModel extends FightEventFightSlotModelData {}

export class FightEventFightSlotModel {}
