export interface FightEventLocationLightModelData {
    name: string;
    address: {
        country: string;
        city: string;
    };
    isPending: boolean;
    image: string | null;
}

export interface FightEventLocationLightModel extends FightEventLocationLightModelData {}

export class FightEventLocationLightModel {
    get preview(): string {
        return [this.address.country, this.address.city, this.name].join(', ');
    }
}
