import { Inject, Injectable } from '@angular/core';
import { ObjectHelper } from '../shared/helpers/object-helper';
import { AppFullConfig } from './app-full-config';
import { AppMainConfigInterface, APP_FULL_CONFIG } from './app-main-config.interface';

/**
 * Сервис инкапсулирует знание о общей конфигурации приложения, которая необходима всему приложению
 */
@Injectable()
export class AppMainConfigService {
    private config: AppMainConfigInterface;

    constructor(@Inject(APP_FULL_CONFIG) private appMainFullConfig: AppFullConfig) {}

    /**
     * Получает обновленный конфиг
     *
     * @returns {AppMainConfigInterface}
     */
    getConfig(): AppMainConfigInterface {
        const customMerge = <T>(target: T, source: T) => {
            if (Array.isArray(source) && Array.isArray(target)) {
                return source;
            } else {
                return { ...target, ...source };
            }
        };
        if (!this.config) {
            this.config = ObjectHelper.deepMerge<AppMainConfigInterface>(
                [
                    this.appMainFullConfig.default,
                    // (contextId && this.appMainFullConfig.context?.[contextId.toLowerCase()]) || {},
                ],
                customMerge
            );
        }

        return this.config;
    }
}
