<div class='flex align-items-center'>
  <div class="progress-bar-wrapper">
    <div
      [style.width]="percent + '%'"
      class="progress-bar background-image"
      [class.background-animation]="percent?.toString() !== '100.00'"
    ></div>
  </div>
  <div class="percent">{{ percent | number : '1.0-2' }}%</div>
</div>
