<ng-container *transloco="let t">
    <div class="user-data-popup-wrapper">
        <div class="personal-data">
            <p class="data-section-title">{{ t('content-creator-account-personal-data-label') }}</p>

            <label class="label">{{ t('shared-firstname-label') }}</label>
            <input [(ngModel)]="firstName" name="firstName" type="text" class="main-input" />

            <label class="label">{{ t('shared-lastName-label') }}</label>
            <input [(ngModel)]="lastName" name="secondName" type="text" class="main-input" />

            <label class="label">{{ t('shared-nickaname-label') }}</label>
            <input [(ngModel)]="nickName" name="nickaname" type="text" class="main-input" />

            <!--            <label class="label">{{ t('shared-description-label') }}</label>-->
            <!--            <input [(ngModel)]="description" name="description" type="text" class="main-input" />-->
        </div>

        <div class="personal-data avatar-uploader-wrapper">
            <p class="data-section-title">{{ t('content-creator-account-personal-photo-label') }}</p>

            <label class="label">{{ t('shared-avatar-label') }}</label>
            <app-avatar-uploader (photoChange)="onChangePhoto($event)" [cardImageBase64]="image"></app-avatar-uploader>
        </div>
    </div>

    <div class="action-btn-list">
        <button class="continue-btn" (click)="dialogRef.close()">
            {{ t('shared-cancel-button') }}
        </button>
        <button class="main-btn" (click)="updateUser()">
            {{ t('shared-submit-button') }}
        </button>
    </div>
</ng-container>
