import { Language } from '../../../shared/enums/language.enum';

export enum EFightEventVideoStatus {
    PENDING = 'PENDING',
    LIVE = 'LIVE',
    FINISHED = 'FINISHED',
}

export interface FightEventVideoModelData {
    id: string;
    status: EFightEventVideoStatus;
    name: string | null;
    streamUrl: string | null;
    videoUrl: string | null;
    thumbnail: string | null;
    languages: Language[] | null;
}

export interface FightEventVideoModel extends FightEventVideoModelData {}

export class FightEventVideoModel {
    videoSrc(token: string | undefined): string | null {
        const value = this.streamUrl || this.videoUrl;
        return value && token?.length ? `${value}?token=${token}` : value ? value : null;
    }

    videoSrcFromSubEvent(token: string | undefined, url: string): string | null {
        return url && token?.length ? `${url}?token=${token}` : url ? url : null;
    }
}
