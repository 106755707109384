import { EFightWinner } from '../../../fight/fight.model';

export interface FightEventFightSlotLightModelData {
    fight: {
        winner: EFightWinner;
        initiator?: {
            id: number;
            image: string;
            ranking: number;
        };
        target?: {
            id: number;
            image: string;
            ranking: number;
        };
    };
    isMainCard: boolean;
}

export interface FightEventFightSlotLightModel extends FightEventFightSlotLightModelData {}

export class FightEventFightSlotLightModel {}
