import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../../fca-api-service-base.service';

@Injectable({
    providedIn: 'root',
})
export class FollowersApiService extends FcaApiServiceBase {
    followUser(followToUserId: number) {
        const url = [this.apiUrl, 'followers/follow'].join('/');
        return this.http.post<any>(url, { followToUserId }, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
    unfollowUser(followToUserId: number) {
        const url = [this.apiUrl, 'followers/unfollow'].join('/');
        return this.http
            .request<any>('DELETE', url, { headers: this.defaultHeaders, body: { followToUserId } })
            .pipe(shareReplay(1));
    }
    loadFollowers() {
        const url = [this.apiUrl, 'followers/followers'].join('/');
        return this.http.get<any>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
    loadFollows() {
        const url = [this.apiUrl, 'followers/follows'].join('/');
        return this.http.get<any>(url, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
}
