import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FlatButtonIconData } from '../flat-button/flat-button.component';

@Component({
    selector: 'app-donate-button',
    templateUrl: './donate-button.component.html',
    styleUrls: ['./donate-button.component.scss'],
})
export class DonateButtonComponent implements OnInit {
    @Output() onClick = new EventEmitter<any>();

    icon: FlatButtonIconData = {
        alt: 'Donate',
        assetUrl: 'assets/images/ic_donate.svg',
        title: 'Donate',
        width: 16,
        height: 24,
    };

    constructor() {}

    ngOnInit(): void {}

    click(e: Event) {
        e.preventDefault();
        this.onClick.emit(e);
    }
}
