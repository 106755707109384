import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDoubleClick]',
})
export class DoubleClickDirective {
    @Output() singleClick: EventEmitter<any> = new EventEmitter();
    @Output() doubleClick: EventEmitter<any> = new EventEmitter();

    private clickTimeout: any;
    private clickCount = 0;

    @HostListener('click', ['$event'])
    onClick(event: any) {
        this.clickCount++;
        if (this.clickCount === 1) {
            this.clickTimeout = setTimeout(() => {
                this.singleClick.emit(event);
                this.clickCount = 0;
            }, 250); // Adjust the delay as needed
        } else if (this.clickCount === 2) {
            clearTimeout(this.clickTimeout);
            this.doubleClick.emit(event);
            this.clickCount = 0;
        }
    }
}
