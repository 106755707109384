<div
    class="slot-wrapper"
    [class.target-winner]="slot.fight.winner === eFightWinner.TARGET"
    [class.initiator-winner]="slot.fight.winner === eFightWinner.INITIATOR"
    [class.current-fight]="checkCurrentFight()"
    *transloco="let t"
>
    <div class="slot">
        <div class="slot-inner">
            <div class="slot-data-row">
                <a
                    [routerLink]="'/profile/' + slot.fight?.initiator.id"
                    class="fighter-image-wrapper center left-fighter"
                >
                    <img
                        appImageLoadError
                        onErrorSrc="assets/images/avatar_placeholder.svg"
                        [src]="slot.fight.initiator.fullFaceAvatar"
                        alt="fighter avatar"
                        class="fighter-image"
                    />
                </a>
                <a
                    [routerLink]="'/profile/' + slot.fight?.target.id"
                    class="fighter-image-wrapper center right-fighter"
                >
                    <img
                        appImageLoadError
                        onErrorSrc="assets/images/avatar_placeholder.svg"
                        [src]="slot.fight.target.fullFaceAvatar"
                        alt="fighter avatar"
                        class="fighter-image"
                    />
                </a>
            </div>

            <div class="slot-data-row">
                <a
                    [routerLink]="'/profile/' + slot.fight?.initiator.id"
                    class="fighter-full-name left-fighter"
                    [innerHTML]="slot.fight.initiator.fullName"
                ></a>
                <a
                    [routerLink]="'/profile/' + slot.fight?.target.id"
                    class="fighter-full-name right-fighter"
                    [innerHTML]="slot.fight.target.fullName"
                ></a>
            </div>

            <div class="slot-data-row">
                <p [class.no-nickname]="!slot?.fight?.initiator?.nickname" class="fighter-full-nickname left-fighter">
                    {{ slot?.fight?.initiator?.nickname || t('fight-card-no-nickname') }}
                </p>
                <p [class.no-nickname]="!slot?.fight?.target?.nickname" class="fighter-full-nickname right-fighter">
                    {{ slot?.fight?.target?.nickname || t('fight-card-no-nickname') }}
                </p>
            </div>

            <div class="slot-data-row">
                <div class="ranking left-fighter">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 0L9.05725 4.16844L13.6574 4.83688L10.3287 8.08156L11.1145 12.6631L7 10.5L2.8855 12.6631L3.6713 8.08156L0.342604 4.83688L4.94275 4.16844L7 0Z"
                            fill="#FD5003"
                        />
                    </svg>
                    {{ slot.fight.initiator.details.ranking || 0 }}
                </div>
                <div class="ranking right-fighter">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 0L9.05725 4.16844L13.6574 4.83688L10.3287 8.08156L11.1145 12.6631L7 10.5L2.8855 12.6631L3.6713 8.08156L0.342604 4.83688L4.94275 4.16844L7 0Z"
                            fill="#FD5003"
                        />
                    </svg>
                    {{ slot.fight.target.details.ranking || 0 }}
                </div>
            </div>

            <div class="main-info">
                <div class="time">
                    <p class="hours">{{ slot?.fromDate | date : 'HH:mm' }}</p>
                    <p class="current-time-label">{{ t('fight-card-current-fight-label') }}</p>
                </div>
                <div class="arrow-wrapper">
                    <span>{{ t('fight-card-winner-label') }}</span>
                    <img
                        src="/assets/images/event-info-fighter-card-winner-arrow.svg"
                        alt="show winner in fight with arrow"
                    />
                </div>
                <div class="vs-wrapper">VS</div>
                <div class="heavyweight">
                    {{ translate.selectTranslate(slot?.fight?.target?.details?.getLocaleKey()) | async }}
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="slot-wrapper"
    [class.main-card]="slot.isMainCard"
    [class.fight-of-the-night]="slot.isMainCard"
    *ngIf="false"
>
    <div class="fight-night-icon-wrapper center">
        <img src="/assets/images/fight-of-the-night-crown.svg" alt="orange crown" />
    </div>
    <div class="slot">
        <div class="slot-title main">
            {{ 'main card'.toUpperCase() }}
        </div>
        <div class="slot-title fight-night">
            {{ 'FIGHT OF THE NIGHT'.toUpperCase() }}
        </div>

        <div class="slot-inner flex flex-column align-content-start align-items-start">
            <div class="slot-weight">
                <span>{{ translate.selectTranslate(slot.fight.initiator.details.getLocaleKey()) | async }}</span>
            </div>
            <div class="spacer-h14"></div>
            <div class="slot-container flex flex-row align-items-end">
                <div
                    class="slot-fighter slot-fighter__initiator flex flex-row"
                    [ngClass]="{ winner: slot.fight.winner == 'INITIATOR' }"
                >
                    <div
                        class="slot-fighter__avatar"
                        [ngClass]="slot.fight.initiator.fullFaceAvatar.length === 0 ? 'placeholder' : ''"
                        [ngStyle]="{ 'background-image': 'url(' + slot.fight.initiator.fullFaceAvatar + ')' }"
                    ></div>
                    <div class="spacer-w16"></div>
                    <div class="slot-info flex flex-column justify-content__space-between">
                        <div class="flex flex-row">
                            <img src="/assets/images/ic_star.svg" alt="ranking of fighter" />
                            <div class="spacer-w4"></div>
                            {{ slot.fight.initiator.details.ranking }}
                        </div>
                        <p [innerHTML]="slot.fight.initiator.fullName"></p>
                    </div>
                </div>
                <div class="slot-spacer">VS</div>
                <div
                    class="slot-fighter slot-fighter__target flex flex-row-reverse"
                    [ngClass]="{ winner: slot.fight.winner == 'TARGET' }"
                >
                    <div
                        class="slot-fighter__avatar"
                        [ngClass]="slot.fight.initiator.fullFaceAvatar.length === 0 ? 'placeholder' : ''"
                        [ngStyle]="{ 'background-image': 'url(' + slot.fight.target.fullFaceAvatar + ')' }"
                    ></div>
                    <div class="spacer-w16"></div>
                    <div class="slot-info flex flex-column justify-content__space-between align-items-end">
                        <div class="flex flex-row">
                            <img src="/assets/images/ic_star.svg" alt="ranking of fighter" />
                            <div class="spacer-w4"></div>
                            {{ slot.fight.initiator.details.ranking }}
                        </div>
                        <p [innerHTML]="slot.fight.target.fullName"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
