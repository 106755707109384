import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppMainConfigService } from '../../../app/config/app-main-config.service';
import { BaseSocialService } from '../base-social.service';
import { SocialUser } from '../interfaces/social-user.interface';

declare var AppleID: any;

interface AppleSignInResponse {
    authorization: {
        state: string;
        code: string;
        id_token: string;
    };
}

@Injectable()
export class AppleApiService extends BaseSocialService {
    private readonly PROVIDER_ID = 'apple';

    constructor(protected readonly appConfigService: AppMainConfigService) {
        super(appConfigService);
        this.loadScript(
            this.PROVIDER_ID,
            '//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
            () => {
                AppleID.auth.init({
                    clientId: appConfigService.getConfig().apple.clientId,
                    scope: 'name email',
                    redirectURI: appConfigService.getConfig().apple.redirectURI,
                    state: 'init',
                    nonce: 'test',
                    usePopup: true,
                });
            }
        );
    }

    signIn(): Observable<SocialUser | null> {
        const subject = new Subject<SocialUser | null>();
        AppleID.auth
            .signIn()
            .then((data: any) => {
                const { authorization } = data as AppleSignInResponse;
                const { code, id_token } = authorization;
                const { email, sub } = this.parseJwt(id_token);

                subject.next({
                    identifier: sub,
                    email,
                    provider: this.PROVIDER_ID,
                    firstName: '',
                    lastName: '',
                    photoUrl: '',
                    token: code,
                    name: '',
                });
            })
            .catch(() => subject.next(null));

        return subject.asObservable();
    }

    signOut(): void {}
}
