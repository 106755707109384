import { Component, Input, OnInit } from '@angular/core';
import { FightEventLightModel } from '../../../../models/fight-event/fight-event-light/fight-event-light.model';

@Component({
    selector: 'app-past-event-card',
    templateUrl: './past-event-card.component.html',
    styleUrls: ['./past-event-card.component.scss'],
})
export class PastEventCardComponent implements OnInit {
    @Input() event: FightEventLightModel;

    constructor() {}

    ngOnInit(): void {}
}
