export const slidersConfig = {
    mainSlideConfig: {
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 5000,
        draggable: true,
        focusOnSelect: false,
        pauseOnDotsHover: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: true,
        arrows: false,
        swipeToSlide: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    },
    minSlideConfig: {
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        draggable: true,
        focusOnSelect: false,
        pauseOnDotsHover: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: true,
        arrows: false,
        swipeToSlide: true,
        fade: false,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    minPreviewSlickConfig: {
        dots: false,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        draggable: true,
        focusOnSelect: false,
        pauseOnDotsHover: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: true,
        arrows: false,
        swipeToSlide: true,
        fade: false,
        slidesToShow: 7.2,
        slidesToScroll: 1,
        centered: false,
    },
};
