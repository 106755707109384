import { FightEventAdditionalApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { EEventType, EFightRequirements, EProviding } from './enums/fight-event-additional.enum';
import { FightEventAdditionalModelData } from './fight-event-additional.model';

export class FightEventAdditionalMapper
    implements ModelDataMapper<FightEventAdditionalApiResponse, FightEventAdditionalModelData>
{
    mapData(raw: FightEventAdditionalApiResponse): FightEventAdditionalModelData {
        return {
            description: raw.description,
            type: raw.type as EEventType,
            fightRequirements: raw.fightRequirements as EFightRequirements[],
            providing: raw.providing as EProviding[],
        };
    }
}
