import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { UploadFileProgressResultModel } from '../shared/models/shared/upload-file-progress-result.model';
import { UploadFileProgressParams } from '../shared/models/shared/upload-file-progress.params';

@Injectable({
    providedIn: 'root',
})
export class UploadFileProgressService {
    private progressArray: UploadFileProgressParams[] = [];
    private progress$ = new BehaviorSubject<UploadFileProgressResultModel>(new UploadFileProgressResultModel());

    updateProgress(params: UploadFileProgressParams): void {
        this.progressArray[params.part] = params;
        const result = this.progressArray.reduce(
            (acc: any, item: UploadFileProgressParams) => {
                acc = {
                    total: acc.total + item.total,
                    loaded: acc.loaded + item.loaded,
                };
                return acc;
            },
            {
                total: 0,
                loaded: 0,
            }
        );
        this.progress$.next(result);
    }

    getProgressPercent(): Observable<string> {
        return this.progress$
            .asObservable()
            .pipe(map((data: UploadFileProgressResultModel) => ((data.loaded / (data.total || 1)) * 100).toFixed(2)));
    }

    reset() {
        this.progressArray = [];
        this.progress$.next({ total: 0, loaded: 0 });
    }
}
