import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EFightEventStatus } from '../../../models/fight-event/fight-event.model';
import { Language } from '../../enums/language.enum';
import { MonetizationTypeEnum } from '../../enums/monetization-type.enum';
import { ThreeDotsMenuItemModel } from '../../models/shared/three-dots-menu-item.model';

@Component({
    selector: 'app-creator-video-card',
    templateUrl: './creator-video-card.component.html',
    styleUrls: ['./creator-video-card.component.scss'],
})
export class CreatorVideoCardComponent implements OnInit {
    @Input() title = '';
    @Input() date = '';
    @Input() id = '';
    @Input() backgroundImage = '';
    @Input() status: EFightEventStatus | null;
    @Input() hoverEffect = true;
    @Input() disabled = false;
    @Input() disabledRedirect = false;
    @Input() description = '';
    @Input() time = '';
    @Input() duration = 0;
    @Input() videoUrl = '';
    @Input() theme: 'light' | 'dark' = 'dark';
    @Input() isPPV = false;
    @Input() isFree = false;
    @Input() onlyForPremium = false;
    @Input() additionalInfo = '';
    @Input() lang: Language | null;
    @Input() langCount = 0;
    @Input() videoCount = 0;
    @Input() eventType = '';
    @Input() detailsImagePath = '';
    @Input() isWhite = false;
    @Input() routingPath = '';
    @Input() loading = false;
    @Input() creatorAvatar = '';
    @Input() showCreatorAvatar = true;
    @Input() creatorName = '';
    @Input() access: MonetizationTypeEnum;
    @Input() views = 0;
    @Input() threeDotsMenuList: ThreeDotsMenuItemModel[] = [];
    @Output() onThreeDotsSelect = new EventEmitter<string>();

    langEnum = Language;
    statusEnum = EFightEventStatus;
    isHover = false;
    isHover1 = false;
    monetizationTypeEnum = MonetizationTypeEnum;

    ngOnInit() {}

    getUrl() {
        return this.videoUrl.replace('video.m3u8', 'default.mp4');
    }

    mouseEnter() {
        if (!this.videoUrl) {
            return;
        }
        this.isHover1 = true;
        setTimeout(() => {
            // this.isHover = true;
        }, 100);
    }

    mouseLeave() {
        if (!this.videoUrl) {
            return;
        }
        // this.isHover = false;
        this.isHover1 = false;
    }

    onThreeDotsSelectHandler(event: string) {
        this.onThreeDotsSelect.emit(event);
    }
}
