import { FightEventLocationAddressApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../interfaces/model-mapper.interface';
import { GeneralAddressModelData } from './general-address.model';

export class GeneralAddressMapper
    implements ModelDataMapper<FightEventLocationAddressApiResponse, GeneralAddressModelData>
{
    mapData(raw: FightEventLocationAddressApiResponse): GeneralAddressModelData {
        return {
            formattedAddress: raw.formattedAddress,
            country: raw.country,
            city: raw.city,
            street: raw.street,
            building: raw.building,
            latitude: raw.latitude,
            longitude: raw.longitude,
        };
    }
}
