<div class='post-wrapper'>
  <div class='post-mobile-header'>
    <ng-container *ngIf='false else textContentHeader'></ng-container>
  </div>

  <div class='media-slider'>
    <button class='full-screen center' (click)='onImageClick()'>
      <img src='assets/images/full-screen.png' alt='' />
    </button>
    <ngx-slick-carousel #slickMainCarousel [config]='mainSlideConfig'>
      <div class='slick-item' ngxSlickItem *ngFor='let image of data.images'>
        <div class='slide-media-wrapper scroll-bar center'>
          <img [src]='image.url' alt='' />
        </div>
      </div>
    </ngx-slick-carousel>
    <div class='carousel-nav flex align-items-center justify-content__space-between'>
      <button class='center' (click)='slickMainCarousel.slickPrev()'>
        <img src='assets/images/arrow-white-left.svg' alt='' />
      </button>
      <button class='center' (click)='slickMainCarousel.slickNext()'>
        <img src='assets/images/arrow-white-right.svg' alt='' />
      </button>
    </div>
  </div>

  <div class='text-content-wrapper' *transloco='let t'>
    <ng-container *ngIf='false else textContentHeader'></ng-container>
    <div
      class='text-content-body scroll-bar'
      [class.open-mobile]='isCommentOpen'
      infinite-scroll
      [infiniteScrollDistance]='2'
      [infiniteScrollUpDistance]='1.5'
      [infiniteScrollThrottle]='300'
      [scrollWindow]='false'
      (scrolled)='onScrollToEndData()'
    >
      <button class='close-btn' (click)='toggleComment()'>
        <img src='assets/images/close-popup.svg' alt='' />
      </button>
      <div class='post-text-wrapper flex'>
        <div class='avatar-wrapper center'>
          <img [src]='data.avatar | imagePathWhiteSpace' alt='' />
        </div>
        <div>
          <p class='full-name'>{{ data.name }} {{ data.surname }}</p>
          <p class='post-text' #postText [class.hidden-text]='hiddenTextPart'>
            {{ data.text }}
          </p>
          <button
            class='show-more'
            (click)='hiddenTextPart = false'
            *ngIf='checkTreeDots(postText) && hiddenTextPart'
          >
            {{ t('post-item-more-btn') }}
          </button>
          <p class='post-date'>
            {{ data.date | date : 'dd MMM yyyy' }}
          </p>
        </div>
      </div>
      <ng-container *ngFor='let comment of commentList'>
        <app-comment-item [data]='comment' (onSend)='onReplay($event)'></app-comment-item>
      </ng-container>
    </div>
    <div class='text-content-footer'>
      <div class='post-action flex align-items-center justify-content__space-between'>
        <div class='flex align-items-center'>
          <button class='comment-toggle-btn center' (click)='toggleComment()'>
            <img src='assets/images/comments.svg' alt=''>
          </button>
          <div class='like-wrapper' (click)='postLikeToggle()'>
            <img [hidden]='data.liked' src='assets/images/like-white.svg' alt='' />
            <img [hidden]='!data.liked' src='assets/images/like-active.svg' alt='' />
          </div>
          <div class='like-list-wrapper'>
            <div class='flex align-items-center'>
              <ng-container *ngIf='data.likes'>
                <div *ngFor='let item of data.likeUsers' class='like-user-avatar-wrapper center'>
                  <img width='32px' height='32px' [src]='item.thumbnailAvatar' alt='' />
                </div>
              </ng-container>
              <p class='like-counter'>
                {{ data.likes }}
                {{ t('post-item-likes') }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <app-three-dots-menu
            (onSelect)='treeDotsActionHandler($event)'
            [threeDotsMenuList]='getMenuList() | async'
          ></app-three-dots-menu>
        </div>
      </div>
      <div [class.open-mobile]='isCommentOpen' class='add-comment-wrapper' *ngIf='data.commentsEnabled'>
        <div
          #messageContent
          class='input-form-field-text scroll-bar'
          contenteditable='true'
          (keydown.enter)='onEnterKeyDown($event)'
          [attr.placeholder]="t('dashboard-event-tab-live-chat-enter-message')"
        ></div>

        <div class='flex align-items-center justify-content__space-between'>
          <div></div>
          <div>
            <button (click)='sendComment({ text: messageContent.innerHTML })' class='send-message-btn'>
              {{ t('post-view-send-btn') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<ng-template #textContentHeader>
  <div class='text-content-header flex align-items-center justify-content__space-between'>
    <div class='author flex align-items-center' (click)='dialogRef.close()' [routerLink]="'/profile/' + data.ownerId">
      <div class='avatar-wrapper center'>
        <img [src]='data.avatar | imagePathWhiteSpace' alt='' />
      </div>
      <p class='full-name'>{{ data.name }} {{ data.surname }}</p>
      <p class='nickName'>{{ data.nickName }}</p>
    </div>
    <button class='close-btn' (click)='closeModel()'>
      <img src='assets/images/close-popup.svg' alt='' />
    </button>
  </div>
</ng-template>
