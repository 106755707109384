import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '../fca-api-service-base.service';

@Injectable({
    providedIn: 'root',
})
export class PostDislikesApiService extends FcaApiServiceBase {
    addDislikeToComment(id: string) {
        const url = [this.postUrl, 'dislikes/comments', id].join('/');
        return this.http.post<any>(url, {});
    }

    deleteDislikeFromComment(id: string) {
        const url = [this.postUrl, 'dislikes/comments', id].join('/');
        return this.http.delete<any>(url);
    }
}
