import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { FightEventCommentModel } from '../../../models/fight-event/fight-event-comments/fight-event-comment.model';
import { KicksInfoByIdModel } from '../../../models/kicks/kicks-info-by-id/kicks-info-by-id.model';
import { WINDOW } from '../../../shared/tokens/window.token';
import { ComplexVideoData } from '../models/complex-video-data';

@Injectable({
    providedIn: 'root',
})
export class KicksDataService {
    constructor(@Inject(WINDOW) private window: Window) {}
    private complexDataList$ = new BehaviorSubject<ComplexVideoData[]>([]);
    private complexDataList: ComplexVideoData[] = [];

    private videoFullDataList$ = new BehaviorSubject<KicksInfoByIdModel[]>([]);
    private videoFullDataList: KicksInfoByIdModel[] = [];

    private videoCommentList$ = new BehaviorSubject<FightEventCommentModel[]>([]);
    private videoCommentList: FightEventCommentModel[] = [];

    private toggleCommentsState = false;
    private toggleComments$ = new BehaviorSubject<boolean>(this.toggleCommentsState);

    updateVideoFullDataList(data: KicksInfoByIdModel[]) {
        this.videoFullDataList.push(...data);
        this.videoFullDataList$.next(this.videoFullDataList);
    }
    getVideoFullDataList(): Observable<KicksInfoByIdModel[]> {
        return this.videoFullDataList$.asObservable().pipe(shareReplay(1));
    }
    updateVideoCommentList$(data: FightEventCommentModel[][]) {
        this.videoCommentList.push(...data[0]);
        this.videoCommentList$.next(this.videoCommentList);
    }
    getVideoCommentList$(): Observable<FightEventCommentModel[]> {
        return this.videoCommentList$.asObservable().pipe(shareReplay(1));
    }
    updateComplexDataList(data: ComplexVideoData[], toArrayStart = false) {
        this.complexDataList.push(...data);
        this.complexDataList$.next(this.complexDataList);
    }
    removeComplexDataList() {
        this.complexDataList = [];
        this.complexDataList$.next(this.complexDataList);
    }
    getComplexDataList$(): Observable<ComplexVideoData[]> {
        return this.complexDataList$.asObservable();
    }
    updateVideoDataInComplexDataList(data: KicksInfoByIdModel) {
        this.complexDataList.forEach((item: ComplexVideoData) => {
            if (item.data.id === data.id) {
                item.data = data;
            }
        });
        this.updateComplexDataList(this.complexDataList);
    }

    toggleComments(value?: boolean) {
        if (value?.toString()) {
            this.toggleCommentsState = value;
        } else {
            this.toggleCommentsState = !this.toggleCommentsState;
        }
        this.toggleComments$.next(this.toggleCommentsState);
    }

    getToggleCommentState(): Observable<boolean> {
        return this.toggleComments$.asObservable();
    }

    getPreviousPageUrlFromLocalstorage(): string {
        return this.window?.localStorage.getItem('kicks-previous-page') || '';
    }

    setPreviousPageUrlToLocalstorage(value: string): void {
        this.window?.localStorage.setItem('kicks-previous-page', value);
    }
}
