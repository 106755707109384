import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, take } from 'rxjs';
import { SnackbarService, SnackBarStyles } from '../services/snackbar.service';

export interface ApiErrorData {
    error: {
        code: ErrorCode;
        message: string;
    };
    statusCode: number;
}

export enum ErrorCode {
    USER_NOT_FOUND = 'user_not_found_error',
    INVALID_PIN = 'invalid_pin_code_error',
    DUPLICATE_NICKNAME = 'duplicate_nickname_error',
}

@Injectable()
export class FcaErrorHandler {
    readonly errorStyles = {
        [ErrorCode.USER_NOT_FOUND]: SnackBarStyles.ERROR,
        [ErrorCode.INVALID_PIN]: SnackBarStyles.ERROR,
        [ErrorCode.DUPLICATE_NICKNAME]: SnackBarStyles.ERROR,
    };

    constructor(private readonly snackbarService: SnackbarService, private readonly translate: TranslocoService) {}

    handleError(err: ApiErrorData): void {
        const { error } = err;
        this.errorMessage(error.code)
            .pipe(take(1))
            .subscribe((translatedErrorText: string) => {
                this.snackbarService.show({
                    message: translatedErrorText,
                    style: this.errorStyles[error.code],
                });
            });
    }

    private errorMessage(code: string): Observable<string> {
        return this.translate.selectTranslate(`errors.${code}`);
    }
}
