import { EUserRole } from '../../../shared/enums/user-role.enum';

export class GetProfilesPublicModelData {
    id: number;
    roleName: EUserRole;
    socials: string[];
    firstName: string;
    lastName: string;
    nickname: string;
    description: string;
    backgroundImage: string;
    avatar: string;
    thumbnailAvatar: string;
    isPremium: boolean;
    rating: number;
    followers: number;
    videos: number;
    views: number;
    isFollowed: boolean;
    hasKicks: boolean;
    hasVideos: boolean;
    hasQuestions: boolean;
    hasBlog: boolean;
    hasStream: boolean;
    donateRate: number;
    paidSubscribers: number;
    hasStreams: boolean;
    hasPosts: boolean;
    verified: boolean;
    isContentCreator: boolean;
}
export interface GetProfilesPublicModel extends GetProfilesPublicModelData {}
export class GetProfilesPublicModel {}
