import { ModelFactory } from '../../../../shared/abstract/model-factory.abstract';
import { FightEventFightSlotLightModel, FightEventFightSlotLightModelData } from './fight-event-fight-slot-light.model';

export class FightEventFightSlotLightFactory extends ModelFactory<
    FightEventFightSlotLightModelData,
    FightEventFightSlotLightModel
> {
    protected getInstance(): FightEventFightSlotLightModel {
        return new FightEventFightSlotLightModel();
    }
}
