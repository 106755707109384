import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { user } from '@angular/fire/auth';
import { off } from '@angular/fire/database';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { defer, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { NewCommentParams } from '../../../../api/fca/bloods-video/params/new-comment.params';
import { AddCommentModel } from '../../../../api/fca/post/params/add-comment.model';
import { EMPTY_CONTENT_REGEX } from '../../../constants';
import { EImageType } from '../../../models/images/user-image.model';
import { PostListModelData } from '../../../models/post/post-list/post-list.model';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { ConfirmationPopupService } from '../../../services/confirmation-popup.service';
import { LikeReactionService } from '../../../services/like-reaction.service';
import { PostService, ThreeDotsItemEnum } from '../../../services/post.service';
import { FcaStoreService } from '../../../store/store.service';
import { LikeReactionEnum } from '../../enums/like-reaction.enum';
import { OffsetPagination } from '../../interfaces/offset-pagination.interface';
import { ImageModel } from '../../models/image/image.model';
import { CloseModalActionInterface } from '../../models/shared/close-modal-action.interface';
import { CommentReactionModel } from '../../models/shared/comment-reaction.model';
import { OpenPostDataModel } from '../../models/shared/open-post-data.model';
import { ThreeDotsMenuItemModel } from '../../models/shared/three-dots-menu-item.model';
import { ViewMediaModel } from '../../models/shared/view-media.model';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';
import { SnackbarService } from '../../services/snackbar.service';
import { ViewMediaComponent } from '../view-media/view-media.component';

@UntilDestroy()
@Component({
    selector: 'app-view-post-item',
    templateUrl: './view-post-item.component.html',
    styleUrls: ['./view-post-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewPostItemComponent implements OnInit {
    mainSlideConfig = {
        dots: true,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 5000,
        draggable: true,
        focusOnSelect: false,
        pauseOnDotsHover: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: true,
        arrows: false,
        swipeToSlide: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    commentPaging = new OffsetPagination({ page: 1 });
    commentList: any[] = [];
    @ViewChild('messageContent') messageContent: ElementRef<HTMLDivElement> | undefined;
    @ViewChild('slickMainCarousel') slickMainCarousel: SlickCarouselComponent | undefined;
    hiddenTextPart = true;
    isCommentOpen = false;
    @Output() onEdit = new EventEmitter<OpenPostDataModel>();

    constructor(
        public dialogRef: MatDialogRef<ViewPostItemComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OpenPostDataModel,
        public _cdr: ChangeDetectorRef,
        private postService: PostService,
        private store: FcaStoreService,
        private _route: ActivatedRoute,
        private _router: Router,
        private readonly likeReactionService: LikeReactionService,
        private snackbarService: SnackbarService,
        private confirmationPopupService: ConfirmationPopupService,
        private readonly dialog: MatDialog
    ) {
        this.subOnReaction();
    }

    getMenuList(): Observable<ThreeDotsMenuItemModel<ThreeDotsItemEnum>[]> {
        return this.postService.getPostThreeDotsMenuList(this.data.ownerId, this.data.commentsEnabled);
    }

    subOnReaction() {
        this.likeReactionService
            .getChangeReaction$()
            .pipe(
                untilDestroy(this),
                filter(Boolean),
                switchMap((resp: CommentReactionModel | null) => {
                    if (resp?.action === LikeReactionEnum.LIKE) {
                        return this.postService.addLikeToComment(resp?.commentId || '');
                    } else {
                        return this.postService.addDislikeToComment(resp?.commentId || '');
                    }
                })
            )
            .subscribe();
    }

    postLikeToggle() {
        this.store
            .getUser()
            .pipe(
                switchMap((user: AuthUserModel | null) => {
                    if (!user) {
                        this._router.navigate(['/auth/login']);
                        this.dialogRef.close();
                        throw new Error('Unauthorized');
                    } else {
                        return of(user);
                    }
                }),
                switchMap((user: AuthUserModel) => {
                    if (this.data.liked) {
                        return this.postService.deleteLikeFromPost(this.data.id).pipe(map(() => user));
                    } else {
                        return this.postService.addLikeToPost(this.data.id).pipe(map(() => user));
                    }
                }),
                tap((user: AuthUserModel) => {
                    this.data.liked = !this.data.liked;
                    if (this.data.liked) {
                        this.data.likes++;
                        const item = {
                            firstName: user.firstName,
                            id: user.id,
                            lastName: user.lastName,
                            nickname: user.nickname,
                            thumbnailAvatar:
                                user.images.find((image: ImageModel) => image.imageType === EImageType.AVATAR_THUMBNAIL)
                                    ?.href || user.images[0]?.href,
                        };
                        this.data.likeUsers.push(item);
                    } else {
                        this.data.likes--;
                        this.data.likeUsers = this.data.likeUsers.filter((item: any) => item.id !== user.id);
                    }
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    loadCommentList() {
        this.postService
            .getCommentList(this.commentPaging, this.data.id)
            .pipe(
                untilDestroy(this),
                take(1),
                tap((response: any) => {
                    this.commentList.push(...response.data);
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    ngOnInit() {
        this.loadCommentList();
        setTimeout(() => {
            this._cdr.detectChanges();
        }, 1000);
    }

    onEnterKeyDown(ev: KeyboardEvent): void {
        ev.preventDefault();
        // @ts-ignore
        this.sendComment({ text: ev.target.innerHTML } as NewCommentParams);
    }

    onSendMessage(el: HTMLElement): void {
        const content = el.innerHTML.replace(/&nbsp;/g, '').trim();
        if (!this.isEmptyMessage(content)) {
            el.innerHTML = '';
        }
    }

    private isEmptyMessage(content: string): boolean {
        return EMPTY_CONTENT_REGEX.test(content) || content.length === 0;
    }

    sendComment(data: NewCommentParams) {
        const text = data.text.replace(/&nbsp;/g, '').trim();
        if (!this.isEmptyMessage(text)) {
            const comment: AddCommentModel = {
                text,
                // @ts-ignore
                parentId: data.parentId,
                postId: this.data.id,
            };
            this.store
                .getUser()
                .pipe(
                    switchMap((user: AuthUserModel | null) => {
                        if (!user) {
                            this._router.navigate(['/auth/login']);
                            this.dialogRef.close();
                            throw new Error('Provider not found');
                        } else {
                            return of(user);
                        }
                    }),
                    switchMap(() => {
                        return this.postService.addComment(comment);
                    }),
                    take(1),
                    untilDestroy(this),
                    tap((resp: any) => {
                        if (data.parentId) {
                            this.commentList.find((item: any) => item.id === data.parentId).children.push(resp);
                        } else {
                            if (this.messageContent) {
                                this.messageContent.nativeElement.innerHTML = '';
                            }
                            this.commentList.push(resp);
                        }
                        this._cdr.detectChanges();
                    })
                )
                .subscribe();
        }
    }

    onReplay($event: NewCommentParams) {
        this.sendComment($event);
    }

    onScrollToEndData() {
        this.commentPaging.page++;
        this.loadCommentList();
    }

    checkTreeDots(postText: HTMLParagraphElement) {
        return postText.scrollHeight > postText.clientHeight;
    }

    closeModel() {
        this.dialogRef.close({ action: 'close', data: this.data });
    }

    deletePost() {
        this.postService
            .openDeletePostModal(this.data.id)
            .pipe(
                untilDestroy(this),
                take(1),
                tap((resp: CloseModalActionInterface<string> | null) => {
                    this.dialogRef.close({ action: 'deleted', data: this.data });
                })
            )
            .subscribe();
    }

    openEditPostModal() {
        this.postService
            .openEditPostModal(this.data)
            .pipe(
                take(1),
                tap((resp: CloseModalActionInterface<PostListModelData>) => {
                    this.data = {
                        id: resp.data.id,
                        name: resp.data.owner.firstName,
                        surname: resp.data.owner.lastName,
                        nickName: resp.data.owner.nickname,
                        avatar: resp.data.owner.thumbnailAvatar,
                        date: resp.data.createdAt,
                        access: resp.data.access,
                        text: resp.data.text,
                        images: resp.data.images,
                        likeUsers: resp.data.likeUsers,
                        liked: resp.data.liked,
                        commentsEnabled: resp.data.commentsEnabled,
                        likes: resp.data.likes,
                        ownerId: resp.data.ownerId,
                    };
                    this.onEdit.emit(this.data);
                    this.dialogRef.close({ action: 'edit', data: this.data } as CloseModalActionInterface);
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    treeDotsActionHandler($event: ThreeDotsItemEnum) {
        switch ($event) {
            case ThreeDotsItemEnum.DELETE: {
                this.deletePost();
                break;
            }
            case ThreeDotsItemEnum.TURN_OFF_COMMENTS: {
                this.toggleCommentEnabledParams();
                break;
            }
            case ThreeDotsItemEnum.TURN_ON_COMMENTS: {
                this.toggleCommentEnabledParams();
                break;
            }
            case ThreeDotsItemEnum.EDIT: {
                this.openEditPostModal();
                break;
            }
            case ThreeDotsItemEnum.COPY_LINK: {
                this.copyLink();
                break;
            }
        }
    }

    copyLink() {
        const link = `${window.location.origin}/profile/${this.data.ownerId}?openPostModalId=${this.data.id}`;
        navigator.clipboard.writeText(link);
    }

    toggleCommentEnabledParams() {
        this.postService
            .togglePostComment(this.data.id, !this.data.commentsEnabled)
            .pipe(
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.data.commentsEnabled = !this.data.commentsEnabled;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    onImageClick() {
        this.dialog.open<ViewMediaComponent, ViewMediaModel>(ViewMediaComponent, {
            backdropClass: 'view-media-popup-backdrop',
            panelClass: 'view-media-popup-panel',
            hasBackdrop: true,
            disableClose: true,
            data: {
                // @ts-ignore
                image: this.data.images[this.slickMainCarousel?.currentIndex].url,
            },
        });
    }

    toggleComment() {
        this.isCommentOpen = !this.isCommentOpen;
    }
}
