import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs';
import { ChatService } from '../../../../api/fca/chat/chat.service';
import { eventReactionsIcons } from '../../../config/event-reaction.config';
import { EFightEventReactionType, FightEventReaction } from '../../../models/fight-event/fight-event.model';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { FcaAuthService } from '../../../services/fca-auth.service';
import { FcaChatService } from '../../../services/fca-chat.service';
import { FcaFightEventsService } from '../../../services/fca-fight-events.service';
import { UntilDestroy, untilDestroy } from '../../operators/until-destroy.operator';
import { LiveEventReactionService } from '../../services/live-event-reaction.service';

@UntilDestroy()
@Component({
    selector: 'app-event-reactions',
    templateUrl: './event-reactions.component.html',
    styleUrls: ['./event-reactions.component.scss'],
})
export class EventReactionsComponent {
    private readonly reactionsIcons = eventReactionsIcons;
    @Input() reactions: FightEventReaction[] = [];
    @Input() fightEventId: string;
    @Input() isLive: boolean;
    @Input() animation: boolean = true;
    @Input() autoIncreaseCount: boolean = true;
    currentUser: AuthUserModel | null;
    reactionIsDisabled = false;
    @Output() onAction = new EventEmitter<EFightEventReactionType>();

    constructor(
        private readonly fightEventsService: FcaFightEventsService,
        private authService: FcaAuthService,
        private liveEventReactionService: LiveEventReactionService,
        private _cdk: ChangeDetectorRef
    ) {
        this.authService.currentUser$.pipe(take(1)).subscribe((value: AuthUserModel | null) => {
            this.currentUser = value;
        });
        this.liveEventReactionService.reactionList$
            .pipe(untilDestroy(this))
            .subscribe((value: FightEventReaction[]) => {
                this.findDifferInReactions(value);
                this.reactions = value;
                this._cdk.detectChanges();
            });
    }

    findDifferInReactions(reactions: FightEventReaction[]) {
        reactions.map((reaction: FightEventReaction) => {
            const reactionIndex = this.reactions.findIndex((item: FightEventReaction) => item.type === reaction.type);
            if (this.reactions[reactionIndex]?.count !== reaction?.count) {
                this.liveEventReactionService.emitAction(reaction.type);
            }
        });
    }

    onReactionClick(type: EFightEventReactionType, el: HTMLButtonElement): void {
        if (this.animation) {
            el.classList.add('bounce-animation');
        }
        setTimeout(() => {
            el.classList.remove('bounce-animation');
        }, 500);
        if (!this.isLive) {
            if (this.animation) {
                el.classList.add('timer-animation');
                this.reactionIsDisabled = true;
            }
            if (this.autoIncreaseCount) {
                this.reactions.find((r) => r.type === type)!.count++;
            }
            setTimeout(() => {
                this.reactionIsDisabled = false;
                this._cdk.detectChanges();
                el.classList.remove('timer-animation');
            }, 10000);
        }
        this.onAction.emit(type);
        this._cdk.detectChanges();
    }

    getReactionIcon(type: EFightEventReactionType): string {
        return this.reactionsIcons[type];
    }

    identify(index: any, item: FightEventReaction) {
        return item.type;
    }
}
