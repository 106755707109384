import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageLinks } from '../../../config/seo/seo-config.interface';
import { SeoSetUpService } from '../../../config/seo/seo-set-up.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsComponent {
    constructor(private readonly seoService: SeoSetUpService) {
        this.seoService.setMetaByPage(PageLinks.TermsOfUse);
    }
}
