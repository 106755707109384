import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadFileProgressService } from '../../../services/upload-file-progress.service';
import { FileUploaderEmitModel } from '../../models/shared/file-uploader-emit.model';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
    @Input() invalid = false;
    @Input() accept = '';
    @Input() file: File | null;
    @Input() placeholder = '';
    @Input() showProgressBar = true;
    @Output() onChange = new EventEmitter<FileUploaderEmitModel | null>();
    @Output() onDeleted = new EventEmitter<void>();
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
    uploadFileProgressService = new UploadFileProgressService();

    constructor() {}

    ngOnInit(): void {}

    videoFileInputChangeHandler(event: Event) {
        const file: File | undefined = (event.target as HTMLInputElement)?.files?.[0];
        if (!file) {
            return;
        }
        this.file = file;
        this.onChange.emit({ file: this.file, progressChangeHandler: this.uploadFileProgressService });
    }

    deleteVideo($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.file = null;
        this.fileInput.nativeElement.value = '';
        this.onDeleted.emit();
    }
}
