import { EUserRole } from '../../../shared/enums/user-role.enum';
import { ImageModel } from '../../../shared/models/image/image.model';
import { EImageType } from '../../images/user-image.model';
import { UserFanModelData } from '../user-fan/user-fan.model';
import { UserModelData } from '../user.model';

export interface AuthUserModelData
    extends Pick<UserModelData, 'roleName' | 'email' | 'id' | 'images' | 'stripeCustomerId' | 'locale' | 'isPremium' | 'isContentCreator'>,
        Pick<UserFanModelData, 'firstName' | 'lastName' | 'nickname'> {}

export interface AuthUserModel extends AuthUserModelData {}

export class AuthUserModel {
    get isAdmin(): boolean {
        return this.roleName === EUserRole.ADMIN;
    }

    get avatarUrl(): string | null {
        return (
            this.images?.find((item: ImageModel) => item.imageType === EImageType.FULL_FACE_AVATAR)?.href ||
            this.images?.find((item: ImageModel) => item.imageType === EImageType.FAN_AVATAR)?.href ||
            this.images?.[0]?.href ||
            null
        );
    }
}
