<div class="popup-wrapper" *transloco="let t">
    <div class="title-wrapper flex align-items-center justify-content__space-between">
        <div class="flex align-items-center">
            {{ t('header-nav-creators-tab') }}
        </div>
        <button class="close-btn center" (click)="dialogRef.close(false)">
            <img src="assets/images/close-popup.svg" alt="" />
        </button>
    </div>
    <div class="search-wrapper flex align-items-center">
        <input
            type="text"
            (input)="searchFilter()"
            [(ngModel)]="userListParams.search"
            name="search"
            [placeholder]="t('shared-search-label')"
        />
        <img src="assets/images/search-loop.svg" alt="" />
        <div *ngIf="loading && userListParams.search" class="loader-wrapper">
            <mat-progress-spinner
                class="example-margin"
                [color]="'primary'"
                [diameter]="17"
                [mode]="'indeterminate'"
                [value]="20"
            >
            </mat-progress-spinner>
        </div>
    </div>
    <ul
        class="followers-list scroll-bar"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollUpDistance]="1.5"
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
    >
        <ng-container *ngFor="let user of creatorsList">
            <li class="follower-wrapper flex align-items-center justify-content__space-between">
                <a
                    href=""
                    [routerLink]="'/profile/' + user.id"
                    (click)="dialogRef.close()"
                    class="flex align-items-center"
                >
                    <div class="avatar center">
                        <img
                            [src]="(user.thumbnailAvatar | imagePathWhiteSpace) || 'assets/images/avatar_placeholder.svg'"
                            alt=""
                        />
                    </div>
                    <div class="data">
                        <p class="full-name">
                            {{ user.fullName }}
                        </p>
                        <p class="nickname" *ngIf="user.nickName">@{{ user.nickName }}</p>
                    </div>
                </a>
                <button class="main-btn action-btn" *ngIf="!user.isFollowing" (click)="followUnfollowUser(user)">
                    {{ t('profile-page-followers-list-follow-btn') }}
                </button>
                <button class="cancel-btn action-btn" *ngIf="user.isFollowing" (click)="followUnfollowUser(user)">
                    {{ t('profile-page-followers-list-unfollow-btn') }}
                </button>
            </li>
        </ng-container>
        <ng-container *ngIf="loading">
            <li
                *ngFor="let item of [1, 2, 3, 4, 5, 6]"
                class="follower-wrapper flex align-items-center justify-content__space-between"
            >
                <div class="flex align-items-center">
                    <div class="avatar center">
                        <ngx-skeleton-loader
                            [theme]="{
                                width: '50px',
                                height: '50px',
                                margin: '0',
                                borderRadius: ''
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <div class="data">
                        <p class="full-name">
                            <ngx-skeleton-loader
                                [theme]="{
                                    width: '200px',
                                    height: '19px',
                                    margin: '0',
                                    borderRadius: ''
                                }"
                            ></ngx-skeleton-loader>
                        </p>
                        <p class="nickname">
                            <ngx-skeleton-loader
                                [theme]="{
                                    width: '150px',
                                    height: '17px',
                                    margin: '0',
                                    borderRadius: ''
                                }"
                            ></ngx-skeleton-loader>
                        </p>
                    </div>
                </div>
                <ngx-skeleton-loader
                    [theme]="{
                        width: '100px',
                        height: '36px',
                        margin: '0',
                        borderRadius: ''
                    }"
                ></ngx-skeleton-loader>
            </li>
        </ng-container>
    </ul>
</div>
