import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ObjectHelper } from './shared/helpers/object-helper';

export interface LayoutStateInterface {
    showHeader: boolean;
    showMainContent: boolean;
    showFooter: boolean;
    showSideBar: boolean;
}

@Injectable()
export class AppService {
    private layoutState$ = new BehaviorSubject<LayoutStateInterface>({
        showHeader: false,
        showFooter: false,
        showMainContent: false,
        showSideBar: false,
    });
    private readonly modulesWithoutFooter: string[] = ['auth'];
    private readonly modulesWithoutHeader: string[] = ['auth', 'terms', 'privacy'];
    private readonly modulesWithoutMainContent: string[] = ['auth', 'terms', 'privacy', 'contacts'];
    // private readonly modulesWithoutSideBar: string[] = ['auth', 'terms', 'privacy', 'other-events', 'dashboard'];
    private readonly modulesWithoutSideBar: string[] = ['auth', 'terms', 'privacy'];

    activeModule$ = new BehaviorSubject<string | null>(null);

    getLayoutState$(): Observable<LayoutStateInterface> {
        return this.layoutState$.pipe(
            filter((layout) => !!layout),
            distinctUntilChanged(ObjectHelper.simpleCompare)
        );
    }

    setLayoutState(state: LayoutStateInterface): void {
        this.layoutState$.next(state);
    }

    changeActiveModule(module: string): void {
        if (module !== this.activeModule$.getValue()) {
            this.activeModule$.next(module);
            this.layoutState$.next({
                showHeader: !this.modulesWithoutHeader.includes(module),
                showFooter: !this.modulesWithoutFooter.includes(module),
                showMainContent: !this.modulesWithoutMainContent.includes(module),
                showSideBar: !this.modulesWithoutSideBar.includes(module),
            });
        }
    }
}
