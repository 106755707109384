import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FcaImagesApiService } from '../../api/fca/images/fca-images-api.service';

@Injectable()
export class FcaImagesService {
    constructor(private readonly imagesService: FcaImagesApiService) {}

    uploadUserPhoto(userId: number, file: File, type: string, position: number = 0): Observable<any> {
        return this.imagesService.uploadPhoto(userId, { file, type, position });
    }
}
