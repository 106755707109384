<ng-container *ngIf="!loading; else preloader">
    <ng-container *transloco="let t">
        <div (click)='goToProfile()' class="creator-wrapper">
            <div class="logo-wrapper">
                <img [src]="avatarUrl | imagePathWhiteSpace : 'assets/images/avatar_placeholder.svg'" alt="" />
            </div>
            <p class="full-name">{{ fullName }}</p>
            <p class="followers-wrapper">
                {{ followers | reductionOfNumbers }}
                <span>{{ t(getFollowersRuVersion(+followers)) }}</span>
            </p>
            <div class="action flex justify-content__center">
                <button class="main-btn" (click)="followUnfollowUser($event)" *ngIf="!isFollowed; else unfollowTemplate">
                    {{ t('profile-page-follow-btn') }}
                </button>
                <ng-template #unfollowTemplate>
                    <button class="cancel-btn" (click)="followUnfollowUser($event)">
                        {{ t('profile-page-unfollow-btn') }}
                    </button>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #preloader>
    <ngx-skeleton-loader
        [theme]="{
            width: '100%',
            height: '158px',
            margin: '0px',
            borderRadius: '15px'
        }"
    ></ngx-skeleton-loader>
</ng-template>
