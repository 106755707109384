import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { NewCommentParams } from '../../api/fca/bloods-video/params/new-comment.params';
import { PostLikeParams } from '../../api/fca/bloods-video/params/post-like.params';
import { KicksVideoApiService } from '../../api/fca/kicks-video/kicks-video-api.service';
import { GetKicksVideoListParams } from '../../api/fca/kicks-video/params/get-kicks-video-list.params';
import { KickInfoByIdResponse } from '../../api/fca/kicks-video/response/kick-info-by-id.response';
import { KicksListMinInfoApiResponse } from '../../api/fca/kicks-video/response/kicks-list-min-info-api.response';
import { FightEventCommentFactory } from '../models/fight-event/fight-event-comments/fight-event-comment.factory';
import { FightEventCommentMapper } from '../models/fight-event/fight-event-comments/fight-event-comment.mapper';
import { FightEventCommentModel } from '../models/fight-event/fight-event-comments/fight-event-comment.model';
import { KicksInfoByIdFactory } from '../models/kicks/kicks-info-by-id/kicks-info-by-id.factory';
import { KicksInfoByIdMapper } from '../models/kicks/kicks-info-by-id/kicks-info-by-id.mapper';
import { KicksInfoByIdModel } from '../models/kicks/kicks-info-by-id/kicks-info-by-id.model';
import { KicksMinInfoListItemFactory } from '../models/kicks/kicks-min-info-list-item/kicks-min-info-list-item.factory';
import { KicksMinInfoListItemMapper } from '../models/kicks/kicks-min-info-list-item/kicks-min-info-list-item.mapper';
import { KicksMinInfoListItemModel } from '../models/kicks/kicks-min-info-list-item/kicks-min-info-list-item.model';
import { LikeUnlikeEnum } from '../shared/enums/like-unlike.enum';
import { OffsetPagination } from '../shared/interfaces/offset-pagination.interface';

@Injectable({
    providedIn: 'root',
})
export class KicksVideoService {
    constructor(private kicksVideoApiService: KicksVideoApiService) {}

    addNewComment(videoId: string, body: NewCommentParams): Observable<FightEventCommentModel> {
        return this.kicksVideoApiService.addNewComment(videoId, body);
    }

    addLikeForComment(body: PostLikeParams): Observable<FightEventCommentModel> {
        return this.kicksVideoApiService.addLikeForComment(body);
    }

    setAsViewed(videoId: string, userId: number): Observable<KicksInfoByIdModel> {
        return this.kicksVideoApiService.setAsViewed(videoId, userId);
    }

    getKicksVideoMinInfoList(data: GetKicksVideoListParams): Observable<KicksMinInfoListItemModel[]> {
        return this.kicksVideoApiService.getKickMinInfoList(data).pipe(
            map((rawData: KicksListMinInfoApiResponse[]) => {
                return rawData.reduce<KicksMinInfoListItemModel[]>((acc, raw) => {
                    acc.push(
                        new KicksMinInfoListItemFactory().getModelFromData(
                            new KicksMinInfoListItemMapper().mapData(raw)
                        )
                    );
                    return acc;
                }, []);
            }),
        );
    }

    getKicksInfoById(id: string): Observable<KicksInfoByIdModel> {
        return this.kicksVideoApiService.loadKicksInfoById(id).pipe(
            map((rawData: KickInfoByIdResponse) => {
                return new KicksInfoByIdFactory().getModelFromData(new KicksInfoByIdMapper().mapData(rawData));
            })
        );
    }

    likeAndUnlikeVideo(type: LikeUnlikeEnum, id: string): Observable<KicksInfoByIdModel> {
        return this.kicksVideoApiService.likeAndUnlikeVideo(id, type).pipe(
            map((raw) => {
                return new KicksInfoByIdFactory().getModelFromData(new KicksInfoByIdMapper().mapData(raw));
            })
        );
    }

    getCommentsList(videoId: string, paging: OffsetPagination): Observable<FightEventCommentModel[]> {
        return this.kicksVideoApiService.loadCommentList(videoId, paging).pipe(
            map((raw) =>
                raw.data.map((rawComment) => {
                    return new FightEventCommentFactory().getModelFromData(
                        new FightEventCommentMapper().mapData(rawComment)
                    );
                })
            )
        );
    }
}
