import { YookassaSubscriptionResponse } from '../../../../api/fca/payments/response/yookassa-subscription.response';
import { currencySymbolConfig } from '../../../config/currency-symbol.config';
import { CoinPaymentSystemEnum } from '../../../shared/enums/coin-payment-system.enum';
import { CurrencyEnum } from '../../../shared/enums/currency.enum';
import { SubscriptionInterval } from '../../../shared/enums/subscription-interval.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { SubscriptionModelData } from '../subscription.model';

export class YookassaSubscriptionMapper
    implements ModelDataMapper<YookassaSubscriptionResponse, SubscriptionModelData>
{
    mapData(raw: YookassaSubscriptionResponse): SubscriptionModelData {
        return {
            id: raw.id,
            amount: raw.price,
            displayAmount: raw.price,
            currency: CurrencyEnum.RUB,
            // @ts-ignore
            currencySymbol: currencySymbolConfig[CurrencyEnum.RUB],
            interval: raw.interval,
            intervalCount: raw.intervalCount,
            monthIntervalCount: raw.interval === SubscriptionInterval.YEAR ? raw.intervalCount * 12 : raw.intervalCount,
            paymentSystem: CoinPaymentSystemEnum.YOOKASSA,
        };
    }
}
