import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FightEventsPreview } from '../../../../api/fca/fight-event/params/fight-events-preview';
import { FightEventLightModel } from '../../../models/fight-event/fight-event-light/fight-event-light.model';
import { EFightEventStatus } from '../../../models/fight-event/fight-event.model';
import { FcaFightEventsService } from '../../../services/fca-fight-events.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
    events$: Observable<FightEventLightModel[]> = of([]);

    constructor(private readonly fightEventsService: FcaFightEventsService, private router: Router) {
        this.events$ = this.fightEventsService.getFightEventsForMainPage(
            new FightEventsPreview({
                status: [EFightEventStatus.FINISHED],
                limit: 8,
            })
        );
    }
}
