<div id='reactions' class='flex flex-row'>
  <button
    class='reactions-item no-select draw center'
    #element
    (click)='onReactionClick(reaction.type, element)'
    [disabled]='reactionIsDisabled'
    *ngFor='let reaction of reactions; trackBy:identify'
  >
    <svg width='55' height='26' viewBox='0 0 55 26' fill='none' xmlns='http://www.w3.org/2000/svg'
         xmlns:xlink='http://www.w3.org/1999/xlink'>
      <rect y="0.5" x='-0.000244141' width='55' height='26' rx='13' fill='#262626' />
      <rect y="0.5" class='border' x='-0.000244141' width='55' height='24' rx='13' fill='#262626' stroke='#FF0101'
            stroke-dasharray='0 550' />
    </svg>
    <span class='btn-content center'>
      <span class='reactions-item__type'>{{ getReactionIcon(reaction.type) }}</span>
      <span class='reactions-item__count'>{{ reaction.count }}</span>
    </span>
  </button>
</div>




