import { Component, Inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleItem } from '../roles/roles-list.component';

export interface JoinNowComponentParams {
    isModalLayout?: boolean;
}

interface JoinNowComponentErrors {
    hasRoleError: boolean;
    hasEmptyEmail: boolean;
    hasEmailError: boolean;
}

@Component({
    selector: 'app-join-now',
    templateUrl: './join-now.component.html',
    styleUrls: ['./join-now.component.scss'],
})
export class JoinNowComponent {
    @Input() data: JoinNowComponentParams;
    public errors: JoinNowComponentErrors;
    public email: FormControl;
    private selectedRole?: RoleItem;
    get hasErrors(): boolean {
        return this.errors.hasEmailError || this.errors.hasRoleError || this.errors.hasEmptyEmail;
    }
    constructor() {
        this.errors = {
            hasRoleError: false,
            hasEmptyEmail: false,
            hasEmailError: false,
        };
        this.email = new FormControl('', {
            validators: [Validators.email, Validators.required],
            updateOn: 'change',
        });
    }
    public onSelectRole(e: RoleItem) {
        this.selectedRole = e;
        this.errors.hasRoleError = false;
    }
    public onChangeEmail(e: any) {
    }
    public onSubmit() {
        this.email.markAsDirty();
        this.email.markAsTouched();
        this.email.updateValueAndValidity();
        if (!this.selectedRole) {
            this.errors.hasRoleError = true;
        }
        if (this.email.invalid) {
            this.errors.hasEmptyEmail = true;
        }
    }
}

@Component({
    template: `<div class="join-now-modal"><app-join-now [data]="params"></app-join-now></div>`,
})
export class JoinNowModalComponent {
    @Input() data: JoinNowComponentParams;
    constructor(@Inject(MAT_DIALOG_DATA) public params: JoinNowComponentParams) {}
}
