import { UserImageApiResponse } from '../../../api/fca/images/response/user-image-api.response';
import { ModelDataMapper } from '../../shared/interfaces/model-mapper.interface';
import { ImageMapper } from '../../shared/models/image/image.mapper';
import { EImageType, UserImageModelData } from './user-image.model';

export class UserImageMapper implements ModelDataMapper<UserImageApiResponse, UserImageModelData> {
    mapData(raw: UserImageApiResponse): UserImageModelData {
        return {
            ...new ImageMapper().mapData(raw),
            imageType: raw.imageType as EImageType,
        };
    }
}
