<ng-container *transloco="let t">
    <div
        #commentsContainer
        class="comments"
        [class.new-comment-on-top]="newCommentOnTop"
        [class.new-comment-on-bottom]="!newCommentOnTop"
    >
        <div *ngIf="newCommentOnTop" class="comments-header flex flex-column">
            <app-new-comment style="width: 100%" (onSend)="onSendComment($event)"> </app-new-comment>
        </div>
        <div class="comments-list-wrapper">
            <div class="loading" *ngIf="loading">Loading...</div>
            <section
                class="comments-list scroll-bar"
                infinite-scroll
                [infiniteScrollDistance]="2"
                [infiniteScrollUpDistance]="1.5"
                [infiniteScrollThrottle]="300"
                [scrollWindow]="false"
                (scrolled)="onScrollToEndData()"
                *ngIf="currentUser$ | async as currentUser"
            >
                <ng-container *ngIf="!loading; else commentPlaceholder">
                    <app-comment-item
                        *ngFor="let comment of comments"
                        [data]="comment"
                        [currentUserId]="currentUser?.id"
                        (onSend)="onSendComment($event)"
                    >
                    </app-comment-item>
                </ng-container>

                <ng-template #commentPlaceholder> </ng-template>
            </section>
        </div>
        <div *ngIf="!newCommentOnTop" class="comments-header flex flex-column">
            <app-new-comment style="width: 100%" (onSend)="onSendComment($event)"> </app-new-comment>
        </div>
    </div>
</ng-container>
