import { EUserRole } from '../../enums/user-role.enum';

export class UserPersonalDataModel {
    id: number | null;
    firstName: string = '';
    lastName: string = '';
    nickname: string = '';
    description: string = '';
    roleName: EUserRole = EUserRole.NONE;
    thumbnailAvatar: string = '';
    backgroundPhoto: string = '';
    isContentCreator: boolean;
}
