import { UrlMatchResult, UrlSegment } from '@angular/router';

export interface RoutingParams {
    regexp: string;
    segments: number[];
    value?: string;
    name: string;
    required: boolean;
}

export class RoutingRegex {
    // static match: UrlMatcher;
    static regex(segments: UrlSegment[], params: RoutingParams[]): UrlMatchResult | null {
        const posParams: { [key: string]: UrlSegment } = {};
        const consumed: UrlSegment[] = [];
        const withRes: boolean[] = [];
        let correctPath = true;
        Object.keys(params).forEach((key) => {
            // @ts-ignore
            const param = params[key];
            const part: RegExp = new RegExp(param.regexp, 'i');
            const paramName: string = param.name;
            const required: boolean = param.hasOwnProperty('required') && param.required;
            let keyFound = false;
            // @ts-ignore
            Object.keys(params[key].segments).forEach((segmentKey) => {
                if (keyFound) {
                    return;
                }

                const segmentNum: number = param.segments[segmentKey];
                // required param not found in segment
                if (typeof segments[segmentNum] === 'undefined' && param.required) {
                    correctPath = false;
                }
                if (typeof segments[segmentNum] === 'undefined' || withRes[segmentNum] === true) {
                    return;
                }
                if (part.test(segments[segmentNum].path)) {
                    withRes[segmentNum] = true;
                    keyFound = true;
                    consumed.push(segments[segmentNum]);
                    // @ts-ignore
                    if (params[key].value === 'nextSegment') {
                        const nextSegmentPosition = segmentNum + 1;
                        withRes[nextSegmentPosition] = true;
                        if (segments[nextSegmentPosition]) {
                            consumed.push(segments[nextSegmentPosition]);
                            posParams[paramName] = segments[nextSegmentPosition];
                        } else {
                            posParams[paramName] = segments[segmentNum];
                        }
                    } else {
                        posParams[paramName] = segments[segmentNum];
                    }

                    return;
                    // required param not correct
                } else if (required) {
                    correctPath = false;
                }
            });
        });

        return correctPath ? { consumed, posParams } : null;
    }
}
