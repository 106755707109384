import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { FcaAuthService } from '../../services/fca-auth.service';
import { UtilsService } from '../../services/utils.service';
import { AuthData } from '../../shared/interfaces/auth-data.interface';
import { FcaStoreService } from '../../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class CheckTokenGuard implements CanActivate {
    constructor(
        private authService: FcaAuthService,
        private utilsService: UtilsService,
        private _router: Router,
        private readonly storeService: FcaStoreService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.storeService.getAuthData().pipe(
            switchMap((authData: AuthData | null) => {
                if (authData?.jwt) {
                    const exp = (this.utilsService.parseJwt(authData?.jwt)?.exp || 1) * 1000;
                    if (exp < new Date().getTime()) {
                        return this.authService.refreshTokens().pipe(map(() => true));
                    }
                    return of(true);
                } else {
                    return of(true);
                }
            })
        );
    }
}
