import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreeDotsMenuItemModel } from '../../models/shared/three-dots-menu-item.model';

@Component({
    selector: 'app-three-dots-menu',
    templateUrl: './three-dots-menu.component.html',
    styleUrls: ['./three-dots-menu.component.scss'],
})
export class ThreeDotsMenuComponent implements OnInit {
    @Input() threeDotsMenuList: ThreeDotsMenuItemModel[] = [];
    @Input() menuPosition: 'left' | 'right' = 'left';
    @Output() onSelect = new EventEmitter<string>();
    isOpen = false;

    constructor() {}

    ngOnInit(): void {}

    dotClick(event: MouseEvent) {
        this.isOpen = !this.isOpen;
    }

    stopPropagation($event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    menuItemClick($event: MouseEvent, item: ThreeDotsMenuItemModel) {
        this.onSelect.emit(item.value);
        this.isOpen = false;
    }
}
