import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { defer, filter, forkJoin, Observable, of, switchMap, take, tap } from 'rxjs';
import { CreatorSubscriptionsListData } from '../../../../api/fca/payments/params/personal_subscription';
import { AddImageToPostParams } from '../../../../api/fca/post/params/add-image-to-post.params';
import { AddPostParams, AddPostParamsImage } from '../../../../api/fca/post/params/add-post.params';
import { ChangePostParams } from '../../../../api/fca/post/params/change-post.params';
import { UpdateImageParams } from '../../../../api/fca/post/params/update-image.params';
import { PostListModelData } from '../../../models/post/post-list/post-list.model';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { PostService } from '../../../services/post.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { UtilsService } from '../../../services/utils.service';
import { FcaStoreService } from '../../../store/store.service';
import { PostContentAccessEnum } from '../../enums/post-content-access.enum';
import { CloseModalActionInterface } from '../../models/shared/close-modal-action.interface';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';
import { slidersConfig } from './sliders.config';

interface PostDataFileItemModel {
    file?: File;
    src: SafeUrl;
    customFrontId: string;
    uploadedFileResponse?: UploadedFileItemModel;
}

class PostDataModel {
    files: PostDataFileItemModel[] = [];
    id = '';
}

interface AccessItemModel {
    label: string;
    name: string;
    description: string;
    disabled?: boolean;
    tooltipText?: string;
    value: PostContentAccessEnum;
}

interface UploadedFileItemModel {
    postId: string;
    file?: File;
    path: string;
    url: string;
}

@UntilDestroy()
@Component({
    selector: 'app-add-post-popup',
    templateUrl: './add-post-popup.component.html',
    styleUrls: ['./add-post-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPostPopupComponent implements OnInit {
    @ViewChild('slickMainCarousel') slickMainCarousel: SlickCarouselComponent;
    @ViewChild('slickMinCarousel') slickMinCarousel: SlickCarouselComponent;

    postData = new PostDataModel();
    activeStep = 0;
    mainSlideConfig = slidersConfig.mainSlideConfig;
    minSlideConfig = slidersConfig.minSlideConfig;
    minPreviewSlickConfig = slidersConfig.minPreviewSlickConfig;
    activeSlideIndex = 0;
    description = '';
    access: PostContentAccessEnum = PostContentAccessEnum.FREE;
    accessRadioList: AccessItemModel[] = [
        {
            label: 'add-post-access-subscribers-only-label',
            name: 'access',
            description: 'add-post-access-subscribers-only-description',
            value: PostContentAccessEnum.SUBSCRIBERS,
            tooltipText: 'content-creator-upload-video-page-free-for-sub-force-set-price',
        },
        {
            label: 'add-post-access-public-label',
            name: 'access',
            description: 'add-post-access-public-description',
            value: PostContentAccessEnum.FREE,
        },
    ];
    submitText = false;
    commentsEnabled = true;
    isEdit = false;
    removedImageIds: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddPostPopupComponent>,
        private _cdr: ChangeDetectorRef,
        private postService: PostService,
        private utilsService: UtilsService,
        private _domSanitizer: DomSanitizer,
        private readonly store: FcaStoreService,
        private subService: SubscriptionService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.postData.id = utilsService.generateUuid();
        this.initDataForEdit();
    }

    initDataForEdit() {
        this.isEdit = this.data?.isEdit;
        if (!this.isEdit) {
            return;
        }
        this.activeStep = 2;
        this.postData.files = this.data.post['images'].map((item: any) => {
            return {
                file: undefined,
                src: item.url,
                customFrontId: item.id,
                uploadedFileResponse: {
                    postId: item.id,
                    file: undefined,
                    path: item.name,
                    url: item.url,
                },
            };
        });
        this.description = this.data.post['text'];
        this.access = this.data.post['access'];
        this.commentsEnabled = this.data.post['commentsEnabled'];
    }

    ngOnInit(): void {
        this.checkPersonalSub();
    }

    checkPersonalSub() {
        this.store
            .getUser()
            .pipe(
                take(1),
                untilDestroy(this),
                filter(Boolean),
                switchMap((user: AuthUserModel) => {
                    return this.subService.getSubscriptions({ userId: user.id });
                }),
                tap((res: CreatorSubscriptionsListData[]) => {
                    this.accessRadioList.find(
                        (item: AccessItemModel) => item.value === PostContentAccessEnum.SUBSCRIBERS
                    )!.disabled = !res.length;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    fileInputChangeHandler($event: FileList | null) {
        const files: FileList | null = $event;
        if (!files) {
            return;
        }
        this.mapFiles(files);
        this.activeStep = 1;
        this.activeSlideIndex = 0;
        this._cdr.detectChanges();
    }

    mapFiles(files: FileList) {
        for (let i = 0; i < files.length && this.postData.files.length < 10; i++) {
            this.postData.files.push({
                file: files[i],
                src: this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[i])),
                customFrontId: this.utilsService.generateUuid(),
            });
        }
    }

    addFileOnEdit($event: FileList | null) {
        const files: FileList | null = $event;
        if (!files) {
            return;
        }
        this.mapFiles(files);
        this.sendImagesToAws();
    }

    sliderChangeHandler(currentIndex: number) {
        this.activeSlideIndex = currentIndex;
        this.slickMainCarousel.slickGoTo(currentIndex);
        this.slickMinCarousel.slickGoTo(currentIndex);
        this._cdr.detectChanges();
    }

    removeSlide(i: number) {
        if (i === this.postData.files.length - 1) {
            this.sliderChangeHandler(i - 1);
        }
        this.postData.files.splice(i, 1);
        if (this.postData.files.length === 0) {
            this.activeStep = 0;
        }
        this._cdr.detectChanges();
    }

    onFileDropped($event: any) {
        this.fileInputChangeHandler($event);
    }

    publish() {
        this.submitText = true;
        if (this.description.length < 3) {
            return;
        }
        const params: AddPostParams = {
            id: this.postData.id,
            text: this.description,
            state: 'published',
            access: this.access,
            commentsEnabled: this.commentsEnabled,
            images: this.postData.files.map((item: PostDataFileItemModel, i: number) => {
                return {
                    position: i,
                    name: item.uploadedFileResponse?.path,
                } as AddPostParamsImage;
            }),
        };
        this.postService
            .addPost(params)
            .pipe(
                take(1),
                tap((response: any) => {
                    this.submitText = false;
                    this.dialogRef.close({ action: 'add', data: response });
                })
            )
            .subscribe();
    }

    update() {
        const params: UpdateImageParams = {
            postId: this.data.post['id'],
            images: this.postData.files.map((item: PostDataFileItemModel, position: number) => {
                return {
                    imageId: item.uploadedFileResponse?.postId || '',
                    position,
                };
            }),
        };

        const addImages: Observable<void>[] = [];
        for (let i = 0; i < this.postData.files.length; i++) {
            if (this.postData.files[i].file) {
                const params: AddImageToPostParams = {
                    name: this.postData.files[i].uploadedFileResponse?.path || '',
                    position: i,
                    postId: this.data.post.id,
                };
                addImages.push(this.postService.addImageToPost(params));
            }
        }

        const body: ChangePostParams = {
            text: this.description,
            state: 'published',
            access: this.access,
            commentsEnabled: this.commentsEnabled,
        };

        const removeImage = this.removedImageIds.map((id: string) =>
            this.postService.deleteImage(id, this.data.post.id)
        );

        defer(() => {
            return removeImage.length ? forkJoin(removeImage) : of(null);
        })
            .pipe(
                switchMap(() => (addImages.length ? forkJoin(addImages) : of(null))),
                switchMap(() => this.postService.updateImages(params)),
                switchMap(() => this.postService.updatePost(this.data.post['id'], body)),
                switchMap(() => this.postService.getPostById(this.data.post['id'])),
                tap((resp: PostListModelData) => {
                    this.dialogRef.close({
                        action: 'edit',
                        data: resp,
                    } as CloseModalActionInterface<PostListModelData>);
                })
            )
            .subscribe();
    }

    deleteImage(imageId: string) {
        this.removedImageIds.push(imageId);
        this.postData.files = this.postData.files.filter(
            (item: PostDataFileItemModel) => item.customFrontId !== imageId
        );
        this._cdr.detectChanges();
    }

    stepToggle(event: number) {
        this.activeStep += event;
        if (this.activeStep === 2) {
            this.sendImagesToAws();
        }
    }

    sendImagesToAws() {
        this.postService
            .uploadImagesToAws(
                this.postData.files
                    .filter((item: PostDataFileItemModel) => !item.uploadedFileResponse)
                    .map((item: PostDataFileItemModel) => {
                        return {
                            photo: item.file!,
                            customFrontId: item.customFrontId,
                        };
                    }),
                this.postData.id
            )
            .pipe(
                tap((resp: any) => {
                    const item: UploadedFileItemModel = {
                        postId: resp.response1.id,
                        file: resp.file,
                        path: resp.response3.path,
                        url: resp.response3.url,
                    };
                    const findItem = this.postData.files.find((item: any) => item.customFrontId === resp.customFrontId);
                    if (findItem) {
                        findItem.uploadedFileResponse = item;
                    }
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    drop(event: CdkDragDrop<unknown>) {
        moveItemInArray(this.postData.files, event.previousIndex, event.currentIndex);
    }

    changeCommentsEnabled($event: boolean) {
        this.commentsEnabled = !$event;
    }

    isPublishBtnDisabled(): boolean {
        return (
            !this.postData.files?.length ||
            !this.postData.files.every((item: PostDataFileItemModel) => item.uploadedFileResponse)
        );
    }
}
