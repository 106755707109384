<label
  *transloco='let t'
  class='label'
  [matTooltip]='t(tooltipText)'
  [matTooltipDisabled]='!disabled'
  [class.disabled]='disabled'
>
    <span
      class='flex align-items-center'
    >
        <input
          type='radio'
          (input)='onSelect.emit(value)'
          [value]='value'
          [(ngModel)]='selectedValue'
          [name]='name'
          [disabled]='disabled'
        />
        <span class='circle'></span>
        <span class='label-text'>
            {{ t(label) }}
        </span>
    </span>
  <span class='description'>
        {{ t(description) }}
    </span>
</label>
