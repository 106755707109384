<div class='image-cropper-wrapper' *transloco='let t'>
  <p class='title'>{{t('image-cropper-popup-title')}}</p>
  <p class='sub-title'>{{t('image-cropper-popup-subtitle')}}</p>
  <p class='note'>{{t('image-cropper-popup-note')}}</p>
  <div class='cropped-image-wrapper'>
    <image-cropper
      [imageChangedEvent]='imageChangedEvent'
      (imageCropped)='imageCropped($event)'
      format='jpeg'
      [maintainAspectRatio]='true'
      [aspectRatio]="aspectRatio"
      class='cropper-main'
    ></image-cropper>
  </div>

  <button class='main-btn w-100' (click)='done()'>
    {{t('image-cropper-popup-done-btn')}}
  </button>

  <button class='w-100 cancel-btn' mat-dialog-close>
    {{t('image-cropper-popup-cancel-btn')}}
  </button>

</div>
