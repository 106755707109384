import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { CreateCustomerParams } from './params/create-customer.params';
import { CreateSubscriptionParams } from './params/create-subscription.params';
import { GetStripePaymentRedirectUrlParams } from './params/get-stripe-payment-redirect-url.params';
import { YookassaSubscriptionServiceParams } from './params/yookassa-subscription-service.params';
import { ActiveSubscriptionResponse } from './response/active-subscription.response';
import { CancelStripeSubscriptionResponse } from './response/cancel-stripe-subscription.response';
import { CreateSubscriptionResponse } from './response/create-subscription.response';
import { StripePricesResponse } from './response/stripe-prices.response';
import {
    BuyPersonalSubscriptionBody,
    CreateSubscriptionBody,
    CreatorSubscriptionsList,
    CreatorSubscriptionsListData,
    PersonalSubscriber,
    PersonalSubscriptionStatus,
    PersonalSubscriptionStatusResult,
    UpdateSubscriptionPriceBody,
} from './params/personal_subscription';

declare let stripe: any;

@Injectable({
    providedIn: 'root',
})
export class PaymentsApiService extends FcaApiServiceBase {
    getStripePrices(currency: string): Observable<StripePricesResponse> {
        const url = [this.apiUrl, 'payments/stripe-prices'].join('/');
        const params = new HttpParams().append('currency', currency);
        return this.http.get<StripePricesResponse>(url, { params });
    }

    createCustomer(body: CreateCustomerParams): Observable<any> {
        const url = [this.apiUrl, 'payments/stripe-create-customer'].join('/');
        // @ts-ignore
        return this.http.post<any>(url, body, { responseType: 'text' });
    }

    createSubscription(body: CreateSubscriptionParams): Observable<CreateSubscriptionResponse> {
        const url = [this.apiUrl, 'payments/stripe-create-subscription'].join('/');
        return this.http.post<CreateSubscriptionResponse>(url, { ...body });
    }

    confirmPayment(clientSecret: string, cardElement: any): Observable<any> {
        return stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
    }

    getActiveStripeSubscription(): Observable<ActiveSubscriptionResponse | null> {
        const url = [this.apiUrl, 'payments/stripe-active-subscription'].join('/');
        return this.http.get<ActiveSubscriptionResponse | null>(url);
    }

    getStripePaymentRedirectUrl(body: GetStripePaymentRedirectUrlParams): Observable<string> {
        const url = [this.apiUrl, 'payments/stripe-ppv-payment-session'].join('/');
        // @ts-ignore
        return this.http.post<string>(
            url,
            body,
            // @ts-ignore
            { responseType: 'text' }
        );
    }

    getSubscriptionList(ip: string) {
        const url = [this.apiUrl, `subscriptions/list`].join('/');
        return this.http.get<any>(url);
    }

    yookassaSubscriptionSession(body: YookassaSubscriptionServiceParams): Observable<ArrayBuffer> {
        const url = [this.apiUrl, 'yookassa-payments/subscription-session'].join('/');
        // @ts-ignore
        return this.http.post<any>(url, body, { responseType: 'text' });
    }

    getActiveYookassaSubscription() {
        const url = [this.apiUrl, 'yookassa-subscriptions/check-active-subscription'].join('/');
        return this.http.get<any>(url);
    }

    cancelYookassaSubscription(subscriptionId: string) {
        const url = [this.apiUrl, `yookassa-subscriptions/${subscriptionId}/unsubscribe`].join('/');
        return this.http.put(url, {});
    }

    cancelStripeSubscription(subscriptionId: string): Observable<CancelStripeSubscriptionResponse> {
        const url = [this.apiUrl, `payments/stripe-cancel-subscription/${subscriptionId}`].join('/');
        return this.http.delete<CancelStripeSubscriptionResponse>(url);
    }

    updateSubscriptionPrice(body: UpdateSubscriptionPriceBody): Observable<any> {
        const url = [this.apiUrl, 'user-personal-subscriptions/update-price'].join('/');
        return this.http.post(url, body);
    }

    buyPersonalSubscription(body: BuyPersonalSubscriptionBody): Observable<Object> {
        const url = [this.apiUrl, 'user-personal-subscriptions/session'].join('/');
        return this.http.post(url, body, {
            responseType: 'text',
        });
    }

    checkPersonalSubscriptionStatus(data: PersonalSubscriptionStatus): Observable<PersonalSubscriptionStatusResult> {
        const url = [this.apiUrl, `user-personal-subscriptions/${data.targetId}/status`].join('/');
        return this.http.get<PersonalSubscriptionStatusResult>(url);
    }

    getSubscriptions(data: CreatorSubscriptionsList): Observable<Array<CreatorSubscriptionsListData>> {
        const url = [this.apiUrl, `user-personal-subscriptions/${data.userId}`].join('/');
        return this.http.get<Array<CreatorSubscriptionsListData>>(url);
    }

    createSubscriptionPlan(body: CreateSubscriptionBody): Observable<boolean> {
        const url = [this.apiUrl, 'user-personal-subscriptions'].join('/');
        return this.http.post<boolean>(url, body);
    }

    unsubscribe(targetId: number): Observable<any> {
        const url = [this.apiUrl, `user-personal-subscriptions/${targetId}/unsubscribe`].join('/');
        return this.http.delete(url);
    }

    getSubscribers(): Observable<Array<PersonalSubscriber>> {
        const url = [this.apiUrl, 'user-personal-subscriptions/subscribers'].join('/');
        return this.http.get<Array<PersonalSubscriber>>(url);
    }

    getSubscribes(): Observable<PersonalSubscriber[]> {
        const url = [this.apiUrl, 'user-personal-subscriptions/subscribes'].join('/');
        return this.http.get<PersonalSubscriber[]>(url);
    }
}
