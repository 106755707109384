import { CurrencyEnum } from '../../../shared/enums/currency.enum';

export interface FightEventPriceModelData {
    currency: CurrencyEnum;
    default: boolean;
    discountPrice: number;
    discountPriceForDisplay: number;
    originalPrice: number;
    originalPriceForDisplay: number;
    priceId: string;
    sale: number;
}

export interface FightEventPriceModel extends FightEventPriceModelData {}

export class FightEventPriceModel {}
