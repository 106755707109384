<button class='app-share-button button-h38 button-h38-hasIcon' (click)='click($event)'>
  <img
    [src]='icon.assetUrl'
    [alt]='icon.alt'
    [width]='icon.width'
    [height]='icon.height'
    [title]='icon.title'
  />
  <span>Share</span>
</button>
