import { MainPageMenuPaths } from '../app.component';
import { FightEventPlaylistModel } from '../models/fight-event/fight-event-playlist/fight-event-playlist.model';
import { EventDataBeforePayment } from '../models/ppv-payment/event-data-before-payment/event-data-before-payment';
import { ActiveSubscriptionModel } from '../models/subscription/active-subscription/active-subscription.model';
import { AuthUserModelData } from '../models/users/auth-user/auth-user.model';
import { Language } from '../shared/enums/language.enum';
import { PermissionEnum } from '../shared/enums/permission.enum';
import { AuthData } from '../shared/interfaces/auth-data.interface';
import { SideBarStateModel } from '../shared/models/shared/side-bar-state.model';

export interface FcaState {
    authData: AuthData | null;
    user: AuthUserModelData | null;
    currentFightEventId: string | null;
    settings: {
        language: Language | null;
        mainPageMenuActiveItem: string;
        lastEventVideoWatchTime: string | null;
        videoVolume: number | 1;
    };
    subscriptionData: ActiveSubscriptionModel | null;
    currentItemInPlaylist: FightEventPlaylistModel | null;
    eventDataBeforePayment: EventDataBeforePayment | null;
    spartaCoinsBalance: number | null;
    userPermissions: PermissionEnum[] | null;
    sideBarState: SideBarStateModel | null;
    fcmToken: string | null;
}

export const FCA_INITIAL_STATE: FcaState = {
    authData: null,
    user: null,
    currentFightEventId: null,
    settings: {
        language: null,
        mainPageMenuActiveItem: MainPageMenuPaths.ALL,
        lastEventVideoWatchTime: null,
        videoVolume: 1,
    },
    subscriptionData: null,
    currentItemInPlaylist: null,
    eventDataBeforePayment: null,
    spartaCoinsBalance: null,
    userPermissions: null,
    sideBarState: null,
    fcmToken: null,
};
