import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { FightEventCommentModel } from '../../../models/fight-event/fight-event-comments/fight-event-comment.model';
import { FcaFightEventCommentsService } from '../../../services/fca-fight-event-comments.service';
import { LikeReactionService } from '../../../services/like-reaction.service';
import { FcaStoreService } from '../../../store/store.service';
import { LikeReactionEnum } from '../../enums/like-reaction.enum';
import { CommentReactionModel } from '../../models/shared/comment-reaction.model';
import { untilDestroy } from '../../operators/until-destroy.operator';

@Component({
    selector: 'app-comment-reaction',
    templateUrl: './comment-reaction.component.html',
    styleUrls: ['./comment-reaction.component.scss'],
})
export class CommentReactionComponent implements OnInit {
    @Input() comment: FightEventCommentModel;
    @Input() likes: number;
    @Input() dislikes: number;
    @Input() hasLike: boolean;
    @Input() hasDislike: boolean;
    @Input() replyAvailable = true;
    @Input() replyHidden = true;

    constructor(
        private readonly storeService: FcaStoreService,
        private readonly likeReactionService: LikeReactionService,
        private _cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    onHideReply(): void {
        this.replyHidden = true;
    }

    onPutLikeClick(): void {
        if (!this.hasLike) {
            this.hasLike = true;
            this.hasDislike = false;
            this.likes++;
        } else {
            this.hasLike = false;
            this.likes--;
        }
        this.likeReactionService.setReaction({
            commentId: this.comment.id,
            action: LikeReactionEnum.LIKE,
            parentId: this.comment.parentId,
        });
    }

    onPutDislikeClick(): void {
        if (!this.hasDislike) {
            this.hasDislike = true;
            if (this.hasLike) {
                this.hasLike = false;
                this.likes--;
            }
        } else {
            this.hasDislike = false;
        }
        this.likeReactionService.setReaction({
            commentId: this.comment.id,
            action: LikeReactionEnum.DISLIKE,
            parentId: this.comment.parentId,
        });
    }
}
