import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'backgroundImageUrl',
})
export class BackgroundImageUrlPipe implements PipeTransform {
    transform(value: string, alternativePath?: string): unknown {
        const url = value?.replace(/ /g, '%20').replace(/\(/g, '\\(').replace(/\)/g, '\\)');
        return `url(${url || ''}), url(${alternativePath || ''})`;
    }
}
