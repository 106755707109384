<div class='w-100' *transloco='let t'>
  <div class='achievement center'>
    <img src='assets/images/gold-helmet.png' alt=''>
  </div>
  <p class='subscription-type'>
    {{t('subscription-plan-premium')}}
  </p>
  <p class='title' [innerHTML]="t('buy-subscription-popup-title')"></p>
  <p class='subtitle'>{{t('buy-subscription-popup-subtitle')}}</p>
  <div class='action-btn-list'>
    <button (click)='closeDialog()' class='continue-btn'>{{t('buy-subscription-popup-continue-with-ads-btn')}}</button>
    <button (click)='subscribe()' class='submit-btn'>{{t('buy-subscription-popup-subscribe-btn')}}</button>
  </div>
</div>
