import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { ConfirmationPopupComponent } from '../shared/components/confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupDataModel } from '../shared/models/shared/confirmation-popup-data.model';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationPopupService {
    constructor(private readonly dialog: MatDialog) {}
    openUnPublishConfirmationPopup(data: ConfirmationPopupDataModel): Observable<boolean> {
        const dialog = this.dialog.open(ConfirmationPopupComponent, {
            backdropClass: 'confirmation-popup-backdrop',
            panelClass: 'confirmation-popup-panel',
            disableClose: true,
            data,
        });
        return dialog.afterClosed().pipe(take(1));
    }
}
