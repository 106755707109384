import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
    croppedImage: string | null | undefined = '';
    imageChangedEvent: any;
    aspectRatio: 1;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { imageChangedEvent: any, aspectRatio: any },
        private dialogRef: MatDialogRef<ImageCropperComponent>
    ) {
        this.imageChangedEvent = data.imageChangedEvent;
        this.aspectRatio = data.aspectRatio || 1;
    }

    ngOnInit(): void {}

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    done() {
        this.dialogRef.close(this.croppedImage);
    }
}
