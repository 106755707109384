import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'passedTime',
})
export class PassedTimePipe implements PipeTransform {
    transform(value: string): unknown {
        const MS_PER_SECOND = 1000;
        const MS_PER_MINUTE = MS_PER_SECOND * 60;
        const MS_PER_HOUR = MS_PER_MINUTE * 60;
        const MS_PER_DAY = MS_PER_HOUR * 24;
        const MS_PER_MONTH = MS_PER_DAY * 30;
        const MS_PER_YEAR = MS_PER_DAY * 365;

        const now = new Date().getTime();
        const uploadedAt = new Date(value).getTime();
        const elapsed = now - uploadedAt;

        if (elapsed < MS_PER_MINUTE) {
            const seconds = Math.round(elapsed / MS_PER_SECOND);
            return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
        } else if (elapsed < MS_PER_HOUR) {
            const minutes = Math.round(elapsed / MS_PER_MINUTE);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else if (elapsed < MS_PER_DAY) {
            const hours = Math.round(elapsed / MS_PER_HOUR);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else if (elapsed < MS_PER_MONTH) {
            const days = Math.round(elapsed / MS_PER_DAY);
            return `${days} day${days === 1 ? '' : 's'} ago`;
        } else if (elapsed < MS_PER_YEAR) {
            const months = Math.round(elapsed / MS_PER_MONTH);
            return `${months} month${months === 1 ? '' : 's'} ago`;
        } else {
            const years = Math.round(elapsed / MS_PER_YEAR);
            return `${years} year${years === 1 ? '' : 's'} ago`;
        }
    }
}
