import { Component } from '@angular/core';
import { PreloadService } from '../../services/preload.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
    constructor(public readonly preloadService: PreloadService) {}
}
