import { FightEventFightSlotLightApiResponse } from '../../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../../shared/interfaces/model-mapper.interface';
import { EFightWinner } from '../../../fight/fight.model';
import { FightEventFightSlotLightModelData } from './fight-event-fight-slot-light.model';

export class FightEventFightSlotLightMapper
    implements ModelDataMapper<FightEventFightSlotLightApiResponse, FightEventFightSlotLightModelData>
{
    mapData(raw: FightEventFightSlotLightApiResponse): FightEventFightSlotLightModelData {
        return {
            fight: {
                winner: raw.fight.winner as EFightWinner,
                ...(raw.fight.initiator && {
                    initiator: {
                        id: raw.fight.initiator.id,
                        image: raw.fight.initiator.image,
                        ranking: raw.fight.initiator.ranking,
                    },
                }),
                ...(raw.fight.target && {
                    target: {
                        id: raw.fight.target.id,
                        image: raw.fight.target.image,
                        ranking: raw.fight.target.ranking,
                    },
                }),
            },
            isMainCard: raw.isMainCard,
        };
    }
}
