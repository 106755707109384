import * as moment from 'moment-timezone';
import { EWeightCategory } from '../../../../shared/enums/e-weight-category.enum';
import { EFightStyle } from '../../../../shared/enums/fight-style.enum';
import { EGender } from '../../../../shared/enums/gender.enum';
import { EStand } from '../../../../shared/enums/stand.enum';
import { EUserExperience } from '../../../../shared/enums/user-experience.enum';
import { EUnitSystem } from '../user-fighter.model';
import { FEMALE_CATEGORIES, FighterWeightCategoryData, MALE_CATEGORIES } from './fighter-weight-category';

export enum ESearchDistance {
    LOCAL = 'LOCAL',
    REGIONAL = 'REGIONAL',
    NATIONAL = 'NATIONAL',
    INTERNATIONAL = 'INTERNATIONAL',
    GLOBAL = 'GLOBAL',
}

export interface FighterDetailsModelData {
    birthdate: moment.Moment | null;
    club: string;
    coaches: string;
    draw: number;
    experience: EUserExperience;
    fightStyle: EFightStyle;
    gender: EGender;
    handsSize: number;
    height: number;
    isActive: boolean;
    loss: number;
    ranking: number;
    searchDistance: ESearchDistance;
    sigStrikes: number;
    stand: EStand;
    takedowns: number;
    unitSystem: EUnitSystem;
    weight: EWeightCategory;
    win: number;
    winsByKo: number;
    winsBySub: number;
}

export interface FighterDetailsModel extends FighterDetailsModelData {}

export class FighterDetailsModel {
    getWeightString(): string {
        const weight = this.getWeightCategory();
        const minValue = weight.unitType === EUnitSystem.METRIC ? weight.minKg : weight.minLbs;
        const maxValue = weight.unitType === EUnitSystem.METRIC ? weight.maxKg : weight.maxLbs;
        const weightUnitSystem = weight.unitType === EUnitSystem.METRIC ? 'weightTypesKg' : 'weightTypesLbs';
        return `${minValue}-${maxValue} ${weightUnitSystem} – ${weight.weightCategory}`;
    }

    getWeightCategory(): FighterWeightCategoryData {
        const categories = (this.gender === EGender.MALE ? MALE_CATEGORIES : FEMALE_CATEGORIES).map((c) => ({
            ...c,
            unitType: this.unitSystem,
        }));
        return categories.find((cat) => cat.weightCategory === this.weight)!;
    }

    getLocaleKey(): string {
        return getFighterWeightCategoryLocaleKey(this.getWeightCategory().weightCategory);
    }
}

export const getFighterWeightCategoryLocaleKey = (value: EWeightCategory): string => {
    switch (value) {
        case EWeightCategory.STRAW_WEIGHT:
            return 'weightClassesStrawweight';
        case EWeightCategory.BANTAM_WEIGHT:
            return 'weightClassesBantamweight';
        case EWeightCategory.FLY_WEIGHT:
            return 'weightClassesFlyweight';
        case EWeightCategory.FEATHER_WEIGHT:
            return 'weightClassesFeatherweight';
        case EWeightCategory.LIGHT_WEIGHT:
            return 'weightClassesLightweight';
        case EWeightCategory.WELTER_WEIGHT:
            return 'weightClassesWelterweight';
        case EWeightCategory.MIDDLE_WEIGHT:
            return 'weightClassesMiddleweight';
        case EWeightCategory.LIGHT_HEAVY_WEIGHT:
            return 'weightClassesLightHeavyweight';
        case EWeightCategory.HEAVY_WEIGHT:
            return 'weightClassesHeavyweight';
    }
};
