<div class='event-item-wrapper'>
  <div class='event-item' [routerLink]="'/fight-event/' + event.id">
    <div
      class='event-body flex align-items-end justify-content__space-between'
      [style.background-image]="event?.cardImagePath? 'url(' + (event?.cardImagePath | imagePathWhiteSpace) + ')' : ''"
    >
      <p class='event-time wrapper'
      >
        {{event.fromDate | date : 'HH:mm:ss'}}
      </p>
    </div>
    <div class='event-footer'>
      <h3 class='event-name'>{{event?.name}}</h3>
      <p class='event-date'>{{event?.fromDate | date : 'dd MMM yyyy, HH:mm'}}</p>
    </div>
  </div>
</div>
