import { Observable } from 'rxjs';
import { AppMainConfigService } from '../../app/config/app-main-config.service';
import { SocialUser } from './interfaces/social-user.interface';

export abstract class BaseSocialService {
    protected constructor(protected readonly appConfigService: AppMainConfigService) {}

    protected loadScript(id: string, src: string, onload: any, parentElement: any = null): void {
        // get document if platform is only browser
        if (typeof document !== 'undefined' && !document.getElementById(id)) {
            const signInJS = document.createElement('script');

            signInJS.id = id;
            signInJS.async = true;
            signInJS.src = src;
            signInJS.onload = onload;

            if (!parentElement) {
                parentElement = document.head;
            }

            parentElement.appendChild(signInJS);
        }
    }

    abstract signIn(): Observable<SocialUser | null>;
    abstract signOut(): void;

    protected parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
}
