import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, map, Observable, tap } from 'rxjs';
import { FollowersApiService } from '../../api/fca/api/followers/followers-api.service';

@Injectable({
    providedIn: 'root',
})
export class FollowersService {
    private followsList: any[] = [];
    private followsList$ = new BehaviorSubject<any[]>([]);

    constructor(private followersApiService: FollowersApiService) {}

    followUser(id: number) {
        return this.followersApiService.followUser(id);
    }

    unfollowUser(id: number) {
        return this.followersApiService.unfollowUser(id);
    }

    getFollowers() {
        return this.followersApiService.loadFollowers();
    }

    reset() {
        this.followsList = [];
        this.followsList$.next(this.followsList);
    }

    getFollows(): Observable<any[]> {
        if (this.followsList.length) {
            return this.followsList$;
        } else {
            return this.followersApiService.loadFollows().pipe(
                tap((response: any[]) => {
                    this.followsList = [...response];
                    this.followsList$.next(this.followsList);
                })
            );
        }
    }

    updateFollowList(user: any, isAdd: boolean) {
        if (isAdd) {
            this.followsList.push(user);
        } else {
            this.followsList = this.followsList.filter((item: any) => item.userId === user.userId);
        }
    }
}
