<div class="nav-wrapper scroll-bar flex flex-column justify-content__space-between" *transloco="let t">
    <div>
        <div class="mobile-menu-user-item subscription-status flex align-items-center justify-content__space-between">
            <app-subscription-status></app-subscription-status>
            <app-user-balance></app-user-balance>
        </div>
        <div class="mobile-menu-user-item">
            <login-status-item></login-status-item>
        </div>
        <div class="content-creator-nav" *ngIf="user$ | async">
            <ng-container *ngIf="profileData$ | async as userDataForTemplate; else userDataLoader">
                <a
                    *ngIf="userDataForTemplate?.isContentCreator; else toBeContentCreator"
                    href=""
                    class="content-creator-nav-item white"
                    [routerLink]="'/account/upload-video'"
                >
                    <img src="assets/images/add-video-content-creator.svg" alt="" />
                    {{ t('content-creator-upload-video-page-video-file-uploader-placeholder') }}
                </a>
                <ng-template #toBeContentCreator>
                    <div
                        class="content-creator-nav-item white"
                        (click)="openBecomeContentCreatorPopup(userDataForTemplate.userId)"
                    >
                        <img src="assets/images/add-video-content-creator.svg" alt="" />
                        {{ t('profile-page-become-content-creator-btn') }}
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #userDataLoader>
                <ngx-skeleton-loader
                    [theme]="{
                        width: '205px',
                        height: '41px',
                        margin: '0px',
                        margin: '0px',
                        borderRadius: '10px'
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
        <div class="main-menu">
            <ul>
                <ng-container *ngFor="let menuItem of navMenu; let i = index">
                    <li [class.has-sub-menu]="menuItem.subMenuList?.length">
                        <div
                            [routerLink]="!menuItem.href ? null : lang + menuItem.href"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="menu-item flex align-items-center justify-content__space-between"
                            (click)="toggleSubMenu(menuItemEl, menuItem)"
                            #menuItemEl
                        >
                            <span class="flex align-items-center">
                                <span class="icon-wrapper">
                                    <img
                                        class="default-icon"
                                        [src]="'assets/images/side-nav/' + menuItem.iconName"
                                        [alt]="menuItem.text"
                                    />
                                    <img
                                        class="active-icon"
                                        [src]="
                                            'assets/images/side-nav/' + menuItem.iconName.replace('.svg', '-active.svg')
                                        "
                                        [alt]="menuItem.text"
                                    />
                                </span>
                                <span class="text">{{ t(menuItem.text) }}</span>
                            </span>
                            <div class="arrow-icon-wrapper" *ngIf="menuItem.subMenuList">
                                <img src="assets/images/arrow-down.svg" alt="" />
                            </div>
                        </div>
                        <div class="sub-menu-wrapper">
                            <ul class="sub-menu">
                                <li *ngFor="let subMenu of menuItem.subMenuList">
                                    <div
                                        [routerLink]="!subMenu.href ? null : lang + subMenu.href"
                                        (click)='subMenuClick(subMenu)'
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="flex align-items-center menu-item"
                                    >
                                        <span class="flex align-items-center">
                                            <span class="icon-wrapper">
                                                <img
                                                    [src]="'assets/images/side-nav/' + subMenu.iconName"
                                                    [alt]="subMenu.text"
                                                />
                                            </span>
                                            <span class="text">{{ t(subMenu.text) }}</span>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ng-container>
                <li *ngIf="user$ | async">
                    <div
                        class="menu-item flex align-items-center justify-content__space-between"
                        (click)="toggleCreatorsList(followList)"
                        #followList
                        [class.open]="sideBarState?.isCreatorsSpaceItemOpened"
                    >
                        <span class="flex align-items-center">
                            <span class="icon-wrapper">
                                <img src="assets/images/side-nav/side-nav-followed-creators.svg" alt="" />
                            </span>
                            <span class="text">{{ t('side-bar-menu-followed-creators') }}</span>
                        </span>
                        <div class="arrow-icon-wrapper">
                            <img src="assets/images/arrow-down.svg" alt="" />
                        </div>
                    </div>
                    <div class="sub-menu-wrapper">
                        <ul class="creator-list scroll-bar">
                            <ng-container *ngFor="let user of followsList$ | async">
                                <li>
                                    <a
                                        href=""
                                        [routerLink]="'/profile/' + user.id"
                                        class="creator-list-item flex align-items-center"
                                    >
                                        <div class="avatar-wrapper">
                                            <img
                                                [src]="
                                                    (user.avatar | imagePathWhiteSpace) ||
                                                    'assets/images/avatar_placeholder.svg'
                                                "
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div class="flex align-items-center">
                                                <p class="full-name">{{ user.fullName }}</p>
                                                <div class="verified-account-wrapper">
                                                    <img
                                                        *ngIf="user.verified"
                                                        src="assets/images/verified-account.svg"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <p class="followers">
                                                {{ user.countFollowers | reductionOfNumbers }} followers
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <app-footer></app-footer>
</div>
