import * as moment from 'moment-timezone';
import { languageCurrencyConfig } from '../../config/laguage-currency.config';
import { Language } from '../../shared/enums/language.enum';
import { ImageModel } from '../../shared/models/image/image.model';
import { FightEventAdditionalModel } from './fight-event-additional/fight-event-additional.model';
import { FightEventConferenceModel } from './fight-event-conference/fight-event-conference.model';
import { FightEventFightSlotModel } from './fight-event-fight-slot/fight-event-fight-slot.model';
import { FightEventLocationModel } from './fight-event-location/fight-event-location.model';
import { FightEventPlaylistModel } from './fight-event-playlist/fight-event-playlist.model';
import { FightEventPriceModel } from './fight-event-price/fight-event-price.model';
import { FightEventVideoModel } from './fight-event-video/fight-event-video.model';
import { FightEventWeightingModel } from './fight-event-weighting/fight-event-weighting.model';

export enum EFightEventStatus {
    UPCOMING = 'UPCOMING',
    ONGOING = 'ONGOING',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
}

export enum EFightEventReactionType {
    RED_HEART = 'red_heart',
    BLUE_HEART = 'blue_heart',
    FIRE = 'fire',
    SHOCK = 'shock',
}

export interface FightEventReaction {
    type: EFightEventReactionType;
    count: number;
}

export interface FightEventModelData {
    id: string;
    name: string;
    video: FightEventVideoModel;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    hidden: boolean;
    geoipBlock: boolean;
    location: FightEventLocationModel;
    fightSlots: FightEventFightSlotModel[];
    status: EFightEventStatus;
    honorarium: number;
    images: ImageModel[];
    additional: FightEventAdditionalModel;
    weighting?: FightEventWeightingModel | null;
    conference?: FightEventConferenceModel | null;
    votingAvailable: boolean;
    reactions: FightEventReaction[];
    ppv: boolean;
    isFreeForSubscribers: boolean;
    stripeProductId: string;
    isUserPay4PPV: boolean;
    prices: FightEventPriceModel[];
}

export interface FightEventModel extends FightEventModelData {}

export class FightEventModel {
    get isLive(): boolean {
        return this.status === EFightEventStatus.ONGOING;
    }

    get datePreview(): string {
        return this.fromDate.format('DD MMM yyyy HH:mm');
    }

    get cardImagePath() {
        return this.video?.thumbnail || this.images?.[0]?.href;
    }

    getPriceDataByLang(lang: Language): FightEventPriceModel | null {
        return (
            this.prices.find((price: FightEventPriceModel) => !price.currency) ||
            null
        );
    }

    get getPlaylistData(): FightEventPlaylistModel[] {
        const playList: FightEventPlaylistModel[] = [];
        if (this.weighting) {
            const weightingItem: FightEventPlaylistModel = {
                label: 'shared-weighting-label',
                image: this.images[0]?.href || this.weighting?.video?.thumbnail || '',
                title: this.name,
                description: this.additional?.description || '',
                id: this.weighting?.video?.id,
                video: this.weighting?.video,
                fromDate: this.weighting?.fromDate,
                toDate: this.weighting?.toDate,
                status: this.weighting?.status,
                statusByDate:
                    this.weighting?.video?.streamUrl && this.weighting.status === EFightEventStatus.ONGOING
                        ? EFightEventStatus.ONGOING
                        : new Date(this.weighting?.fromDate) > new Date() &&
                          this.weighting?.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.weighting?.status,
            };
            playList.push(weightingItem);
        }
        if (this.conference) {
            const conferenceItem: FightEventPlaylistModel = {
                label: 'shared-conference-label',
                image: this.images[0]?.href || this.conference?.video?.thumbnail || '',
                title: this.name,
                description: this.additional?.description || '',
                id: this.conference?.video?.id,
                video: this.conference?.video,
                fromDate: this.conference?.fromDate,
                toDate: this.conference?.toDate,
                status: this.conference?.status,
                statusByDate:
                    this.conference?.video?.streamUrl && this.conference.status === EFightEventStatus.ONGOING
                        ? EFightEventStatus.ONGOING
                        : new Date(this.conference?.fromDate) > new Date() &&
                          this.conference?.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.conference?.status,
            };
            playList.push(conferenceItem);
        }
        if (playList.length) {
            const fight: FightEventPlaylistModel = {
                label: 'shared-fight-label',
                image: this.images[0]?.href || this.video?.thumbnail || '',
                title: this.name,
                description: this.additional?.description || '',
                id: this.video?.id,
                video: this.video,
                status: this.status,
                statusByDate:
                    this.video?.streamUrl && this.status === EFightEventStatus.ONGOING
                        ? EFightEventStatus.ONGOING
                        : new Date(this.fromDate.toString()) > new Date() && this.status !== EFightEventStatus.FINISHED
                        ? EFightEventStatus.UPCOMING
                        : this.status,
                fromDate: this.fromDate.toString(),
                toDate: this.toDate.toString(),
            };
            playList.push(fight);
        }
        return playList;
    }
}
