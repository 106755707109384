<div class='contacts-wrapper' *transloco='let t'>
  <div class='page'>
    <div class='page-inner'>
      <div class='content'>
        <p class='title'>{{t('contact-us-title')}}</p>
        <p class='sub-title'>{{t('contact-us-subtitle')}}</p>
        <form #form='ngForm' class='form'>
          <p class='form-title'>{{t('contact-us-form-title')}}</p>

          <label for='first-name' class='label'>{{t('contact-us-form-name-label')}}</label>
          <input
            name='name'
            [(ngModel)]='commentForm.name'
            required
            minlength='2'
            [placeholder]="t('contact-us-form-name-placeholder')"
            id='first-name'
            type='text'
            class='main-input'
          >

          <label for='email' class='label'>{{t('contact-us-form-email-label')}}</label>
          <input
            name='email'
            [(ngModel)]='commentForm.email'
            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
            required minlength='2'
            [placeholder]="t('contact-us-form-email-placeholder')"
            id='email'
            type='email'
            class='main-input'
          >

          <label for='subject' class='label'>{{t('contact-us-form-subject-label')}}</label>
          <input
            name='subject'
            [(ngModel)]='commentForm.subject'
            required
            minlength='2'
            [placeholder]="t('contact-us-form-subject-placeholder')"
            id='subject'
            type='text'
            class='main-input'
          >

          <label for='comment' class='label'>{{t('contact-us-form-comment-label')}}</label>
          <textarea
            name='comment'
            [(ngModel)]='commentForm.comment'
            required
            minlength='2'
            [placeholder]="t('contact-us-form-comment-placeholder')"
            class='main-input textarea scroll-bar'
            id='comment'
          ></textarea>

        </form>
        <button class='main-btn' [disabled]='form.invalid' (click)='leaveComment(form)'>
          {{t('contact-us-form-submit')}}
        </button>
      </div>
    </div>
  </div>
</div>
