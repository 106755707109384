import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { UploadPhotoParams } from './params/upload-photo.params';
import { UserImageApiResponse } from './response/user-image-api.response';

@Injectable()
export class FcaImagesApiService extends FcaApiServiceBase {
    uploadPhoto(userId: number, data: UploadPhotoParams): Observable<UserImageApiResponse[]> {
        const url = [this.apiUrl, 'images', 'upload-image'].join('/');
        const formData: FormData = new FormData();
        formData.append('image', data.file, data.file.name);
        formData.append('imageType', data.type);
        formData.append('positionId', String(data.position));
        formData.append('userId', String(userId));

        return this.http.post<UserImageApiResponse[]>(url, formData).pipe(shareReplay(1));
    }
}
