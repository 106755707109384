<main *ngIf='(user$ | async) === null' class='auth'>
  <div class='auth-wrapper'>
    <header class='auth-header'>
      <a [routerLink]="'/'">
        <img class='auth-header-logo' src='assets/images/logo.new.svg' alt='Spartacus MMA' />
      </a>
    </header>
    <router-outlet></router-outlet>
    <footer class='auth-footer'>
      <div class='auth-footer-app'>
        <a
          href='https://apps.apple.com/ru/app/spartacus-mma/id1589781592'
          target='_blank'
          class='auth-footer-app-link'
          rel='noreferrer'
        >
          <img src='assets/images/app_store.svg' alt='Spartacus MMA in AppStore' />
        </a>
        <a
          href='https://play.google.com/store/apps/details?id=com.spartacus.mma'
          target='_blank'
          class='auth-footer-app-link'
          rel='noreferrer'
        >
          <img src='assets/images/google_play.svg' alt='Spartacus MMA on GooglePlay' />
        </a>
      </div>
    </footer>
  </div>
</main>
