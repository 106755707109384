import { UserResponse, UserResponseDevices } from '../../../../api/fca/auth/response/user.response';
import { EUserRole } from '../../../shared/enums/user-role.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { UserImageFactory } from '../../images/user-image.factory';
import { UserImageMapper } from '../../images/user-image.mapper';
import { AuthUserModelData } from './auth-user.model';

export class AuthUserMapper implements ModelDataMapper<UserResponse, AuthUserModelData> {
    mapData(raw: UserResponse): AuthUserModelData {
        return {
            id: raw.id,
            email: raw.email,
            roleName: raw.roleName as EUserRole,
            firstName: raw.firstName,
            lastName: raw.lastName,
            nickname: raw.nickname,
            isPremium: raw.isPremium,
            isContentCreator: raw.isContentCreator,
            stripeCustomerId: raw.stripeCustomerId || '',
            images: raw.images.map((e) => new UserImageFactory().getModelFromData(new UserImageMapper().mapData(e))),
            locale:
                raw.devices.find((device: UserResponseDevices) => device.deviceName === 'web-site')?.locale ||
                raw.devices[0]?.locale ||
                '',
        };
    }
}
