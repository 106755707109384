import * as moment from 'moment-timezone';
import { EUserRole } from '../../shared/enums/user-role.enum';
import { ImageModel } from '../../shared/models/image/image.model';

export interface UserModelData {
    id: number;
    roleName: EUserRole;
    email: string;
    createDate: string;
    deletedDate: moment.Moment | null;
    images: ImageModel[];
    socials?: { name: string; href: string }[];
    penaltyInfo?: {
        banned: boolean;
        unbanDate: moment.Moment | null;
    };
    stripeCustomerId: string | null;
    referralCode: string;
    locale: string;
    isPremium: boolean;
    isContentCreator: boolean;
}

export interface UserModel extends UserModelData {}

export class UserModel {}
