import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { catchError, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { AuthResponse } from '../../../api/fca/auth/response/login.response';
import { AppMainConfigService } from '../../config/app-main-config.service';
import { FcaAuthService } from '../../services/fca-auth.service';
import { SaveAuthDataAction } from '../../store/actions';
import { FcaStoreService } from '../../store/store.service';
import { AuthData } from '../interfaces/auth-data.interface';

@Injectable({ providedIn: 'root' })
export class AuthTokenInterceptor implements HttpInterceptor {
    cookieService = inject(SsrCookieService);
    counter = 0;

    constructor(
        private readonly storeService: FcaStoreService,
        private readonly configService: AppMainConfigService,
        private _router: Router,
        private authService: FcaAuthService,
        @Inject(PLATFORM_ID) private _platformId: Object
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        if (!this.isRequestToApi(request)) {
            return next.handle(request);
        }

        return this.storeService.getAuthData().pipe(
            take(1),
            map((data: AuthData | null) => {
                if (isPlatformBrowser(this._platformId)) {
                    return request.clone({
                        setHeaders: {
                            Authorization: request?.headers?.get('authorization') || `Bearer ${data?.jwt || ''}`,
                        },
                    });
                }
                if (isPlatformServer(this._platformId)) {
                    console.log(this.counter++, request.url);
                    const authDataString = this.cookieService.get('AuthData');
                    if (!authDataString) {
                        return request;
                    }
                    const authData: AuthData = JSON.parse(authDataString);
                    return request.clone({
                        setHeaders: {
                            Authorization: request?.headers?.get('authorization') || `Bearer ${authData?.jwt || ''}`,
                        },
                    });
                } else {
                    return request;
                }
            }),
            switchMap((req: HttpRequest<any>) => {
                return this.isRefreshRequest(req)
                    ? next.handle(req)
                    : next.handle(req).pipe(
                          catchError((err) => {
                              if ([HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized].includes(err.status)) {
                                  return this.storeService.getAuthData().pipe(
                                      take(1),
                                      switchMap((authData) => {
                                          return !authData
                                              ? of(null).pipe(
                                                    tap(() => {
                                                        this.authService.logout(this._router.url);
                                                    })
                                                )
                                              : this.authService.refreshTokens().pipe(
                                                    switchMap((tokens: AuthResponse) => {
                                                        this.storeService.dispatch(
                                                            new SaveAuthDataAction({
                                                                data: {
                                                                    ...tokens,
                                                                    email: authData?.email || '',
                                                                    stripeCustomerId: authData?.stripeCustomerId || '',
                                                                },
                                                            })
                                                        );
                                                        return next.handle(
                                                            request.clone({
                                                                setHeaders: {
                                                                    Authorization: `Bearer ${
                                                                        tokens?.refreshToken || ''
                                                                    }`,
                                                                },
                                                            })
                                                        );
                                                    })
                                                );
                                      }),
                                      catchError(() => {
                                          this.authService.logout(this._router.url);
                                          return of(null);
                                      })
                                  );
                              }
                              return throwError(err);
                          })
                      );
            })
        );
    }

    private isRequestToApi(request: HttpRequest<any>): boolean {
        return !request.url.includes('.amazonaws.com');
    }

    private isRefreshRequest(request: HttpRequest<any>): boolean {
        return request.url.indexOf('auth/refresh') >= 0;
    }
}
