<ng-container *transloco="let t">
    <div class="event-info-container" *ngIf="name">
        <p class="event-info-name" [innerHTML]="name">{{ name }}</p>

        <div class="ppv-wrapper flex align-items-center" *ngIf="isPPV">
            <div class="ppv flex align-items-center">
                <p class="content">
                    <span>{{ currency | currencySymbol }}</span> {{ t('shared-ppv-tag') }}
                </p>
                <div class="price-wrapper flex align-items-center">
                    <ng-container *ngIf="!isUserPay4PPV; else alreadyBye">
                        <p class="price">{{ originalPriceForDisplay }}{{ currency | currencySymbol }}</p>
                        <div
                            class="discount-description flex align-items-center"
                            *ngIf="isFreeForSubscribers || originalPrice !== discountPrice"
                        >
                            <p class="discount">
                                <ng-container *ngIf="!isFreeForSubscribers; else free">
                                    {{ discountPriceForDisplay }}{{ currency | currencySymbol }}
                                </ng-container>
                                <ng-template #free>
                                    {{ t('free-event-label') }}
                                </ng-template>
                                <span>
                                    {{ t('fight-event-info-ppv-for-premium-prefix') }}
                                </span>
                            </p>
                            <div class="for-premium-tag">
                                {{ t('fight-event-info-ppv-premium-label') }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #alreadyBye>
                        <p class="purchased">{{ t('fight-event-info-ppv-purchased') }}</p>
                    </ng-template>
                </div>
            </div>
        </div>

        <p class="event-info-date">{{ date | date : 'dd MMMM yyyy / HH:mm' }}</p>
        <p class="event-info-description">{{ description }}</p>
        <ng-container *ngIf="false">
            <p class="event-info-arena-label">{{ t('dashboard-event-info-arena-label') }}</p>
            <div class="event-info-arena flex align-items-center">
                <div class="arena-logo-wrapper center">
                    <img src="assets/images/logo.svg" alt="arena logo" />
                </div>
                <div class="flex flex-column">
                    <p class="arena-name">{{ locationName }}</p>
                    <p class="arena-location">{{ country }}, {{ city }}</p>
                </div>
            </div>
        </ng-container>
        <div class="related-videos-wrapper" *ngIf="showRelatedVideo">
            <p class="title">{{ t('event-info-related-videos') }}</p>
            <ngx-slick-carousel
                [config]="slideConfig"
                (beforeChange)="onScrollStart($event)"
                (afterChange)="onScrollEnd($event)"
                class="related-videos-slider"
            >
                <ng-container *ngIf="relatedVideoList.length === 0 && inputListOfRelatedVideo.length === 0">
                    <div ngxSlickItem *ngFor="let event of [1, 2, 3]" class="slide">
                        <div class="slide-image-wrapper">
                            <ngx-skeleton-loader
                                [theme]="{
                                    width: '100%',
                                    margin: '5px',
                                    height: '160px'
                                }"
                            ></ngx-skeleton-loader>
                            <ngx-skeleton-loader
                                [theme]="{
                                    width: '75%',
                                    margin: '5px',
                                    height: '15px'
                                }"
                            ></ngx-skeleton-loader>
                            <ngx-skeleton-loader
                                [theme]="{
                                    width: '40%',
                                    margin: '5px',
                                    height: '15px'
                                }"
                            ></ngx-skeleton-loader>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isShowInputListOfRelatedVideo">
                    <div ngxSlickItem *ngFor="let event of relatedVideoList" class="slide">
                        <div class="slide-image-wrapper">
                            <app-event-card
                                [backgroundImage]="event.cardImagePath"
                                [id]="event.id"
                                [disabled]="dragging"
                                [hoverEffect]="false"
                                [title]="event.name"
                                [date]="event.fromDate"
                                [status]="event.statusLabel"
                                [isPPV]="event.ppv"
                                [duration]="event.videoDuration"
                            ></app-event-card>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isShowInputListOfRelatedVideo">
                    <div ngxSlickItem *ngFor="let event of inputListOfRelatedVideo" class="slide">
                        <div class="slide-image-wrapper">
                            <app-event-card
                                [backgroundImage]="event.cardImagePath"
                                [id]="event.id"
                                [disabled]="dragging"
                                [hoverEffect]="false"
                                [routingPath]="routingPathWithoutId + event.id"
                                [title]="event.name"
                                [date]="event.fromDate"
                                [status]="event.statusLabel"
                                [isPPV]="event.ppv"
                                [duration]="event.videoDuration"
                            ></app-event-card>
                        </div>
                    </div>
                </ng-container>
            </ngx-slick-carousel>
        </div>
    </div>
</ng-container>
