import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { SpartaCoinsService } from '../../../services/sparta-coins.service';
import { FcaStoreService } from '../../../store/store.service';
import { BuyWithSpartaCoinsPopupParams } from '../../models/shared/buy-with-sparta-coins-popup.params';
import { UntilDestroy, untilDestroy } from '../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-buy-with-sparta-coins-popup',
    templateUrl: './buy-with-sparta-coins-popup.component.html',
    styleUrls: ['./buy-with-sparta-coins-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyWithSpartaCoinsPopupComponent implements OnInit {
    balance$ = new Observable<number | null>();
    price = 0;
    eventId = '';

    constructor(
        private storeService: FcaStoreService,
        private spartaCoinsService: SpartaCoinsService,
        private _cdk: ChangeDetectorRef,
        private _router: Router,
        public dialogRef: MatDialogRef<BuyWithSpartaCoinsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BuyWithSpartaCoinsPopupParams
    ) {
        this.price = +data.price;
        this.eventId = data.eventId;
    }

    ngOnInit(): void {
        this.getBalance();
    }

    getBalance(): void {
        this.balance$ = this.storeService.getSpartaCoinsBalance().pipe(take(1), untilDestroy(this));
    }

    payForEvent(): void {
        this.spartaCoinsService
            .purchase(this.eventId)
            .pipe(
                untilDestroy(this),
                take(1),
                tap((response: any) => {
                    this.spartaCoinsService.updateBalance();
                    this.dialogRef.close(true);
                })
            )
            .subscribe();
    }

    goToCoinsList() {
        this.dialogRef.close(false);
        const extras: NavigationExtras = {
            queryParams: {
                redirectUrl: this._router.routerState.snapshot.url,
            },
        };
        this._router.navigate(['/coins'], extras).then();
    }
}
