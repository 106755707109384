import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    inject,
    Inject,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import { ActivationEnd, NavigationEnd, Router, Event } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { BehaviorSubject, filter, Observable, take, tap } from 'rxjs';
import { AppService, LayoutStateInterface } from './app.service';
import { VIEWER, ViewerType } from './constants';
import { AuthUserModel } from './models/users/auth-user/auth-user.model';
import { FcaAuthService } from './services/fca-auth.service';
import { FcaFightEventsService } from './services/fca-fight-events.service';
import { LanguageService } from './services/language.service';
import { Language } from './shared/enums/language.enum';
import { SideMenuStateModel } from './shared/models/shared/side-menu-state.model';
import { untilDestroy, UntilDestroy } from './shared/operators/until-destroy.operator';
import { PreloadService } from './shared/services/preload.service';
import { ScreenSizeService } from './shared/services/screen-size.service';
import { WINDOW } from './shared/tokens/window.token';
import { ChangeLanguageDataAction, SaveMainPageActiveMenuItem, SetFcmToken } from './store/actions';
import { FcaStoreService } from './store/store.service';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';

export enum MainPageMenuPaths {
    ALL = '/',
    LIVE = 'live-events',
    UPCOMING = 'upcoming-events',
    PAST = 'past-events',
}

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    private readonly _messaging = inject(Messaging);
    private readonly _message = new Messaging(this._messaging);
    layoutState$: Observable<LayoutStateInterface>;
    auth$: Observable<AuthUserModel | null>;
    mainPageMenuPaths = MainPageMenuPaths;
    isForMobile: Observable<boolean>;
    isMobileLayout: boolean = false;
    isSideMenuOpen = false;
    sideMenuState: SideMenuStateModel = {
        isOpen: false,
    };

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenSizeService.screenSize$.next(event.target.innerWidth);
    }

    constructor(
        public readonly preloadService: PreloadService,
        public readonly translate: TranslocoService,
        private readonly ccService: NgcCookieConsentService,
        private readonly languageService: LanguageService,
        private readonly storeService: FcaStoreService,
        private readonly authService: FcaAuthService,
        private readonly appService: AppService,
        private readonly fightEventsService: FcaFightEventsService,
        private readonly screenSizeService: ScreenSizeService,
        private readonly store: FcaStoreService,
        private readonly _cdr: ChangeDetectorRef,
        @Inject(VIEWER) viewer: ViewerType,
        private _router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(WINDOW) private window: Window
    ) {
        this.authService.authSeoBot(viewer);
        this.authService.generateAndSaveSid();
        translate.setDefaultLang(Language.EN);
        translate.setActiveLang(Language.EN);
        this.ccService.popupOpen$.pipe(take(1)).subscribe();
        this.layoutState$ = this.appService.getLayoutState$();
        this.auth$ = storeService.getUser();
        this.isForMobile = this.screenSizeService.isForMobile().pipe(untilDestroy(this));
        this.checkPlatform();
    }

    checkPlatform() {
        if (isPlatformBrowser(this.platformId)) {
            this.checkLang();
        }
    }

    checkLang() {
        const userLang = this.window?.navigator.language.split('-')[0];
        const newLang = userLang === Language.PT ? Language.PT : Language.EN;
        this.storeService
            .getLanguage()
            .pipe(
                take(1),
                tap((lang: Language) => {
                    if (!lang) {
                        this.store.dispatch(new ChangeLanguageDataAction({ lang: newLang }));
                        this.translate.setActiveLang(newLang);
                    } else {
                        this.store.dispatch(new ChangeLanguageDataAction({ lang }));
                        this.translate.setActiveLang(lang);
                    }
                }),
                untilDestroy(this)
            )
            .subscribe();
    }

    ngOnInit(): void {
        this._router.events
            .pipe(
                untilDestroy(this),
                filter((ev: Event) => ev instanceof ActivationEnd)
            )
            .subscribe((ev: any) => {
                if (ev.snapshot.data['module']) {
                    this.appService.changeActiveModule(ev.snapshot.data['module']);
                }
                const url = ev.snapshot._routerState.url;
                const id = url.split('/')[url.split('/').length - 1];
                if (url.startsWith('/video/spartacusEvent/')) {
                    this._router.navigate(['/fight-event', id]);
                } else if (url.startsWith('/video/otherEvent/')) {
                    this._router.navigate(['/view', id]);
                } else if (url.startsWith('/video/tv/')) {
                    this._router.navigate(['/fight-event/live-tv', id]);
                }
            });
        this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            this.isMobileLayout = !(event as NavigationEnd).url.includes('privacy-policy');
        });
        this.fcmInit();
    }

    fcmInit() {
        // Request permission to receive notifications
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                // console.log('Permission granted');
            } else if (permission === 'denied') {
                // console.log('Permission denied');
            }
        });

        // Get the current FCM token
        getToken(this._messaging)
            .then((token) => {
                this.store.dispatch(new SetFcmToken(token));
            })
            .catch((error) => {
                // console.log('Token error', error)
            });

        // Listen for messages from FCM
        onMessage(this._message, {
            next: (payload) => {
                // console.log('Message: ');
                // console.dir(payload);
            },
            error: (error) => {
                // console.log('Message error', error)
            },
            complete: () => {
                // console.log('Done listening to messages')
            },
        });
    }

    onSelectMenuItem(value: MainPageMenuPaths): void {
        this.storeService.dispatch(new SaveMainPageActiveMenuItem({ value }));
    }

    burgerMenuClickHandler(event: boolean) {
        this.isSideMenuOpen = event;
        this._cdr.detectChanges();
    }
}
