import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { GetProfilesPublicModel } from '../../../models/profiles/get-profiles-public/get-profiles-public.model';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { UserPersonalDataPopupComponent } from '../../../modules/account/components/user-personal-data-popup/user-personal-data-popup.component';
import { FcaAuthService } from '../../../services/fca-auth.service';
import { ProfilesPublicService } from '../../../services/profiles-public.service';
import { FcaStoreService } from '../../../store/store.service';
import { PermissionEnum } from '../../enums/permission.enum';
import { UserPersonalDataModel } from '../../models/shared/user-personal-data.model';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
    selector: 'login-status-item',
    templateUrl: './login-status-item.component.html',
    styleUrls: ['./login-status-item.component.scss'],
})
export class LoginStatusItemComponent {
    auth$: Observable<AuthUserModel | null>;
    open = false;
    @Output() menuItemClick = new EventEmitter();

    constructor(
        private readonly authService: FcaAuthService,
        private readonly storeService: FcaStoreService,
        private readonly dialog: MatDialog,
        private readonly profilesPublicService: ProfilesPublicService,
        private readonly router: Router
    ) {
        this.auth$ = storeService.getUser();
    }

    onLogoutClick(): void {
        this.authService.logout();
        this.menuItemClick.emit(true);
    }

    close(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        this.open = false;
    }

    goToProfileEdit() {
      this.router.navigate(['account/edit']);
    }
    openEditProfilePopup() {
        const popupData = new UserPersonalDataModel();
        this.menuItemClick.emit(true);
        this.storeService
            .getUser()
            .pipe(
                take(1),
                untilDestroy(this),
                filter(Boolean),
                tap((user: AuthUserModel) => {
                    popupData.firstName = user.firstName;
                    popupData.lastName = user.lastName;
                    popupData.nickname = user.nickname;
                    popupData.roleName = user.roleName;
                    popupData.isContentCreator = user.isContentCreator;
                }),
                switchMap((user: AuthUserModel) => this.profilesPublicService.getProfilePublic(user.id)),
                tap((response: GetProfilesPublicModel) => {
                    popupData.id = response.id;
                    popupData.thumbnailAvatar = response.thumbnailAvatar;
                    popupData.isContentCreator = response.isContentCreator;
                    this.dialog.open(UserPersonalDataPopupComponent, {
                        backdropClass: 'account-personal-info-popup-backdrop',
                        panelClass: 'account-personal-info-popup-panel',
                        disableClose: true,
                        data: popupData as UserPersonalDataModel,
                    });
                })
            )
            .subscribe();
    }
}
