import { FightEventAdditionalApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelFactory } from '../../../shared/abstract/model-factory.abstract';
import { FightEventAdditionalModel, FightEventAdditionalModelData } from './fight-event-additional.model';

export class FightEventAdditionalFactory extends ModelFactory<
    FightEventAdditionalApiResponse,
    FightEventAdditionalModelData
> {
    protected getInstance(): FightEventAdditionalModelData {
        return new FightEventAdditionalModel();
    }
}
