import { UrlSegment } from '@angular/router';
import { RoutingParams, RoutingRegex } from './routing-regex.helper';

export class MainPageRoutingRegex extends RoutingRegex {
    private static emptyRegExp = '^$';

    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: MainPageRoutingRegex.emptyRegExp, segments: [0, 1], name: 'request', required: false },
        ];
        return MainPageRoutingRegex.homePageRegex(segments, params);
    }

    private static homePageRegex(segments: UrlSegment[], params: any) {
        return RoutingRegex.regex(segments, params);
    }
}

export class ContactsPageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^contacts$', segments: [0, 1], name: 'contacts', required: true },
        ];
        return ContactsPageRoutingRegex.regex(segments, params);
    }
}

export class PrivacyPolicyPagePageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^privacy-policy', segments: [0, 1], name: 'privacy-policy', required: true },
        ];
        return PrivacyPolicyPagePageRoutingRegex.regex(segments, params);
    }
}

export class TermsOfUsePagePageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^terms-of-use', segments: [0, 1], name: 'terms-of-use', required: true },
        ];
        return TermsOfUsePagePageRoutingRegex.regex(segments, params);
    }
}

export class AuthPageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^auth', segments: [0, 1], name: 'auth', required: true },
        ];
        return AuthPageRoutingRegex.regex(segments, params);
    }
}

export class DashboardPageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^fight-event', segments: [0, 1], name: 'fight-event', required: true },
        ];
        return DashboardPageRoutingRegex.regex(segments, params);
    }
}

export class LoginPageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^login', segments: [0, 1], name: 'login', required: true },
        ];
        return LoginPageRoutingRegex.regex(segments, params);
    }
}

export class SignUpPageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^sign-up', segments: [0, 1], name: 'sign-up', required: true },
        ];
        return SignUpPageRoutingRegex.regex(segments, params);
    }
}

export class RegisterPagePageRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^register', segments: [0, 1], name: 'register', required: true },
        ];
        return RegisterPagePageRoutingRegex.regex(segments, params);
    }
}

export class FighterProfileModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^fighter-profile', segments: [0, 1], name: 'fighter-profile', required: true },
        ];
        return FighterProfileModuleRoutingRegex.regex(segments, params);
    }
}

export class FighterSubscriptionModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^subscription', segments: [0, 1], name: 'subscription', required: true },
        ];
        return FighterSubscriptionModuleRoutingRegex.regex(segments, params);
    }
}

export class PaymentModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^payment', segments: [0, 1], name: 'payment', required: true },
        ];
        return PaymentModuleRoutingRegex.regex(segments, params);
    }
}

export class KicksModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^kicks', segments: [0, 1], name: 'kicks', required: true },
        ];
        return KicksModuleRoutingRegex.regex(segments, params);
    }
}

export class CoinsModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^coins', segments: [0, 1], name: 'coins', required: true },
        ];
        return CoinsModuleRoutingRegex.regex(segments, params);
    }
}

export class OtherEventsModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^other-events', segments: [0, 1], name: 'other-events', required: true },
        ];
        return OtherEventsModuleRoutingRegex.regex(segments, params);
    }
}

export class AccountModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^account', segments: [0, 1], name: 'account', required: true },
        ];
        return AccountModuleRoutingRegex.regex(segments, params);
    }
}

export class InviteLinkRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^invite_link', segments: [0, 1], name: 'invite_link', required: true },
            { regexp: '', segments: [1, 2], name: 'inviteLinkId', required: true },
        ];
        return InviteLinkRoutingRegex.regex(segments, params);
    }
}

export class ProfileModuleRoutingRegex extends RoutingRegex {
    static match(segments: UrlSegment[]): any {
        const params: RoutingParams[] = [
            { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
            { regexp: '^profile', segments: [0, 1], name: 'profile', required: true },
        ];
        return ProfileModuleRoutingRegex.regex(segments, params);
    }
}
