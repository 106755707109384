import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {WINDOW} from "../../tokens/window.token";
import * as _ from "lodash";
import {ImageCropperComponent} from "../image-cropper/image-cropper.component";

@Component({
  selector: 'app-background-uploader',
  templateUrl: './background-uploader.component.html',
  styleUrls: ['./background-uploader.component.scss']
})
export class BackgroundUploaderComponent {
  @Output() photoChange = new EventEmitter<File | null>();
  imageError: string | null;
  isImageSaved: boolean | null;
  @Input() cardImageBase64: string | null = null;
  url: SafeUrl = '';
  croppedImage: string | null | undefined = '';
  imageChangedEvent: Event;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(WINDOW) private window: Window,
  ) {}

  onPhotoSelected(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const maxSize = 20971520;
      const allowedTypes = ['image/png', 'image/jpeg'];

      if (fileInput.target.files[0].size > maxSize) {
        this.imageError = 'Maximum size allowed is ' + maxSize / 1000 + 'Mb';
        return false;
      }
      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      this.imageChangedEvent = fileInput;
      this.openCrop(fileInput);
    }
    return false;
  }

  openCrop(event: any) {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '380px',
      minHeight: '500px',
      panelClass: 'image-cropper-bg',
      disableClose: true,
      data: {
        imageChangedEvent: event,
        aspectRatio: 1280/260,
      },
    });

    dialogRef.afterClosed().subscribe((value: any) => {
      if (value) {
        this.url = this.sanitizer.bypassSecurityTrustHtml(value);
        this.cardImageBase64 = value;
        this.croppedImage = value;
        this.changeDetect();
        this._cdr.detectChanges();
      }
      this.fileInput.nativeElement.value = '';
    });
  }

  changeDetect() {
    const blob = this.dataURItoBlob(this.croppedImage || '');
    const filename = (this.imageChangedEvent.target as any).files[0].name;
    this.photoChange.emit(
      new File([blob], filename, {
        type: 'image/jpeg',
      })
    );
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString: string = this.window?.atob(dataURI.replace('data:image/jpeg;base64,', ''));
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], { type: 'image/jpeg' });
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.photoChange.emit(null);
  }
}
