import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { ProfilePublicListParams } from './params/profile-public-list.params';
import { ProfileByIdResponse } from './response/profile-by-id.response';
import { ProfilePublicListItemResponse } from './response/profile-public-list-item.response';

@Injectable({
    providedIn: 'root',
})
export class ProfilesPublicApiService extends FcaApiServiceBase {
    getProfileInfoPublicById(id: number): Observable<ProfileByIdResponse> {
        const url = [this.apiUrl, 'profile/public', id].join('/');
        return this.http.get<ProfileByIdResponse>(url).pipe(shareReplay(1));
    }

    getProfilePublicList(body: ProfilePublicListParams): Observable<ProfilePublicListItemResponse[]> {
        const url = [this.apiUrl, 'profile/public/list'].join('/');
        return this.http.post<ProfilePublicListItemResponse[]>(url, body).pipe(shareReplay(1));
    }
}
