import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FcaStoreModule } from '../store/store.module';
import { TranslocoTranslateModule } from '../transloco-translate.module';
import { AddPostPopupComponent } from './components/add-post-popup/add-post-popup.component';
import { AvatarUploaderComponent } from './components/avatar-uploader/avatar-uploader.component';
import { BackgroundUploaderComponent } from './components/background-uploader/background-uploader.component';
import { BecomeContentCreatorPopupComponent } from './components/become-content-creator-popup/become-content-creator-popup.component';
import { BuyWithSpartaCoinsPopupComponent } from './components/buy-with-sparta-coins-popup/buy-with-sparta-coins-popup.component';
import { CommentItemComponent } from './components/comment-item/comment-item.component';
import { CommentReactionComponent } from './components/comment-reaction/comment-reaction.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { ContentCreatorBlockComponent } from './components/content-creator-block/content-creator-block.component';
import { ContentCreatorPreviewComponent } from './components/content-creator-preview/content-creator-preview.component';
import { CreatorVideoCardComponent } from './components/creator-video-card/creator-video-card.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { EventInfoSharedComponent } from './components/event-info-shared/event-info-shared.component';
import { EventReactionsComponent } from './components/event-reactions/event-reactions.component';
import { FightCardComponent } from './components/fight-card/fight-card.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { GetMoreComponent } from './components/get-more/get-more.component';
import { HlsPlayerComponent } from './components/hls-player/hls-player.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { JoinNowComponent, JoinNowModalComponent } from './components/join-now/join-now.component';
import { KicksListItemComponent } from './components/kicks-list-item/kicks-list-item.component';
import { BuySubscriptionPopupComponent } from './components/layout/buy-subscription-popup/buy-subscription-popup.component';
import { CreatorsListComponent } from './components/layout/creators-list/creators-list.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderNavComponent } from './components/layout/header-nav/header-nav.component';
import { InviteLinkComponent } from './components/layout/invite-link/invite-link.component';
import { LeftMenuComponent } from './components/layout/left-menu/left-menu.component';
import { LocalizationComponent } from './components/layout/localization/localization.component';
import { MobileViewComponent } from './components/layout/mobile-view/mobile-view.component';
import { PastEventCardComponent } from './components/layout/past-event-card/past-event-card.component';
import { SubscriptionStatusComponent } from './components/layout/subscription-status/subscription-status.component';
import { UserBalanceComponent } from './components/layout/user-balance/user-balance.component';
import { UserPersonalDataPopupComponent } from './components/layout/user-personal-data-popup/user-personal-data-popup.component';
import { VideoPreviewSelectionScaleComponent } from './components/layout/video-preview-selection-scale/video-preview-selection-scale.component';
import { LoadingContentComponent } from './components/loading-content/loading-content.component';
import { LoginStatusItemComponent } from './components/login-status-item/login-status-item.component';
import { NewCommentComponent } from './components/new-comment/new-comment.component';
import { PostItemComponent } from './components/post-item/post-item.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RadioBtnComponent } from './components/radion-btn/radio-btn.component';
import { RolesListComponent } from './components/roles/roles-list.component';
import { SharedPopupComponent } from './components/shared-popup/shared-popup.component';
import { SuccessPaymentComponent } from './components/success-payment/success-payment.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { ThreeDotsMenuComponent } from './components/three-dots-menu/three-dots-menu.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { UpcomingEventCardComponent } from './components/upcoming-event-card/upcoming-event-card.component';
import { ViewMediaComponent } from './components/view-media/view-media.component';
import { ViewPostItemComponent } from './components/view-post-item/view-post-item.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DndDirective } from './directives/dnd.directive';
import { DoubleClickDirective } from './directives/double-click.directive';
import { ImageLoadErrorDirective } from './directives/image-load-error.directive';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { BackgroundImageUrlPipe } from './pipes/background-image-url.pipe';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ImagePathWhiteSpacePipe } from './pipes/image-path-white-space.pipe';
import { PassedTimePipe } from './pipes/passed-time.pipe';
import { ReductionOfNumbersPipe } from './pipes/reduction-of-numbers.pipe';
import { TranslateParamsPipe } from './pipes/translate-params.pipe';
import { SnackbarService } from './services/snackbar.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        FcaStoreModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        CdkAccordionModule,
        TranslocoTranslateModule,
        RouterModule,
        NgxSkeletonLoaderModule,
        ImageCropperModule,
        InfiniteScrollModule,
        SlickCarouselModule,
        MatProgressSpinnerModule,
        DragDropModule,
    ],
    exports: [
        DragDropModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        FcaStoreModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        CdkAccordionModule,
        RolesListComponent,
        JoinNowComponent,
        JoinNowModalComponent,
        AvatarUploaderComponent,
        HlsPlayerComponent,
        GetMoreComponent,
        ArraySortPipe,
        LoadingContentComponent,
        TranslocoTranslateModule,
        FooterComponent,
        RouterModule,
        NgxSkeletonLoaderModule,
        ScrollToBottomDirective,
        FightCardComponent,
        ClickOutsideDirective,
        DoubleClickDirective,
        PastEventCardComponent,
        LocalizationComponent,
        ImageCropperModule,
        MobileViewComponent,
        InfiniteScrollModule,
        ImagePathWhiteSpacePipe,
        SubscriptionStatusComponent,
        EventCardComponent,
        ImageLoadErrorDirective,
        SlickCarouselModule,
        UpcomingEventCardComponent,
        CurrencySymbolPipe,
        DurationPipe,
        InputFieldComponent,
        NewCommentComponent,
        CommentReactionComponent,
        EventInfoSharedComponent,
        EventReactionsComponent,
        HeaderNavComponent,
        CommentsComponent,
        CommentItemComponent,
        BackgroundImageUrlPipe,
        KicksListItemComponent,
        FileSizePipe,
        CustomSelectComponent,
        TagsInputComponent,
        TabsComponent,
        ProgressBarComponent,
        FileUploaderComponent,
        ToggleComponent,
        UserPersonalDataPopupComponent,
        TranslateParamsPipe,
        ReductionOfNumbersPipe,
        ContentCreatorPreviewComponent,
        CreatorVideoCardComponent,
        LeftMenuComponent,
        VideoPreviewSelectionScaleComponent,
        LoginStatusItemComponent,
        UserBalanceComponent,
        PostItemComponent,
        RadioBtnComponent,
        DndDirective,
        BecomeContentCreatorPopupComponent,
        BackgroundUploaderComponent,
        ContentCreatorBlockComponent,
    ],
    declarations: [
        JoinNowComponent,
        RolesListComponent,
        JoinNowModalComponent,
        AvatarUploaderComponent,
        HlsPlayerComponent,
        GetMoreComponent,
        ArraySortPipe,
        LoadingContentComponent,
        FooterComponent,
        ScrollToBottomDirective,
        FightCardComponent,
        PastEventCardComponent,
        LocalizationComponent,
        ImageCropperComponent,
        BuySubscriptionPopupComponent,
        MobileViewComponent,
        ImagePathWhiteSpacePipe,
        ClickOutsideDirective,
        DoubleClickDirective,
        SubscriptionStatusComponent,
        EventCardComponent,
        ImageLoadErrorDirective,
        SuccessPaymentComponent,
        UpcomingEventCardComponent,
        CurrencySymbolPipe,
        DurationPipe,
        NewCommentComponent,
        InputFieldComponent,
        CommentReactionComponent,
        EventInfoSharedComponent,
        EventReactionsComponent,
        BackgroundImageUrlPipe,
        HeaderNavComponent,
        CommentsComponent,
        CommentItemComponent,
        KicksListItemComponent,
        DoubleClickDirective,
        BuyWithSpartaCoinsPopupComponent,
        TranslateParamsPipe,
        FileSizePipe,
        CustomSelectComponent,
        TagsInputComponent,
        TabsComponent,
        ThreeDotsMenuComponent,
        ConfirmationPopupComponent,
        ProgressBarComponent,
        FileUploaderComponent,
        InviteLinkComponent,
        ToggleComponent,
        UserPersonalDataPopupComponent,
        ReductionOfNumbersPipe,
        VideoPreviewSelectionScaleComponent,
        CreatorsListComponent,
        ContentCreatorPreviewComponent,
        PassedTimePipe,
        CreatorVideoCardComponent,
        LeftMenuComponent,
        LoginStatusItemComponent,
        UserBalanceComponent,
        SharedPopupComponent,
        BackgroundUploaderComponent,
        SharedPopupComponent,
        BecomeContentCreatorPopupComponent,
        PostItemComponent,
        RadioBtnComponent,
        AddPostPopupComponent,
        DndDirective,
        ViewPostItemComponent,
        ViewMediaComponent,
        ContentCreatorBlockComponent,
    ],
    providers: [SnackbarService, { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }, DatePipe],
})
export class SharedModule {}
