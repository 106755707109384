import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imagePathWhiteSpace',
})
export class ImagePathWhiteSpacePipe implements PipeTransform {
    transform(value: string, placeholder?: string): string {
        return (value || placeholder || '').replace(/ /g, '%20');
    }
}
