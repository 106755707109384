import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppMainConfigService } from '../../app/config/app-main-config.service';

@Injectable()
export class FcaApiServiceBase {
    readonly apiUrl: string;
    readonly kickVideoUrl: string;
    readonly bloodsVideoUrl: string;
    readonly metricsUrl: string;
    readonly otherEventsUrl: string;
    readonly qaUrl: string;
    readonly discoveryUrl: string;
    readonly postUrl: string;
    readonly defaultHeaders = { 'Content-Type': 'application/json' };
    readonly production: boolean;

    constructor(protected readonly configService: AppMainConfigService, protected readonly http: HttpClient) {
        this.apiUrl = configService.getConfig().apiUrl;
        this.kickVideoUrl = configService.getConfig().kickVideoUrl;
        this.bloodsVideoUrl = configService.getConfig().bloodsVideoUrl;
        this.metricsUrl = configService.getConfig().metricsUrl;
        this.otherEventsUrl = configService.getConfig().otherEventsUrl;
        this.qaUrl = configService.getConfig().qaUrl;
        this.discoveryUrl = configService.getConfig().discoveryUrl;
        this.postUrl = configService.getConfig().postUrl;
        this.production = configService.getConfig().production;
    }
}
