export enum SocialProvider {
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
    APPLE = 'apple',
}

export interface DeviceParams {
    deviceToken: string;
    deviceIdentifier: string;
    deviceName: string;
}

export interface LoginByEmailParams extends DeviceParams {
    email: string;
}

export interface LoginDefaultParams extends LoginByEmailParams {
    registrationToken: string;
}

export interface LoginSocialParams extends DeviceParams {
    provider: SocialProvider;
}

export interface LoginGoogleParams extends LoginSocialParams {}

export interface LoginFacebookParams extends LoginSocialParams {}

export interface LoginAppleParams extends LoginSocialParams {}
