import * as moment from 'moment-timezone';
import { FighterApiResponse } from '../../../../api/fca/users/response/user-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { UserImageFactory } from '../../images/user-image.factory';
import { UserImageMapper } from '../../images/user-image.mapper';
import { UserMapper } from '../user.mapper';
import { FighterDetailsFactory } from './fighter-details/fighter-details.factory';
import { FighterDetailsMapper } from './fighter-details/fighter-details.mapper';
import { UserFighterModelData } from './user-fighter.model';

export class UserFighterMapper implements ModelDataMapper<FighterApiResponse, UserFighterModelData> {
    mapData(raw: FighterApiResponse): UserFighterModelData {
        return {
            ...new UserMapper().mapData(raw),
            firstName: raw.firstName,
            lastName: raw.lastName,
            nickname: raw.nickname,
            startFightDate: raw.startFightDate ? moment(raw.startFightDate) : null,
            endFightDate: raw.endFightDate ? moment(raw.endFightDate) : null,
            hasFight: raw.hasFight,
            phoneNumber: raw.phoneNumber || null,
            profileFillingInfo: raw.profileFillingInfo,
            fightEventRequests: raw.fightEventRequests || [],
            details: new FighterDetailsFactory().getModelFromData(new FighterDetailsMapper().mapData(raw.details)),
            photos: raw.images.map((e) => new UserImageFactory().getModelFromData(new UserImageMapper().mapData(e))),
        };
    }
}
