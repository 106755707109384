import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';
import { ScreenSizeService } from '../../services/screen-size.service';

@UntilDestroy()
@Component({
    selector: 'app-upcoming-event-card',
    templateUrl: './upcoming-event-card.component.html',
    styleUrls: ['./upcoming-event-card.component.scss'],
})
export class UpcomingEventCardComponent implements OnInit {
    @Input() backgroundImage = '';
    @Input() title = '';
    @Input() fromDate = '';
    @Input() id = '';
    @Input() isPpv = false;
    dateTimer = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    interval: any;
    isForTablet = false;

    constructor(private _cdk: ChangeDetectorRef, private screenSizeService: ScreenSizeService) {
        this.screenSizeService
            .isForTablet()
            .pipe(untilDestroy(this))
            .subscribe((value: boolean) => (this.isForTablet = value));
    }

    ngOnInit(): void {}

    runTrigger() {
        if (this.isForTablet) {
            return;
        }
        this.dateTimer = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        this.trigger(this.fromDate);
        this.interval = setInterval(() => {
            this.trigger(this.fromDate);
        }, 1000);
    }

    stopTrigger() {
        if (this.isForTablet) {
            return;
        }
        this.dateTimer = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        clearInterval(this.interval);
    }

    trigger(date: any) {
        let delta = Math.abs(new Date(date).getTime() - new Date().getTime()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.trunc(delta % 60);
        this.dateTimer = {
            days,
            hours,
            minutes,
            seconds,
        };
        this._cdk.markForCheck();
    }
}
