import * as moment from 'moment-timezone';
import { CommentResponse } from '../../../../api/fca/comments/response/comment.response';
import { EUserRole } from '../../../shared/enums/user-role.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { FightEventCommentModel, FightEventCommentModelData } from './fight-event-comment.model';
import { FightEventCommentFactory } from './fight-event-comment.factory';

export class FightEventCommentMapper implements ModelDataMapper<CommentResponse, FightEventCommentModelData> {
    mapData(raw: CommentResponse): FightEventCommentModelData {
        return {
            id: raw.id,
            text: raw.text,
            parentId: raw.parentId || null,
            user: {
                id: raw.user.id,
                lastName: raw.user.lastName,
                firstName: raw.user.firstName,
                nickName: raw.user.nickName || null,
                logo: raw.user.logo,
                role: raw.user.role as EUserRole,
            },
            children: (raw.children || []).reduce<FightEventCommentModel[]>((acc, rawChild) => {
                acc.push(
                    new FightEventCommentFactory().getModelFromData(new FightEventCommentMapper().mapData(rawChild))
                );

                return acc;
            }, []),
            created: moment(raw.created),
            edited: raw.edited,
            deleted: raw.deleted,
            userHasDislike: raw.userHasDislike,
            userHasLike: raw.userHasLike,
            likes: raw.count.likes,
            dislikes: raw.count.dislikes,
        };
    }
}
