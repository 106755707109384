import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { concatMap, filter, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { AddCommentModel } from '../../api/fca/post/params/add-comment.model';
import { AddImageToPostParams } from '../../api/fca/post/params/add-image-to-post.params';
import { AddPostParams } from '../../api/fca/post/params/add-post.params';
import { ChangePostParams } from '../../api/fca/post/params/change-post.params';
import { GetSignedUploadUrlParams } from '../../api/fca/post/params/get-signed-upload-url.params';
import { UpdateImageParams } from '../../api/fca/post/params/update-image.params';
import { PostApiService } from '../../api/fca/post/post-api.service';
import { PostCommentsApiService } from '../../api/fca/post/post-comments-api.service';
import { PostDislikesApiService } from '../../api/fca/post/post-dislikes-api.service';
import { PostImageApiService } from '../../api/fca/post/post-image-api.service';
import { PostLikeApiService } from '../../api/fca/post/post-like-api.service';
import { HandleUploadApiResponse } from '../../api/fca/post/response/handle-upload-api.response';
import { PostListItemApiResponse } from '../../api/fca/post/response/post-list-item-api.response';
import { PostListFactory } from '../models/post/post-list/post-list.factory';
import { PostListMapper } from '../models/post/post-list/post-list.mapper';
import { PostListModelData } from '../models/post/post-list/post-list.model';
import { AuthUserModel } from '../models/users/auth-user/auth-user.model';
import { AddPostPopupComponent } from '../shared/components/add-post-popup/add-post-popup.component';
import { ViewPostItemComponent } from '../shared/components/view-post-item/view-post-item.component';
import { OffsetPagination } from '../shared/interfaces/offset-pagination.interface';
import { CloseModalActionInterface } from '../shared/models/shared/close-modal-action.interface';
import { OpenPostDataModel } from '../shared/models/shared/open-post-data.model';
import { PaginationInterface } from '../shared/models/shared/pagination.interface';
import { ThreeDotsMenuItemModel } from '../shared/models/shared/three-dots-menu-item.model';
import { UploadFileProgressParams } from '../shared/models/shared/upload-file-progress.params';
import { untilDestroy } from '../shared/operators/until-destroy.operator';
import { SnackbarService } from '../shared/services/snackbar.service';
import { FcaStoreService } from '../store/store.service';
import { ConfirmationPopupService } from './confirmation-popup.service';
import { UtilsService } from './utils.service';

export enum ThreeDotsItemEnum {
    DELETE = 'delete',
    TURN_OFF_COMMENTS = 'turnOffComments',
    TURN_ON_COMMENTS = 'turnOnComments',
    COPY_LINK = 'copyLink',
    EDIT = 'edit',
}

const threeDotsListMap = new Map<ThreeDotsItemEnum, ThreeDotsMenuItemModel<ThreeDotsItemEnum>>([
    [ThreeDotsItemEnum.DELETE, { text: 'post-item-menu-delete', value: ThreeDotsItemEnum.DELETE }],
    [
        ThreeDotsItemEnum.TURN_ON_COMMENTS,
        { text: 'post-item-menu-turn-on-comments', value: ThreeDotsItemEnum.TURN_ON_COMMENTS },
    ],
    [
        ThreeDotsItemEnum.TURN_OFF_COMMENTS,
        { text: 'post-item-menu-turn-off-comments', value: ThreeDotsItemEnum.TURN_OFF_COMMENTS },
    ],
    [ThreeDotsItemEnum.COPY_LINK, { text: 'post-item-menu-copy-link', value: ThreeDotsItemEnum.COPY_LINK }],
    [ThreeDotsItemEnum.EDIT, { text: 'post-item-menu-edit', value: ThreeDotsItemEnum.EDIT }],
]);

@Injectable({
    providedIn: 'root',
})
export class PostService {
    constructor(
        private postApiService: PostApiService,
        private postLikeApiService: PostLikeApiService,
        private postCommentsApiService: PostCommentsApiService,
        private postImageApiService: PostImageApiService,
        private dislikeLikeApiService: PostDislikesApiService,
        private http: HttpClient,
        private readonly dialog: MatDialog,
        private store: FcaStoreService,
        private confirmationPopupService: ConfirmationPopupService,
        private snackbarService: SnackbarService
    ) {}

    getPostList(paramsData: OffsetPagination): Observable<PostListModelData[]> {
        return this.postApiService.getPostList(paramsData).pipe(
            map((resp: PaginationInterface<PostListItemApiResponse>) => {
                return resp.data.map((respData: PostListItemApiResponse) => {
                    return new PostListFactory().getModelFromData(new PostListMapper().mapData(respData));
                });
            })
        );
    }

    hidePost(postId: string) {
        return this.postApiService.hidePost(postId);
    }

    deleteImage(imageId: string, postId: string) {
        return this.postImageApiService.deleteImage(imageId, postId);
    }

    updateImages(body: UpdateImageParams) {
        return this.postImageApiService.updateImages(body);
    }

    addImageToPost(body: AddImageToPostParams) {
        return this.postImageApiService.addImageToPost(body);
    }

    togglePostComment(postId: string, commentsEnabled: boolean) {
        return this.postApiService.updatePost(postId, { commentsEnabled });
    }

    addPost(body: AddPostParams) {
        return this.postApiService.addPost(body);
    }

    getPostListByUserId(paramsData: OffsetPagination, userId: number): Observable<PostListModelData[]> {
        return this.postApiService.getPostListByUserId(paramsData, userId).pipe(
            map((resp: PaginationInterface<PostListItemApiResponse>) => {
                return resp.data.map((respData: PostListItemApiResponse) => {
                    return new PostListFactory().getModelFromData(new PostListMapper().mapData(respData));
                });
            })
        );
    }

    updatePost(postId: string, body: ChangePostParams) {
        return this.postApiService.updatePost(postId, body);
    }

    getPostById(id: string): Observable<PostListModelData> {
        return this.postApiService.getPostById(id).pipe(
            map((resp: PostListItemApiResponse) => {
                return new PostListFactory().getModelFromData(new PostListMapper().mapData(resp));
            })
        );
    }

    addLikeToPost(id: string) {
        return this.postLikeApiService.addLikeToPost(id);
    }

    deleteLikeFromPost(id: string) {
        return this.postLikeApiService.deleteLikeFromPost(id);
    }

    addLikeToComment(id: string) {
        return this.postLikeApiService.addLikeToComment(id);
    }

    deleteLikeFromComment(id: string) {
        return this.postLikeApiService.deleteLikeFromComment(id);
    }

    addDislikeToComment(id: string) {
        return this.dislikeLikeApiService.addDislikeToComment(id);
    }

    deleteDislikeFromComment(id: string) {
        return this.dislikeLikeApiService.deleteDislikeFromComment(id);
    }

    getCommentList(paging: OffsetPagination, postId: string) {
        return this.postCommentsApiService.getCommentList(paging, postId);
    }

    addComment(body: AddCommentModel) {
        return this.postCommentsApiService.addComment(body);
    }

    uploadImagesToAws(fileList: { photo: File; customFrontId: string }[], postId: string): Observable<any> {
        return from(fileList).pipe(
            concatMap((item: { photo: File; customFrontId: string }) =>
                this.uploadLogic(item.photo, postId, item.customFrontId)
            ),
            tap((totalResp: any) => {})
        );
    }

    uploadLogic(file: File, postId: string, customFrontId: string) {
        const data: GetSignedUploadUrlParams = {
            fileName: file.name,
            postId,
        };
        return this.postImageApiService.getSignedUploadUrl(data).pipe(
            concatMap((response1) =>
                this.http.put<HttpResponse<void>>(response1.url, file).pipe(
                    concatMap((response2) =>
                        this.postImageApiService.handleUpload(postId).pipe(
                            concatMap((response3) =>
                                of({
                                    file,
                                    response1,
                                    response2,
                                    response3,
                                    customFrontId,
                                })
                            )
                        )
                    )
                )
            )
        );
    }

    openEditPostModal(postData: any): Observable<CloseModalActionInterface<PostListModelData>> {
        return this.dialog
            .open(AddPostPopupComponent, {
                backdropClass: 'add-post-popup-backdrop',
                panelClass: 'add-post-popup-panel',
                hasBackdrop: true,
                disableClose: true,
                data: { isEdit: true, post: postData },
            })
            .afterClosed();
    }

    getPostThreeDotsMenuList(
        ownerId: number,
        commentsEnabled: boolean
    ): Observable<ThreeDotsMenuItemModel<ThreeDotsItemEnum>[]> {
        return this.store.getUser().pipe(
            take(1),
            filter(Boolean),
            map((user: AuthUserModel) => {
                const threeDotsMenuList: ThreeDotsMenuItemModel<ThreeDotsItemEnum>[] = [
                    threeDotsListMap.get(ThreeDotsItemEnum.COPY_LINK)!,
                ];
                if (user.id === ownerId && user.isContentCreator) {
                    if (commentsEnabled) {
                        threeDotsMenuList.unshift(threeDotsListMap.get(ThreeDotsItemEnum.TURN_OFF_COMMENTS)!);
                    } else {
                        threeDotsMenuList.unshift(threeDotsListMap.get(ThreeDotsItemEnum.TURN_ON_COMMENTS)!);
                    }
                    threeDotsMenuList.unshift(
                        threeDotsListMap.get(ThreeDotsItemEnum.DELETE)!,
                        threeDotsListMap.get(ThreeDotsItemEnum.EDIT)!
                    );
                }
                return threeDotsMenuList;
            })
        );
    }

    openDeletePostModal(postId: string): Observable<CloseModalActionInterface<string> | null> {
        return this.confirmationPopupService
            .openUnPublishConfirmationPopup({
                text: 'post-item-post-deleted-popup-text',
                title: 'post-item-post-deleted-popup-title',
            })
            .pipe(
                switchMap((result: boolean) => {
                    if (result) {
                        return this.hidePost(postId).pipe(
                            map(() => {
                                this.snackbarService.show({ message: 'post-item-post-deleted-success' });
                                return { action: 'deleted', data: postId };
                            })
                        );
                    }
                    return of(null);
                })
            );
    }

    openPostById(postId: string) {
        this.getPostById(postId)
            .pipe(
                take(1),
                tap((resp: PostListModelData) => {
                    this.dialog.open<ViewPostItemComponent, OpenPostDataModel>(ViewPostItemComponent, {
                        backdropClass: 'view-post-popup-backdrop',
                        panelClass: 'view-post-popup-panel',
                        hasBackdrop: true,
                        disableClose: true,
                        maxWidth: '90vw',
                        data: {
                            id: resp.id,
                            name: resp.owner.firstName,
                            surname: resp.owner.lastName,
                            nickName: resp.owner.nickname,
                            avatar: resp.owner.thumbnailAvatar,
                            text: resp.text,
                            images: resp.images,
                            likeUsers: resp.likeUsers,
                            liked: resp.liked,
                            commentsEnabled: resp.commentsEnabled,
                            likes: resp.likes,
                            date: resp.createdAt,
                            ownerId: resp.ownerId,
                            access: resp.access,
                        } as OpenPostDataModel,
                    });
                })
            )
            .subscribe();
    }
}
