import { Observable, of } from 'rxjs';
import { LoginSocialParams } from '../../../../api/fca/auth/params/login.params';
import { LoginResponse } from '../../../../api/fca/auth/response/login.response';
import { FcaAuthService } from '../../fca-auth.service';

export abstract class BaseAuthSocialHandler {
    private _next: BaseAuthSocialHandler | null;

    protected constructor(protected authService: FcaAuthService) {}

    handleLogin(params: LoginSocialParams, token: string, userId?: string): Observable<LoginResponse | null> {
        return this.allowHandle(params)
            ? this.doHandle(params, token, userId)
            : this.runNextHandler(params, token, userId);
    }

    setNext(handler: BaseAuthSocialHandler): BaseAuthSocialHandler {
        this._next = handler;

        return handler;
    }

    private runNextHandler(
        params: LoginSocialParams,
        token: string,
        userId?: string
    ): Observable<LoginResponse | null> {
        return this._next ? this._next.handleLogin(params, token, userId) : of(null);
    }
    protected abstract doHandle(
        params: LoginSocialParams,
        token: string,
        userId?: string
    ): Observable<LoginResponse | null>;
    protected abstract allowHandle(params: LoginSocialParams): boolean;
}
