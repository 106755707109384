import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { KicksDataService } from '../../../modules/kicks/services/kicks-data.service';
import { ThreeDotsMenuItemModel } from '../../models/shared/three-dots-menu-item.model';

@Component({
    selector: 'app-kicks-list-item',
    templateUrl: './kicks-list-item.component.html',
    styleUrls: ['./kicks-list-item.component.scss'],
})
export class KicksListItemComponent implements OnInit {
    @Input() id = '';
    @Input() thumbnailHref = '';
    @Input() videoUrl = '';
    @Input() description = '';
    @Input() views = 0;
    @Input() loading = false;
    @Input() disabled = false;
    @Input() disabledRedirect = false;
    @Input() redirectUrl = '';
    @Input() additionalInfo = '';
    @Input() threeDotsMenuList: ThreeDotsMenuItemModel[] = [];
    @Output() onThreeDotsSelect = new EventEmitter<string>();
    isHover = false;
    isHover1 = false;
    @ViewChild('videoPlayer') videoElementRef: ElementRef<HTMLVideoElement> | undefined;

    constructor(
        private kicksDataService: KicksDataService,
        private _router: Router,
        public translate: TranslocoService
    ) {}

    ngOnInit(): void {}

    getUrl() {
        return this.videoUrl.replace('video.m3u8', 'default.mp4');
    }

    mouseEnter() {
        if (!this.videoUrl) {
            return;
        }
        this.isHover1 = true;
        setTimeout(() => {
            this.isHover = true;
        }, 100);
        this.videoElementRef?.nativeElement.play();
    }

    mouseLeave() {
        if (!this.videoUrl) {
            return;
        }
        this.isHover = false;
        this.isHover1 = false;
    }

    get getThumbnailHref(): string {
        return this.thumbnailHref;
    }

    clickItem(id: string) {
        if (this.disabledRedirect) {
            return;
        }
        this.kicksDataService.setPreviousPageUrlToLocalstorage(location.href.split('spartacus-mma.com')[1]);
        this._router.navigate([(this.redirectUrl || '/kicks/') + id]);
    }

    onThreeDotsSelectHandler(event: string) {
        this.onThreeDotsSelect.emit(event);
    }

    getViewsRuVersion(views: number) {
        const cases = [2, 0, 1, 1, 1, 2];
        const titles = ['shared-views-label-ru-0', 'shared-views-label-ru-1', 'shared-views-label-ru-2'];
        return titles[views % 100 > 4 && views % 100 < 20 ? 2 : cases[Math.min(views % 10, 5)]];
    }
}
