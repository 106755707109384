import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    LoginPageRoutingRegex,
    RegisterPagePageRoutingRegex,
    SignUpPageRoutingRegex,
} from '../shared/helpers/routing-helpers';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            { component: LoginComponent, matcher: LoginPageRoutingRegex.match, data: { page: 'login' } },
            { component: LoginComponent, matcher: SignUpPageRoutingRegex.match, data: { page: 'sign-up' } },
            { component: RegisterComponent, matcher: RegisterPagePageRoutingRegex.match },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
