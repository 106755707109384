import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactUsParams } from '../../../../api/fca/app/params/contact-us-params';
import { PageLinks } from '../../../config/seo/seo-config.interface';
import { SeoSetUpService } from '../../../config/seo/seo-set-up.service';
import { FcaAppService } from '../../../services/fca-app.service';
import { SnackbarService, SnackBarStyles } from '../../services/snackbar.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent {
    commentForm = new ContactUsParams();

    constructor(
        private readonly seoService: SeoSetUpService,
        private readonly fcaAppService: FcaAppService,
        private readonly _cdk: ChangeDetectorRef,
        private readonly snackbarService: SnackbarService
    ) {
        this.seoService.setMetaByPage(PageLinks.ContactUs);
    }

    leaveComment(form: NgForm): void {
        this.fcaAppService.postComment(this.commentForm).subscribe((value: void) => {
            form.resetForm();
            this.snackbarService.show({
                message: 'Form was success send!!!',
                style: SnackBarStyles.SUCCESS,
            });
            this._cdk.detectChanges();
        });
    }
}
