<div class="post-owner-wrapper"
     [style.background-image]="ownerBackgroundImg | backgroundImageUrl"
     [routerLink]="'/profile/' + ownerId"
>
  <div class="avatar-wrapper">
    <img [src]="avatar || 'assets/images/avatar_placeholder.svg'" alt="" />
  </div>
  <p class="full-name">{{ name }} {{ surname }}</p>
  <div class="post-owner-data flex align-items-center" *transloco='let t'>
    <div class="post-owner-data-item flex align-items-center">
      <p class="value">{{ t('shared-followers') }}</p>
      <p class="label">{{ followers | reductionOfNumbers }}</p>
    </div>
    <div class="post-owner-data-item flex align-items-end">
      <p class="value">{{ t('shared-posts') }}</p>
      <p class="label">{{ posts }}</p>
    </div>
    <div class="post-owner-data-item flex align-items-end">
      <p class="value">{{ t('shared-videos') }}</p>
      <p class="label">{{ videos }}</p>
    </div>
    <div class="post-owner-data-item flex align-items-end">
      <p class="value">{{ t('shared-views') }}</p>
      <p class="label">{{ views | reductionOfNumbers }}</p>
    </div>
  </div>
</div>
