import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommentReactionModel } from '../shared/models/shared/comment-reaction.model';

@Injectable({
    providedIn: 'root',
})
export class LikeReactionService {
    private changeReaction$ = new BehaviorSubject<CommentReactionModel | null>(null);

    getChangeReaction$(): Observable<CommentReactionModel | null> {
        return this.changeReaction$.asObservable();
    }

    setReaction(data: CommentReactionModel) {
        this.changeReaction$.next(data);
    }
}
