import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LanguageService } from '../../services/language.service';
import { Language } from '../../shared/enums/language.enum';
import { FcaStoreService } from '../../store/store.service';
import { MetaTags, PageLinks } from './seo-config.interface';
import { buildAlternateHref, PAGES, PAGES_ALTERNATES, PAGES_CANONICAL } from './seo-pages.config';

@Injectable({ providedIn: 'root' })
export class SeoSetUpService {
    constructor(
        private readonly languageService: LanguageService,
        private readonly title: Title,
        private readonly meta: Meta,
        private readonly storeService: FcaStoreService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    setTitle(pageLink: PageLinks): void {
        const lang = this.languageService.currentLanguage;
        const page = PAGES[pageLink]!;

        if (!page) {
            console.error('Page not found');
        }

        this.title.setTitle(page.lng[lang].title);
    }

    setLinkForCanonicalURLs(page: PageLinks) {
        const canonicals = PAGES_CANONICAL[page];
        if (canonicals) {
            const currentLang = this.languageService.currentLanguage;
            this.document.querySelectorAll('link[rel="canonical"]').forEach((tag) => tag.remove());
            canonicals.forEach((canonical) => {
                let canonicalTag: HTMLLinkElement;
                if (currentLang === Language.EN) {
                    canonicalTag = this.createCanonicalTag(canonical.href!.replace('/:page-lang:', ''));
                } else {
                    canonicalTag = this.createCanonicalTag(canonical.href!.replace(':page-lang:', currentLang)!);
                }
                this.document.head.appendChild(canonicalTag);
            });
        }
    }

    setLinkForCanonicalURL(path: string) {
        const currentLang = this.languageService.currentLanguage;
        this.document.querySelectorAll('link[rel="canonical"]').forEach((tag) => tag.remove());
        let canonicalTag: HTMLLinkElement;
        if (currentLang !== Language.EN) {
            canonicalTag = this.createCanonicalTag(`https://spartacus-mma.com/${currentLang}/${path}`);
        } else {
            canonicalTag = this.createCanonicalTag(`https://spartacus-mma.com/${path}`);
        }
        this.document.head.appendChild(canonicalTag);
    }

    setAlternates(page: PageLinks): void {
        const alternates = PAGES_ALTERNATES[page];

        if (alternates) {
            this.document.querySelectorAll('link[rel="alternate"]').forEach((tag) => tag.remove());
            alternates.forEach((alternate) => {
                this.document.head.appendChild(this.createAlternateTag(alternate.href!, alternate.hreflang!));
            });
        }
    }

    setAlternate(path: string): void {
        this.document.querySelectorAll('link[rel="alternate"]').forEach((tag) => tag.remove());
        Object.values(Language).forEach((lng) => {
            const href = buildAlternateHref(path, lng);
            const alternateTag = this.createAlternateTag(href, lng);

            this.document.head.appendChild(alternateTag);
        });
    }

    setMetaByPage(page: PageLinks): void {
        this.setTitle(page);
        // this.setAlternates(page);
        this.setLinkForCanonicalURLs(page);
        const meta = PAGES[page]!.lng[this.languageService.currentLanguage].meta;

        Object.values(MetaTags).forEach((key) => {
            const value = meta[key as MetaTags];

            if (value) {
                this.meta.updateTag({ name: key, content: value });
            }
        });
    }

    updateMeta(title: string, path: string, meta: Partial<Record<MetaTags, string>>): void {
        this.title.setTitle(title);
        // this.setAlternate(path);

        Object.values(MetaTags).forEach((key) => {
            const value = meta[key as MetaTags];

            if (value) {
                this.meta.updateTag({ name: key, content: value });
            }
        });
    }

    private createCanonicalTag(href: string): HTMLLinkElement {
        const link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', href);
        return link;
    }

    private createAlternateTag(href: string, hrefLang: string): HTMLLinkElement {
        const alternateLink: HTMLLinkElement = this.document.createElement('link');
        alternateLink.setAttribute('rel', 'alternate');
        alternateLink.setAttribute('href', href);
        alternateLink.setAttribute('hreflang', hrefLang);

        return alternateLink;
    }

    addSchemaMarkup() {
        const myAwesomeScript = this.document.createElement('script');
        myAwesomeScript.setAttribute('type', 'application/ld+json');

        myAwesomeScript.innerHTML = `
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Spartacus MMA",
            "url": "https://spartacus-mma.com/",
            "logo": "https://spartacus-mma.com/assets/images/logo.new.svg",
            "sameAs": [
              "https://www.facebook.com/spartacusapp",
              "https://twitter.com/spartacus_mma",
              "https://www.instagram.com/spartacusapp/",
              "https://www.youtube.com/channel/UCuY3EJcJ_Vicpix_7DX4o8Q"
            ],
            "description": "Enjoy real-time MMA entertainment, challenge opponents based on your skills, connect with the MMA community, and more. Available on Android and iOS",
          }
         `;
        this.document.head.appendChild(myAwesomeScript);
    }
}
