import * as moment from 'moment-timezone';
import { EImageType, UserImageModel } from '../../images/user-image.model';
import { UserModel, UserModelData } from '../user.model';
import { FighterDetailsModel } from './fighter-details/fighter-details.model';

export enum EUnitSystem {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL',
}

export interface UserFighterModelData extends UserModelData {
    firstName: string;
    lastName: string;
    nickname: string;
    startFightDate: moment.Moment | null;
    endFightDate: moment.Moment | null;
    hasFight: boolean;
    phoneNumber: string | null;
    profileFillingInfo: {
        hasSignedContractWithOrganization: boolean;
        hasFullAddress: boolean;
        hasPhone: boolean;
        hasBillingInfo: boolean;
    };
    fightEventRequests: number[];
    details: FighterDetailsModel;
    photos: UserImageModel[];
}

export interface UserFighterModel extends UserFighterModelData {}

export class UserFighterModel extends UserModel {
    get fullFaceAvatar(): string {
        const fullFace = this.photos.find((e) => e.imageType === EImageType.FULL_FACE_AVATAR);
        if (fullFace !== undefined && fullFace.href.length > 0) {
            return fullFace.href;
        } else {
            return '';
        }
    }

    get fullName(): string {
        return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '-';
    }
}
