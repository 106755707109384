import { environment } from '../../../environments/environment';
import { AppMainConfigInterface } from '../app-main-config.interface';

export function defaultApplicationConfigsFactory(): AppMainConfigInterface {
    return {
        apiUrl: environment.apiUrl,
        webUrl: environment.webUrl,
        chatUrl: environment.chatUrl,
        kickVideoUrl: environment.kickVideoUrl,
        bloodsVideoUrl: environment.bloodsVideoUrl,
        metricsUrl: environment.metricsUrl,
        otherEventsUrl: environment.otherEventsUrl,
        i18nHost: environment.i18nHost,
        google: {
            clientId: environment.googleClientId,
        },
        apple: {
            clientId: environment.appleClientId,
            redirectURI: environment.appleRedirectURI,
        },
        facebook: {
            appId: environment.fbAppId,
        },
        fightEvents: {
            limit: 10,
        },
        production: environment.production,
        qaUrl: environment.qaUrl,
        discoveryUrl: environment.discoveryUrl,
        postUrl: environment.postUrl,
    };
}
