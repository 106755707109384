import { Injectable } from '@angular/core';
import { KicksVideoService } from './kicks-video.service';
import { ProfilesPublicService } from './profiles-public.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private profilesPublicService: ProfilesPublicService, private kicksVideoService: KicksVideoService) {}

    getUserDataById(id: number) {
        return this.profilesPublicService.getProfilePublic(id);
    }
}
