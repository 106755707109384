import { StripePricesItemResponse } from '../../../api/fca/payments/response/stripe-prices.response';
import { currencySymbolConfig } from '../../config/currency-symbol.config';
import { CoinPaymentSystemEnum } from '../../shared/enums/coin-payment-system.enum';
import { SubscriptionInterval } from '../../shared/enums/subscription-interval.enum';
import { ModelDataMapper } from '../../shared/interfaces/model-mapper.interface';
import { SubscriptionModelData } from './subscription.model';

export class SubscriptionMapper implements ModelDataMapper<StripePricesItemResponse, SubscriptionModelData> {
    mapData(raw: StripePricesItemResponse): SubscriptionModelData {
        return {
            id: raw.id,
            amount: raw.unit_amount,
            displayAmount: raw.unit_amount / 100,
            currency: raw.currency,
            // @ts-ignore
            currencySymbol: currencySymbolConfig[raw.currency.toUpperCase()],
            interval: raw.recurring.interval,
            intervalCount: raw.recurring.interval_count,
            monthIntervalCount:
                raw.recurring.interval === SubscriptionInterval.YEAR
                    ? raw.recurring.interval_count * 12
                    : raw.recurring.interval_count,
            paymentSystem: CoinPaymentSystemEnum.STRIPE,
        };
    }
}
