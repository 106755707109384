import { defaultApplicationConfigsFactory } from './app-config/app-configs';
import { AppMainConfigInterface } from './app-main-config.interface';

export function appFullConfigFactory(): AppFullConfig {
    return {
        default: defaultApplicationConfigsFactory(),
    };
}

/**
 * Интерфейс конфигурации приложения
 */
export interface AppFullConfig {
    default: AppMainConfigInterface;
    // context: {
    //     brazil: DeepPartial<AppMainConfigInterface>;
    // };
}
