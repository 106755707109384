import { HeaderNavModel } from '../shared/models/shared/header-nav.model';

export const HEADER_NAV_CONFIG: HeaderNavModel[] = [
    // { path: '', label: 'index-body-main-menu-item-all-events', color: '' },
    // { path: '/live-events', label: 'index-body-main-menu-item-live-events', color: '' },
    // { path: '/blood-sweat-tears', label: 'index-body-main-menu-item-bst', color: '' },
    // { path: '/tv', label: 'index-body-main-menu-item-tv', color: ' },
    // { path: '/upcoming-events', label: 'index-body-main-menu-item-upcoming-events', color: '' },
    // { path: '/past-events', label: 'index-body-main-menu-item-past-events', color: '' },
    // { path: '/other', label: 'index-body-main-menu-item-other', color: ' },
    // { path: '/ppv', label: 'index-body-main-menu-item-ppv', color: '#2E54B6' },
    // { path: '/kicks', label: 'index-body-main-menu-item-kicks', color: '' },
    // { path: '/other-events', label: 'index-body-main-menu-item-other-events', color: '' },
    { path: '/', label: 'side-bar-menu-discovery', color: '' },
    { path: '/other-events', label: 'side-bar-menu-category', color: '' },
    { path: '/kicks', label: 'side-bar-menu-kicks', color: '' },
    { path: '/live-events', label: 'side-bar-menu-live', color: '' },
    { path: '/events', label: 'side-bar-menu-events', color: '' },
    { path: '/account', label: 'side-bar-menu-added-videos', color: '' },
];

