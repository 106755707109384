import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EMPTY_CONTENT_REGEX } from '../../../constants';

@Component({
    selector: 'app-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent {
    @Output() sendClick = new EventEmitter<string>();

    onSendMessage(el: HTMLElement): void {
        const content = el.innerHTML.replace(/&nbsp;/g, '').trim();
        if (!this.isEmptyMessage(content)) {
            this.sendClick.emit(content);
            el.innerHTML = '';
        }
    }

    onEnterKeyDown(ev: KeyboardEvent): void {
        ev.preventDefault();
        this.onSendMessage(ev.target as HTMLElement);
    }

    private isEmptyMessage(content: string): boolean {
        return EMPTY_CONTENT_REGEX.test(content) || content.length === 0;
    }
}
