import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '../fca-api-service-base.service';

@Injectable({
    providedIn: 'root',
})
export class PostLikeApiService extends FcaApiServiceBase {
    addLikeToPost(id: string) {
        const url = [this.postUrl, 'likes/posts', id].join('/');
        return this.http.post<any>(url, {});
    }

    deleteLikeFromPost(id: string) {
        const url = [this.postUrl, 'likes/posts', id].join('/');
        return this.http.delete<any>(url);
    }

    addLikeToComment(id: string) {
        const url = [this.postUrl, 'likes/comments', id].join('/');
        return this.http.post<any>(url, {});
    }

    deleteLikeFromComment(id: string) {
        const url = [this.postUrl, 'likes/comments', id].join('/');
        return this.http.delete<any>(url);
    }
}
