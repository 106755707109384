import { HostListener, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, share, shareReplay, switchMap } from 'rxjs';
import { WINDOW } from '../tokens/window.token';

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService {
    screenSize$ = new BehaviorSubject<number>(0);

    constructor(
      @Inject(WINDOW) private window: Window,
    ) {
        this.checkInitialSize();
    }

    checkInitialSize() {
        this.screenSize$.next(this.window?.innerWidth);
    }

    isForTablet(screenSize = 1220) {
        return this.screenSize$.pipe(
            shareReplay(1),
            map((v) => {
                return v < screenSize;
            })
        );
    }

    isForMobile(screenSize = 750) {
        return this.screenSize$.pipe(
            shareReplay(1),
            map((v) => {
                return v < screenSize;
            })
        );
    }
}
