import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[appScrollToBottom]',
})
export class ScrollToBottomDirective implements AfterViewInit {
    @Output() scrolledToBottom = new EventEmitter<boolean>();

    constructor(private self: ElementRef) {}

    public ngAfterViewInit() {
        this.self.nativeElement.addEventListener('scroll', () => {
            if (
                this.self.nativeElement.scrollHeight -
                    (this.self.nativeElement.scrollTop + this.self.nativeElement.offsetHeight) <=
                1
            ) {
                this.scrolledToBottom.emit(true);
            }
        });
    }
}
