<button [disabled]='disabled' class="app-button app-button__flat" [ngClass]="type || defaultType">
    <img
        *ngIf="icon"
        [attr.src]="icon.assetUrl"
        [attr.width]="icon?.width"
        [attr.height]="icon?.height"
        [attr.alt]="icon?.alt"
        [attr.title]="icon?.title"
    />
    <div *ngIf="icon" class="app-button-separator"></div>
    <span *ngIf="text" [innerText]="text || defaultText"></span>
</button>
