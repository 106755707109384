import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {
    constructor(private _translocoService: TranslocoService) {}

    transform(value: number, symbol?: string, showHours = true): unknown {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const remainingSeconds = Math.floor(value % 60);
        let formattedTime = '';
        if (symbol) {
            if (showHours) {
                formattedTime += `${hours < 10 ? `0${hours}` : hours}${symbol}`;
            }
            formattedTime += `${minutes < 10 ? `0${minutes}` : minutes}${symbol}`;
            return formattedTime + `${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
        } else {
            if (hours && showHours) {
                formattedTime += `${hours} ${this._translocoService.translate('shared-abbreviated-hour')} `;
            }
            if (minutes) {
                formattedTime += `${minutes} ${this._translocoService.translate('shared-abbreviated-minute')} `;
            }
            return (
                formattedTime + `${remainingSeconds} ${this._translocoService.translate('shared-abbreviated-second')} `
            );
        }
    }
}
