import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
// @ts-ignore
import * as uuid from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    getAge(date: number): number {
        const ageDifMs = Date.now() - new Date(date).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    checkScreenSizeForVideo(file: File, checkingSizeList: { x: number; y: number }[]) {
        return new Promise((resolve) => {
            const video = this.document.createElement('video');
            video.setAttribute('src', URL.createObjectURL(file));
            video.onloadedmetadata = () => {
                const result = checkingSizeList.some((screen: { x: number; y: number }) => {
                    return +screen.x === +video.videoWidth && +screen.y === +video.videoHeight;
                });
                resolve(result);
            };
        });
    }

    generateUuid() {
        return uuid.v4();
    }

    parseJwt(jwt: string) {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    }
}
