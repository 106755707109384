import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-radio-btn',
    templateUrl: './radio-btn.component.html',
    styleUrls: ['./radio-btn.component.scss'],
})
export class RadioBtnComponent {
    @Input() label = '';
    @Input() name = '';
    @Input() value = '';
    @Input() selectedValue = '';
    @Input() description = '';
    @Input() disabled = false;
    @Input() tooltipText = '';
    @Output() onSelect = new EventEmitter();
}
