<ng-container *transloco="let t">
    <div
        *ngIf="!isLoading; else selectLoadingTemplate"
        class="select-wrapper"
        [class.open]="isOpen"
        (clickOutside)="isOpen = false"
    >
        <div class="select-inner" [class.invalid]="invalid && !isOpen" (click)="isOpen = !isOpen">
            {{ t(getSelectInnerText) }}
            <div class="arrow-wrapper">
                <img src="assets/images/white-arrow_down.svg" alt="" />
            </div>
        </div>

        <div class="select-option-list-wrapper">
            <ul class="select-option-list scroll-bar">
                <ng-container *ngFor="let option of optionList">
                    <li (click)="selectOption(option)" [class.active]="checkIsSelected(option)">
                        {{ t(option.text) }}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <ng-template #selectLoadingTemplate>
        <div class="select-wrapper">
            <ngx-skeleton-loader
                [theme]="{
                    width: '100%',
                    margin: '5px',
                    borderRadius: '5px',
                    height: '45px'
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-template>
</ng-container>
