<div class='page' *transloco='let t'>

  <div class='main-content'>
    <div class='title'>
      <span>4</span>
      <img src='assets/images/page-not-found-main-img.png' alt='golden helmet'>
      <span>4</span>
    </div>
    <p class='subtitle'>
      {{t('page-not-found-title')}}
    </p>
    <button [routerLink]="'/'" class='main-btn'>
      {{t('page-not-found-main-page-btn')}}
    </button>
  </div>

  <p class='past-events-title'>
    {{t('page-not-found-past-events-title')}}
  </p>

  <ng-container *ngIf='(events$ | async) as events; else loading'>
    <div class='event-list'>
      <ng-container *ngFor='let event of events'>
        <app-past-event-card [event]='event'></app-past-event-card>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class='event-list'>
      <div class='event-item flex flex-column' *ngFor='let skeleton of [0,1,2,3]'>
        <ngx-skeleton-loader
          [theme]="{
          width: '100%',
          margin: '5px',
          height: '160px'
        }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{
          width: '50%',
          margin: '5px',
          height: '15px'
        }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{
          width: '25%',
          margin: '5px',
          height: '15px'
        }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </ng-template>

</div>
