import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { FcaAuthService } from '../../../services/fca-auth.service';
import { untilDestroy, UntilDestroy } from '../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-become-content-creator-popup',
    templateUrl: './become-content-creator-popup.component.html',
    styleUrls: ['./become-content-creator-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BecomeContentCreatorPopupComponent implements OnInit {
    text = '';
    constructor(
        public dialogRef: MatDialogRef<BecomeContentCreatorPopupComponent>,
        private _cdr: ChangeDetectorRef,
        private fcaAuthService: FcaAuthService
    ) {}

    ngOnInit(): void {}

    submit() {
        this.fcaAuthService
            .requestContentCreator(this.text)
            .pipe(
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.dialogRef.close();
                })
            )
            .subscribe();
    }
}
