import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SessionStorage } from '../../services/storage/session-storage.service';

@Component({
    selector: 'app-get-more',
    templateUrl: './get-more.component.html',
    styleUrls: ['./get-more.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetMoreComponent {
    readonly showBannerKey = 'show-banner';
    visible: boolean;

    constructor(private readonly sessionStorage: SessionStorage) {
        const showBanner = sessionStorage.getItem('show-banner');
        this.visible = showBanner === 'true' || showBanner == null;
    }

    onClose(): void {
        this.visible = false;
        this.sessionStorage.setItem(this.showBannerKey, 'false');
    }
}
