import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { HEADER_NAV_CONFIG } from '../../../../config/header-nav.config';
import { FcaStoreService } from '../../../../store/store.service';
import { Language } from '../../../enums/language.enum';
import { HeaderNavModel } from '../../../models/shared/header-nav.model';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';
import { WINDOW } from '../../../tokens/window.token';

@UntilDestroy()
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavComponent implements OnInit {
    routerConfig: HeaderNavModel[] = HEADER_NAV_CONFIG;
    lang: Language | '';
    isOpenMenu = false;

    constructor(
        public readonly translate: TranslocoService,
        private readonly storeService: FcaStoreService,
        private _cdr: ChangeDetectorRef,
        @Inject(WINDOW) private window: Window,
    ) {
        storeService
            .getLanguage()
            .pipe(
                tap((lang: Language) => {
                    this.lang = lang === Language.EN ? '' : lang;
                }),
                untilDestroy(this)
            )
            .subscribe();
        this.checkScroll();
    }
    checkScroll() {
        this.window?.addEventListener('scroll', (e: any) => {
            this.isOpenMenu = false;
            this._cdr.detectChanges();
        });
    }

    ngOnInit(): void {}
}
