<div class="post-wrapper" (click)="openPost()" *transloco="let t">
    <div class="post-media center">
        <img class="post-media-content" [src]="imageList[0]?.url" alt="" />

        <div class="media-type center">
            <img src="assets/images/post-type-image.svg" alt="" />
            <img *ngIf="false" src="assets/images/post-type-video.svg" alt="" />
        </div>

        <button class="subscribe-note" *ngIf="isPrivate">
            {{ t('post-item-follow-to-creator-label') }}
        </button>

        <div *ngIf="false" class="blocked-post center">
            <img src="assets/images/lock.svg" alt="" />
        </div>
    </div>
    <div class="post-action-list flex align-items-center justify-content__space-between">
        <div class="flex">
            <div class="action-btn-item center">
                <button (click)="postLikeToggle($event)">
                    <img [hidden]="liked" src="assets/images/like-white.svg" alt="" />
                    <img [hidden]="!liked" src="assets/images/like-active.svg" alt="" />
                </button>
            </div>
            <div class="action-btn-item center">
                <button *ngIf="commentsEnabled">
                    <img src="assets/images/comments.svg" alt="" />
                </button>
            </div>
            <div class="action-btn-item center">
                <button *ngIf="false" class="donate-btn flex align-items-center">
                    {{ t('post-item-donate') }}
                    <img width="20px" height="20px" src="assets/images/coins-current-balance-image.png" alt="" />
                </button>
            </div>
        </div>
        <div *ngIf="true">
            <app-three-dots-menu
                (onSelect)="treeDotsActionHandler($event)"
                [threeDotsMenuList]="getMenuList() | async"
            ></app-three-dots-menu>
        </div>
    </div>
    <div class="post-data flex align-items-start">
        <div class="avatar-wrapper center">
            <img [src]="avatar | imagePathWhiteSpace" alt="" />
        </div>
        <div class="post-data-content">
            <p class="full-name">{{ surname }} {{ name }}</p>
            <p class="post-text" #postText>
                {{ text }}
            </p>
            <button class="show-more" *ngIf="checkTreeDots(postText)">
                {{ t('post-item-more-btn') }}
            </button>
            <p class="other-data">
                <span class="counter">{{ likes | reductionOfNumbers }}</span>
                {{ t('post-item-likes') }}
                <span class="date"> {{ date | date : 'dd MMM yyyy' }} </span>
            </p>
        </div>
    </div>
</div>

<ng-container *ngIf="showCreatorData">
    <app-content-creator-block
        [ownerId]="ownerId"
        [posts]="posts"
        [views]="views"
        [videos]="videos"
        [ownerBackgroundImg]="ownerBackgroundImg"
        [followers]="followers"
        [name]="name"
        [surname]="surname"
        [avatar]="avatar"
    ></app-content-creator-block>
</ng-container>
