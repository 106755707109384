<div id='get-more' *ngIf='visible == true; else empty;'>
  <span class='close center' (click)='onClose()'>
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6665 2.27334L9.72652 1.33334L5.99986 5.06001L2.27319 1.33334L1.33319 2.27334L5.05986 6.00001L1.33319 9.72668L2.27319 10.6667L5.99986 6.94001L9.72652 10.6667L10.6665 9.72668L6.93986 6.00001L10.6665 2.27334Z'
        fill='#ffffff' stroke='#ffffff' stroke-width='0.5' />
    </svg>
  </span>
  <div class='get-more-content flex flex-wrap' *transloco='let t'>
    <div class='get-more-list'>
      <div class='get-more-header flex align-items-center'>
        <div class='app-icon-wrapper'>
          <img src='/assets/images/app_icon.svg' alt='Spartacus MMA logo'>
        </div>
        <h3 [innerHTML]="t('dashboard-event-get-more-title')"></h3>
      </div>
      <ul>
        <li>{{t('dashboard-event-get-more-feature-1')}}</li>
        <li>{{t('dashboard-event-get-more-feature-2')}}</li>
        <li>{{t('dashboard-event-get-more-feature-3')}}</li>
        <li>{{t('dashboard-event-get-more-feature-4')}}</li>
      </ul>
      <p [innerHTML]="t('dashboard-event-download-application')"></p>
    </div>
    <div class='get-more-buttons flex flex-column'>
      <img class='get-more-men-img' src='/assets/images/event-dashboard-get-more-bg.png' alt='get more in Spartacus MMA app info background'>
<!--      <a-->
<!--        href='https://apps.apple.com/ru/app/spartacus-mma/id1589781592'-->
<!--        target='_blank'-->
<!--        class='get-more-button get-more-button-ios'-->
<!--      >-->
<!--        <img src='/assets/images/app_store-black-without_qr.svg' alt='Spartacus MMA in App Store'>-->
<!--      </a>-->
<!--      <a-->
<!--        href='https://play.google.com/store/apps/details?id=com.spartacus.mma'-->
<!--        target='_blank'-->
<!--        class='get-more-button get-more-button-android'-->
<!--      >-->
<!--        <img src='/assets/images/google_play-black-without_qr.svg' alt='Spartacus MMA in Play Market'>-->
<!--      </a>-->
    </div>
  </div>
</div>
<ng-template #empty></ng-template>
