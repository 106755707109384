import { TvEventResponse } from '../../../../api/fca/fight-event/response/tv-event.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { TvEventModelData } from './fight-event-tv.model';

export class FightEventTvMapper implements ModelDataMapper<TvEventResponse, TvEventModelData> {
    mapData(raw: TvEventResponse): TvEventModelData {
        return {
            id: raw.id,
            name: raw.name,
            video: {
                id: raw.video.id,
                name: raw.video.name,
                status: raw.video.status,
                streamUrl: raw.video.streamUrl,
                thumbnail: raw.video.thumbnail,
                videoUrl: raw.video.videoUrl,
            },
            isLive: true,
        };
    }
}
