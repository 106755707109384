import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translateParams',
})
export class TranslateParamsPipe implements PipeTransform {
    transform(value: string, args: { [key: string]: string | number }): string {
        Object.keys(args).map((key: string) => {
            value = value.replace(`::${key}::`, args[key]?.toString());
        });
        return value;
    }
}
