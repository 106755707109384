import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FightEventCommentModel } from '../../../app/models/fight-event/fight-event-comments/fight-event-comment.model';
import { KicksInfoByIdModel } from '../../../app/models/kicks/kicks-info-by-id/kicks-info-by-id.model';
import { LikeUnlikeEnum } from '../../../app/shared/enums/like-unlike.enum';
import { OffsetPagination } from '../../../app/shared/interfaces/offset-pagination.interface';
import { PaginationSelectResult } from '../../../app/shared/interfaces/pagination-select.interface';
import { NewCommentParams } from '../bloods-video/params/new-comment.params';
import { PostLikeParams } from '../bloods-video/params/post-like.params';
import { CommentResponse } from '../comments/response/comment.response';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { GetKicksVideoListParams } from './params/get-kicks-video-list.params';
import { KickInfoByIdResponse } from './response/kick-info-by-id.response';
import { KicksListMinInfoApiResponse } from './response/kicks-list-min-info-api.response';

@Injectable({
    providedIn: 'root',
})
export class KicksVideoApiService extends FcaApiServiceBase {
    getKickMinInfoList(body: GetKicksVideoListParams): Observable<KicksListMinInfoApiResponse[]> {
        const url = [this.kickVideoUrl, 'video/list'].join('/');
        return this.http.post<KicksListMinInfoApiResponse[]>(url, body).pipe(shareReplay(1));
    }

    loadKicksInfoById(id: string): Observable<KickInfoByIdResponse> {
        const url = [this.kickVideoUrl, 'video', id, 'data'].join('/');
        return this.http.get<KickInfoByIdResponse>(url).pipe(shareReplay(1));
    }

    likeAndUnlikeVideo(id: string, type: LikeUnlikeEnum): Observable<KickInfoByIdResponse> {
        const url = [this.kickVideoUrl, 'video', id, 'like'].join('/');
        return this.http.post<KickInfoByIdResponse>(url, { type }).pipe(shareReplay(1));
    }

    loadCommentList(videoId: string, paging: OffsetPagination): Observable<PaginationSelectResult<CommentResponse>> {
        const params = new HttpParams().appendAll({ ...paging, videoId });
        const url = [this.kickVideoUrl, 'comment', videoId, 'list'].join('/');
        return this.http.get<PaginationSelectResult<CommentResponse>>(url, { params }).pipe(shareReplay(1));
    }

    addNewComment(videoId: string, body: NewCommentParams): Observable<FightEventCommentModel> {
        const url = [this.kickVideoUrl, 'comment', videoId].join('/');
        return this.http.post<FightEventCommentModel>(url, body).pipe(shareReplay(1));
    }

    addLikeForComment(body: PostLikeParams): Observable<FightEventCommentModel> {
        const url = [this.kickVideoUrl, 'comment/like'].join('/');
        return this.http.post<FightEventCommentModel>(url, body).pipe(shareReplay(1));
    }

    setAsViewed(videoId: string, userId: number): Observable<KicksInfoByIdModel> {
        const url = [this.kickVideoUrl, 'video', videoId, 'viewed'].join('/');
        return this.http.post<KicksInfoByIdModel>(url, { userId }).pipe(shareReplay(1));
    }
}
