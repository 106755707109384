<ng-container *transloco="let t">
    <div class="confirmation-popup-wrapper">
        <button class="close-btn center">
            <img src="assets/images/close-popup.svg" (click)="dialogRef.close(false)" alt="" />
        </button>

        <p class="title">{{ t(data?.title) }}</p>
        <p class="text">{{ t(data?.text) }}</p>

        <div class="action-btn-list">
            <button class="continue-btn" (click)="dialogRef.close(false)">
                {{ t(data.cancelBtnText || 'shared-cancel-button') }}
            </button>
            <button class="main-btn" (click)="dialogRef.close(true)">
                {{ t(data.submitBtnText || 'shared-submit-button') }}
            </button>
        </div>
    </div>
</ng-container>
