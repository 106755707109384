import { ModelFactory } from '../../../../shared/abstract/model-factory.abstract';
import { FightEventLocationLightModel, FightEventLocationLightModelData } from './fight-event-location-light.model';

export class FightEventLocationLightFactory extends ModelFactory<
    FightEventLocationLightModelData,
    FightEventLocationLightModel
> {
    protected getInstance(): FightEventLocationLightModel {
        return new FightEventLocationLightModel();
    }
}
