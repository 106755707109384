import { FightEventWeightingApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { FightEventVideoFactory } from '../fight-event-video/fight-event-video.factory';
import { FightEventVideoMapper } from '../fight-event-video/fight-event-video.mapper';
import { EFightEventStatus } from '../fight-event.model';
import { FightEventWeightingModel } from './fight-event-weighting.model';

export class FightEventWeightingMapper
    implements ModelDataMapper<FightEventWeightingApiResponse, FightEventWeightingModel>
{
    mapData(raw: FightEventWeightingApiResponse): FightEventWeightingModel {
        return {
            fromDate: raw.fromDate,
            status: raw.status as EFightEventStatus,
            toDate: raw.toDate,
            requirements: raw.requirements,
            video: new FightEventVideoFactory().getModelFromData(new FightEventVideoMapper().mapData(raw.video)),
        };
    }
}
