import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    NgZone,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, catchError, finalize, interval, of, Subscription, take, tap } from 'rxjs';
import { SocialProvider } from '../../../api/fca/auth/params/login.params';
import { AuthType } from '../../../api/fca/auth/params/send-sms.params';
import { EUserState } from '../../../api/fca/auth/response/check-user.response';
import { LoginResponse } from '../../../api/fca/auth/response/login.response';
import { PageLinks } from '../../config/seo/seo-config.interface';
import { SeoSetUpService } from '../../config/seo/seo-set-up.service';
import { FcaAuthService, SocialLoginResponse } from '../../services/fca-auth.service';
import { ApiErrorData, ErrorCode } from '../../shared/error/fca-error-handler.service';
import { AuthData } from '../../shared/interfaces/auth-data.interface';
import { UntilDestroy, untilDestroy } from '../../shared/operators/until-destroy.operator';
import { PreloadService } from '../../shared/services/preload.service';
import { SnackbarService, SnackBarStyles } from '../../shared/services/snackbar.service';
import { SaveAuthDataAction } from '../../store/actions';
import { FcaStoreService } from '../../store/store.service';
import {
    EFlatButtonType,
    FlatButtonData,
    FlatButtonIconData,
} from '../../uikit/buttons/flat-button/flat-button.component';
import { RegisterRouteParams } from '../register/register.component';

const resendTimeout = 30;

enum ELoginStateType {
    SELECT_TYPE,
    ENTER_EMAIL,
    ENTER_CODE,
}

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnDestroy {
    private intervalSubscription: Subscription | null = null;

    @ViewChild('codeInput', { static: false }) codeInput: CodeInputComponent;
    cookieService = inject(SsrCookieService);
    socialProvider = SocialProvider;
    loginStateType: typeof ELoginStateType = ELoginStateType;
    timerEmitter$: BehaviorSubject<number | null>;
    resendTimeout: number | null = null;
    currentLoginStep: ELoginStateType;
    buttons = new Map<string, FlatButtonData>([
        [
            'apple',
            {
                name: 'apple',
                type: EFlatButtonType.social_apple,
                icon: {
                    width: 16,
                    alt: 'Sign with Apple icon',
                    title: 'Sign with Apple',
                    assetUrl: 'assets/images/social_apple.svg',
                } as FlatButtonIconData,
            },
        ],
        [
            'google',
            {
                name: 'google',
                type: EFlatButtonType.social_google,
                icon: {
                    width: 16,
                    alt: 'Sign with Google icon',
                    title: 'Sign with Google',
                    assetUrl: 'assets/images/social_google.svg',
                } as FlatButtonIconData,
            },
        ],
        [
            'facebook',
            {
                name: 'facebook',
                type: EFlatButtonType.social_facebook,
                icon: {
                    width: 16,
                    alt: 'Sign with Facebook icon',
                    title: 'Sign with Facebook',
                    assetUrl: 'assets/images/social_fb.svg',
                } as FlatButtonIconData,
            },
        ],
        [
            'login',
            {
                name: 'login',
                type: EFlatButtonType.primary,
            },
        ],
        [
            'register',
            {
                name: 'register',
                type: EFlatButtonType.secondary,
            },
        ],
        [
            'email',
            {
                name: 'email',
                type: EFlatButtonType.primary,
            },
        ],
        [
            'back',
            {
                name: 'back',
                type: EFlatButtonType.secondary,
            },
        ],
    ]);
    email = new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    ]);
    authType: AuthType = 'LOGIN';
    pinCode = '';
    invalidPinCode = false;
    isLoginPage = true;
    errorMessage = '';

    constructor(
        private readonly authService: FcaAuthService,
        private readonly preloadService: PreloadService,
        private readonly snackbarService: SnackbarService,
        private readonly store: FcaStoreService,
        private readonly seoService: SeoSetUpService,
        private _cdr: ChangeDetectorRef,
        private _router: Router,
        private _route: ActivatedRoute,
        private _zone: NgZone
    ) {
        this.timerEmitter$ = new BehaviorSubject<number | null>(this.resendTimeout);
        this.currentLoginStep = ELoginStateType.ENTER_EMAIL;
        this.timerEmitter$.pipe(untilDestroy(this)).subscribe(() => {
            if (this.resendTimeout === 0) {
                this.resetTimer();
            }
        });
        this.isLoginPage = this._route.snapshot.data['page'] === 'login';
        if (this.isLoginPage) {
            this.seoService.setMetaByPage(PageLinks.Login);
        }
    }

    initTimer(): void {
        if (this.intervalSubscription === null || this.intervalSubscription?.closed) {
            this.resendTimeout = resendTimeout;
            this.timerEmitter$.next(this.resendTimeout);
            this.intervalSubscription = interval(1000).subscribe(() => {
                if (this.resendTimeout! > 0) {
                    this.resendTimeout!--;
                    this.timerEmitter$.next(this.resendTimeout);
                }
            });
        }
    }

    resetTimer(): void {
        this.resendTimeout = null;
        this.timerEmitter$.next(this.resendTimeout);
        this.intervalSubscription?.unsubscribe();
    }

    onCreateNewAccountClick(): void {
        if (this.email.valid) {
            this.authType = 'REGISTRATION';
            this.preloadService.preload(true);
            this.authService
                .sendPinToEmail(this.email.value!, this.authType)
                .pipe(
                    take(1),
                    finalize(() => this.preloadService.preload(false)),
                    catchError((err: HttpErrorResponse) => {
                        const errorData = err.error as ApiErrorData;
                        // this._errorHandler.handleError(errorData);

                        return of(null);
                    }),
                    untilDestroy(this)
                )
                .subscribe(() => this.switchToEnterCode());
        }
    }

    onEmailLoginClick(): void {
        this.email.markAllAsTouched();
        if (this.email.valid) {
            this.preloadService.preload(true);
            this.authService
                .sendPinToEmail(this.email.value!, this.authType)
                .pipe(
                    take(1),
                    tap(() => this.switchToEnterCode()),
                    catchError((err: HttpErrorResponse) => {
                        const errorData = err.error as ApiErrorData;
                        // this._errorHandler.handleError(errorData);
                        // if (errorData.error.code === ErrorCode.USER_NOT_FOUND) {
                        //     this.needToShowCreateAccountButton$.next(true);
                        // }
                        if (errorData.error.code === ErrorCode.USER_NOT_FOUND) {
                            this.errorMessage = 'errors-user_not_found_error';
                            this._cdr.detectChanges();
                        }

                        return of(null);
                    }),
                    finalize(() => this.preloadService.preload(false)),
                    untilDestroy(this)
                )
                .subscribe();
        }
    }

    onSocialLogin(provider: SocialProvider): void {
        this.authService
            .loginSocial(provider)
            .pipe(
                take(1),
                catchError(() => {
                    this._zone.run(() => {
                        this.currentLoginStep = ELoginStateType.ENTER_EMAIL;
                        this._cdr.markForCheck();
                    });

                    return of(null);
                }),
                untilDestroy(this)
            )
            .subscribe((resp: SocialLoginResponse | null) => {
                if (resp?.loginData) {
                    this.doActionsAfterLogin(resp.loginData);
                } else if (resp?.providerData && resp?.userExists === false) {
                    this._zone.run(() => {
                        this._router
                            .navigate(['/auth/register'], {
                                state: {
                                    email: resp.providerData!.email,
                                    firstName: resp.providerData!.firstName,
                                    lastName: resp.providerData!.lastName,
                                    token: resp.providerData!.token,
                                    identifier: resp.providerData!.identifier || '',
                                    provider,
                                } as RegisterRouteParams,
                                queryParams: { inviteLinkId: this._route.snapshot.queryParams['inviteLinkId'] },
                            })
                            .then();
                    });
                }
            });
    }

    onBackToLoginClick(): void {
        this.currentLoginStep = ELoginStateType.ENTER_EMAIL;
        this.resetTimer();
    }

    onCodeChanged(code: string) {
        this.pinCode = code;
        this.invalidPinCode = false;
        this._cdr.detectChanges();
    }

    checkPinCode(): void {
        const email: string = this.email.value!;

        this.preloadService.preload(true);
        this.authService
            .checkUser(email, this.pinCode)
            .pipe(
                take(1),
                tap((resp) => {
                    switch (resp.userState) {
                        case EUserState.EXISTS:
                            this.authService
                                .loginDefault(email, resp.registrationToken)
                                .pipe(take(1), untilDestroy(this))
                                .subscribe((loginResponse: LoginResponse) => this.doActionsAfterLogin(loginResponse));
                            break;
                        case EUserState.NOT_FOUND:
                            const extras: NavigationExtras = {
                                state: { email, token: resp.registrationToken } as RegisterRouteParams,
                                queryParams: { inviteLinkId: this._route.snapshot.queryParams['inviteLinkId'] },
                            };
                            this._router.navigate(['/auth/register'], extras).then();
                            break;
                        case EUserState.DELETED:
                            // TODO: Translate
                            this.snackbarService.show({
                                message: 'The account is deleted. Try to restore',
                                style: SnackBarStyles.WARNING,
                            });
                            break;
                    }
                }),
                catchError((err: HttpErrorResponse) => {
                    const errorData = err.error as ApiErrorData;
                    if (errorData.error.code === ErrorCode.INVALID_PIN) {
                        this.invalidPinCode = true;
                        this._cdr.detectChanges();
                    } else {
                        // this._errorHandler.handleError(errorData);
                    }
                    return of(null);
                }),
                finalize(() => this.preloadService.preload(false)),
                untilDestroy(this)
            )
            .subscribe(() => {
                this.codeInput.reset(false);
            });
    }

    onResendCodeClick(): void {
        this.authService
            .sendPinToEmail(this.email.value!, this.authType)
            .pipe(take(1), untilDestroy(this))
            .subscribe(() => {
                this.initTimer();
                this._cdr.markForCheck();
            });
    }

    ngOnDestroy() {
        this.intervalSubscription?.unsubscribe();
    }

    private doActionsAfterLogin(loginResponse: LoginResponse): void {
        const data: AuthData = {
            id: loginResponse.id,
            email: loginResponse.email,
            jwt: loginResponse.jwt,
            refreshToken: loginResponse.refreshToken,
            provider: loginResponse.provider,
            stripeCustomerId: loginResponse.stripeCustomerId,
        };
        this.store.dispatch(new SaveAuthDataAction({ data }));
        this.cookieService.set('AuthData', JSON.stringify(data));
        this.authService.currentUser$.subscribe((value: any) => {
            const redirectUrl = this._route.snapshot?.queryParams?.['redirectUrl'];
            this._router.navigate([redirectUrl || '/']).then(() => {});
        });
    }

    private switchToEnterCode(): void {
        this.currentLoginStep = ELoginStateType.ENTER_CODE;
        this.initTimer();
        this._cdr.markForCheck();
    }

    userOtherEmail() {
        this.currentLoginStep = ELoginStateType.ENTER_EMAIL;
        this._cdr.markForCheck();
    }
}
