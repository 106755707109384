import { FightEventConferenceApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { FightEventVideoFactory } from '../fight-event-video/fight-event-video.factory';
import { FightEventVideoMapper } from '../fight-event-video/fight-event-video.mapper';
import { EFightEventStatus } from '../fight-event.model';
import { FightEventConferenceModel } from './fight-event-conference.model';

export class FightEventConferenceMapper
    implements ModelDataMapper<FightEventConferenceApiResponse, FightEventConferenceModel>
{
    mapData(raw: FightEventConferenceApiResponse): FightEventConferenceModel {
        return {
            fromDate: raw.fromDate,
            status: raw.status as EFightEventStatus,
            toDate: raw.toDate,
            video: new FightEventVideoFactory().getModelFromData(new FightEventVideoMapper().mapData(raw.video)),
        };
    }
}
