<div
  class='nav-wrapper'
  (clickOutside)='isOpenMenu = false'
  [class.active]='isOpenMenu'
>
  <div
    class='label'
    (click)='isOpenMenu = !isOpenMenu'
  >
    <img src='assets/images/burger-menu.svg' class='burger-menu' alt=''>
  </div>

  <div class='h-left-menu'>
    <ul (click)='isOpenMenu = false'>
      <ng-container *ngFor="let item of routerConfig">
        <li>
          <a
            [routerLink]="lang + item?.path"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ translate.selectTranslate(item.label) | async }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
