import { Language } from '../../shared/enums/language.enum';

export enum MetaTags {
    Description = 'description',
    Robots = 'robots',
}

export enum Alternate {
    Href = 'href',
    Hreflang = 'hreflang',
}

export enum PageLinks {
    Main = 'main',
    ContactUs = 'contact_us',
    TermsOfUse = 'terms_of_use',
    PrivacyPolicy = 'privacy_policy',
    Login = 'login',
    LiveEvents = 'live_events',
    UpcomingEvents = 'upcoming_events',
    PastEvents = 'past_events',
}

export enum Canonical {
  Href = 'href',
}

export type Pages = Partial<Record<PageLinks, PageItem>>;

export interface PageItem {
    alternate?: Partial<Record<Alternate, string>>[];
    lng: {
        [key in Language]: {
            title: string;
            meta: Partial<Record<MetaTags, string>>;
        };
    };
    canonical?: Partial<Record<Canonical, string>>[];
}

export type PagesAlternates = Partial<Record<PageLinks, PageAlternateItem>>;

export type PageAlternateItem = Partial<Record<Alternate, string>>[];



export type PageCanonical = Partial<Record<PageLinks, PageAlternateItem>>;

export type PageCanonicalItem = Partial<Record<Canonical, string>>[];


