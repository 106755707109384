import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessPaymentModel } from '../../models/shared/success-payment.model';

@Component({
    selector: 'app-success-payment',
    templateUrl: './success-payment.component.html',
    styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent {
    constructor(
        public dialogRef: MatDialogRef<SuccessPaymentComponent>,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: SuccessPaymentModel
    ) {}

    close() {
        this.dialogRef.close(true);
        if (this.data.redirectUrl) {
            this._router.navigate(['/subscription']);
        }
    }
}
