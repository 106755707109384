import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppApiService } from '../../api/fca/app/app-api.service';
import { ContactUsParams } from '../../api/fca/app/params/contact-us-params';

@Injectable({
    providedIn: 'root',
})
export class FcaAppService {
    constructor(private appApiService: AppApiService) {}

    postComment(params: ContactUsParams): Observable<void> {
        return this.appApiService.postComment(params);
    }
}
