<div
  class="event-item-wrapper"
  *ngIf="!loading"
  [class.hover]="hoverEffect"
  [class.disabled]="disabled"
  [class.is-white]="isWhite"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
>
  <div class="event-item" *transloco="let t">
    <div
      class="content-wrapper"
      [class.hidden]="isHover"
      [class.disabled]="disabledRedirect"
      [routerLink]="routingPath ? routingPath : '/fight-event/' + id"
    >
      <div
        class="event-body flex align-items-end"
        [style.background-image]="
                    backgroundImage | backgroundImageUrl : '/assets/images/past-event-no-banner.png'
                "
      ></div>
      <div class="event-details flex justify-content__space-between w-100">
        <div class="flex align-items-center">
          <div class="ppv item flex align-items-center" *ngIf="isPPV">
            <img width="5px" height="10px" src="assets/images/usd-white.svg" alt="" />
            PPV
          </div>
          <div class="free item" *ngIf="isFree">
            {{t('shared-free')}}
          </div>
          <div class="premium item flex align-items-center" *ngIf="onlyForPremium; else accessTemplate">
            <img src="assets/images/crown-white.svg" alt="" />
            {{ t('shared-video-only-for-premium-tag') }}
          </div>
          <ng-template #accessTemplate>
            <div class='common-tag' *ngIf='access'>
              {{ t('creator-video-card-label-' + access) }}
            </div>
          </ng-template>
          <div class="additionalInfo item" *ngIf="additionalInfo">{{ t(additionalInfo) }}</div>
          <div class="premium item" *ngIf="isPremium">Premium</div>
          <div class="status item" *ngIf="status === statusEnum.UPCOMING">
            {{ t(status) }}
          </div>
          <div class="live item" *ngIf="status === statusEnum.ONGOING">Live</div>
          <p class="event-time item" *ngIf="time">
            {{ time | date : 'mm:ss' }}
          </p>
          <p class="event-time item" *ngIf="duration">
            {{ duration | duration : ':' : false }}
          </p>
          <p class="event-time item" *ngIf="passedTime">
            {{ passedTime | passedTime }}
          </p>
          <div class="lang item flex align-items-center" *ngIf="lang">
            <img
              *ngIf="lang.toLowerCase() === langEnum.PT.toLowerCase()"
              src="assets/images/br-flag.svg"
              alt=""
            />
            <img
              *ngIf="lang.toLowerCase() === langEnum.EN.toLowerCase()"
              src="assets/images/en-flag.svg"
              alt=""
            />
            <ng-container *ngIf="langCount"> +{{ langCount }} </ng-container>
          </div>
        </div>
        <div class="video-amount flex align-items-center" *ngIf="videoCount">
          {{ videoCount }}
          <img src="assets/images/event-card-video-amount.svg" alt="" />
        </div>
      </div>
      <div class="vide-container" #videoContainer>
        <video
          *ngIf="isHover1"
          preload="auto"
          width="100%"
          height="auto"
          [muted]="true"
          [loop]="true"
          [autoplay]="true"
          [controls]="false"
        >
          <source [src]="getUrl()" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="event-footer">
      <div class="flex align-items-start justify-content__space-between">
        <div class="flex align-items-start">
          <div class="details-image-wrapper center" *ngIf="detailsImagePath">
            <img src="assets/images/avatar_placeholder.svg" alt="" />
          </div>
          <div>
            <h3 class="event-name">{{ title }}</h3>
            <div class="flex align-items-center">
              <p class="event-date" *ngIf="description">{{ description }}</p>
              <p class="event-date" *ngIf="date">{{ date | date : 'dd MMM yyyy, HH:mm' }}</p>
            </div>
          </div>
        </div>
        <app-three-dots-menu
          [threeDotsMenuList]="threeDotsMenuList"
          (onSelect)="onThreeDotsSelectHandler($event)"
        ></app-three-dots-menu>
      </div>
    </div>
  </div>
</div>
<div class="event-item-wrapper" *ngIf="loading">
  <div class="event-item flex flex-column">
    <ngx-skeleton-loader
      [theme]="{
                width: '100%',
                margin: '5px',
                height: '160px'
            }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{
                width: '50%',
                margin: '5px',
                height: '15px'
            }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{
                width: '25%',
                margin: '5px',
                height: '15px'
            }"
    ></ngx-skeleton-loader>
  </div>
</div>
