import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "sort"
})
export class ArraySortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (typeof a[field] === "boolean") {
        return a[field] === true && b[field] === true ? 0 : a[field] === true ? -1 : b[field] === true ? 1 : 0;
      }
      else {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }
}
