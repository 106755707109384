import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
    @Output() onChange = new EventEmitter<boolean>();
    @Input() active = false;
    @Input() label = '';

    constructor(private _cdr: ChangeDetectorRef) {}

    toggle() {
        this._cdr.detectChanges();
        this.active = !this.active;
        this.onChange.emit(this.active);
    }
}
