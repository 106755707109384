import { PaymentSystemEnum } from '../../../shared/enums/payment-system.enum';
import { SubscriptionInterval } from '../../../shared/enums/subscription-interval.enum';

export interface ActiveSubscriptionModelData {
    subscriptionId: string;
    interval: SubscriptionInterval;
    intervalCount: number;
    currency: string;
    startDate: number;
    endDate: number;
    canceledAt: number | null;
    cancellationReason: string | null;
    paymentSystem: PaymentSystemEnum;
}

export interface ActiveSubscriptionModel extends ActiveSubscriptionModelData {}

export class ActiveSubscriptionModel {}
