import { Action } from '@ngrx/store';
import { MainPageMenuPaths } from '../app.component';
import { FightEventPlaylistModel } from '../models/fight-event/fight-event-playlist/fight-event-playlist.model';
import { EventDataBeforePayment } from '../models/ppv-payment/event-data-before-payment/event-data-before-payment';
import { ActiveSubscriptionModel } from '../models/subscription/active-subscription/active-subscription.model';
import { AuthUserModelData } from '../models/users/auth-user/auth-user.model';
import { Language } from '../shared/enums/language.enum';
import { PermissionEnum } from '../shared/enums/permission.enum';
import { AuthData } from '../shared/interfaces/auth-data.interface';
import { SideBarStateModel } from '../shared/models/shared/side-bar-state.model';

export enum ActionTypes {
    SAVE_AUTH_DATA = '[FCA.Auth_Data] Save auth tokens',
    CLEAR_AUTH_DATA = '[FCA.Clear_Tokens] Clear auth tokens',
    SAVE_USER_DATA = '[FCA.User_Data] Save user data',
    CLEAR_USER_DATA = '[FCA.Clear_User_Data] Clear user data',
    CHANGE_LANGUAGE = '[FCA.Change_language] Change language',
    CHANGE_VOLUME = '[FCA.Change_language] Change volume',
    SET_CURRENT_FIGHT_EVENT = '[FCA.Set_Current_Fight_Event] Set current fight event',
    SAVE_MAIN_PAGE_ACTIVE_MENU_ITEM = '[FCA.Save_Main_Page_Active_Menu_item] Save main page active menu item',
    SAVE_SUBSCRIPTION_DATA = '[FCA.Save_Subscription_Data] Save active subscription data',
    SAVE_LAST_EVENT_VIDEO_WATCH_TIME = '[FCA.Save_Last_Event_Video_Watch_Time] Save last event video watch time',
    SAVE_ACTIVE_ITEM_FROM_PLAYLIST = '[FCA.Save_Active_Item_From_Playlist] Save active item on playlist click',
    SAVE_EVENT_DATA_BEFORE_PAYMENT = '[FCA.Save_Event_Data_Before_Payment] Save event data before payment',
    SAVE_SPARTA_COINS_BALANCE = '[FCA.Save_Sparta_Coins_Balance] Save Sparta Coins Balance',
    SAVE_USER_PERMISSIONS = '[FCA.Save_user_permissions] Save User Permissions',
    SAVE_SIDE_BAR_STATE = '[FCA.Save_Side_Bar_State] Save Side Bar State',
    SAVE_FCM_TOKEN = '[FCA.FCM] Save token',
}

export class SaveAuthDataAction implements Action {
    readonly type = ActionTypes.SAVE_AUTH_DATA;

    constructor(public payload: { data: AuthData }) {}
}

export class SaveUserDataAction implements Action {
    readonly type = ActionTypes.SAVE_USER_DATA;

    constructor(public payload: { data: AuthUserModelData }) {}
}

export class ClearAuthDataAction implements Action {
    readonly type = ActionTypes.CLEAR_AUTH_DATA;
}

export class ClearUserDataAction implements Action {
    readonly type = ActionTypes.CLEAR_USER_DATA;
}

export class SaveMainPageActiveMenuItem implements Action {
    readonly type = ActionTypes.SAVE_MAIN_PAGE_ACTIVE_MENU_ITEM;

    constructor(public payload: { value: MainPageMenuPaths }) {}
}

export class ChangeLanguageDataAction implements Action {
    readonly type = ActionTypes.CHANGE_LANGUAGE;

    constructor(public payload: { lang: Language }) {}
}

export class ChangeVolumeDataAction implements Action {
  readonly type = ActionTypes.CHANGE_VOLUME;

  constructor(public payload: { volume: number }) {}
}

export class SetCurrentFightEventDataAction implements Action {
    readonly type = ActionTypes.SET_CURRENT_FIGHT_EVENT;

    constructor(public payload: { fightEventId: string | null }) {}
}

export class SaveSubscriptionData implements Action {
    readonly type = ActionTypes.SAVE_SUBSCRIPTION_DATA;

    constructor(public payload: { subscriptionData: ActiveSubscriptionModel | null }) {}
}

export class SaveLastEventVideoWatchTime implements Action {
    readonly type = ActionTypes.SAVE_LAST_EVENT_VIDEO_WATCH_TIME;

    constructor(public payload: { lastEventVideoWatchTime: string | null }) {}
}

export class SaveActiveItemFromPlaylist implements Action {
    readonly type = ActionTypes.SAVE_ACTIVE_ITEM_FROM_PLAYLIST;

    constructor(public payload: { currentItemInPlaylist: FightEventPlaylistModel | null }) {}
}

export class SaveEventDataBeforePayment implements Action {
    readonly type = ActionTypes.SAVE_EVENT_DATA_BEFORE_PAYMENT;

    constructor(public payload: { eventDataBeforePayment: EventDataBeforePayment | null }) {}
}

export class SaveSpartaCoinsBonus implements Action {
    readonly type = ActionTypes.SAVE_SPARTA_COINS_BALANCE;

    constructor(public payload: { spartaCoinsBalance: number | null }) {}
}

export class SaveUserPermissions implements Action {
    readonly type = ActionTypes.SAVE_USER_PERMISSIONS;

    constructor(public payload: { userPermissions: PermissionEnum[] | null }) {}
}

export class SaveSideBarState implements Action {
    readonly type = ActionTypes.SAVE_SIDE_BAR_STATE;

    constructor(public payload: { sidebarState: SideBarStateModel | null }) {
    }
}

export class SetFcmToken implements Action {
  readonly type = ActionTypes.SAVE_FCM_TOKEN;
  constructor(public token: string) {
  }
}

export type Actions =
    | SaveAuthDataAction
    | ClearAuthDataAction
    | ChangeLanguageDataAction
    | ChangeVolumeDataAction
    | SaveMainPageActiveMenuItem
    | SaveUserDataAction
    | ClearUserDataAction
    | SetCurrentFightEventDataAction
    | SaveSubscriptionData
    | SaveLastEventVideoWatchTime
    | SaveEventDataBeforePayment
    | SaveActiveItemFromPlaylist
    | SaveSpartaCoinsBonus
    | SaveUserPermissions
    | SaveSideBarState
    | SetFcmToken;
