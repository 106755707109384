import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { StripeCoinsPaymentCheckoutParams } from '../../api/fca/sparta-coins/params/stripe-coins-payment-checkout.params';
import { YookassaCoinsPaymentCheckoutParams } from '../../api/fca/sparta-coins/params/yookassa-coins-payment-checkout.params';
import { CoinsListItemApiResponse } from '../../api/fca/sparta-coins/response/coins-list-item-api.response';
import { SpartaCoinsApiService } from '../../api/fca/sparta-coins/sparta-coins-api.service';
import { CoinsListItemFactory } from '../models/sparta-coins/coins-list-item/coins-list-item.factory';
import { CoinsListItemMapper } from '../models/sparta-coins/coins-list-item/coins-list-item.mapper';
import {
    CoinsListItemModel,
    CoinsListItemModelData,
} from '../models/sparta-coins/coins-list-item/coins-list-item.model';
import { AuthData } from '../shared/interfaces/auth-data.interface';
import { untilDestroy } from '../shared/operators/until-destroy.operator';
import { SaveSpartaCoinsBonus } from '../store/actions';
import { FcaStoreService } from '../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class SpartaCoinsService {
    coinList: CoinsListItemModelData[] = [];
    coinList$: BehaviorSubject<CoinsListItemModelData[]> = new BehaviorSubject<CoinsListItemModelData[]>([]);

    constructor(private spartaCoinsApiService: SpartaCoinsApiService, private storeService: FcaStoreService) {}

    getBalance() {
        return this.spartaCoinsApiService.loadBalance().pipe(shareReplay(1));
    }
    getHistory() {
        return this.spartaCoinsApiService.loadHistory();
    }

    updateBalance() {
        this.storeService
            .getAuthData()
            .pipe(
                take(1),
                switchMap((authData: AuthData | null) => {
                    if (authData) {
                        return this.getBalance();
                    } else {
                        return of(null);
                    }
                }),
                tap((response: number | null) => {
                    this.storeService.dispatch(new SaveSpartaCoinsBonus({ spartaCoinsBalance: response }));
                })
            )
            .subscribe();
    }

    purchase(eventId: string): Observable<void> {
        return this.spartaCoinsApiService.purchase(eventId);
    }
    getCoinsList(): Observable<CoinsListItemModelData[]> {
        return this.spartaCoinsApiService.loadCoinsList().pipe(
            map((raw: CoinsListItemApiResponse[]) => {
                return raw
                    .map((data: CoinsListItemApiResponse) => {
                        return new CoinsListItemFactory().getModelFromData(new CoinsListItemMapper().mapData(data));
                    })
                    .sort((a: CoinsListItemModel, b: CoinsListItemModel) => a.count - b.count);
            }),
            tap((response: CoinsListItemModelData[]) => {
                this.coinList = response;
                this.coinList$.next(this.coinList);
            })
        );
    }
    stripeCoinsPaymentCheckout(body: StripeCoinsPaymentCheckoutParams): Observable<string> {
        return this.spartaCoinsApiService.stripeCoinsPaymentCheckout(body);
    }
    yookassaCoinsPaymentCheckout(body: YookassaCoinsPaymentCheckoutParams): Observable<string> {
        return this.spartaCoinsApiService.yookassaCoinsPaymentCheckout(body);
    }
    getCoinListFromMemory() {
        return defer(() => (this.coinList?.length ? this.coinList$.asObservable() : this.getCoinsList()));
    }
}
