<div
  class='localization flex flex-column align-items-end'
  clickOutside
  (clickOutside)='isOpen = false'
>
  <div class='localization-wrapper localization-wrapper-bg' (click)='isOpen = !isOpen'>
    <div class='flag-wrapper'>
      <img [src]='currentLang.icnPath' alt='language flag'>
    </div>
  </div>
  <ul
    class='drop-down localization-wrapper'
    [class.open]='isOpen'
  >
    <ng-container *ngFor='let lang of langList'>
      <li class='drop-down-item' (click)='onSwitchLanguage(lang)'>
        <p>
          {{lang.text}}
        </p>
        <div class='flag-wrapper'>
          <img [src]='lang.icnPath' alt='language flag'>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
