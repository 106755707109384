<ng-container *transloco="let t">
    <ng-container *ngIf="!loading">
        <div
            class="kicks-item"
            [class.disabled]="disabled"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
            (click)="clickItem(id)"
        >
            <div class="rectangle">
                <div
                    class="thumbnail"
                    [style.backgroundImage]="
                        getThumbnailHref | backgroundImageUrl : '/assets/images/past-event-no-banner.png'
                    "
                ></div>
                <div class="preview-video">
                    <video
                        *ngIf="isHover1"
                        preload="auto"
                        width="100%"
                        height="100%"
                        #videoPlayer
                        [loop]="true"
                        [autoplay]="true"
                        [controls]="false"
                        [muted]="true"
                    >
                        <source [src]="getUrl()" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="three-dots-wrapper" *ngIf="threeDotsMenuList.length">
                <app-three-dots-menu
                    (onSelect)="onThreeDotsSelectHandler($event)"
                    [threeDotsMenuList]="threeDotsMenuList"
                    [menuPosition]="'right'"
                >
                </app-three-dots-menu>
            </div>
            <div class="additionalInfo item" *ngIf="additionalInfo">{{ t(additionalInfo) }}</div>
        </div>
        <div class="kicks-data">
            <p class="description">{{ description }}</p>
            <p class="views">
                {{ views || 0 | reductionOfNumbers }}
                <ng-container *ngIf="translate.getActiveLang() !== 'ru' else viewsRuVersion">
                  {{ t('shared-views-label') }}
                </ng-container>
                <ng-template #viewsRuVersion>
                  {{t(getViewsRuVersion(views))}}
                </ng-template>
            </p>
        </div>
    </ng-container>
</ng-container>
<div class="kicks-item" *ngIf="loading">
    <div class="rectangle">
        <div class="thumbnail">
            <ngx-skeleton-loader
                [theme]="{
                    width: '100%',
                    height: '100%',
                    margin: '0px',
                    borderRadius: '0'
                }"
            ></ngx-skeleton-loader>
        </div>
        <p class="views views-skeleton">
            <ngx-skeleton-loader
                [theme]="{
                    width: '41px',
                    height: '27px',
                    margin: '0px'
                }"
            ></ngx-skeleton-loader>
        </p>
    </div>
</div>
