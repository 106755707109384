import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    map,
    Observable,
    scan,
    switchMap,
    take,
    takeWhile,
    tap,
    throwError,
} from 'rxjs';
import { ProfilePublicListParams } from '../../../../../api/fca/profiles-public/params/profile-public-list.params';
import { ProfilePublicListItemResponse } from '../../../../../api/fca/profiles-public/response/profile-public-list-item.response';
import { FcaAuthService } from '../../../../services/fca-auth.service';
import { FollowersService } from '../../../../services/followers.service';
import { ProfilesPublicService } from '../../../../services/profiles-public.service';
import { FcaStoreService } from '../../../../store/store.service';
import { AuthData } from '../../../interfaces/auth-data.interface';
import { CreatorsListModel } from '../../../models/shared/creators-list.model';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-creators-list',
    templateUrl: './creators-list.component.html',
    styleUrls: ['./creators-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatorsListComponent {
    usersList$ = new Observable<CreatorsListModel[]>();
    userListParams = new ProfilePublicListParams();
    userListObserver$ = new BehaviorSubject<ProfilePublicListParams>(this.userListParams);
    loading = false;
    creatorsList: CreatorsListModel[] = [];

    constructor(
        private _cdr: ChangeDetectorRef,
        private followersService: FollowersService,
        private profilesPublicService: ProfilesPublicService,
        public dialogRef: MatDialogRef<CreatorsListComponent>,
        public storeService: FcaStoreService,
        public _router: Router,
        public authService: FcaAuthService
    ) {
        this.initListData();
    }

    initListData() {
        this.userListParams.type = ['KICKS', 'VIDEOS'];
        this.userListObserver$
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.loading = true;
                    this._cdr.detectChanges();
                }),
                switchMap((params: ProfilePublicListParams) => {
                    return this.profilesPublicService.getProfilePublicList(params);
                }),
                untilDestroy(this),
                map((list: ProfilePublicListItemResponse[]) => {
                    return list.map((item: ProfilePublicListItemResponse) => {
                        return {
                            avatar: item.avatar,
                            fullName: `${item.firstName} ${item.lastName}`,
                            nickName: item.nickname,
                            isFollowing: item.isFollowed,
                            followersCount: item.followers,
                            thumbnailAvatar: item.thumbnailAvatar,
                            id: item.id,
                        } as CreatorsListModel;
                    });
                }),
                tap((response: any) => {
                    this.loading = false;
                    if (this.userListParams.page === 0) {
                        this.creatorsList = response;
                    } else {
                        this.creatorsList.push(...response);
                    }
                    this._cdr.detectChanges();
                }),
                catchError((err: HttpErrorResponse) => {
                    this.loading = false;
                    return throwError(err);
                })
            )
            .subscribe();
    }

    searchFilter() {
        this.userListParams.page = 0;
        this.userListObserver$.next(this.userListParams);
    }

    followUnfollowUser(user: CreatorsListModel) {
        this.storeService
            .getAuthData()
            .pipe(
                takeWhile((authData: AuthData | null) => {
                    if (authData) {
                        return true;
                    } else {
                        this.dialogRef.close();
                        this.authService.logout(this._router.url);
                        return false;
                    }
                }),
                switchMap(() => {
                    if (user.isFollowing) {
                        return this.followersService.unfollowUser(+user.id);
                    } else {
                        return this.followersService.followUser(+user.id);
                    }
                }),
                take(1),
                untilDestroy(this),
                tap((response: any) => {
                    user.isFollowing = !user.isFollowing;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    loadMore() {
        this.userListParams.page++;
        this.userListObserver$.next(this.userListParams);
    }
}
