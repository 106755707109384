import { FightEventVideoApiResponse } from '../../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { EFightEventVideoStatus, FightEventVideoModelData } from './fight-event-video.model';

export class FightEventVideoMapper implements ModelDataMapper<FightEventVideoApiResponse, FightEventVideoModelData> {
    mapData(raw: FightEventVideoApiResponse): FightEventVideoModelData {
        return {
            id: raw.id,
            status: raw.status as EFightEventVideoStatus,
            name: raw.name || null,
            streamUrl: raw.streamUrl || null,
            videoUrl: raw.videoUrl || null,
            thumbnail: raw.thumbnail || null,
            languages: raw.languages || [],
        };
    }
}
