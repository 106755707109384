/**
 * Полезные функции для работы с объектами
 */
import {DeepPartial} from "../types/deep-partial.type";

export class ObjectHelper {
    /**
     * Проверяет на пустоту обьект
     *
     * @public
     * @static
     * @param {object} obj
     * @return {boolean}
     */
    public static isEmptyObj<T extends object>(obj: T): boolean {
        return !ObjectHelper.getLength(obj);
    }

    /**
     * Возвращает длину свойств объекта
     *
     * @public
     * @static
     * @param {object} obj
     * @return {number}
     */
    public static getLength<T extends object>(obj: T): number {
        return Object.keys(obj || {}).length;
    }

    /**
     * Проверяет равенство двух объектов
     *
     * @param {T} objA
     * @param {T} objB
     * @returns {boolean}
     */
    public static simpleCompare<T>(objA: T, objB: T): boolean {
        return JSON.stringify(objA) === JSON.stringify(objB);
    }

    /**
     * Удаляет пустые ключи из объекта
     *
     * @param {object} object
     * @returns {T}
     */
    public static removeEmptyKeys<T>(object: any): T {
        return Object.keys(object).reduce(
            (acc, key) => (object[key] === null || object[key] === undefined ? acc : { ...acc, [key]: object[key] }),
            {}
        ) as T;
    }

    /**
     *
     * @param {DeepPartial<T>[]} sources
     * @param {(source: (object | []), target: (object | [])) => (object | [])} customizer
     * @returns {T}
     */
    public static deepMerge<T>(
        sources: DeepPartial<T>[],
        customizer?: (source: object | [], target: object | []) => object | []
    ): T {
        const defaultMerge = (source: [] | object, target: [] | object): [] | object => {
            if (Array.isArray(source) && Array.isArray(target)) {
                return [...source, ...target];
            } else {
                return { ...source, ...target };
            }
        };
        const merge = customizer || defaultMerge;
        let acc = {} as any;
        for (const source of sources) {
            if (source instanceof Array) {
                if (!(acc instanceof Array)) {
                    acc = [];
                }
                acc = merge(acc, source);
            } else if (source instanceof Object) {
                for (let [key, value] of Object.entries(source)) {
                    if (value instanceof Object && key in acc) {
                        value = ObjectHelper.deepMerge([acc[key], value], merge);
                    }
                    acc = merge(acc, { [key]: value });
                }
            }
        }
        return acc;
    }
}
