import { Component, Inject, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { ChangeLanguageDataAction } from '../../../../store/actions';
import { FcaStoreService } from '../../../../store/store.service';
import { Language } from '../../../enums/language.enum';
import { UntilDestroy, untilDestroy } from '../../../operators/until-destroy.operator';
import { WINDOW } from '../../../tokens/window.token';

interface SelectItem {
    text: string;
    icnPath: string;
    value: Language;
}

@UntilDestroy()
@Component({
    selector: 'app-localization',
    templateUrl: './localization.component.html',
    styleUrls: ['./localization.component.scss'],
})
export class LocalizationComponent {
    langList: SelectItem[] = [
        { text: 'Portuguese', icnPath: '/assets/images/br-flag.svg', value: Language.PT },
        { text: 'English', icnPath: '/assets/images/en-flag.svg', value: Language.EN },
        { text: 'Russian', icnPath: '/assets/images/ru-flag.svg', value: Language.RU },
    ];
    currentLang: SelectItem;
    isOpen = false;

    constructor(
        @Inject(WINDOW) private window: Window,
        private readonly store: FcaStoreService,
        private readonly storeService: FcaStoreService
    ) {
        this.currentLang = this.langList[0];
        storeService
            .getLanguage()
            .pipe(
                tap((lang: Language) => {
                    this.currentLang =
                        this.langList.find((item: SelectItem) => item.value === lang) || this.langList[0];
                }),
                untilDestroy(this)
            )
            .subscribe();
    }

    onSwitchLanguage(event: SelectItem): void {
        this.currentLang = event;
        this.isOpen = !this.isOpen;
        this.store.dispatch(new ChangeLanguageDataAction({ lang: this.currentLang.value }));
        this.window?.location.reload();
    }
}
