import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FcaApiServiceBase } from '../../fca-api-service-base.service';
import { GetPermissionListParams } from './params/get-permission-list.params';
import { PermissionListItemApiResponse } from './responses/permission-list-item-api.response';

@Injectable({
    providedIn: 'root',
})
export class PermissionsUserApiService extends FcaApiServiceBase {
    loadPermissionList(body: GetPermissionListParams): Observable<PermissionListItemApiResponse[]> {
        const url = [this.apiUrl, 'permissions/user/list'].join('/');
        return this.http
            .post<PermissionListItemApiResponse[]>(url, body, { headers: this.defaultHeaders })
            .pipe(shareReplay(1));
    }

    requestContentCreator(description: string): Observable<void> {
        const url = [this.apiUrl, 'permissions/user/request-content-creator'].join('/');
        return this.http
            .post<void>(url, { description }, { headers: this.defaultHeaders })
            .pipe(shareReplay(1));
    }
}
