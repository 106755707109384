<div class="buy-popup" *transloco="let t">
    <img class="main-coin-img" src="assets/images/coins-current-balance-image.png" alt="" />
    <div class="buy-popup-wrapper" *ngIf="(balance$ | async)?.toString() as userBalance">
        <button class="close-popup-btn center" (click)="dialogRef.close()">
            <img src="assets/images/close-popup.svg" alt="" />
        </button>
        <div class="buy-popup-inner">
            <div class="main-content">
                <p
                    class="main-text"
                    [innerHTML]="
                        t('buy-event-with-coin-popup-open-event-with-price') | translateParams : { price: price }
                    "
                >
                </p>
                <div class="balance align-items-center">
                    {{ t('buy-event-with-coin-popup-balance') }}
                    <span>{{ userBalance }}</span>
                    <img src="assets/images/coin-icon_gold.svg" alt="" />
                </div>
                <button
                    (click)="payForEvent()"
                    class="buy-action-btn align-items-center"
                    *ngIf="userBalance >= price; else buyCoinsTemplate"
                >
                    {{ t('buy-event-with-coin-popup-open-event') }}
                    {{ price }}
                    <img src="assets/images/coin-icon.svg" alt="" />
                </button>
                <ng-template #buyCoinsTemplate>
                    <button (click)='goToCoinsList()' class="buy-coins-btn flex align-items-center">
                        {{ t('buy-event-with-coin-popup-buy-coins') }}
                        <span>{{ price - +userBalance }}</span>
                        <img src="assets/images/coin-icon.svg" alt="" />
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
