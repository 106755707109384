import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NewCommentParams } from '../../../../api/fca/bloods-video/params/new-comment.params';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { FcaStoreService } from '../../../store/store.service';

@Component({
    selector: 'app-new-comment',
    templateUrl: './new-comment.component.html',
    styleUrls: ['./new-comment.component.scss'],
})
export class NewCommentComponent {
    @Output() onSend = new EventEmitter<NewCommentParams>();
    user$: Observable<AuthUserModel | null> = this.storeService.getUser();

    constructor(private readonly storeService: FcaStoreService) {}

    onSendHandler(text: string) {
        this.onSend.emit({ text });
    }
}
