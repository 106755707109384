import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FcaStoreService } from '../../../../store/store.service';

@Component({
    selector: 'app-user-balance',
    templateUrl: './user-balance.component.html',
    styleUrls: ['./user-balance.component.scss'],
})
export class UserBalanceComponent implements OnInit {
    balance = new Observable<number | null>();

    constructor(private storeService: FcaStoreService) {
        this.balance = this.storeService.getSpartaCoinsBalance();
    }

    ngOnInit(): void {}
}
