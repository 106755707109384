export enum EWeightCategory {
    STRAW_WEIGHT = 'STRAWWEIGHT',
    BANTAM_WEIGHT = 'BANTAMWEIGHT',
    FLY_WEIGHT = 'FLYWEIGHT',
    FEATHER_WEIGHT = 'FEATHERWEIGHT',
    LIGHT_WEIGHT = 'LIGHTWEIGHT',
    WELTER_WEIGHT = 'WELTERWEIGHT',
    MIDDLE_WEIGHT = 'MIDDLEWEIGHT',
    LIGHT_HEAVY_WEIGHT = 'LIGHTHEAVYWEIGHT',
    HEAVY_WEIGHT = 'HEAVYWEIGHT',
}
