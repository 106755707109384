import { ɵComponentType as ComponentType, ɵDirectiveType as DirectiveType } from '@angular/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Отписывает от потока, при дестрое компонента component
 *
 * Используем в потоке в виде оператора.
 * Во время вызова ngOnDestroy, произойдет отписка от потока service.data$
 *
 * Ivy on
 *
 * @UntilDestroy()
 * @Component()
 * class SomeComponent {
 *      private data$: Observable<any>;
 *
 *     constructor (service: Service) {
 *          this.data$ = service.data$.pipe(
 *              untilDestroy(this)
 *          )
 *     }
 *
 * }
 *
 * Ivy off
 *
 * @UntilDestroy()
 * @Component()
 * class SomeComponent implements OnDestroy {
 *      private data$: Observable<any>;
 *
 *     constructor (service: Service) {
 *          this.data$ = service.data$.pipe(
 *              untilDestroy(this)
 *          )
 *     }
 *
 *     ngOnDestroy() {
 *
 *     }
 *
 * }
 */

/**
 * Имя переменной для хранения сабджекта
 *
 * @type {typeof DESTROY}
 */
const DESTROY: unique symbol = Symbol('__destroy');
/**
 * Для дев-мода чтоб срезать при продакшн бандле (работает благодаря angular-cli)
 */
declare const ngDevMode: boolean;

/**
 * Для обозначения что класс уже декорирован
 *
 * @type {typeof DECORATOR_APPLIED}
 */
const DECORATOR_APPLIED: unique symbol = Symbol('__decoratorApplied');

/**
 * Возвращает имя переменной куда мы будет присваивать сбаджект
 *
 * @returns {symbol}
 */
export function getSymbol(): symbol {
    return DESTROY;
}

/**
 * Оператора для автоматической отписки
 *
 * @param instance
 * @returns {OperatorFunction<T, T>}
 */
export function untilDestroy<T>(instance: any): OperatorFunction<T, T> {
    return <U>(source: Observable<U>) => {
        const symbol = getSymbol();

        // If `destroyMethodName` is passed then the developer applies
        // this operator to something non-related to Angular DI system
        if (ngDevMode) {
            ensureClassIsDecorated(instance);
        }
        createSubjectOnTheInstance(instance, symbol);

        return source.pipe(takeUntil<U>(instance[symbol]));
    };
}

/**
 * Декорарирует класс для корректной работы оператора untilDestroy
 *
 * @returns {ClassDecorator}
 * @constructor
 */
export function UntilDestroy(): ClassDecorator {
    return (type: any) => {
        decorateDirectiveOrComponent(type);
        markAsDecorated(type);
    };
}

/**
 * Обновляет хук ОнДестрой нужным функционалом
 *
 * @param {(() => void) | null | undefined} ngOnDestroy
 * @returns {(this:T) => void}
 */
function decorateNgOnDestroy<T>(ngOnDestroy: (() => void) | null | undefined): (this: T) => void {
    return function (this: T) {
        // Invoke the original `ngOnDestroy` if it exists
        if (ngOnDestroy) {
            ngOnDestroy.call(this);
        }
        // It's important to use `this` instead of caching instance
        // that may lead to memory leaks
        completeSubjectOnTheInstance<T>(this, getSymbol());
    };
}

/**
 * Оборачивает хук ОнДестрой компонент или директиву
 * @param {DirectiveType<T> | ComponentType<T>} type
 */
function decorateDirectiveOrComponent<T>(type: DirectiveType<T> | ComponentType<T>): void {
    type.prototype.ngOnDestroy = decorateNgOnDestroy<T>(type.prototype.ngOnDestroy);
}

function markAsDecorated<T>(type: DirectiveType<T> | ComponentType<T>): void {
    // Store this property on the prototype if component or directive.
    // We will be able to handle class extension this way.
    type.prototype[DECORATOR_APPLIED] = true;
}

/**
 * Создает сабджет в инстансе
 *
 * @param {T} instance
 * @param {symbol} symbol
 */
function createSubjectOnTheInstance<T>(instance: T, symbol: symbol): void {
    // @ts-ignore
    if (!instance[symbol]) {
        // @ts-ignore
        instance[symbol] = new Subject<void>();
    }
}

/**
 * Комплитит сабджект в инстансе если он существует
 *
 * @param {T} instance
 * @param {symbol} symbol
 */
function completeSubjectOnTheInstance<T>(instance: T, symbol: symbol): void {
    // @ts-ignore
    if (instance[symbol]) {
        // @ts-ignore
        instance[symbol].next();
        // @ts-ignore
        instance[symbol].complete();
        /* нужно для чтоб потом могли пересоздать на том же инстансе*/
        // @ts-ignore
        instance[symbol] = null;
    }
}

/**
 * Проверяет обернут ли класс в декоратор
 *
 * @param {InstanceType<any>} instance
 */
function ensureClassIsDecorated(instance: InstanceType<any>): never | void {
    const prototype = Object.getPrototypeOf(instance);
    const missingDecorator = !(DECORATOR_APPLIED in prototype);

    if (missingDecorator) {
        throw new Error(
            'untilDestroy operator cannot be used inside directives or ' +
                'components that are not decorated with UntilDestroy decorator'
        );
    }
}
