import { GeneralAddressModel } from '../../../shared/models/general-address/general-address.model';
import { ImageModel } from '../../../shared/models/image/image.model';
import { FightEventModel } from '../fight-event.model';

export interface FightEventLocationModelData {
    id: number;
    name: string;
    address?: GeneralAddressModel;
    link: string;
    description: string;
    isPending: boolean;
    timeZone: string;
    timeZoneOffset: number;
    images?: ImageModel[];
    fightEvents: FightEventModel[];
}

export interface FightEventLocationModel extends FightEventLocationModelData {}

export class FightEventLocationModel {
    get preview(): string {
        return [this.address?.country, this.address?.city, this.name].filter(Boolean).join(', ');
    }
}
