<footer class="footer">
    <div class="footer-wrapper" *transloco="let t">
        <div class="footer-content">
          <ul class="page-nav">
            <li class="page-nav-item" *ngFor="let page of pageList">
              <a
                [href]="page.url"
                [target]="page?.openNewTab ? '_blank' : '_self'"
                class="page-nav-link"
                [class.disabled]="!page.url"
              >
                {{ t(page.text) }}
              </a>
            </li>
          </ul>
            <ul class="page-nav-out">
                <li class="page-nav-out-item web" *ngFor="let page of pageOutList">
                    <a href="" [routerLink]="page.url" class="page-nav-out-link" [class.disabled]="page.disabled">{{
                        t(page.text)
                    }}</a>
                </li>
            </ul>
          <ul class="sm-list flex">
            <li class="sm-list-item" *ngFor="let sm of smList">
              <a [href]="sm.url" target="_blank" class="sm-list-link center">
                <img [src]="'assets/images/' + sm.imgName" [alt]="sm.alt" />
              </a>
            </li>
          </ul>
        </div>
    </div>
</footer>
