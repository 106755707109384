<div class='mobile-view center' *transloco='let t'>
  <div class='lang-wrapper'>
    <app-localization></app-localization>
  </div>
  <div>
    <div class='achievement center'>
      <img src='assets/images/gold-helmet.png' alt='gold helmet'>
    </div>
    <p class='title' [innerHTML]="t('mobile-view-title')"></p>
    <div class='action-btn-list'>
      <a
        href='https://apps.apple.com/ru/app/spartacus-mma/id1589781592'
        class='auth-footer-app-link'
        rel='noreferrer'
      >
        <img src='assets/images/app-store_white_no-qr.svg' alt='Spartacus MMA in AppStore' />
      </a>
      <a
        href='https://play.google.com/store/apps/details?id=com.spartacus.mma'
        class='auth-footer-app-link'
        rel='noreferrer'
      >
        <img src='assets/images/google-play_white_no-qr.svg' alt='Spartacus MMA on GooglePlay' />
      </a>
    </div>
  </div>

</div>
