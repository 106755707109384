<div class="w-100 content success-payment-popup" *transloco="let t" [class.ppv]="data?.type === 'ppv'">
    <div class="achievement center">
        <img *ngIf="data?.type !== 'ppv'" src="assets/images/gold-helmet.png" alt="" />
        <img *ngIf="data?.type === 'ppv'" src="assets/images/blue-helmet.png" alt="" />
    </div>
    <p class="tag">
        {{ t(data?.tag || 'subscription-plan-premium') }}
    </p>
    <p class="title" [innerHTML]="t(data?.title || 'subscription-success-payment-title')"></p>
    <p class="subtitle">{{ t(data?.subtitle || 'subscription-success-payment-subtitle') }}</p>
    <button (click)="close()" class="submit-btn">
        {{ t(data?.actionBtn || 'subscription-success-payment-action-btn') }}
    </button>
</div>
<p></p>
