<div class="background-uploader" *transloco="let t">
  <label
    for="background-uploader"
    [matTooltip]="t('edit-profile-upload-bg-tooltip')"
    class="background-uploader-block"
    [ngStyle]="cardImageBase64?.length ? { 'background-image': (cardImageBase64 | backgroundImageUrl : '') } : {}"
  >
    <input
      type="file"
      id="background-uploader"
      style="display: none"
      #fileInput
      (input)="onPhotoSelected($event)"
      accept="image/jpeg, image/jpg, image/png, image/webp"
    />
    <span *ngIf="!cardImageBase64" class="background-uploader-button background-uploader-button-empty center">
            <span class="note">
                {{ t('edit-profile-upload-bg-label') }}
            </span>
        </span>
    <span
      *ngIf="cardImageBase64"
      class="background-uploader-button background-uploader-button-filled"
      (click)="removeImage()"
    >
        </span>
  </label>
</div>
