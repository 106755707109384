import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AppMainConfigService } from '../../../app/config/app-main-config.service';
import { ReactionType } from '../fight-event/response/fight-event-api.response';

export enum EChatEvent {
    ROOM_INIT_EVENT = 'room_init',
    USER_DISCONNECT_EVENT = 'user_disconnected',
    USER_CONNECT_EVENT = 'user_connected',
    CONNECT_EVENT = 'connected',
    USER_BLOCK_EVENT = 'user_blocked_message',
    DONATE_MESSAGE_EVENT = 'donated_message',
    SEND_MESSAGE_EVENT = 'send_message',
    EDIT_MESSAGE_EVENT = 'edited_message',
    DELETE_MESSAGE_EVENT = 'deleted_message',
    TIME_SLOT_CHANGE_EVENT = 'timeslot_changed_message',
    USER_UNBLOCK_EVENT = 'user_unblocked_message',
    NEW_REACTION = 'user_reaction',
    REACTIONS_EVENT = 'reactions',
}

@Injectable()
export class ChatService {
    private socket: Socket;

    constructor(private readonly appConfig: AppMainConfigService) {}

    onConnect(roomId: string, token: string): void {
        this.socket = io(this.appConfig.getConfig().chatUrl, {
            auth: { token },
            query: { roomId },
        });
    }

    disconnect(): void {
        this.socket.disconnect();
    }

    onProcessMessage<D>(event: EChatEvent): Observable<D> {
        const subj = new Subject<D>();

        this.socket.on(event, (message) => {
            subj.next(message);
        });

        return subj.asObservable();
    }

    newMessage(params: { content: string; userId: number; roomId: string }) {
        const args = {
            content: params.content,
            userId: params.userId,
            roomId: params.roomId,
            status: 'TEXT',
        };
        this.socket.emit('new_message', args);
    }

    editMessage(content: any, messageId: any) {
        this.socket.emit('edit_message', { messageId, content });
    }

    addReaction(userId: number, reaction: ReactionType) {
        this.socket.emit(EChatEvent.NEW_REACTION, { userId, reaction });
    }
}
