import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mobile-view',
    templateUrl: './mobile-view.component.html',
    styleUrls: ['./mobile-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileViewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
