import * as moment from 'moment-timezone';
import {
    FightEventApiResponse,
    FightEventPriceApiResponse,
    FightEventVideoApiResponse,
} from '../../../api/fca/fight-event/response/fight-event-api.response';
import { ModelDataMapper } from '../../shared/interfaces/model-mapper.interface';
import { ImageFactory } from '../../shared/models/image/image.factory';
import { ImageMapper } from '../../shared/models/image/image.mapper';
import { ImageModel } from '../../shared/models/image/image.model';
import { FightEventAdditionalFactory } from './fight-event-additional/fight-event-additional.factory';
import { FightEventAdditionalMapper } from './fight-event-additional/fight-event-additional.mapper';
import { FightEventConferenceFactory } from './fight-event-conference/fight-event-conference.factory';
import { FightEventConferenceMapper } from './fight-event-conference/fight-event-conference.mapper';
import { FightEventFightSlotFactory } from './fight-event-fight-slot/fight-event-fight-slot.factory';
import { FightEventFightSlotMapper } from './fight-event-fight-slot/fight-event-fight-slot.mapper';
import { FightEventFightSlotModel } from './fight-event-fight-slot/fight-event-fight-slot.model';
import { FightEventLocationFactory } from './fight-event-location/fight-event-location.factory';
import { FightEventLocationMapper } from './fight-event-location/fight-event-location.mapper';
import { FightEventPriceFactory } from './fight-event-price/fight-event-price.factory';
import { FightEventPriceMapper } from './fight-event-price/fight-event-price.mapper';
import { FightEventPriceModel } from './fight-event-price/fight-event-price.model';
import { FightEventVideoFactory } from './fight-event-video/fight-event-video.factory';
import { FightEventVideoMapper } from './fight-event-video/fight-event-video.mapper';
import { FightEventWeightingFactory } from './fight-event-weighting/fight-event-weighting.factory';
import { FightEventWeightingMapper } from './fight-event-weighting/fight-event-weighting.mapper';
import { EFightEventReactionType, EFightEventStatus, FightEventModelData } from './fight-event.model';

export class FightEventMapper implements ModelDataMapper<FightEventApiResponse, FightEventModelData> {
    mapData(raw: FightEventApiResponse): FightEventModelData {
        return {
            id: raw.id,
            name: raw.name,
            video: new FightEventVideoFactory().getModelFromData(new FightEventVideoMapper().mapData(raw.video)),
            fromDate: moment(raw.fromDate),
            toDate: moment(raw.toDate),
            hidden: raw.hidden,
            geoipBlock: raw.geoipBlock,
            status: raw.status as EFightEventStatus,
            honorarium: raw.honorarium,
            stripeProductId: raw.stripeProductId,
            additional: new FightEventAdditionalFactory().getModelFromData(
                new FightEventAdditionalMapper().mapData(raw.additional)
            ),
            fightSlots: (raw.fightSlots || []).reduce<FightEventFightSlotModel[]>((acc, rawFightSlot) => {
                acc.push(
                    new FightEventFightSlotFactory().getModelFromData(
                        new FightEventFightSlotMapper().mapData(rawFightSlot)
                    )
                );
                return acc;
            }, []),
            images: (raw.images || []).reduce<ImageModel[]>((acc, rawImg) => {
                acc.push(new ImageFactory().getModelFromData(new ImageMapper().mapData(rawImg)));
                return acc;
            }, []),
            votingAvailable: raw.votingAvailable,
            reactions: raw.reactions
                ? Object.entries(raw.reactions).map(([type, count]) => {
                      return {
                          type: type as EFightEventReactionType,
                          count,
                      };
                  })
                : Object.values(EFightEventReactionType).map((type) => ({
                      type: type as EFightEventReactionType,
                      count: 0,
                  })),
            location: new FightEventLocationFactory().getModelFromData(
                new FightEventLocationMapper().mapData(raw.location)
            ),
            ppv: raw.ppv,
            isFreeForSubscribers: raw.isFreeForSubscribers,
            isUserPay4PPV: raw.isUserPay4PPV,
            weighting: raw.weighting
                ? new FightEventWeightingFactory().getModelFromData(
                      new FightEventWeightingMapper().mapData(raw.weighting)
                  )
                : null,
            conference: raw.conference
                ? new FightEventConferenceFactory().getModelFromData(
                      new FightEventConferenceMapper().mapData(raw.conference)
                  )
                : null,
            prices: (raw.prices || []).reduce<FightEventPriceModel[]>(
                (acc: FightEventPriceModel[], item: FightEventPriceApiResponse) => {
                    acc.push(new FightEventPriceFactory().getModelFromData(new FightEventPriceMapper().mapData(item)));
                    return acc;
                },
                []
            ),
        };
    }
}
