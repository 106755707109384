import * as moment from 'moment-timezone';
import { EUserRole } from '../../../shared/enums/user-role.enum';

export interface FightEventCommentModelData {
    id: string;
    text: string;
    parentId: string | null;
    user: {
        id: number;
        logo: string | null;
        role: EUserRole;
        firstName: string;
        lastName: string;
        nickName: string | null;
    };
    children: FightEventCommentModel[];
    created: moment.Moment;
    edited: boolean;
    deleted: boolean;
    userHasLike: boolean;
    userHasDislike: boolean;
    likes: number;
    dislikes: number;
}

export interface FightEventCommentModel extends FightEventCommentModelData {}

export class FightEventCommentModel {}
