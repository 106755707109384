<ng-container *transloco='let t'>
  <form [formGroup]='form' class='register-container'>
    <div class='register-form'>
      <p class='register-title'>{{t('register-personal-data-title')}}</p>
      <p class='register-sub-title'>{{t('register-personal-data-subtitle')}}</p>

      <label class='register-label'>{{t('register-first-name-label')}}</label>
      <input [placeholder]="t('register-first-name-placeholder')" type='text' class='main-input'
             [class.invalid]="form.controls['firstName'].errors && form.controls['firstName'].touched"
             maxlength='15'
             id='firstName'
             name='firstName'
             (input)="inputChange('firstName')"
             formControlName='firstName'
      >
      <ng-container *ngIf="form.get('firstName').invalid && form.get('firstName').touched">
        <p class='error-text' *ngIf="form.controls['firstName'].errors['required']">
          {{t('input-invalid-required')}}
        </p>
        <p class='error-text' *ngIf="form.controls['firstName'].errors['minlength']">
          {{t('input-invalid-min-max-length')}}
        </p>
        <ng-container *ngIf="form.controls['firstName'].errors['pattern']">
          <p class='error-text' *ngIf="changePattern('firstName', latinPattern)">
            {{t('input-invalid-only-latin')}}
          </p>
          <p class='error-text' *ngIf="changePattern('firstName', symbolsPattern)">
            {{t('register-name-surname-symbols-validation-error')}}
          </p>
        </ng-container>
      </ng-container>

      <label class='register-label'>{{t('register-last-name-label')}}</label>
      <input [placeholder]="t('register-last-name-placeholder')" type='text' class='main-input'
             [class.invalid]="form.controls['lastName'].errors && form.controls['lastName'].touched"
             id='lastName'
             name='lastName'
             maxlength='15'
             (input)="inputChange('lastName')"
             formControlName='lastName'
      >
      <ng-container *ngIf="form.get('lastName').invalid && form.get('lastName').touched">
        <p class='error-text' *ngIf="form.controls['lastName'].errors['required']">
          {{t('input-invalid-required')}}
        </p>
        <p class='error-text' *ngIf="form.controls['lastName'].errors['minlength']">
          {{t('input-invalid-min-max-length')}}
        </p>
        <ng-container *ngIf="form.controls['lastName'].errors['pattern']">
          <p class='error-text' *ngIf="changePattern('lastName', latinPattern)">
            {{t('input-invalid-only-latin')}}
          </p>
          <p class='error-text' *ngIf="changePattern('lastName', symbolsPattern)">
            {{t('register-name-surname-symbols-validation-error')}}
          </p>
        </ng-container>
      </ng-container>

      <label class='register-label'>{{t('register-nickname-label')}}</label>
      <input [placeholder]="t('register-nickname-placeholder')" type='text' class='main-input'
             [class.invalid]="errors?.get('nickname') && form.get('nickname').touched"
             id='nickname'
             name='nickname'
             maxlength='20'
             (input)="inputChange('nickname')"
             formControlName='nickname'
      >
      <ng-container *ngIf="form.get('nickname').invalid && form.get('nickname').touched">
        <p class='error-text' *ngIf="form.controls['nickname'].errors['required']">
          {{t('input-invalid-required')}}
        </p>
        <p class='error-text' *ngIf="form.controls['nickname'].errors['minlength']">
          {{t('input-invalid-min-max-length')}}
        </p>
        <p class='error-text' *ngIf="form.controls['nickname'].errors['pattern']">
          {{t('register-nickname-symbols-validation-error')}}
        </p>
      </ng-container>

      <input type='hidden' formControlName='email' />

    </div>
    <div class='register-form avatar-uploader-container'>

      <p class='register-title'>{{t('register-personal-photo-title')}}</p>
      <p class='register-sub-title'>{{t('register-personal-photo-subtitle')}}</p>

      <div class='upload-avatar-block'>
        <label class='register-label'>{{t('register-avatar-photo-label')}}</label>
        <app-avatar-uploader (photoChange)='onChangePhoto($event)'></app-avatar-uploader>
      </div>
    </div>
  </form>

  <button
    class='main-btn'
    [type]='buttonType'
    (click)='onSubmit()'
  >
    {{t('register-continue-btn')}}
  </button>
</ng-container>
