import { CoinsListItemApiResponse } from '../../../../api/fca/sparta-coins/response/coins-list-item-api.response';
import { CoinPaymentSystemEnum } from '../../../shared/enums/coin-payment-system.enum';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { CoinsListItemModelData } from './coins-list-item.model';

export class CoinsListItemMapper implements ModelDataMapper<CoinsListItemApiResponse, CoinsListItemModelData> {
    mapData(raw: CoinsListItemApiResponse): CoinsListItemModelData {
        return {
            id: raw.id,
            name: raw.name,
            count: raw.count,
            stripeProductId: raw.stripeProductId,
            currency: raw.prices[0].currency,
            paymentSystem: raw.prices[0].paymentSystem,
            originalPrice: raw.prices[0].originalPrice,
            priceForDisplay:
                raw.prices[0].paymentSystem === CoinPaymentSystemEnum.STRIPE
                    ? +raw.prices[0].originalPrice / 100
                    : +raw.prices[0].price,
            priceForPayment:
                raw.prices[0].paymentSystem === CoinPaymentSystemEnum.STRIPE
                    ? +raw.prices[0].originalPrice
                    : +raw.prices[0].price,
            priceId: raw.prices[0].paymentSystem === CoinPaymentSystemEnum.STRIPE ? raw.prices[0].price : '',
        };
    }
}
