import { FightApiResponse } from '../../../api/fca/fights/response/fight-api.response';
import { ModelDataMapper } from '../../shared/interfaces/model-mapper.interface';
import { UserFighterFactory } from '../users/user-fighter/user-fighter.factory';
import { UserFighterMapper } from '../users/user-fighter/user-fighter.mapper';
import { EFightStatus, EFightWinner, FightModelData } from './fight.model';

export class FightMapper implements ModelDataMapper<FightApiResponse, FightModelData> {
    mapData(raw: FightApiResponse): FightModelData {
        return {
            id: raw.id,
            status: raw.status as EFightStatus,
            winner: raw.winner as EFightWinner,
            initiator: new UserFighterFactory().getModelFromData(new UserFighterMapper().mapData(raw.initiator)),
            target: new UserFighterFactory().getModelFromData(new UserFighterMapper().mapData(raw.target)),
            fightLink: raw.fightLink || null,
        };
    }
}
