import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';
import { EImageType } from '../../../models/images/user-image.model';
import { PostListModelData } from '../../../models/post/post-list/post-list.model';
import { AuthUserModel } from '../../../models/users/auth-user/auth-user.model';
import { PostService, ThreeDotsItemEnum } from '../../../services/post.service';
import { FcaStoreService } from '../../../store/store.service';
import { PostContentAccessEnum } from '../../enums/post-content-access.enum';
import { ImageModel } from '../../models/image/image.model';
import { CloseModalActionInterface } from '../../models/shared/close-modal-action.interface';
import { OpenPostDataModel } from '../../models/shared/open-post-data.model';
import { ThreeDotsMenuItemModel } from '../../models/shared/three-dots-menu-item.model';
import { UntilDestroy, untilDestroy } from '../../operators/until-destroy.operator';
import { ViewPostItemComponent } from '../view-post-item/view-post-item.component';

@UntilDestroy()
@Component({
    selector: 'app-post-item',
    templateUrl: './post-item.component.html',
    styleUrls: ['./post-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostItemComponent implements OnInit {
    @Input() date = '';
    @Input() name = '';
    @Input() id = '';
    @Input() surname = '';
    @Input() nickName = '';
    @Input() imageList: any[] = [];
    @Input() likeUsers: any[] = [];
    @Input() views = 0;
    @Input() likes = 0;
    @Input() ownerId = 0;
    @Input() ownerBackgroundImg = '';
    @Input() posts = 0;
    @Input() followers = 0;
    @Input() videos = 0;
    @Input() avatar = '';
    @Input() text = '';
    @Input() liked = false;
    @Input() commentsEnabled = false;
    @Input() access: PostContentAccessEnum;
    @Input() subscribed = false;
    @Input() showCreatorData = false;
    @Output() onPostDeleted = new EventEmitter<string>();

    constructor(
        private readonly dialog: MatDialog,
        private postService: PostService,
        private store: FcaStoreService,
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _router: Router
    ) {}

    ngOnInit(): void {
        // if (this.id === '5b99f683-13f2-482a-a1fb-901ff7d6bd99') {
        //     this.openPost();
        // }
    }

    checkTreeDots(postText: HTMLParagraphElement) {
        return postText.offsetWidth < postText.scrollWidth;
    }

    get isPrivate() {
        return this.access === 'subscribers' && !this.subscribed;
    }

    openPost() {
        this.dialog
            .open<ViewPostItemComponent, OpenPostDataModel>(ViewPostItemComponent, {
                backdropClass: 'view-post-popup-backdrop',
                panelClass: 'view-post-popup-panel',
                hasBackdrop: true,
                disableClose: true,
                data: {
                    id: this.id,
                    name: this.name,
                    surname: this.surname,
                    nickName: this.nickName,
                    avatar: this.avatar,
                    text: this.text,
                    images: this.imageList,
                    likeUsers: this.likeUsers,
                    liked: this.liked,
                    commentsEnabled: this.commentsEnabled,
                    likes: this.likes,
                    date: this.date,
                    ownerId: this.ownerId,
                    access: this.access,
                } as OpenPostDataModel,
            })
            .afterClosed()
            .pipe(
                tap((params: { action: 'close' | 'deleted' | 'edit'; data: OpenPostDataModel }) => {
                    if (params.action === 'close') {
                        const { liked, likes } = params.data;
                        this.liked = liked;
                        this.likes = likes;
                        this._cdr.detectChanges();
                    }
                    if (params.action === 'deleted') {
                        this.onPostDeleted.emit(this.id);
                        this._cdr.detectChanges();
                    }
                    if (params.action === 'edit') {
                        this.id = params.data.id;
                        this.name = params.data.name;
                        this.surname = params.data.surname;
                        this.nickName = params.data.nickName;
                        this.avatar = params.data.avatar;
                        this.text = params.data.text;
                        this.imageList = params.data.images;
                        this.likeUsers = params.data.likeUsers;
                        this.liked = params.data.liked;
                        this.commentsEnabled = params.data.commentsEnabled;
                        this.likes = params.data.likes;
                        this.date = params.data.date;
                        this.ownerId = params.data.ownerId;
                        this.access = params.data.access;
                        this._cdr.detectChanges();
                    }
                })
            )
            .subscribe();
    }

    postLikeToggle($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.store
            .getUser()
            .pipe(
                switchMap((user: AuthUserModel | null) => {
                    if (!user) {
                        this._router.navigate(['/auth/login']);
                        this.dialog.closeAll();
                        throw new Error('Unauthorized');
                    } else {
                        return of(user);
                    }
                }),
                switchMap((user: AuthUserModel) => {
                    if (this.liked) {
                        return this.postService.deleteLikeFromPost(this.id).pipe(map(() => user));
                    } else {
                        return this.postService.addLikeToPost(this.id).pipe(map(() => user));
                    }
                }),
                tap((user: AuthUserModel) => {
                    this.liked = !this.liked;
                    if (this.liked) {
                        this.likes++;
                        const item = {
                            firstName: user.firstName,
                            id: user.id,
                            lastName: user.lastName,
                            nickname: user.nickname,
                            thumbnailAvatar:
                                user.images.find((image: ImageModel) => image.imageType === EImageType.AVATAR_THUMBNAIL)
                                    ?.href || user.images[0]?.href,
                        };
                        this.likeUsers.push(item);
                    } else {
                        this.likes--;
                        this.likeUsers = this.likeUsers.filter((item: any) => item.id !== user.id);
                    }
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    getMenuList(): Observable<ThreeDotsMenuItemModel<ThreeDotsItemEnum>[]> {
        return this.postService.getPostThreeDotsMenuList(this.ownerId, this.commentsEnabled);
    }

    deletePost() {
        this.postService
            .openDeletePostModal(this.id)
            .pipe(
                untilDestroy(this),
                take(1),
                tap((resp: CloseModalActionInterface<string> | null) => {})
            )
            .subscribe();
    }

    treeDotsActionHandler($event: ThreeDotsItemEnum) {
        switch ($event) {
            case ThreeDotsItemEnum.DELETE: {
                this.deletePost();
                break;
            }
            case ThreeDotsItemEnum.TURN_OFF_COMMENTS: {
                this.toggleCommentEnabledParams();
                break;
            }
            case ThreeDotsItemEnum.TURN_ON_COMMENTS: {
                this.toggleCommentEnabledParams();
                break;
            }
            case ThreeDotsItemEnum.EDIT: {
                this.openEditPostModal();
                break;
            }
            case ThreeDotsItemEnum.COPY_LINK: {
                this.copyLink();
                break;
            }
        }
    }

    copyLink() {
        const link = `${window.location.origin}/profile/${this.ownerId}?openPostModalId=${this.id}`;
        navigator.clipboard.writeText(link);
    }

    toggleCommentEnabledParams() {
        this.postService
            .togglePostComment(this.id, !this.commentsEnabled)
            .pipe(
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.commentsEnabled = !this.commentsEnabled;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    openEditPostModal() {
        const data: OpenPostDataModel = {
            id: this.id,
            name: this.name,
            surname: this.surname,
            nickName: this.nickName,
            avatar: this.avatar,
            text: this.text,
            images: this.imageList,
            likeUsers: this.likeUsers,
            liked: this.liked,
            commentsEnabled: this.commentsEnabled,
            likes: this.likes,
            date: this.date,
            ownerId: this.ownerId,
            access: this.access,
        };
        this.postService
            .openEditPostModal(data)
            .pipe(
                take(1),
                untilDestroy(this),
                tap((resp: CloseModalActionInterface<PostListModelData>) => {
                    if (resp?.action === 'edit') {
                        this.id = resp.data.id;
                        this.name = resp.data.owner.firstName;
                        this.surname = resp.data.owner.lastName;
                        this.nickName = resp.data.owner.nickname;
                        this.avatar = resp.data.owner.thumbnailAvatar;
                        this.date = resp.data.createdAt;
                        this.access = resp.data.access;
                        this.text = resp.data.text;
                        this.imageList = resp.data.images;
                        this.likeUsers = resp.data.likeUsers;
                        this.liked = resp.data.liked;
                        this.commentsEnabled = resp.data.commentsEnabled;
                        this.likes = resp.data.likes;
                        this.ownerId = resp.data.ownerId;
                        this._cdr.detectChanges();
                    }
                })
            )
            .subscribe();
    }
}
