import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OffsetPagination } from '../../../app/shared/interfaces/offset-pagination.interface';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { AddCommentModel } from './params/add-comment.model';

@Injectable({
    providedIn: 'root',
})
export class PostCommentsApiService extends FcaApiServiceBase {
    getCommentList(paging: OffsetPagination, postId: string) {
        const params = new HttpParams().appendAll({ ...paging });
        const url = [this.postUrl, `comments/${postId}/list`].join('/');
        return this.http.get<any>(url, { params });
    }

    addComment(body: AddCommentModel) {
        const url = [this.postUrl, `comments`].join('/');
        return this.http.post<any>(url, body);
    }
}
