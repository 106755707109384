import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationPopupDataModel } from '../../models/shared/confirmation-popup-data.model';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupDataModel
    ) {
    }
}
