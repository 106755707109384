import produce from 'immer';
import { set } from 'lodash';
import { Actions, ActionTypes } from './actions';
import { FcaState, FCA_INITIAL_STATE } from './state';

export function fcaFeatureReducer(state: FcaState = FCA_INITIAL_STATE, actions: Actions) {
    return produce<(draftState: FcaState, action: Actions) => FcaState>((draftState, action) => {
        switch (action.type) {
            case ActionTypes.SAVE_AUTH_DATA:
                return set(draftState, ['authData'], action.payload.data);
            case ActionTypes.CLEAR_AUTH_DATA:
                return set(draftState, ['authData'], null);
            case ActionTypes.SAVE_USER_DATA:
                return set(draftState, ['user'], action.payload.data);
            case ActionTypes.CLEAR_USER_DATA:
                return set(draftState, ['user'], null);
            case ActionTypes.CHANGE_LANGUAGE:
                return set(draftState, ['settings', 'language'], action.payload.lang);
            case ActionTypes.CHANGE_VOLUME:
                return set(draftState, ['settings', 'videoVolume'], action.payload.volume);
            case ActionTypes.SET_CURRENT_FIGHT_EVENT:
                return set(draftState, ['currentFightEventId'], action.payload.fightEventId);
            case ActionTypes.SAVE_MAIN_PAGE_ACTIVE_MENU_ITEM:
                return set(draftState, ['settings', 'mainPageMenuActiveItem'], action.payload.value);
            case ActionTypes.SAVE_SUBSCRIPTION_DATA:
                return set(draftState, ['subscriptionData'], action.payload.subscriptionData);
            case ActionTypes.SAVE_LAST_EVENT_VIDEO_WATCH_TIME:
                return set(draftState, ['settings', 'lastEventVideoWatchTime'], action.payload.lastEventVideoWatchTime);
            case ActionTypes.SAVE_ACTIVE_ITEM_FROM_PLAYLIST:
                return set(draftState, ['currentItemInPlaylist'], action.payload.currentItemInPlaylist);
            case ActionTypes.SAVE_EVENT_DATA_BEFORE_PAYMENT:
                return set(draftState, ['eventDataBeforePayment'], action.payload.eventDataBeforePayment);
            case ActionTypes.SAVE_SPARTA_COINS_BALANCE:
                return set(draftState, ['spartaCoinsBalance'], action.payload.spartaCoinsBalance);
            case ActionTypes.SAVE_USER_PERMISSIONS:
                return set(draftState, ['userPermissions'], action.payload.userPermissions);
            case ActionTypes.SAVE_SIDE_BAR_STATE:
                return set(draftState, ['sidebarState'], action.payload.sidebarState);
            case ActionTypes.SAVE_FCM_TOKEN:
              return set(draftState, ['fcmToken'], action.token);
        }
    })(state, actions);
}
