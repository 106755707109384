import { ImageModel, ImageModelData } from '../../shared/models/image/image.model';

export enum EImageType {
  THUMBNAIL_AVATAR = 'THUMBNAIL_AVATAR',
  FULL_FACE_AVATAR = 'FULL_FACE_AVATAR',
  HALF_LENGTH_AVATAR = 'HALF_LENGTH_AVATAR',
  ARENA_AVATAR = 'ARENA_AVATAR',
  ARENA_PHOTO = 'ARENA_PHOTO',
  ARENA_PHOTO_BIG = 'ARENA_PHOTO_BIG',
  FAN_AVATAR = 'FAN_AVATAR',
  MANAGER_AVATAR = 'MANAGER_AVATAR',
  BACKGROUND_PHOTO = 'BACKGROUND_PHOTO',
  AVATAR_THUMBNAIL = 'THUMBNAIL_AVATAR',
}

export interface UserImageModelData extends ImageModelData {
    imageType: EImageType;
}

export interface UserImageModel extends UserImageModelData {}

export class UserImageModel extends ImageModel {}
