<div
  class='event-item-wrapper'
  [class.is-for-tablet]='isForTablet'
  *transloco='let t'
>
  <div class='event-item'
       [routerLink]="'/fight-event/' + id"
       [style.background-image]="backgroundImage? 'url(' + (backgroundImage | imagePathWhiteSpace) + ')' : ''"
       (mouseenter)='runTrigger()'
       (mouseleave)='stopTrigger()'
  >
    <div class='event-init-data flex align-items-center'>
      <div class='ppv item flex align-items-center' *ngIf='isPpv'>
        <img width='5px' height='10px' src='assets/images/usd-white.svg' alt=''>
        PPV
      </div>
      <div class='event-date' *ngIf='fromDate'>
        {{fromDate | date : 'hh:mm a MM/dd/yyyy'}}
      </div>
    </div>

    <div class='event-item-hover'>
      <p class='hover-title'>{{title}}</p>
      <p class='hover-start-text'>
        {{t('main-page-upcoming-event-item-start')}}
      </p>
      <div class='hover-timer flex justify-content__center'>
        <div class='time-item'>
          <p class='time-number'>{{dateTimer?.days < 10? '0' + dateTimer?.days : dateTimer?.days}}</p>
          <p class='time-text'>{{t('shared-days')}}</p>
        </div>
        <div class='time-item'>
          <p class='time-number'>{{dateTimer?.hours < 10? '0' + dateTimer?.hours : dateTimer?.hours}}</p>
          <p class='time-text'>{{t('shared-hours')}}</p>
        </div>
        <div class='time-item'>
          <p class='time-number'>{{dateTimer?.minutes < 10? '0' + dateTimer?.minutes : dateTimer?.minutes}}</p>
          <p class='time-text'>{{t('shared-minutes')}}</p>
        </div>
        <div class='time-item'>
          <p class='time-number'>{{dateTimer?.seconds < 10? '0' + dateTimer?.seconds : dateTimer?.seconds}}</p>
          <p class='time-text'>{{t('shared-seconds')}}</p>
        </div>
      </div>
      <p class='hover-start-date'>{{fromDate | date : 'h:mm a MM/dd/yyyy'}}</p>
    </div>
  </div>
</div>
