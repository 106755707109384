import { EFightEventStatus } from '../fight-event.model';

export interface TvEventModelData {
    id: string;
    name: string;
    video: {
        id: string;
        name: string;
        status: EFightEventStatus;
        streamUrl: string;
        thumbnail: string;
        videoUrl: string;
    };
    isLive?: true;
}

export interface FightEventTvModel extends TvEventModelData {}

export class FightEventTvModel {
    videoSrc(token: string | undefined): string | null {
        const value = this.video.streamUrl || this.video.videoUrl;

        return value && token?.length ? `${value}?token=${token}` : value ? value : null;
    }
}
