import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reductionOfNumbers',
})
export class ReductionOfNumbersPipe implements PipeTransform {
    transform(value: number | string): unknown {
        switch (true) {
            case +value < 1000:
                return +value.toString();
            case +value < 1000000:
                return (+value / 1000).toFixed(1) + 'K';
            default:
                return (+value / 1000000).toFixed(1) + 'M';
        }
    }
}
