import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule, Optional } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { first } from 'rxjs';
import { ApiModule } from '../api/api.module';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import localeEN from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';

import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { AppService } from './app.service';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/guards/auth.guard';
import { AppMainConfigModule } from './config/app-main-config.module';
import { AppMainConfigService } from './config/app-main-config.service';
import { SeoSetUpService } from './config/seo/seo-set-up.service';
import { CRAWLER_AGENTS, VIEWER, ViewerType } from './constants';
import { ConsoleLogger } from './logger/console-logger';
import { AppLogger } from './logger/logger';
import { SERVER_LOGGER } from './server/server-logger.token';
import { FcaAuthService } from './services/fca-auth.service';
import { FcaFightEventsService } from './services/fca-fight-events.service';
import { FcaImagesService } from './services/fca-images.service';
import { ContactsComponent } from './shared/components/contacts/contacts.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { TermsComponent } from './shared/components/terms/terms.component';
import { cookieConfig } from './shared/config/cookie-consent.config';
import { FcaErrorHandler } from './shared/error/fca-error-handler';
import { AuthTokenInterceptor } from './shared/interceptors/auth-token.interceptor';
import { PreloadService } from './shared/services/preload.service';
import { MemoryStorage } from './shared/services/storage/memory-storage.service';
import { SessionStorage } from './shared/services/storage/session-storage.service';
import { FcaStoreModule } from './store/store.module';

import { LanguageService } from './services/language.service';
import { PlatformService } from './shared/services/platform.service';
import { WINDOW } from './shared/tokens/window.token';

import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { SharedModule } from './shared/shared.module';
import { FcaStoreService } from './store/store.service';
import { TranslocoTranslateModule } from './transloco-translate.module';
import { UikitModule } from './uikit/uikit.module';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

registerLocaleData(localeEN, 'en');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeRu, 'ru');

@NgModule({
    imports: [
        NgcCookieConsentModule.forRoot(cookieConfig),
        AppMainConfigModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'spartacus-mma' }),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FcaStoreModule,
        StoreModule.forRoot({}),
        AuthModule,
        SharedModule,
        ApiModule,
        TranslocoTranslateModule,
        UikitModule,
        ReactiveFormsModule,
        provideFirebaseApp(() =>
          initializeApp(environment.firebaseConfig)
        ),
        provideMessaging(() => getMessaging()),
    ],
    providers: [
        AppService,
        FcaFightEventsService,
        AuthGuard,
        LanguageService,
        PlatformService,
        FcaAuthService,
        FcaImagesService,
        SeoSetUpService,
        PreloadService,
        AppMainConfigService,
        FcaErrorHandler,
        MemoryStorage,
        SessionStorage,
        SsrCookieService,
        {
            provide: ErrorHandler,
            useClass: FcaErrorHandler,
        },
        {
            provide: WINDOW,
            useFactory: (platformService: PlatformService) => {
                return platformService.isBrowser() ? window : null;
            },
            deps: [PlatformService],
        },
        {
            provide: AppLogger,
            useFactory: (configService: AppMainConfigService, logger?: AppLogger) => {
                // Sentry Logger for production ?
                return logger || (environment.production ? new ConsoleLogger() : new ConsoleLogger());
            },
            deps: [AppMainConfigService, [new Optional(), SERVER_LOGGER]],
        },
        {
            provide: VIEWER,
            useFactory: (platformService: PlatformService, req: any): ViewerType => {
                if (platformService.isBrowser()) {
                    return 'user';
                }
                const userAgent = (req?.get('user-agent') || '').toLowerCase();
                const isCrawler = CRAWLER_AGENTS.some((crawlerAgent) => userAgent.indexOf(crawlerAgent) !== -1);
                return isCrawler ? 'bot' : 'user';
            },
            deps: [PlatformService, [new Optional(), REQUEST]],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: () => {
                const storage = JSON.parse(localStorage.getItem('__fca_app_storage__') || '');
                return storage.settings.language || 'en';
            },
        },
    ],
    declarations: [
        AppComponent,
        PrivacyComponent,
        TermsComponent,
        ContactsComponent,
        PageNotFoundComponent,
        LoaderComponent,
    ],
    bootstrap: [AppComponent],
    exports: [LoaderComponent],
})
export class AppModule {}
