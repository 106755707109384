<div class="header-wrapper" [class.scrolled]="scrolledHeader">
    <header class="header web" *ngIf="!(isForMobile$ | async) && layoutState?.showHeader">
        <div class="h-left">
            <a class="app-header-logo-link center" href="" [routerLink]="lang + ''">
                <img class="app-header-logo" src="assets/images/new-header-logo.svg" alt="Spartacus MMA" />
            </a>
        </div>
        <div class="flex align-items-center">
            <app-subscription-status></app-subscription-status>
            <app-user-balance></app-user-balance>
            <login-status-item></login-status-item>
            <app-localization></app-localization>
        </div>
    </header>
</div>
<header *ngIf="(isForMobile$ | async) && layoutState?.showHeader" class="mobile">
    <div class="content flex justify-content__space-between align-items-center">
        <button class="toggle-btn center" (click)="burgerMenuClickHandler()">
            <img *ngIf="!sideMenuState.isOpen" src="assets/images/burger-menu.svg" alt="" />
            <img *ngIf="sideMenuState.isOpen" class="close-icon" src="assets/images/close-mobile-menu.png" alt="" />
        </button>
        <a class="app-header-logo-link" href="" [routerLink]="lang + ''">
            <img class="app-header-logo" src="assets/images/new-header-logo.svg" alt="Spartacus MMA" />
        </a>

        <app-localization></app-localization>
    </div>

    <div class="mobile-menu">
        <ul *ngIf="layoutState?.showMainContent" class="mobile-nav-list flex-column align-items-start">
            <ng-container *ngFor="let item of routerConfig">
                <li>
                    <a
                        (click)="closeMenu()"
                        [routerLink]="lang + item?.path"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        {{ getTranslate(item.label) | async }}
                    </a>
                </li>
            </ng-container>
        </ul>
        <div (click)="closeMenu()" class="mobile-menu-user-item subscription-status">
            <app-subscription-status></app-subscription-status>
        </div>
        <div class="mobile-menu-user-item">
            <login-status-item (menuItemClick)="sideMenuState.isOpen = false; _cdr.detectChanges()"></login-status-item>
        </div>
    </div>
</header>
