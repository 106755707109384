import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OffsetPagination } from '../../../app/shared/interfaces/offset-pagination.interface';
import { PaginationInterface } from '../../../app/shared/models/shared/pagination.interface';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { AddPostParams } from './params/add-post.params';
import { ChangePostParams } from './params/change-post.params';
import { PostListItemApiResponse } from './response/post-list-item-api.response';

@Injectable({
    providedIn: 'root',
})
export class PostApiService extends FcaApiServiceBase {
    getPostList(paramsData: OffsetPagination): Observable<PaginationInterface<PostListItemApiResponse>> {
        const params = new HttpParams().appendAll({ ...paramsData });
        const url = [this.postUrl, 'posts'].join('/');
        return this.http.get<PaginationInterface<PostListItemApiResponse>>(url, { params });
    }

    addPost(body: AddPostParams) {
        const url = [this.postUrl, 'posts'].join('/');
        return this.http.post<any>(url, body);
    }

    getPostListByUserId(
        paramsData: OffsetPagination,
        userId: number
    ): Observable<PaginationInterface<PostListItemApiResponse>> {
        const params = new HttpParams().appendAll({ ...paramsData });
        const url = [this.postUrl, 'posts', userId, 'list'].join('/');
        return this.http.get<PaginationInterface<PostListItemApiResponse>>(url, { params });
    }

    updatePost(postId: string, body: ChangePostParams) {
        const url = [this.postUrl, `posts/${postId}`].join('/');
        return this.http.patch<any>(url, body);
    }

    getPostById(postId: string) {
        const url = [this.postUrl, `posts/${postId}`].join('/');
        return this.http.get<any>(url);
    }

    hidePost(postId: string) {
        const url = [this.postUrl, `posts/${postId}/hide`].join('/');
        return this.http.delete<any>(url);
    }
}
