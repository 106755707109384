import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationExtras,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthUserModel } from '../../models/users/auth-user/auth-user.model';
import { FcaAuthService } from '../../services/fca-auth.service';
import { FcaStoreService } from '../../store/store.service';

@Injectable()
export class AuthGuard implements CanActivate {
    cookieService = inject(SsrCookieService);

    constructor(
        private authService: FcaAuthService,
        private _router: Router,
        private readonly storeService: FcaStoreService
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.storeService.getUser().pipe(
            map((isLoggedIn: AuthUserModel | null) => {
                if (isLoggedIn) {
                    return true;
                }
                const authUserModelData = this.cookieService.get('authUserModelData');
                if (authUserModelData) {
                    return true;
                }
                const extras: NavigationExtras = {
                    queryParams: {
                        redirectUrl: state.url,
                    },
                };
                this._router.navigate(['/auth/login'], extras).then();
                return false;
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
