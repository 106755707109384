export const environment = {
    production: false,
    url: 'https://web.dev.spartacus-mma.com',
    webUrl: 'https://web.dev.spartacus-mma.com',
    apiUrl: 'https://api.dev.spartacus-mma.com/api/v1',
    chatUrl: 'https://chat.dev.spartacus-mma.com',
    kickVideoUrl: 'https://kicks.dev.spartacus-mma.com/api',
    bloodsVideoUrl: 'https://bloods.dev.spartacus-mma.com/api',
    metricsUrl: 'https://metrics.dev.spartacus-mma.com',
    otherEventsUrl: 'https://videos.dev.spartacus-mma.com/api',
    i18nHost: 'https://web.dev.spartacus-mma.com',
    googleClientId: '1000866745065-csoe6lbgr3065o4nfhihhmhiaen94n7i.apps.googleusercontent.com',
    appleClientId: 'com.spartacus.mma.sevice',
    appleRedirectURI: 'https://dev.spartacus-mma.com',
    fbAppId: '718084042562191',
    paymentRedirectUri: 'https://dev.spartacus-mma.com',
    qaUrl: 'https://qa.dev.spartacus-mma.com/api',
    discoveryUrl: 'https://discovery.dev.spartacus-mma.com',
    postUrl: 'https://posts.dev.spartacus-mma.com/api/v1',
    firebaseConfig: {
      apiKey: "AIzaSyCIdbbKksGAki83y-qx4q4zfHiLjf2Nonk",
      authDomain: "fca-mma.firebaseapp.com",
      projectId: "fca-mma",
      storageBucket: "fca-mma.appspot.com",
      messagingSenderId: "575988358951",
      appId: "1:575988358951:web:08144c0f1d7c37cb6d5302",
      measurementId: "G-3268G6BZ99",
      vapidKey: "BLoenQNjjbkoAqnBWbD2Y9D17KwUeKUpB_XZuQcwZWmVaTZD2sHJVEfmLoV-76htG1nERCKQkbRD2YYWp6pSEdc"
    }
};
