import { ProfileByIdResponse } from '../../../../api/fca/profiles-public/response/profile-by-id.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { GetProfilesPublicModelData } from './get-profiles-public.model';

export class GetProfilesPublicMapper implements ModelDataMapper<ProfileByIdResponse, GetProfilesPublicModelData> {
    mapData(raw: ProfileByIdResponse): GetProfilesPublicModelData {
        return {
            id: raw.id,
            roleName: raw.roleName,
            socials: raw.socials,
            firstName: raw.firstName,
            lastName: raw.lastName,
            nickname: raw.nickname,
            description: raw.description,
            backgroundImage: raw.backgroundImage,
            avatar: raw.avatar,
            thumbnailAvatar: raw.thumbnailAvatar,
            isPremium: raw.isPremium,
            rating: raw.rating,
            followers: raw.followers,
            videos: raw.videos,
            views: raw.views,
            isFollowed: raw.isFollowed,
            hasKicks: raw.hasKicks,
            hasVideos: raw.hasVideos,
            hasQuestions: raw.hasQuestions,
            hasBlog: raw.hasBlog,
            hasStream: raw.hasStream,
            donateRate: raw.donateRate,
            paidSubscribers: raw.paidSubscribers,
            hasStreams: raw.hasStreams,
            hasPosts: raw.hasPosts,
            verified: raw.verified,
            isContentCreator: raw.isContentCreator,
        };
    }
}
