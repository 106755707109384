import { NgModule } from '@angular/core';
import { CodeInputModule } from 'angular-code-input';
import { SharedModule } from '../shared/shared.module';
import { UikitModule } from '../uikit/uikit.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
    imports: [
        AuthRoutingModule,
        UikitModule,
        CodeInputModule.forRoot({
            codeLength: 6,
            isCharsCode: false,
            initialFocusField: 0,
        }),
        SharedModule,
    ],
    declarations: [LoginComponent, RegisterComponent, AuthComponent],
    bootstrap: [AuthComponent],
})
export class AuthModule {}
