<div class='input-form-field' *transloco='let t'>
  <div
    #messageContent
    class='input-form-field-text scroll-bar'
    contenteditable='true'
    (keydown.enter)='onEnterKeyDown($event)'
    [attr.placeholder]="t('dashboard-event-tab-live-chat-enter-message')"
  ></div>
  <button class='input-form-submit center' (click)='onSendMessage(messageContent)'>
    <img src='assets/images/ic_send.svg' alt='send'>
  </button>
</div>
