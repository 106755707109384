import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EUserRole } from '../../enums/user-role.enum';
import { UntilDestroy } from '../../operators/until-destroy.operator';

export interface RoleItem {
    role: string;
    icon: string;
    selected: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-roles-list',
    templateUrl: './roles-list.component.html',
    styleUrls: ['./roles-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesListComponent implements OnInit {
    @Input() hasError: boolean;
    @Output() roleChanged = new EventEmitter<RoleItem>();
    roles: RoleItem[] = [];
    readonly rolesIcons = {
        [EUserRole.FIGHTER]: '\ue903',
        [EUserRole.ARENA]: '\ue900',
        [EUserRole.JUDGE]: '\ue905',
        [EUserRole.MANAGER]: '\ue90b',
        [EUserRole.FAN]: '\ue902',
        [EUserRole.COMMENTATOR]: '\ue90e',
        [EUserRole.ADMIN]: '',
    };

    constructor() {}

    ngOnInit(): void {
        this.roles = Object.values(EUserRole).map((role) => {
            return {
                role: `index-body-roles-${role.toLowerCase()}`,
                // @ts-ignore
                icon: this.rolesIcons[role],
                selected: false,
            };
        });
    }

    onSelectListItem(item: RoleItem): void {
        this.roles.forEach((r) => (r.selected = false));
        item.selected = true;
        this.roleChanged.emit(item);
    }
}
