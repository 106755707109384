import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NewCommentParams } from '../../../../api/fca/bloods-video/params/new-comment.params';
import { FightEventCommentModel } from '../../../models/fight-event/fight-event-comments/fight-event-comment.model';
import { FcaFightEventCommentsService } from '../../../services/fca-fight-event-comments.service';
import { FcaStoreService } from '../../../store/store.service';

@Component({
    selector: 'app-comment-item',
    templateUrl: './comment-item.component.html',
    styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent {
    @Input() data: FightEventCommentModel;
    @Input() isInner: boolean = false;
    @Input() currentUserId: number;
    @Output() onSend = new EventEmitter<NewCommentParams>();
    @ViewChild('commentThread') commentThread: CdkAccordionItem;
    replyHidden = true;

    constructor(private _cdr: ChangeDetectorRef) {}

    get avatarUrl(): string {
        // @ts-ignore
        return this.data.user?.logo || this.data.user?.thumbnailAvatar || '/assets/images/avatar_placeholder.svg';
    }

    get userName(): string {
        return this.data.user?.nickName || this.data.user?.firstName + ' ' + this.data.user?.lastName;
    }

    onSendReply(event: NewCommentParams): void {
        this.replyHidden = true;
        this.onSend.emit({ parentId: this.data.id, text: event.text });
    }
}
