import { KickInfoByIdResponse } from '../../../../api/fca/kicks-video/response/kick-info-by-id.response';
import { ModelDataMapper } from '../../../shared/interfaces/model-mapper.interface';
import { KicksInfoByIdModel } from './kicks-info-by-id.model';

export class KicksInfoByIdMapper implements ModelDataMapper<KickInfoByIdResponse, KicksInfoByIdModel> {
    mapData(raw: KickInfoByIdResponse): KicksInfoByIdModel {
        return {
            id: raw.id,
            description: raw.description,
            href: raw.href,
            thumbnailHref: raw.thumbnailHref,
            likes: raw.likes,
            isLiked: raw.isLiked,
            comments: raw.comments,
            views: raw.views,
            users: raw.users,
            relation: raw.relation,
            tags: raw.tags,
            user: raw.user,
        };
    }
}
