import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharesPopupModule } from '../../models/shared/shares-popup.module';

@Component({
    selector: 'app-shared-popup',
    templateUrl: './shared-popup.component.html',
    styleUrls: ['./shared-popup.component.scss'],
})
export class SharedPopupComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SharesPopupModule,
        public dialogRef: MatDialogRef<SharedPopupComponent>
    ) {}

    ngOnInit(): void {}
}
