import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { PaginationSelectResult } from '../../../app/shared/interfaces/pagination-select.interface';
import { objectToQuery } from '../../helpers/object-to-query.helper';
import { FcaApiServiceBase } from '../fca-api-service-base.service';
import { GetFightEventCommentsParams } from './params/get-fight-event-comments.params';
import { PutLikeDislikeParams } from './params/put-like-dislike.params';
import { PutNewCommentParams } from './params/put-new-comment.params';
import { CommentResponse } from './response/comment.response';

@Injectable()
export class FcaCommentsApiService extends FcaApiServiceBase {
    fightEventComments(params: GetFightEventCommentsParams): Observable<PaginationSelectResult<CommentResponse>> {
        const query = objectToQuery(params);
        const url = [this.apiUrl, 'comments', 'fight-event', query].join('/');

        return this.http
            .get<PaginationSelectResult<CommentResponse>>(url, { headers: this.defaultHeaders })
            .pipe(shareReplay(1));
    }

    putComment(params: PutNewCommentParams): Observable<CommentResponse> {
        const url = [this.apiUrl, 'comments', 'fight-event'].join('/');

        return this.http.post<CommentResponse>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }

    putLikeDislike(params: PutLikeDislikeParams): Observable<void> {
        const url = [this.apiUrl, 'comments', 'like-dislike'].join('/');

        return this.http.post<void>(url, params, { headers: this.defaultHeaders }).pipe(shareReplay(1));
    }
}
