import { Component, Input } from '@angular/core';

export const enum EFlatButtonType {
    primary = 'button-primary',
    secondary = 'button-secondary',
    social_facebook = 'button-social button-social-facebook',
    social_apple = 'button-social button-social-apple',
    social_google = 'button-social button-social-google',
}

@Component({
    selector: 'app-flat-button',
    templateUrl: './flat-button.component.html',
    styleUrls: ['./flat-button.component.scss'],
})
export class FlatButtonComponent {
    @Input() disabled: boolean = false;
    @Input() icon?: FlatButtonIconData | undefined;
    @Input() text?: string | undefined;
    @Input() type: EFlatButtonType | undefined;
    @Input() isDisabled?: boolean;
    defaultType = EFlatButtonType.primary;
    defaultText = 'Button';

    constructor() {}

    onClick(): void {}
}

export interface FlatButtonIconData {
    assetUrl: string;
    alt?: string;
    title?: string;
    width?: number;
    height?: number;
}

export interface FlatButtonData {
    name?: string;
    type?: EFlatButtonType;
    icon?: FlatButtonIconData;
}
