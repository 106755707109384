import { CoinPaymentSystemEnum } from '../../../shared/enums/coin-payment-system.enum';
import { CurrencyEnum } from '../../../shared/enums/currency.enum';

export class CoinsListItemModelData {
    id: string;
    name: string;
    count: number;
    stripeProductId: string;
    currency: CurrencyEnum;
    paymentSystem: CoinPaymentSystemEnum;
    originalPrice: number;
    priceId: string;
    priceForDisplay: number;
    priceForPayment: number;
}
export interface CoinsListItemModel extends CoinsListItemModelData {}
export class CoinsListItemModel {
    getMainPartOfCoin(): number {
        switch (this.count) {
            case 100:
                return 100;
            case 300:
                return 250;
            case 1400:
                return 1250;
            case 3000:
                return 2500;
            case 7500:
                return 5000;
        }
        return this.count;
    }
}
