import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewMediaModel } from '../../models/shared/view-media.model';
import { UntilDestroy } from '../../operators/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-view-media',
    templateUrl: './view-media.component.html',
    styleUrls: ['./view-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMediaComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ViewMediaModel,
        public dialogRef: MatDialogRef<ViewMediaComponent>
    ) {}
}
